import { React, useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import _ from "lodash";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
// @material-ui/icons components
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
// core components src
import GenericButton from "components/Buttons/GenericButton";
import TableBackdrop from "components/Backdrops/TableBackdrop/TableBackdrop";
import ApproveDialog from "components/Dialogs/ApproveDialog";
import RejectDialog from "components/Dialogs/RejectDialog";
import { approveOpportunityCandidateAction, rejectOpportunityCandidateAction } from "redux/actions/opportunitiesAction";
import { getDataSetByNameAction } from "redux/actions/datasetsAction";
import { DATASET } from "utils/consts";

import componentStylesGeneric from "assets/theme/views/admin/generic.js";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function CandidatesListSection(props) {
  const theme = useTheme();
  const classes = useStylesGeneric();
  const [candidateId, setCandidateId] = useState();
  const [overlappingInternship, setOverlappingInternship] = useState(false);

  //Dialogs Logic
  const DIALOG = { SHOW: true, HIDE: false };
  const [dialogs, setDialogs] = useState({
    approveModal: false,
    rejectModal: false,
    updateModal: false,
  });

  useEffect(() => {
    if (
      props.dataSetState[DATASET.OPPORTUNITY_CANDIDATE_REJECT_MOTIVES] === undefined ||
      props.dataSetState[DATASET.OPPORTUNITY_CANDIDATE_REJECT_MOTIVES]?.length === 0
    ) {
      props.getDataSetByName(DATASET.OPPORTUNITY_CANDIDATE_REJECT_MOTIVES);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showHideDialog = (dialog, op) => {
    let dialogs_temp = _.mapValues(dialogs, () => false);

    dialogs_temp[dialog] = op;

    setDialogs(dialogs_temp);
  };

  function handleApproveModal(candidateId, overlappingInternship) {
    setCandidateId(candidateId);
    setOverlappingInternship(overlappingInternship);
    showHideDialog("approveModal", DIALOG.SHOW);
  }

  function handleRejectModal(candidateId) {
    setCandidateId(candidateId);
    showHideDialog("rejectModal", DIALOG.SHOW);
  }

  const onSubmitApproveDialog = () => {
    const approveFilter = {
      candidateId: candidateId,
      opportunityId: props.opportunityId,
    };

    props.approveOpportunityCandidate(approveFilter, props.opportunitiesState.isOpportunityApplication);
  };

  const onSubmitRejectDialog = (rejectMotiveId, rejectMotiveObservations) => {
    let filter = {
      candidateId,
      opportunityId: props.opportunityId,
      rejectMotiveId,
      rejectMotiveObservations,
    };

    props.rejectOpportunityCandidate(filter, props.opportunitiesState.isOpportunityApplication);
  };

  const tableHead = ["Nome", "Nº BI", "Estado", "Data de Candidatura", "", ""];

  return (
    <>
      <TableContainer>
        <Box component={Table} alignItems="center" marginBottom="0!important">
          <TableHead>
            <TableRow>
              {tableHead.map((prop, key) => (
                <TableCell
                  key={key}
                  classes={{
                    root: classes.tableCellRoot + " " + classes.tableCellRootHead,
                  }}
                >
                  {prop}
                </TableCell>
              ))}
              <TableCell
                key="detailsCol"
                classes={{
                  root:
                    classes.tableCellRoot + " " + classes.tableCellRootHead + " " + classes.tableCellHeadStickyRight,
                }}
              >
                Detalhes
              </TableCell>
            </TableRow>
          </TableHead>
          {props.opportunitiesState?.result?.data !== undefined &&
            props.opportunitiesState?.result?.data?.length > 0 && (
              <TableBody>
                {props.opportunitiesState?.result?.data?.map((prop, key) => (
                  <TableRow key={key}>
                    <TableCell classes={{ root: classes.tableCellRoot }}>{prop.userName}</TableCell>
                    <TableCell classes={{ root: classes.tableCellRoot }}>{prop.identityCard}</TableCell>
                    <TableCell classes={{ root: classes.tableCellRoot }}>{prop.candidateStateDescription}</TableCell>
                    <TableCell classes={{ root: classes.tableCellRoot }}>{prop.applicationDate}</TableCell>
                    <TableCell classes={{ root: classes.tableCellRoot }}>
                      <Box alignItems="center" display="flex">
                        <Box alignItems="right">
                          <GenericButton
                            typeSubmit={false}
                            color="tertiary"
                            size="small"
                            disabled={prop.candidateState !== 1}
                            onClick={() => handleRejectModal(prop.alternateKey)}
                          >
                            Rejeitar
                          </GenericButton>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell classes={{ root: classes.tableCellRoot }}>
                      <Box alignItems="center" display="flex">
                        <Box alignItems="left">
                          <GenericButton
                            typeSubmit={false}
                            color="tertiary"
                            size="small"
                            disabled={prop.candidateState !== 1}
                            onClick={() =>
                              handleApproveModal(prop.alternateKey, prop.papeInternshipOverlapsOthersOfUser)
                            }
                          >
                            Recrutar
                          </GenericButton>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell classes={{ root: classes.tableCellRoot + " " + classes.tableCellBodyStickyRight }}>
                      <Tooltip title="Detalhes">
                        <IconButton
                          aria-label="details"
                          component={Link}
                          to={`/admin/candidate-details/${prop.alternateKey}`}
                        >
                          <Box
                            component={ArrowForwardIosIcon}
                            width="12px!important"
                            height="12px!important"
                            color={theme.palette.primary.main}
                          />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            )}
        </Box>

        {props.opportunitiesState?.result?.data?.length === 0 &&
          !props.opportunitiesState?.opportunityUsers_loading && (
            <Box className={classes.noResultsInfoInTable}>Não existem resultados disponíveis.</Box>
          )}
      </TableContainer>

      <TableBackdrop open={props.opportunitiesState?.opportunityUsers_loading} />

      <ApproveDialog
        name="approve-dialog"
        open={dialogs.approveModal}
        onSubmitApproveDialog={onSubmitApproveDialog}
        title={
          overlappingInternship
            ? "O período previsto de estágio da oferta atual sobrepõe o de um estágio profissional já atribuído ao candidato. Tem a certeza que pretende recrutar o candidato?"
            : "Confirma que pretende recrutar o candidato?"
        }
        showErrorIcon={overlappingInternship}
        isLoading={props.opportunitiesState.approveCandidate_loading}
        onClose={() => showHideDialog("approveModal", DIALOG.HIDE)}
      />

      <RejectDialog
        name="reject-dialog"
        open={dialogs.rejectModal}
        onSubmitRejectDialog={onSubmitRejectDialog}
        title={"Pretende rejeitar o candidato?"}
        rejectionMotivesList={
          props.dataSetState[DATASET.OPPORTUNITY_CANDIDATE_REJECT_MOTIVES] !== undefined
            ? props.dataSetState[DATASET.OPPORTUNITY_CANDIDATE_REJECT_MOTIVES]
            : []
        }
        isLoading={props.opportunitiesState.rejectCandidate_loading}
        onClose={() => showHideDialog("rejectModal", DIALOG.HIDE)}
      />
    </>
  );
}
const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  approveOpportunityCandidate: (formData, isOpportunityApplication) =>
    dispatch(approveOpportunityCandidateAction(formData, isOpportunityApplication)),
  rejectOpportunityCandidate: (formData, isOpportunityApplication) =>
    dispatch(rejectOpportunityCandidateAction(formData, isOpportunityApplication)),
  getDataSetByName: (name, addEmptyField) => dispatch(getDataSetByNameAction(name, addEmptyField)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CandidatesListSection);
