import { React, useState } from "react";
import { connect } from "react-redux";
import { useForm, Controller, FormProvider } from "react-hook-form";
import { useIntl } from "react-intl";
import "moment/locale/pt";
import clsx from "clsx";
//@material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import { useTheme } from "@material-ui/core/styles";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputAdornment from "@material-ui/core/InputAdornment";
import OutlinedInput from "@material-ui/core/OutlinedInput";
//@material-ui/icons
//styles and customs
import { REGEX_PATTERNS, UNITS, MONETARYUNITPLACEHOLDER, MONETARY_DECIMAL_SEPARATOR } from "utils/consts";
import { messages, labels } from "resources/resources";
import UpdatePaymentFeedbackReportDialog from "../Dialogs/UpdatePaymentFeedbackReportDialog";
import { validateFeedbackReportAction } from "redux/actions/opportunitiesAction";

import componentStylesGeneric from "assets/theme/views/admin/generic.js";
import GenericButton from "components/Buttons/GenericButton";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function UpdatePaymentFeedbackReportModal(props) {
  const classes = useStylesGeneric();
  const theme = useTheme();
  const intl = useIntl();
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [formattedValue, setFormattedValue] = useState();
  const [filter, setFilter] = useState();

  const methods = useForm({
    mode: "onChange",
  });

  const handleOpenConfirmationDialog = () => {
    setShowConfirmationDialog(true);
  };

  const handleCloseConfirmationDialog = () => {
    setShowConfirmationDialog(false);
  };

  const onValChange = (value, onChange) => {
    value = value?.replace(/\./g, "");
    //TODO - Check better way to format currency on same input
    if (value && value.charAt(value.length - 1) === ",") setFormattedValue(value);
    else {
      if (value) {
        value = value?.replace(/,/g, ".");
        if (intl.formatNumber(value) !== "NaN") setFormattedValue(intl.formatNumber(value));
        else setFormattedValue(value);
      } else {
        setFormattedValue("");
      }
    }
    value = value?.replace(/\./g, ",");
    onChange(value);
  };

  const onSubmit = (data) => {
    let filter = {
      feedbackReportId: props?.feedbackReportId,
      action: true,
      amountPaid: data.amountPaid?.replace(MONETARY_DECIMAL_SEPARATOR.WEB, MONETARY_DECIMAL_SEPARATOR.API),
    };
    setFilter(filter);

    handleOpenConfirmationDialog();
  };

  const handleSubmit = () => {
    handleCloseConfirmationDialog();

    props.validateFeedbackReport(filter, () => {
      props.handleClose();
      props.handleRefreshPage();
    });
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Container>
            <Box className={classes.modalHeader}>Atualizar informação financeira do estágio</Box>
            <Box className={classes.subHeader}>
              Indique o valor transferido para a empresa corresponte ao período de estágio:{" "}
              {props.feedbackReportPeriodDates}
            </Box>
            <Box marginTop="30px">
              <Grid container>
                <Grid item xs={3} md={3} />

                <Grid item xs={6} md={6}>
                  <FormGroup>
                    <FormLabel>Valor transferido</FormLabel>
                    <Controller
                      name="amountPaid"
                      control={methods.control}
                      component={Box}
                      defaultValue=""
                      rules={{
                        required: {
                          value: true,
                          message: intl.formatMessage(messages.Message_Generic_MandatoryField),
                        },
                        pattern: {
                          value: REGEX_PATTERNS.MONETARY,
                          message: intl.formatMessage(messages.Message_Generic_InvalidField),
                        },
                      }}
                      render={({ field: { onChange, name } }) => (
                        <OutlinedInput
                          name={name}
                          value={formattedValue}
                          disabled={false}
                          onChange={(e) => {
                            onValChange(e.target.value, onChange);
                          }}
                          fullWidth
                          autoComplete="off"
                          type="text"
                          placeholder={MONETARYUNITPLACEHOLDER}
                          endAdornment={
                            <InputAdornment disableTypography={true} position="end">
                              {UNITS.MONETARY}
                            </InputAdornment>
                          }
                          classes={{
                            input: classes.monetaryFields,
                            notchedOutline: clsx({
                              [classes.borderWarning]: methods.formState.errors["amountPaid"] !== undefined,
                            }),
                          }}
                        />
                      )}
                    />
                    {methods.formState.errors["amountPaid"] !== undefined && (
                      <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                        {methods.formState.errors["amountPaid"].message}
                      </FormHelperText>
                    )}
                  </FormGroup>
                </Grid>
                <Grid item xs={3} md={3} />
              </Grid>
            </Box>
          </Container>

          <Box textAlign="center">
            <GenericButton
              typeSubmit={true}
              color="primary"
              loading={props.opportunitiesState.validateFeedbackReportLoading}
            >
              {intl.formatMessage(labels.Label_Generic_Submit_Button)}
            </GenericButton>
          </Box>
        </form>
      </FormProvider>

      <UpdatePaymentFeedbackReportDialog
        name="update-payment-dialog"
        open={showConfirmationDialog}
        feedbackReportId={props.feedbackReportId}
        handleSubmit={handleSubmit}
        onClose={handleCloseConfirmationDialog}
      />
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  validateFeedbackReport: (formData, handleClose) => dispatch(validateFeedbackReportAction(formData, handleClose)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdatePaymentFeedbackReportModal);
