import { React, useState } from "react";
// import { useIntl } from "react-intl";
import { connect } from "react-redux";
import clsx from "clsx";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormLabel from "@material-ui/core/FormLabel";
//import icons
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import FormatAlignLeftIcon from "@material-ui/icons/FormatAlignLeft";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import componentStylesGeneric from "assets/theme/views/admin/generic.js";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function ComplementaryDataItemReadOnly(props) {
  const classes = useStylesGeneric();
  // const intl = useIntl();
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      <Card classes={{ root: classes.cardRoot }}>
        <CardHeader
          classes={{ title: classes.innerCardHeaderTitle, action: classes.cardHeaderAction }}
          avatar={
            <Box display="flex">
              <Box className={classes.cardHeaderAvatar}>{props.index + 1}</Box>
              <Box marginLeft="20px">
                {props.complemData.answerTypeId === 1 ? (
                  <FormatListBulletedIcon className={classes.cardHeaderIcon} />
                ) : (
                  <FormatAlignLeftIcon className={classes.cardHeaderIcon} />
                )}
              </Box>
            </Box>
          }
          title={props.complemData.title}
          action={
            <IconButton
              className={clsx(classes.cardExpand, {
                [classes.cardExpandOpen]: expanded,
              })}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show-more"
            >
              <ExpandMoreIcon />
            </IconButton>
          }
        ></CardHeader>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Grid container>
              <Grid item xs={12} md={12}>
                <FormLabel>Texto</FormLabel>
                <OutlinedInput
                  name={`complementaryDataList[${props.complemData.id}].questionText`}
                  value={props.complemData.question}
                  fullWidth
                  multiline
                  rows="3"
                  disabled={true}
                  autoComplete="off"
                  type="text"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name={`complementaryDataList[${props.complemData.id}].isRequired`}
                      checked={props.complemData.isRequired}
                      disabled={true}
                      color="default"
                      inputProps={{ "aria-label": "checkbox with default color" }}
                    />
                  }
                  label={<FormLabel>Obrigatório</FormLabel>}
                />
              </Grid>
              <Grid item xs={12} md={6}></Grid>
              {props.complemData.answerTypeId === 1 && (
                <Grid item xs={12} md={6}>
                  <FormLabel>Opção</FormLabel>
                  <OutlinedInput
                    name={`complementaryDataList[${props.complemData.id}].answer`}
                    value={props.complemData.answerDatasetName}
                    fullWidth
                    disabled={true}
                    autoComplete="off"
                    type="text"
                  />
                </Grid>
              )}
            </Grid>
          </CardContent>
        </Collapse>
      </Card>
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ComplementaryDataItemReadOnly);
