import axios from "axios";
import { INTERNAL_API_URL } from "../index";

export const CREATE_SUBPROGRAM_URL = "SubPrograms/create";
export const GET_FILTERED_SUBPROGRAMS_URL = "SubPrograms/get-filtered-subprograms";
export const GET_SUBPROGRAM_DETAILS_URL = "SubPrograms/get-subprogram-details?subprogramNumber=";
export const UPDATE_SUBPROGRAM_URL = "SubPrograms/update";
export const UPDATE_SUBPROGRAM_STATE_URL = "SubPrograms/update-subprogram-state";

export function createSubprogram(subprogram) {
  return axios.post(INTERNAL_API_URL + CREATE_SUBPROGRAM_URL, { subprogram });
}

export function getFilteredSubprograms(formData) {
  return axios.post(INTERNAL_API_URL + GET_FILTERED_SUBPROGRAMS_URL, formData);
}

export function getSubprogramDetails(data) {
  return axios.get(INTERNAL_API_URL + GET_SUBPROGRAM_DETAILS_URL + data);
}

export function updateSubprogram(subprogram) {
  return axios.post(INTERNAL_API_URL + UPDATE_SUBPROGRAM_URL, { subprogram });
}

export function updateSubprogramState(data) {
  return axios.post(INTERNAL_API_URL + UPDATE_SUBPROGRAM_STATE_URL, data);
}
