import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useState } from "react";

export const QueryProvider = ({ children }) => {
  const [queryClient] = useState(() => {
    return new QueryClient();
  });

  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
};
