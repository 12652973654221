import {
  getDataSetByName,
  getPrograms,
  getSubprogramStates,
  getDepartments,
  getCategories,
  getActiveCategories,
  getInactiveCategories,
  getWarehousesByProvince,
  getDocuments,
  getConditions,
  getNextApplicationStates,
  getSubprograms,
  getBackOfficeUsers,
  getBackOfficeRoles,
  getShapePrograms,
  getShapeSubsites,
  getLoanStates,
  getNotAssignedActiveCategories,
  getSubprogramByProgramId,
  getPredefinedQuestions,
  getAvailableInternshipOpportunities,
  getGovernmentPrograms
} from "crud/datasets.crud";

import { DATASET_ACTIONS } from "utils/actionsConsts";
import { DATASET } from "utils/consts";

export const clearDataSetByNameAction = (name) => async (dispatch) => {
  dispatch({
    type: DATASET_ACTIONS.CLEAR_DATASET_BY_NAME,
    result: true,
    dataSetName: name,
  });
};

export const getDataSetByNameAction = (name, parentKeyValue) => async (dispatch) => {
  getDataSetByName(name, parentKeyValue)
    .then((res) => {
      dispatch({
        type: DATASET_ACTIONS.GET_DATASET_BY_NAME,
        result: true,
        payload: res.data.map((item) => {
          return {
            code: item.id,
            label: item.value,
            keyValue: item.keyValue,
          };
        }),
        dataSetName: name,
      });
    })
    .catch(() => {
      dispatch({
        type: DATASET_ACTIONS.GET_DATASET_BY_NAME,
        result: false,
        payload: [],
        dataSetName: name,
      });
    });
};

export const getProgramsAction = (name, governmentProgramId) => async (dispatch) => {
  getPrograms(governmentProgramId)
    .then((res) => {
      dispatch({
        type: DATASET_ACTIONS.GET_PROGRAMS,
        result: true,
        payload: res.data.map((child) => {
          return {
            code: child.id,
            label: child.value,
          };
        }),
        dataSetName: name,
      });
    })
    .catch((error) => {
      dispatch({
        type: DATASET_ACTIONS.GET_PROGRAMS,
        result: false,
        payload: [],
        dataSetName: name,
      });
      console.log(error);
    });
};

export const getSubprogramStatesAction = (name) => async (dispatch) => {
  getSubprogramStates()
    .then((res) => {
      dispatch({
        type: DATASET_ACTIONS.GET_SUBPROGRAM_STATES,
        result: true,
        payload: res.data.map((child) => {
          return {
            code: child.id,
            label: child.value,
          };
        }),
        dataSetName: name,
      });
    })
    .catch((error) => {
      dispatch({
        type: DATASET_ACTIONS.GET_SUBPROGRAM_STATES,
        result: false,
        payload: [],
        dataSetName: name,
      });
      console.log(error);
    });
};
export const getDepartmentsAction = (name) => async (dispatch) => {
  getDepartments()
    .then((res) => {
      dispatch({
        type: DATASET_ACTIONS.GET_DEPARTMENTS,
        result: true,
        payload: res.data.map((child) => {
          return {
            code: child.id,
            label: child.value,
          };
        }),
        dataSetName: name,
      });
    })
    .catch((error) => {
      dispatch({
        type: DATASET_ACTIONS.GET_DEPARTMENTS,
        result: false,
        payload: [],
        dataSetName: name,
      });
      console.log(error);
    });
};
export const getNotAssignedActiveCategoriesAction = (name) => async (dispatch) => {
  getNotAssignedActiveCategories()
    .then((res) => {
      dispatch({
        type: DATASET_ACTIONS.GET_NOT_ASSIGNED_ACTIVE_CATEGORIES,
        result: true,
        payload: res.data.map((child) => {
          return {
            code: child.id,
            label: child.value,
          };
        }),
        dataSetName: name,
      });
    })
    .catch((error) => {
      dispatch({
        type: DATASET_ACTIONS.GET_NOT_ASSIGNED_ACTIVE_CATEGORIES,
        result: false,
        payload: [],
        dataSetName: name,
      });
      console.log(error);
    });
};
export const getInactiveCategoriesAction = (name) => async (dispatch) => {
  getInactiveCategories()
    .then((res) => {
      dispatch({
        type: DATASET_ACTIONS.GET_INACTIVE_CATEGORIES,
        result: true,
        payload: res.data.map((child) => {
          return {
            code: child.id,
            label: child.value,
          };
        }),
        dataSetName: name,
      });
    })
    .catch((error) => {
      dispatch({
        type: DATASET_ACTIONS.GET_INACTIVE_CATEGORIES,
        result: false,
        payload: [],
        dataSetName: name,
      });
      console.log(error);
    });
};
export const getActiveCategoriesAction = (name) => async (dispatch) => {
  getActiveCategories()
    .then((res) => {
      dispatch({
        type: DATASET_ACTIONS.GET_ACTIVE_CATEGORIES,
        result: true,
        payload: res.data.map((child) => {
          return {
            code: child.id,
            label: child.value,
          };
        }),
        dataSetName: name,
      });
    })
    .catch((error) => {
      dispatch({
        type: DATASET_ACTIONS.GET_ACTIVE_CATEGORIES,
        result: false,
        payload: [],
        dataSetName: name,
      });
      console.log(error);
    });
};
export const getCategoriesAction = (name) => async (dispatch) => {
  getCategories()
    .then((res) => {
      dispatch({
        type: DATASET_ACTIONS.GET_CATEGORIES,
        result: true,
        payload: res.data.map((child) => {
          return {
            code: child.id,
            label: child.value,
          };
        }),
        dataSetName: name,
      });
    })
    .catch((error) => {
      dispatch({
        type: DATASET_ACTIONS.GET_CATEGORIES,
        result: false,
        payload: [],
        dataSetName: name,
      });
      console.log(error);
    });
};


export const getDocumentsAction = (name) => async (dispatch) => {
  getDocuments()
    .then((res) => {
      dispatch({
        type: DATASET_ACTIONS.GET_DOCUMENTS,
        result: true,
        payload: res.data.map((child) => {
          return {
            code: child.id,
            label: child.value,
          };
        }),
        dataSetName: name,
      });
    })
    .catch((error) => {
      dispatch({
        type: DATASET_ACTIONS.GET_DOCUMENTS,
        result: false,
        payload: [],
        dataSetName: name,
      });
      console.log(error);
    });
};

export const getWarehousesByProvinceAction = (name, province) => async (dispatch) => {
  getWarehousesByProvince(province)
    .then((res) => {
      dispatch({
        type: DATASET_ACTIONS.GET_WAREHOUSE_BY_PROVINCE,
        result: true,
        payload: res.data.map((child) => {
          return {
            code: child.id,
            label: child.name,
          };
        }),
        dataSetName: name,
      });
    })
    .catch((error) => {
      dispatch({
        type: DATASET_ACTIONS.GET_WAREHOUSE_BY_PROVINCE,
        result: false,
        payload: null,
        dataSetName: name,
      });
      console.log(error);
    });
};

export const getConditionsAction = (name) => async (dispatch) => {
  getConditions()
    .then((res) => {
      dispatch({
        type: DATASET_ACTIONS.GET_CONDITIONS,
        result: true,
        payload: res.data.map((child) => {
          return {
            code: child.id,
            label: child.value,
          };
        }),
        dataSetName: name,
      });
    })
    .catch((error) => {
      dispatch({
        type: DATASET_ACTIONS.GET_CONDITIONS,
        result: false,
        payload: [],
        dataSetName: name,
      });
      console.log(error);
    });
};

export const getNextApplicationStatesAction = (name, formData) => async (dispatch) => {
  dispatch({
    type: DATASET_ACTIONS.CLEAR_DATASET_BY_NAME,
    result: true,
    dataSetName: name,
  });
  getNextApplicationStates(formData)
    .then((res) => {
      dispatch({
        type: DATASET_ACTIONS.GET_NEXT_APPLICATION_STATES,
        result: true,
        payload: res.data.map((child) => {
          return {
            code: child.id,
            label: child.value,
            group: child.group,
          };
        }),
        dataSetName: name,
      });
    })
    .catch((error) => {
      dispatch({
        type: DATASET_ACTIONS.GET_NEXT_APPLICATION_STATES,
        result: false,
        payload: [],
        dataSetName: name,
      });
      console.log(error);
    });
};

export const getSubprogramsAction = (name) => async (dispatch) => {
  getSubprograms()
    .then((res) => {
      dispatch({
        type: DATASET_ACTIONS.GET_SUBPROGRAMS,
        result: true,
        payload: res.data.map((child) => {
          return {
            code: child.id,
            label: child.value,
          };
        }),
        dataSetName: name,
      });
    })
    .catch((error) => {
      dispatch({
        type: DATASET_ACTIONS.GET_SUBPROGRAMS,
        result: false,
        payload: [],
        dataSetName: name,
      });
      console.log(error);
    });
};

export const getBackOfficeUsersAction = (name) => async (dispatch) => {
  getBackOfficeUsers()
    .then((res) => {
      dispatch({
        type: DATASET_ACTIONS.GET_BO_USERS,
        result: true,
        payload: res.data.map((child) => {
          return {
            code: child.id,
            label: child.value,
          };
        }),
        dataSetName: name,
      });
    })
    .catch((error) => {
      dispatch({
        type: DATASET_ACTIONS.GET_BO_USERS,
        result: false,
        payload: [],
        dataSetName: name,
      });
      console.log(error);
    });
};

export const getBackOfficeRolesAction = (name) => async (dispatch) => {
  getBackOfficeRoles()
    .then((res) => {
      dispatch({
        type: DATASET_ACTIONS.GET_BO_ROLES,
        result: true,
        payload: res.data.map((child) => {
          return {
            code: child.roleKeyValue,
            label: child.name,
          };
        }),
        dataSetName: name,
      });
    })
    .catch((error) => {
      dispatch({
        type: DATASET_ACTIONS.GET_BO_ROLES,
        result: false,
        payload: [],
        dataSetName: name,
      });
      console.log(error);
    });
};

export const getShapeProgramsAction = (target, name) => async (dispatch) => {
  getShapePrograms(target)
    .then((res) => {
      dispatch({
        type: DATASET_ACTIONS.GET_SHAPE_PROGRAMS,
        result: true,
        dataSet: res.data.map((child) => {
          return {
            code: child.id,
            label: child.name,
          };
        }),
        dataSetName: name,
      });
    })
    .catch((error) => {
      dispatch({
        type: DATASET_ACTIONS.GET_SHAPE_PROGRAMS,
        result: false,
        dataSet: [],
        dataSetName: name,
      });
      console.log(error);
    });
};

export const getShapeSubsitesAction = (offer, province, name) => async (dispatch) => {
  getShapeSubsites(offer, province)
    .then((res) => {
      dispatch({
        type: DATASET_ACTIONS.GET_SHAPE_SUBSITES,
        result: true,
        dataSet: res.data.map((child) => {
          return {
            code: child.id,
            label: child.name,
          };
        }),
        dataSetName: name,
      });
    })
    .catch((error) => {
      dispatch({
        type: DATASET_ACTIONS.GET_SHAPE_SUBSITES,
        result: false,
        dataSet: [],
        dataSetName: name,
      });
      console.log(error);
    });
};

export const getLoanStatesAction = (name) => async (dispatch) => {
  getLoanStates()
    .then((res) => {
      dispatch({
        type: DATASET_ACTIONS.GET_LOAN_STATES,
        result: true,
        payload: res.data.map((child) => {
          return {
            code: child.id,
            label: child.value,
          };
        }),
        dataSetName: name,
      });
    })
    .catch((error) => {
      dispatch({
        type: DATASET_ACTIONS.GET_LOAN_STATES,
        result: false,
        payload: [],
        dataSetName: name,
      });
      console.log(error);
    });
};

export const getSubprogramByProgramIdAction = (name, program) => async (dispatch) => {
  getSubprogramByProgramId(program)
    .then((res) => {
      dispatch({
        type: DATASET_ACTIONS.GET_SUBPROGRAM_BY_PROGRAM,
        result: true,
        payload: res.data.map((child) => {
          return {
            code: child.id,
            label: child.value,
          };
        }),
        dataSetName: name,
      });
    })
    .catch((error) => {
      dispatch({
        type: DATASET_ACTIONS.GET_SUBPROGRAM_BY_PROGRAM,
        result: false,
        payload: [],
        dataSetName: name,
      });
      console.log(error);
    });
};

export const getPredefinedQuestionsAction = (name) => async (dispatch) => {
  getPredefinedQuestions()
    .then((res) => {
      dispatch({
        type: DATASET_ACTIONS.GET_PREDEFINED_QUESTIONS,
        result: true,
        payload: res.data.map((child) => {
          return {
            code: child.id,
            label: child.description,
            referenceDataSetId: child.referenceDataSetId,
            referenceDataSetDescription: child.referenceDataSetDescription,
            referenceDataSetName: child.referenceDataSetName,
          };
        }),
        dataSetName: name,
      });
    })
    .catch((error) => {
      dispatch({
        type: DATASET_ACTIONS.GET_PREDEFINED_QUESTIONS,
        result: false,
        payload: [],
        dataSetName: name,
      });
      console.log(error);
    });
};

export const getAvailableInternshipOpportunitiesAction =
  (name, province, professionalCategory, internshipType) => async (dispatch) => {
    getAvailableInternshipOpportunities(province, professionalCategory, internshipType)
      .then((res) => {
        dispatch({
          type: DATASET_ACTIONS.GET_AVAILABLE_INTERNSHIP_OPPORTUNITIES_ACTIONS,
          result: true,
          payload: res?.data.map((record) => {
            return {
              code: record.id,
              label: record.title,
            };
          }),
          dataSetName: name,
        });
      })
      .catch((error) => {
        dispatch({
          type: DATASET_ACTIONS.GET_AVAILABLE_INTERNSHIP_OPPORTUNITIES_ACTIONS,
          result: false,
          payload: [],
          dataSetName: name,
        });
        console.log("error", error);
      });
  };

  export const getGovernmentProgramsAction = () => async (dispatch) => {
    console.log('here')
    getGovernmentPrograms()
      .then((res) => {
        dispatch({
          type: DATASET_ACTIONS.GET_GOVERNMENT_PROGRAMS,
          result: true,
          payload: res.data.map((child) => {
            return {
              code: child.id,
              label: child.value,
            };
          }),
          dataSetName: DATASET.GOVERNMENT_PROGRAMS,
        });
      })
      .catch((error) => {
        dispatch({
          type: DATASET_ACTIONS.GET_GOVERNMENT_PROGRAMS,
          result: false,
          payload: [],
          dataSetName: DATASET.GOVERNMENT_PROGRAMS,
        });
        console.log(error);
      });
  };

export default getDataSetByNameAction;
