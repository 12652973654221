import React from "react";
// @material-ui/core components
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
// @material-ui/icons components
import DirectionsRun from "@material-ui/icons/DirectionsRun";
import Person from "@material-ui/icons/Person";

// core components
import componentStyles from "assets/theme/components/dropdowns/user-dropdown.js";
import componentStylesGeneric from "assets/theme/views/admin/generic";

//Redux
import { connect } from "react-redux";
import logoutAction from "redux/actions/auth/logoutAction";
import { getNameInitials } from "utils/converters";
import HelpIcon from "@material-ui/icons/Help";

const useStyles = makeStyles(componentStyles);
const useStylesGeneric = makeStyles(componentStylesGeneric);

function UserDropdown(props) {
  const theme = useTheme();
  const classes = { ...useStylesGeneric(), ...useStyles() };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const history = useHistory();

  const isMenuOpen = Boolean(anchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    props.logoutAction();
    setAnchorEl(null);
  };

  const getUserFullName = () => {
    return (props.authState?.name ?? "") + " " + (props.authState?.lastName ?? "");
  };
  const menuId = "dropdowns-user-dropdown-id";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <Typography variant="h6" component="h6" classes={{ root: classes.menuTitle }}>
        Bem-vindo!
      </Typography>
      
      <Divider component="div" classes={{ root: classes.dividerRoot }} />
      <Box component={Grid} display={"flex"} flexDirection={"column"}>
        <Box
          component={MenuItem}
          padding=".5rem 1rem"
          color={theme.palette.black.main}
          onClick={() => {
            handleMenuClose();
            history.push("/admin/myProfile");
          }}
          className={classes.nestedItem}
        >
          <Box
            component={Person}
            color={theme.palette.black.main}
            width="1rem!important"
            height="1rem!important"
            marginRight="1rem"
          />
          <span>Meu perfil</span>
        </Box>
        <Box
          component={MenuItem}
          padding=".5rem 1rem"
          color={theme.palette.black.main}
          onClick={() => {
            handleMenuClose();
            history.push("/admin/quick-guides");
          }}
          className={classes.nestedItem}
        >
          <Box
            component={HelpIcon}
            color={theme.palette.black.main}
            width="1.25rem!important"
            height="1.25rem!important"
            marginRight="1rem"
          />
          <span>Ajuda</span>
        </Box>
      </Box>
      <Divider component="div" classes={{ root: classes.dividerRoot }} />
      <Box 
        display="flex!important" 
        alignItems="center!important" 
        component={MenuItem} 
        onClick={handleLogout}
        className={classes.nestedItem}
      >
        <Box
          color={theme.palette.black.main}
          component={DirectionsRun}
          width="1rem!important"
          height="1rem!important"
          marginRight="1rem"
        />
        <span>Sair</span>
      </Box>
    </Menu>
  );

  return (
    <>
      <Button
        edge="end"
        aria-label="account of current user"
        aria-controls={menuId}
        aria-haspopup="true"
        onClick={handleProfileMenuOpen}
        color="inherit"
        classes={{
          label: classes.buttonLabel,
          root: classes.buttonRoot,
        }}
      >
        {props.authState?.photo ? (
          <Box>
            <Avatar
              alt="..."
              src={props.authState.photo}
              classes={{
                root: classes.avatarRoot + " " + classes.avatarIconOutlined,
              }}
              style={{padding: "0px"}}
            />
          </Box>
        ) : (
          <Box 
            component={Avatar} 
            marginRight="1rem" 
            alt="..." 
            classes={{ root: classes.avatarRoot + " " + classes.avatarIconOutlined}}
          >
            {" "}
            {getNameInitials(getUserFullName())}
          </Box>
        )}
        <Hidden smDown>
          <div style={{color: theme.palette.gray[900]}}>
            {getUserFullName()}
          </div>
        </Hidden>
      </Button>
      {renderMenu}
    </>
  );
}
const mapStateToProps = (state) => ({ ...state });
const mapDispatchToProps = (dispatch) => ({
  logoutAction: () => dispatch(logoutAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserDropdown);
