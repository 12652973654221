import { React, useState, useEffect } from "react";
import clsx from "clsx";
import { Controller, useFormContext } from "react-hook-form";
import { connect } from "react-redux";
import { useIntl } from "react-intl";
import { v4 as uuidv4 } from "uuid";
// @material-ui/core components
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import GenericFormSelect from "components/FormFields/GenericFormSelect";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
//import icons
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import FormatAlignLeftIcon from "@material-ui/icons/FormatAlignLeft";
//import custom components
import ConditionSection from "./ConditionSection";
import GenericButton from "components/Buttons/GenericButton";
import { getDataSetByNameAction, getPredefinedQuestionsAction } from "redux/actions/datasetsAction";
import { DATASET, COMPLEMENTARY_DATA_TYPE } from "utils/consts";
import { getSelectOptionsElementByKey } from "utils/formSelect";
import { messages, labels } from "resources/resources";

import componentStylesGeneric from "assets/theme/views/admin/generic.js";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function ComplementaryDataItem(props) {
  const classes = useStylesGeneric();

  const theme = useTheme();
  const intl = useIntl();
  const { control } = useFormContext();
  const [conditionsList, setConditionsList] = useState([]);

  useEffect(() => {
    if (
      props.dataSetState[DATASET.DATASET_TYPES] === undefined ||
      props.dataSetState[DATASET.DATASET_TYPES]?.length === 0
    ) {
      props.getDataSet(DATASET.DATASET_TYPES);
    }

    if (
      props.dataSetState[DATASET.PREDEFINED_QUESTIONS] === undefined ||
      props.dataSetState[DATASET.PREDEFINED_QUESTIONS]?.length === 0
    ) {
      props.getPredefinedQuestions(DATASET.PREDEFINED_QUESTIONS);
    }

    props.setValue(
      `${props.keyValue}_question`,
      props.questionInfo[0]?.itemText !== undefined ? props.questionInfo[0]?.itemText : ""
    );
    props.setValue(
      `${props.keyValue}_isRequired`,
      props.questionInfo[0]?.itemIsRequired !== undefined ? props.questionInfo[0]?.itemIsRequired : false
    );

    props.setValue(
      `${props.keyValue}_answer`,
      getSelectOptionsElementByKey(
        props.dataSetState[DATASET.DATASET_TYPES] ? props.dataSetState[DATASET.DATASET_TYPES] : [],
        props.questionInfo[0] !== undefined ? props.questionInfo[0]?.itemDatasetKey : null
      )
    );

    setConditionsList(props.questionInfo[0] !== undefined ? props.questionInfo[0]?.conditionsList : []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function loadCardContent(aux_complementaryDataType) {
    switch (aux_complementaryDataType) {
      case COMPLEMENTARY_DATA_TYPE.DEFAULT_QUESTIONS:
        return (
          <>
            <Grid container>
              <Grid item xs={12} sm={12} md={12}>
                <FormGroup>
                  <FormLabel>Questão Pré-definida</FormLabel>
                  <GenericFormSelect
                    control={control}
                    fieldName={`${props.keyValue}_predefinedQuestion`}
                    selectOptions={
                      props.dataSetState[DATASET.PREDEFINED_QUESTIONS] !== undefined
                        ? props.dataSetState[DATASET.PREDEFINED_QUESTIONS]
                        : []
                    }
                    getValues={props.getValues}
                    required={true}
                    errors={props.errors}
                    classes={classes}
                  />
                </FormGroup>
              </Grid>
            </Grid>
          </>
        );
      case COMPLEMENTARY_DATA_TYPE.DATASET:
        return (
          <>
            <Grid container>
              <Grid item xs={12} md={12}>
                <FormGroup>
                  <FormLabel>Texto</FormLabel>
                  <Controller
                    name={`${props.keyValue}_question`}
                    control={control}
                    defaultValue=""
                    rules={{
                      required: {
                        value: true,
                        message: "O campo é obrigatório",
                      },
                    }}
                    render={({ field: { onChange, name, value } }) => (
                      <OutlinedInput
                        name={name}
                        value={value}
                        onChange={onChange}
                        onBlur={(event) => {
                          if (event.target.value !== undefined && event.target.value !== "") {
                            props.updateComplementaryDataDropdownListEvent(props.keyValue, event.target.value, null);
                          }
                        }}
                        fullWidth
                        multiline
                        rows="3"
                        autoComplete="off"
                        type="text"
                        classes={{
                          notchedOutline: clsx({
                            [classes.borderWarning]: props.errors[`${props.keyValue}_question`] !== undefined,
                          }),
                        }}
                      />
                    )}
                  />
                  {props.errors[`${props.keyValue}_question`] !== undefined && (
                    <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                      {props.errors[`${props.keyValue}_question`]?.message}
                    </FormHelperText>
                  )}
                </FormGroup>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} md={1}>
                <FormGroup>
                  <Controller
                    name={`${props.keyValue}_isRequired`}
                    control={control}
                    defaultValue={false}
                    render={({ field: { onChange, name, value } }) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            name={name}
                            value={value}
                            checked={value}
                            onChange={onChange}
                            color="default"
                            inputProps={{ "aria-label": "checkbox with default color" }}
                          />
                        }
                        label={<FormLabel>Obrigatório</FormLabel>}
                      />
                    )}
                  />
                  {props.errors[`${props.keyValue}_isRequired`] !== undefined && (
                    <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                      {props.errors[`${props.keyValue}_isRequired`]?.message}
                    </FormHelperText>
                  )}
                </FormGroup>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} md={12}>
                <FormGroup>
                  <FormLabel>Opções</FormLabel>
                  <Controller
                    name={`${props.keyValue}_answer`}
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: intl.formatMessage(messages.Message_Generic_MandatoryField),
                      },
                    }}
                    render={({ field: { onChange, name } }) => (
                      <Autocomplete
                        name={name}
                        options={
                          props.dataSetState[DATASET.DATASET_TYPES] ? props.dataSetState[DATASET.DATASET_TYPES] : []
                        }
                        value={props.getValues(name) ? props.getValues(name) : null}
                        getOptionLabel={(option) => option.label}
                        getOptionSelected={(option, value) => option.code === value.code}
                        classes={{
                          inputRoot: clsx({
                            [classes.selectBorderWarningInputRoot]:
                              props.errors !== undefined && props.errors[`${props.keyValue}_question`] !== undefined,
                          }),
                        }}
                        ListboxProps={{
                          style: {
                            fontWeight: "normal",
                            fontSize: "14px",
                          },
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={"Seleccione opção"}
                            disabled={false}
                            variant="outlined"
                            InputLabelProps={{
                              style: {
                                fontWeight: "normal",
                                fontSize: "14px",
                                color: theme.palette.gray[600],
                              },
                            }}
                          />
                        )}
                        onChange={(_, data) => {
                          onChange(data);
                          if (data !== null) {
                            props.updateComplementaryDataDropdownListEvent(props.keyValue, null, data.code);
                          }
                        }}
                      />
                    )}
                  />
                  {props.errors[`${props.keyValue}_answer`] !== undefined && (
                    <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                      {props.errors[`${props.keyValue}_answer`]?.message}
                    </FormHelperText>
                  )}
                </FormGroup>
              </Grid>
            </Grid>
          </>
        );
      case COMPLEMENTARY_DATA_TYPE.PLAIN_TEXT:
        return (
          <>
            <Grid container>
              <Grid item xs={12} md={12}>
                <FormGroup>
                  <FormLabel>Texto</FormLabel>
                  <Controller
                    name={`${props.keyValue}_question`}
                    control={control}
                    defaultValue=""
                    rules={{
                      required: {
                        value: true,
                        message: "O campo é obrigatório",
                      },
                    }}
                    render={({ field: { onChange, name, value } }) => (
                      <OutlinedInput
                        name={name}
                        value={value}
                        onChange={onChange}
                        onBlur={(event) => {
                          if (event.target.value !== undefined && event.target.value !== "") {
                            props.updateComplementaryDataDropdownListEvent(props.keyValue, event.target.value, null);
                          }
                        }}
                        fullWidth
                        multiline
                        rows="3"
                        autoComplete="off"
                        type="text"
                        classes={{
                          notchedOutline: clsx({
                            [classes.borderWarning]: props.errors[`${props.keyValue}_question`] !== undefined,
                          }),
                        }}
                      />
                    )}
                  />
                  {props.errors?.[`${props.keyValue}_question`]?.message !== undefined && (
                    <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                      {props.errors?.[`${props.keyValue}_question`]?.message}
                    </FormHelperText>
                  )}
                </FormGroup>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} md={1}>
                <FormGroup>
                  <Controller
                    name={`${props.keyValue}_isRequired`}
                    control={control}
                    defaultValue={false}
                    render={({ field: { onChange, name, value } }) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            name={name}
                            value={value}
                            checked={value}
                            onChange={onChange}
                            color="default"
                            inputProps={{ "aria-label": "checkbox with default color" }}
                          />
                        }
                        label={<FormLabel>Obrigatório</FormLabel>}
                      />
                    )}
                  />
                  {props.errors?.[`${props.keyValue}_isRequired`]?.message !== undefined && (
                    <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                      {props.errors?.[`${props.keyValue}_isRequired`]?.message}
                    </FormHelperText>
                  )}
                </FormGroup>
              </Grid>
            </Grid>
          </>
        );
      default:
        break;
    }
  }

  function loadCardTitle(aux_complementaryDataType) {
    switch (aux_complementaryDataType) {
      case COMPLEMENTARY_DATA_TYPE.DEFAULT_QUESTIONS:
        return "Pergunta pré-definida";
      case COMPLEMENTARY_DATA_TYPE.DATASET:
        return "Pergunta de escolha múltipla";
      case COMPLEMENTARY_DATA_TYPE.PLAIN_TEXT:
        return "Pergunta de texto livre";
      default:
        break;
    }
  }

  const getComplementaryDataItemData = () => {
    let aux = {
      keyValue: props.keyValue,
      text: props.getValues(`${props.keyValue}_question`),
      isRequired: props.getValues(`${props.keyValue}_isRequired`),
      answer: props.getValues(`${props.keyValue}_answer`),
      conditionsList: conditionsList,
    };

    return aux;
  };

  const addNewConditionToListEvent = () => {
    setConditionsList((prevState) => {
      let conditionNewUuid = uuidv4();

      const newElement = {
        key: conditionNewUuid,
        selectedDatasetKey: undefined,
        complementaryDataDependencyKey: undefined,
        conditionType: undefined,
        conditionalQuestionId: undefined,
        conditionalValue: undefined,
      };

      return [...prevState, newElement];
    });
  };

  const removeConditionFromListEvent = (conditionKey) => {
    setConditionsList((prevState) => {
      let elements = prevState.filter((condition) => condition.key !== conditionKey);
      return elements;
    });
  };

  const handleConditionalQuestionIdChangeEvent = (conditionKey, datasetKey, complementaryDataDependandKey) => {
    setConditionsList((prevState) => {
      let indexOfConditionItem = prevState.findIndex((cd) => cd.key === conditionKey);

      let condition = prevState[indexOfConditionItem];
      condition.selectedDatasetKey = datasetKey;
      condition.complementaryDataDependencyKey = complementaryDataDependandKey;

      prevState[indexOfConditionItem] = condition;

      return [...prevState];
    });
  };

  const handleGetConditionalDataChangeEvent = (
    conditionKey,
    conditionType,
    conditionalQuestionId,
    conditionalValue
  ) => {
    setConditionsList((prevState) => {
      let indexOfConditionItem = prevState.findIndex((cd) => cd.key === conditionKey);

      let condition = prevState[indexOfConditionItem];

      condition.conditionType = conditionType;
      condition.conditionalQuestionId = conditionalQuestionId;
      condition.conditionalValue = conditionalValue;

      prevState[indexOfConditionItem] = condition;

      return [...prevState];
    });
  };

  return (
    <>
      <Card classes={{ root: classes.cardRoot }}>
        <CardHeader
          classes={{ title: classes.innerCardHeaderTitle, action: classes.cardHeaderAction }}
          avatar={
            <Box display="flex">
              <Box>
                {props.complementaryDataType !== COMPLEMENTARY_DATA_TYPE.PLAIN_TEXT ? (
                  <FormatListBulletedIcon className={classes.cardHeaderIcon} />
                ) : (
                  <FormatAlignLeftIcon className={classes.cardHeaderIcon} />
                )}
              </Box>
            </Box>
          }
          title={loadCardTitle(props.complementaryDataType)}
        ></CardHeader>

        <CardContent>
          {loadCardContent(props.complementaryDataType)}

          {props.hasComplementaryDataWithDatasets && (
            <>
              <Grid container>
                <Grid item xs={12} sm={8} md={9}></Grid>
                <Grid item xs={12} sm={4} md={3}>
                  <FormGroup>
                    <GenericButton
                      typeSubmit={false}
                      color="tertiary"
                      size="small"
                      onClick={() => addNewConditionToListEvent()}
                    >
                      Adicionar
                    </GenericButton>
                  </FormGroup>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} md={12}>
                  {conditionsList?.map((condition, index) => {
                    return (
                      <div key={"ConditionSection" + index}>
                        <ConditionSection
                          key={index}
                          keyValue={condition.key}
                          conditionInfo={condition}
                          removeConditionFromListEvent={removeConditionFromListEvent}
                          control={control}
                          getValues={props.getValues}
                          errors={props.errors}
                          index={index}
                          complementaryDataInfoForConditions={props.complementaryDataInfoForConditions}
                          // complementaryDataInfoForConditions={props.complementaryDataInfoForConditions.filter(
                          //   (r) => r.itemKey !== undefined && r.itemKey !== condition.conditionalQuestionId.code
                          // )}
                          unregister={props.unregister}
                          setValue={props.setValue}
                          handleConditionalQuestionIdChangeEvent={handleConditionalQuestionIdChangeEvent}
                          handleGetConditionalDataChangeEvent={handleGetConditionalDataChangeEvent}
                        />
                      </div>
                    );
                  })}
                </Grid>
              </Grid>
            </>
          )}
          <Grid container>
            <Grid item xs={12} sm={4} md={3}>
              <FormGroup>
                <GenericButton
                  typeSubmit={false}
                  color="tertiary"
                  size="small"
                  onClick={() => {
                    props.resetComplementaryDataItemEvent();
                    props.clearErrors();
                  }}
                >
                  {intl.formatMessage(labels.Label_Generic_Cancel)}
                </GenericButton>
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={4} md={6}></Grid>
            <Grid item xs={12} sm={4} md={3}>
              <FormGroup>
                <GenericButton
                  typeSubmit={false}
                  color="tertiary"
                  size="small"
                  onClick={async () => {
                    await props.trigger([`${props.keyValue}_question`, `${props.keyValue}_answer`]);

                    if (
                      !Object.keys(props.errors).includes(`${props.keyValue}_question`) &&
                      !Object.keys(props.errors).includes(`${props.keyValue}_answer`)
                    ) {
                      props.saveComplementaryDataListEvent(props.complementaryDataType, getComplementaryDataItemData());
                      props.resetComplementaryDataItemEvent();

                      props.clearErrors();
                    }
                  }}
                >
                  {intl.formatMessage(labels.Label_Generic_Save)}
                </GenericButton>
              </FormGroup>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getDataSet: (name, parentKeyValue) => dispatch(getDataSetByNameAction(name, parentKeyValue)),
  getPredefinedQuestions: (name) => dispatch(getPredefinedQuestionsAction(name)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ComplementaryDataItem);
