import { REPORT_TYPES } from "utils/consts";

export function schedulingsMap(formData) {
  if (formData !== null && formData !== undefined) {
    return {
      numApplication: formData.applicationNumber ?? "",
      numBI: formData.numBI ??"",
      userCode: formData.userCode ?? "",
      nameOfUser: formData.nameOfUser ?? "",
      governmentProgramId : formData.governmentProgramId?.code,
      motiveId: formData.motiveId?.code,
      startDate: formData.startDate ? formData.startDate.format("YYYY-MM-DD") : null,
      endDate: formData.endDate ? formData.endDate.format("YYYY-MM-DD") : null,
      provinceId: formData.province?.code,
    };
  }
}

export function schedulesReportMap(filterData) {
  if (filterData !== null && filterData !== undefined) {
    return {
      reportEnumKey: REPORT_TYPES.SCHEDULES_SEARCH_RESULTS,
      parameters: JSON.stringify(filterData),
    };
  }
}

export default schedulingsMap;
