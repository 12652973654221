import { React, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import "moment/locale/pt";
import moment from "moment";
import _ from "lodash";
// @material-ui/core components
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { useTheme } from "@material-ui/core/styles";
import FormGroup from "@material-ui/core/FormGroup";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
//import custom components
import TextField from "components/FeedbackReportDataFields/Text";
import RatingField from "components/FeedbackReportDataFields/Rating";
import GenericBackdrop from "components/Backdrops/GenericBackdrop";
import ApproveDialog from "components/Dialogs/ApproveDialog";
import RejectDialog from "components/Dialogs/RejectDialog";
import GenericButton from "components/Buttons/GenericButton";
import { getDataSetByNameAction } from "redux/actions/datasetsAction";
import { getFeedbackReportDetailsAction, validateFeedbackReportAction } from "redux/actions/opportunitiesAction";
import {
  FEEDBACK_REPORT_QUESTION_TYPE_KEY,
  FORM_FIELDS_PREFIX,
  OPPORTUNITY_FOLLOWUP_FEEDBACK_REPORT_STATE_KEY,
  FEEDBACK_REPORT_TYPE_KEY,
  DATASET,
  UNITS,
} from "utils/consts";
import limitaString from "utils/string";
import { labels } from "resources/resources";

import componentStylesGeneric from "assets/theme/views/admin/generic.js";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function FeedbackReportModal(props) {
  const classes = useStylesGeneric();
  const theme = useTheme();
  const intl = useIntl();
  const [feedbackReportData, setFeedbackReportData] = useState([]);

  const StyledTableRow = withStyles((theme) => ({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.gray[100],
      },
    },
  }))(TableRow);

  const methods = useForm({
    mode: "onChange",
  });

  useEffect(() => {
    props.getFeedbackReportDetails(props.feedbackReportId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    feedbackReportData?.questions?.map((questions) => {
      questions?.questionInformation?.map((questionInformation) => {
        return methods.setValue(questionInformation.questionId, questionInformation.answer ?? "");
      });
      return null;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feedbackReportData]);

  useEffect(() => {
    if (
      (feedbackReportData?.length === 0 || feedbackReportData?.length === undefined) &&
      props.opportunitiesState?.feedbackReportDetails_result?.questions?.length !== 0 &&
      props.opportunitiesState?.feedbackReportDetails_result?.questions?.length !== undefined
    ) {
      setStateFeedbackReportData(props.opportunitiesState?.feedbackReportDetails_result?.questions);
    }

    methods.setValue(
      "feedbackReportCompletedHours",
      props.opportunitiesState?.feedbackReportDetails_result?.feedbackReportCompletedHours ?? ""
    );
    methods.setValue(
      "feedbackReportCompletedHoursJustification",
      props.opportunitiesState?.feedbackReportDetails_result?.feedbackReportCompletedHoursJustification ?? ""
    );
    methods.setValue(
      "feedbackReportRejectionMotive",
      props.opportunitiesState?.feedbackReportDetails_result?.feedbackReportRejectionMotiveDescription ?? ""
    );
    methods.setValue(
      "feedbackReportRejectionMotiveObservations",
      props.opportunitiesState?.feedbackReportDetails_result?.feedbackReportRejectionMotiveObservations ?? ""
    );
    methods.setValue("observations", props.opportunitiesState?.feedbackReportDetails_result?.observations ?? "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.opportunitiesState?.feedbackReportDetails_result]);

  useEffect(() => {
    if (
      props.dataSetState[DATASET.FEEDBACK_REPORT_REJECT_MOTIVES] === undefined ||
      props.dataSetState[DATASET.FEEDBACK_REPORT_REJECT_MOTIVES]?.length === 0
    ) {
      props.getDataSetByName(DATASET.FEEDBACK_REPORT_REJECT_MOTIVES);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setStateFeedbackReportData = (questions) => {
    setFeedbackReportData({
      ...feedbackReportData,
      questions: questions?.map((q) => {
        return {
          ...q,
          questionInformation: q.questionInformation?.map((qi) => {
            return {
              ...qi,
              questionId: FORM_FIELDS_PREFIX.FOLLOWUP_FEEDBACK_REPORT_DATA + qi?.questionId,
            };
          }),
        };
      }),
    });
  };

  //Dialogs Logic
  const DIALOG = { SHOW: true, HIDE: false };
  const [dialogs, setDialogs] = useState({
    approveModal: false,
    rejectModal: false,
    updateModal: false,
  });

  const showHideDialog = (dialog, op) => {
    let dialogs_temp = _.mapValues(dialogs, () => false);

    dialogs_temp[dialog] = op;

    setDialogs(dialogs_temp);
  };

  function handleApproveModal() {
    showHideDialog("approveModal", DIALOG.SHOW);
  }

  function handleRejectModal() {
    showHideDialog("rejectModal", DIALOG.SHOW);
  }

  var isCompanyFeedbackReport = () => {
    return (
      props.opportunitiesState?.feedbackReportDetails_result?.feedbackReportTypeKeyValue ===
      FEEDBACK_REPORT_TYPE_KEY.OPPORTUNITY_FOLLOWUP_COMPANY
    );
  };

  const onSubmitApproveDialog = () => {
    const approveFilter = {
      reportUserId: props?.opportunitiesState?.feedbackReportDetails_result?.submittedByUserId,
      feedbackReportId: props?.feedbackReportId,
      action: true,
    };

    props.validateFeedbackReport(approveFilter, () => {
      showHideDialog("approveModal", DIALOG.HIDE);
      props.handleClose();
      props.handleRefreshPage();
    });
  };

  const onSubmitRejectDialog = (rejectMotiveId, rejectMotiveObservations) => {
    let filter = {
      reportUserId: props?.opportunitiesState?.feedbackReportDetails_result?.submittedByUserId,
      feedbackReportId: props?.feedbackReportId,
      action: false,
      rejectMotiveId,
      rejectMotiveObservations,
    };

    props.validateFeedbackReport(filter, () => {
      showHideDialog("rejectModal", DIALOG.HIDE);
      props.handleClose();
      props.handleRefreshPage();
    });
  };

  return (
    <Container>
      {props.opportunitiesState?.feedbackReportDetails_result?.questions?.length > 0 && (
        <Box>
          <form>
            <GenericBackdrop open={props.opportunitiesState?.getFeedbackReportDetails_loading} />
            <h2 style={{ textAlign: "center" }}>
              Formulário de {props.opportunitiesState?.feedbackReportDetails_result?.submittedBy}
            </h2>
            <h4 style={{ textAlign: "center" }}>
              {props.opportunitiesState?.feedbackReportDetails_result?.feedbackReportPeriodStartDate
                ? moment(props.opportunitiesState?.feedbackReportDetails_result?.feedbackReportPeriodStartDate).format(
                    "DD/MM/YYYY"
                  )
                : ""}
              {props.opportunitiesState?.feedbackReportDetails_result?.feedbackReportPeriodStartDate &&
              props.opportunitiesState?.feedbackReportDetails_result?.feedbackReportPeriodEndDate
                ? " a "
                : ""}
              {props.opportunitiesState?.feedbackReportDetails_result?.feedbackReportPeriodEndDate
                ? moment(props.opportunitiesState?.feedbackReportDetails_result?.feedbackReportPeriodEndDate).format(
                    "DD/MM/YYYY"
                  )
                : ""}
            </h4>

            {isCompanyFeedbackReport() && !props.opportunitiesState?.feedbackReportDetails_result?.isAdHoc && (
              <>
                <TableContainer style={{ marginBottom: "20px" }}>
                  <Box component={Table} alignItems="center" marginBottom="0!important">
                    <TableBody>
                      <TableRow>
                        <TableCell
                          classes={{
                            root: classes.tableCellRoot + " " + classes.tableCellRootHeadMultiple,
                          }}
                          width="100%"
                        >
                          Horas
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Box>
                </TableContainer>

                <Card>
                  <CardContent>
                    <Grid item xs={12} sm={12} md={6} className={classes.detailLabels} style={{ fontSize: ".8rem" }}>
                      <div>
                        <div style={{ float: "left", paddingTop: "15px", paddingRight: "15px" }}>
                          Horas de estágio realizadas:
                        </div>
                        <div>
                          <TextField
                            isRequired={true}
                            isDisabled={!props.isEditable}
                            name={"feedbackReportCompletedHours"}
                            type={"number"}
                            control={methods.control}
                            setValue={methods.setValue}
                            getValues={methods.getValues}
                            errors={methods.formState.errors}
                            classes={classes}
                            theme={theme}
                          />
                        </div>
                      </div>
                    </Grid>

                    <Grid item xs={12} md={12}>
                      <TextField
                        isRequired={false}
                        isDisabled={!props.isEditable}
                        name={"feedbackReportCompletedHoursJustification"}
                        label={"Justificação"}
                        labelDetails={"300 caracteres"}
                        multiline={true}
                        rows={2}
                        maxLength={300}
                        type={"text"}
                        control={methods.control}
                        setValue={methods.setValue}
                        getValues={methods.getValues}
                        errors={methods.formState.errors}
                        classes={classes}
                        theme={theme}
                      />
                    </Grid>

                    {props.opportunitiesState?.feedbackReportDetails_result?.feedbackReportStateKeyValue ===
                      OPPORTUNITY_FOLLOWUP_FEEDBACK_REPORT_STATE_KEY.REJEITADO && (
                      <>
                        <Grid item xs={12} md={12}>
                          <TextField
                            isRequired={false}
                            isDisabled={true}
                            name={"feedbackReportRejectionMotive"}
                            label={"Motivo de Rejeição"}
                            type={"text"}
                            control={methods.control}
                            setValue={methods.setValue}
                            getValues={methods.getValues}
                            errors={methods.formState.errors}
                            classes={classes}
                            theme={theme}
                          />
                        </Grid>

                        <Grid item xs={12} md={12}>
                          <TextField
                            isRequired={false}
                            isDisabled={true}
                            name={"feedbackReportRejectionMotiveObservations"}
                            label={"Observações de Rejeição"}
                            multiline={true}
                            rows={2}
                            type={"text"}
                            control={methods.control}
                            setValue={methods.setValue}
                            getValues={methods.getValues}
                            errors={methods.formState.errors}
                            classes={classes}
                            theme={theme}
                          />
                        </Grid>
                      </>
                    )}

                    {props.opportunitiesState?.feedbackReportDetails_result?.feedbackReportStateKeyValue ===
                      OPPORTUNITY_FOLLOWUP_FEEDBACK_REPORT_STATE_KEY.PAGO && (
                      <Grid item xs={12} sm={12} md={6} className={classes.detailLabels} style={{ fontSize: ".8rem" }}>
                        <div style={{ float: "left", paddingTop: "15px", paddingRight: "15px" }}>
                          Valor transferido da bolsa de estÃ¡gio:
                        </div>
                        <FormGroup>
                          <OutlinedInput
                            disabled={true}
                            readOnly={true}
                            fullWidth
                            value={intl.formatNumber(
                              props.opportunitiesState?.feedbackReportDetails_result?.amountPaid
                            )}
                            classes={{ input: classes.monetaryFields }}
                            endAdornment={
                              <InputAdornment disableTypography={true} position="end">
                                {UNITS.MONETARY}
                              </InputAdornment>
                            }
                            type="text"
                          />
                        </FormGroup>
                      </Grid>
                    )}
                  </CardContent>

                  {props.opportunitiesState?.feedbackReportDetails_result?.feedbackReportStateKeyValue ===
                    OPPORTUNITY_FOLLOWUP_FEEDBACK_REPORT_STATE_KEY.SUBMETIDO || (
                    <Grid container>
                      <Grid item xs={12} sm={1} md={2}></Grid>
                      <Grid item xs={12} sm={4} md={3}>
                        <FormGroup>
                          <GenericButton typeSubmit={false} color="secondary" onClick={handleRejectModal}>
                            {intl.formatMessage(labels.Label_Reject_Submit)}
                          </GenericButton>
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} sm={2} md={2}></Grid>
                      <Grid item xs={12} sm={4} md={3}>
                        <FormGroup>
                          <GenericButton typeSubmit={false} color="primary" onClick={handleApproveModal}>
                            {intl.formatMessage(labels.Label_Approve_Submit)}
                          </GenericButton>
                        </FormGroup>
                      </Grid>
                    </Grid>
                  )}
                </Card>
              </>
            )}

            <TableContainer style={{ marginBottom: "20px" }}>
              <Box component={Table} alignItems="center" marginBottom="0!important">
                <>
                  {feedbackReportData?.questions?.map((questions, key) => (
                    <TableBody key={key}>
                      <TableRow key={key}>
                        <TableCell
                          classes={{
                            root: classes.tableCellRoot + " " + classes.tableCellRootHeadMultiple,
                          }}
                          width="75%"
                        >
                          {limitaString(questions?.questionArea, 100)}
                        </TableCell>
                        <TableCell
                          classes={{
                            root: classes.tableCellRoot + " " + classes.tableCellRootHeadMultiple,
                          }}
                        ></TableCell>
                      </TableRow>
                      <>
                        {questions?.questionInformation?.map((questionInformation, key) => (
                          <StyledTableRow key={key}>
                            <TableCell
                              classes={{ root: classes.tableCellRoot }}
                              width="75%"
                              style={{ paddingLeft: "30px", whiteSpace: "normal", wordWrap: "break-word" }}
                            >
                              {questionInformation.question}
                            </TableCell>
                            <TableCell classes={{ root: classes.tableCellRoot }}>
                              {questionInformation?.questionType ===
                                FEEDBACK_REPORT_QUESTION_TYPE_KEY.STAR_CLASSIFICATION && (
                                <RatingField
                                  isRequired={true}
                                  answerValue={questionInformation.answer}
                                  name={questionInformation.questionId}
                                  isEditable={props.isEditable}
                                  control={methods.control}
                                  setValue={methods.setValue}
                                  getValues={methods.getValues}
                                  errors={methods.formState.errors}
                                  classes={classes}
                                  theme={theme}
                                />
                              )}
                              {questionInformation?.questionType === FEEDBACK_REPORT_QUESTION_TYPE_KEY.TEXT && (
                                <TextField
                                  isRequired={true}
                                  isDisabled={!props.isEditable}
                                  name={questionInformation.questionId}
                                  multiline={true}
                                  rows={2}
                                  maxLength={300}
                                  type={"text"}
                                  control={methods.control}
                                  setValue={methods.setValue}
                                  getValues={methods.getValues}
                                  errors={methods.formState.errors}
                                  classes={classes}
                                  theme={theme}
                                />
                              )}
                            </TableCell>
                          </StyledTableRow>
                        ))}
                      </>
                    </TableBody>
                  ))}
                </>
              </Box>
            </TableContainer>

            <Card>
              <CardContent>
                <Grid item xs={12} md={12}>
                  <TextField
                    isRequired={false}
                    isDisabled={!props.isEditable}
                    name={"observations"}
                    label={"Observações"}
                    labelDetails={"300 caracteres"}
                    multiline={true}
                    rows={4}
                    maxLength={300}
                    type={"text"}
                    control={methods.control}
                    setValue={methods.setValue}
                    getValues={methods.getValues}
                    errors={methods.formState.errors}
                    classes={classes}
                    theme={theme}
                  />
                </Grid>
              </CardContent>
            </Card>

            <ApproveDialog
              name="approve-dialog"
              open={dialogs.approveModal}
              onSubmitApproveDialog={onSubmitApproveDialog}
              title={"Confirma que pretende aprovar o formulário?"}
              showErrorIcon={false}
              isLoading={props.opportunitiesState.validateFeedbackReportLoading}
              onClose={() => showHideDialog("approveModal", DIALOG.HIDE)}
            />

            <RejectDialog
              name="reject-dialog"
              open={dialogs.rejectModal}
              onSubmitRejectDialog={onSubmitRejectDialog}
              title={"Pretende rejeitar o formulário?"}
              rejectionMotivesList={
                props.dataSetState[DATASET.FEEDBACK_REPORT_REJECT_MOTIVES] !== undefined
                  ? props.dataSetState[DATASET.FEEDBACK_REPORT_REJECT_MOTIVES]
                  : []
              }
              isLoading={props.opportunitiesState.validateFeedbackReportLoading}
              onClose={() => showHideDialog("rejectModal", DIALOG.HIDE)}
            />
          </form>
        </Box>
      )}
    </Container>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getFeedbackReportDetails: (feedbackReportId) => dispatch(getFeedbackReportDetailsAction(feedbackReportId)),
  getDataSetByName: (name, addEmptyField) => dispatch(getDataSetByNameAction(name, addEmptyField)),
  validateFeedbackReport: (formData, handleClose) => dispatch(validateFeedbackReportAction(formData, handleClose)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackReportModal);
