import { getListAsString, getListFromFormData } from "utils/formSelectMultiple";

export function backOfficeUserUpdateMap(formData, userId) {
  if (formData !== null && formData !== undefined)
    return {
      id: userId,
      user: {
        email: formData.email ? formData.email : "",
        password: "",
        name: formData.name ? formData.name : "",
        surname: formData.surname ? formData.surname : "",
        active: formData.active ? formData.active.code : false,
        provinceId: formData.province ? formData.province?.code : null,
        profile: formData.function ? getListAsString(formData.function, "##") : "",
        municipalityId: formData.county ? formData.county?.code : null,
        managerBankId: formData.managerBank ? formData.managerBank.code : null,
        managerLoanTypes:
          formData.managerLoanTypes !== undefined ? getListFromFormData(formData.managerLoanTypes) : [],
        employmentCenterId: formData.employmentCenter?.code
      },
    };
  return formData;
}

export default backOfficeUserUpdateMap;
