import { connect } from "react-redux";
import { Controller, useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
//@material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import FormGroup from "@material-ui/core/FormGroup";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormLabel from "@material-ui/core/FormLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
//custom components
import FormSelect from "components/FormFields/FormSelect";
import { DATASET } from "utils/consts";
import { messages } from "resources/resources";

import componentStylesGeneric from "assets/theme/views/admin/generic.js";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function RejectSection(props) {
  const classes = useStylesGeneric();
  const theme = useTheme();
  const intl = useIntl();
  const { control } = useFormContext();

  return (
    <>
      <Container>
        <Box className={classes.modalHeader} marginBottom="30px!important">
          Pretende rejeitar esta candidatura?
        </Box>
        <Box className={classes.subHeader}>
          Ao rejeitar a candidatura, o candidato ficará com a candidatura rejeitada no INEFOP e não irá receber o
          benefício do subprograma a que concorreu. Para prosseguir com a rejeição escolha o motivo, e introduza alguma
          observação que considere pertinente. Se não quiser rejeitar, simplesmente feche a janela.
        </Box>
        <Box marginTop="30px">
          <Grid container>
            <Grid item xs={12} md={12}>
              <FormGroup>
                <FormLabel>Motivo</FormLabel>
                <FormSelect
                  control={control}
                  fieldName="motive"
                  selectOptions={
                    props.dataSetState[DATASET.LOAN_REJECT_MOTIVES] !== undefined
                      ? props.dataSetState[DATASET.LOAN_REJECT_MOTIVES]
                      : []
                  }
                  setValue={props.setValue}
                  getValues={props.getValues}
                  required={true}
                  requiredMessage={intl.formatMessage(messages.Message_Generic_MandatoryField)}
                  errors={props.errors}
                  classes={classes}
                />
                {props.errors["motive"] !== undefined && (
                  <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                    {props.errors["motive"].message}
                  </FormHelperText>
                )}
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={12}>
              <FormGroup>
                <FormLabel>Observações</FormLabel>
                <Controller
                  name="notes"
                  control={control}
                  component={Box}
                  defaultValue=""
                  render={({ field: { onChange, name, value } }) => (
                    <OutlinedInput
                      name={name}
                      value={value}
                      onChange={onChange}
                      fullWidth
                      autoComplete="off"
                      type="text"
                      placeholder={"Escreva aqui as suas observações"}
                      multiline={true}
                      rows={3}
                    />
                  )}
                />
              </FormGroup>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

export default connect(mapStateToProps, null)(RejectSection);
