import { React, useState } from "react";
import { connect } from "react-redux";
import "moment/locale/pt";
import moment from "moment";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Grid from "@material-ui/core/Grid";
//import custom components
import GenericDialog from "components/Dialogs/GenericDialogV2";
import GenericButton from "components/Buttons/GenericButton";
import TableStateChip from "components/Cards/Tables/TableStateChip";
import FeedbackReportModal from "../Modals/FeedbackReportModal";
import UpdatePaymentFeedbackReportModal from "../Modals/UpdatePaymentFeedbackReportModal";
import {
  OPPORTUNITY_FOLLOWUP_FEEDBACK_REPORT_STATE_KEY,
  OPPORTUNITY_CANDIDATE_FOLLOWUP_STATE_KEY,
  OPERATIONS,
  FEEDBACK_REPORT_USER_TYPE_KEY,
  TABLE_STATE_CHIP_COLOR,
} from "utils/consts";
import { checkOperationPermission } from "utils/permission";
import { createAdHocFeedbackReportAction } from "redux/actions/opportunitiesAction";

import componentStylesGeneric from "assets/theme/views/admin/generic.js";

const useStylesGeneric = makeStyles(componentStylesGeneric);

const tableHead = ["Período", "Tipo de Formulário", "Submetido Por", "Data de Entrega", "", "Estado"];

function FollowUpFeedbackReports(props) {
  const classes = useStylesGeneric();
  const [showFeedbackReportModal, setShowFeedbackReportModal] = useState(false);
  const [showUpdatePaymentFeedbackReportModal, setShowUpdatePaymentFeedbackReportModal] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [feedbackReportId, setFeedbackReportId] = useState(false);
  const [feedbackReportPeriodDates, setFeedbackReportPeriodDates] = useState(false);

  const handleOpenFeedbackReportModal = (feedbackReportId, stateKey) => {
    setIsEditable(stateKey === OPPORTUNITY_FOLLOWUP_FEEDBACK_REPORT_STATE_KEY.PORSUBMETER);
    setFeedbackReportId(feedbackReportId);
    setShowFeedbackReportModal(true);
  };

  const handleCloseFeedbackReportModal = () => {
    setShowFeedbackReportModal(false);
  };

  const handleOpenUpdatePaymentFeedbackReportModal = (prop) => {
    setFeedbackReportId(prop.feedbackReportId);
    setFeedbackReportPeriodDates(
      (prop.evaluationPeriodStartDate ? moment(prop.evaluationPeriodStartDate).format("DD/MM/YYYY") : "") +
        " - " +
        (prop.evaluationPeriodEndDate ? moment(prop.evaluationPeriodEndDate).format("DD/MM/YYYY") : "")
    );
    setShowUpdatePaymentFeedbackReportModal(true);
  };

  const handleCloseUpdatePaymentFeedbackReportModal = () => {
    setShowUpdatePaymentFeedbackReportModal(false);
  };

  const handleCreateAdHocFeedbackReport = async () => {
    const data = { opportunityCandidateCode: props.opportunityApplicationFollowUpDetails?.candidateCode };

    await props.createAdHocFeedbackReport(data);

    props.handleRefreshPage();
  };

  const mapOpportunityFollowUpStateToTableChipColors = (stateKey) => {
    switch (stateKey) {
      case OPPORTUNITY_CANDIDATE_FOLLOWUP_STATE_KEY.PORINICIAR:
        return TABLE_STATE_CHIP_COLOR.LIGHT_ORANGE;
      case OPPORTUNITY_CANDIDATE_FOLLOWUP_STATE_KEY.ADECORRER:
        return TABLE_STATE_CHIP_COLOR.GREEN;
      case OPPORTUNITY_CANDIDATE_FOLLOWUP_STATE_KEY.TERMINADO:
        return TABLE_STATE_CHIP_COLOR.RED;
      default:
        return <></>;
    }
  };

  const mapOpportunityFollowUpFeedbackReportStateToTableChipColors = (stateKey) => {
    switch (stateKey) {
      case OPPORTUNITY_FOLLOWUP_FEEDBACK_REPORT_STATE_KEY.PORSUBMETER:
        return TABLE_STATE_CHIP_COLOR.BLUE;
      case OPPORTUNITY_FOLLOWUP_FEEDBACK_REPORT_STATE_KEY.SUBMETIDO:
        return TABLE_STATE_CHIP_COLOR.LIGHT_ORANGE;
      case OPPORTUNITY_FOLLOWUP_FEEDBACK_REPORT_STATE_KEY.VALIDADO:
      case OPPORTUNITY_FOLLOWUP_FEEDBACK_REPORT_STATE_KEY.PAGO:
        return TABLE_STATE_CHIP_COLOR.GREEN;
      case OPPORTUNITY_FOLLOWUP_FEEDBACK_REPORT_STATE_KEY.REJEITADO:
        return TABLE_STATE_CHIP_COLOR.RED;
      default:
        return <></>;
    }
  };

  return (
    <>
      <Card classes={{ root: classes.cardRoot }}>
        <CardHeader
          classes={{ title: classes.cardHeader, action: classes.cardHeaderAction }}
          title="Formulários"
          action={
            <Grid container>
              {props.opportunityApplicationFollowUpDetails?.candidateFollowUpStateKey ===
                OPPORTUNITY_CANDIDATE_FOLLOWUP_STATE_KEY.ADECORRER && (
                <Grid item>
                  <GenericButton
                    typeSubmit={false}
                    color="tertiary"
                    size="small"
                    onClick={handleCreateAdHocFeedbackReport}
                    loading={props.opportunitiesState.createAdHocFeedbackReportLoading}
                  >
                    Gerar Formulário de Acompanhamento
                  </GenericButton>
                </Grid>
              )}
              <Grid item>
                <TableStateChip
                  state={mapOpportunityFollowUpStateToTableChipColors(
                    props.opportunityApplicationFollowUpDetails?.candidateFollowUpStateKey
                  )}
                  message={props.opportunityApplicationFollowUpDetails?.candidateFollowUpStateDescription}
                />
              </Grid>
            </Grid>
          }
        ></CardHeader>

        <TableContainer>
          <Box component={Table} alignItems="center" marginBottom="0!important">
            <TableHead>
              <TableRow>
                {tableHead.map((prop, key) => (
                  <TableCell
                    key={key}
                    classes={{
                      root: classes.tableCellRoot + " " + classes.tableCellRootHead,
                    }}
                  >
                    {prop}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {props.opportunityApplicationFollowUpDetails?.followUpForms?.length > 0 && (
              <TableBody>
                {props.opportunityApplicationFollowUpDetails?.followUpForms?.map((prop, key) => (
                  <TableRow key={key}>
                    <TableCell classes={{ root: classes.tableCellRoot }}>
                      {prop.evaluationPeriodStartDate
                        ? moment(prop.evaluationPeriodStartDate).format("DD/MM/YYYY")
                        : ""}
                      {" - "}
                      {prop.evaluationPeriodEndDate ? moment(prop.evaluationPeriodEndDate).format("DD/MM/YYYY") : ""}
                    </TableCell>
                    <TableCell classes={{ root: classes.tableCellRoot }}>
                      {prop.isAdHoc ? <b>{prop.title} Extra</b> : prop.title}
                    </TableCell>
                    <TableCell classes={{ root: classes.tableCellRoot }}>{prop.submittedBy}</TableCell>
                    <TableCell classes={{ root: classes.tableCellRoot }}>
                      {prop.submissionDate ? moment(prop.submissionDate).format("DD/MM/YYYY") : ""}
                    </TableCell>
                    <TableCell classes={{ root: classes.tableCellRoot }}>
                      <Box alignItems="center" display="flex" style={{ justifyContent: "center" }}>
                        <Box alignItems="center">
                          <GenericButton
                            typeSubmit={false}
                            color="tertiary"
                            size="small"
                            onClick={() => {
                              handleOpenFeedbackReportModal(prop.feedbackReportId, prop.stateKey);
                            }}
                            disabled={prop.stateKey === OPPORTUNITY_FOLLOWUP_FEEDBACK_REPORT_STATE_KEY.PORSUBMETER}
                          >
                            VER FORMULÁRIO
                          </GenericButton>
                        </Box>
                        {prop.submittedByKeyValue === FEEDBACK_REPORT_USER_TYPE_KEY.COMPANY &&
                          !prop.isAdHoc &&
                          checkOperationPermission(
                            props.authState.operationsList,
                            OPERATIONS.OPPORTUNITIES_APPLICATIONS_FOLLOWUP_UPDATE_REPORT_FINANCIAL_INFO
                          ) && (
                            <Box alignItems="center" paddingLeft={"10px"}>
                              <GenericButton
                                typeSubmit={false}
                                color="tertiary"
                                size="small"
                                onClick={() => {
                                  handleOpenUpdatePaymentFeedbackReportModal(prop);
                                }}
                                disabled={prop.stateKey !== OPPORTUNITY_FOLLOWUP_FEEDBACK_REPORT_STATE_KEY.VALIDADO}
                              >
                                Atualizar Pagamento
                              </GenericButton>
                            </Box>
                          )}
                      </Box>
                    </TableCell>
                    <TableCell classes={{ root: classes.tableCellRoot }}>
                      <TableStateChip
                        state={mapOpportunityFollowUpFeedbackReportStateToTableChipColors(prop.stateKey)}
                        message={prop.stateDescription}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            )}
          </Box>

          {props.opportunityApplicationFollowUpDetails?.followUpForms?.length === 0 && (
            <Box className={classes.noResultsInfoInTable}>Não existem dados para apresentar.</Box>
          )}
        </TableContainer>

        {/* FollowUpForm Modal */}
        <GenericDialog maxWidth={"lg"} open={showFeedbackReportModal} onClose={handleCloseFeedbackReportModal}>
          <FeedbackReportModal
            feedbackReportId={feedbackReportId}
            isEditable={isEditable}
            handleClose={handleCloseFeedbackReportModal}
            handleRefreshPage={props.handleRefreshPage}
          />
        </GenericDialog>
      </Card>

      {/* Update Payment on FollowUp Form Modal */}
      <GenericDialog
        maxWidth={"lg"}
        open={showUpdatePaymentFeedbackReportModal}
        onClose={handleCloseUpdatePaymentFeedbackReportModal}
      >
        <UpdatePaymentFeedbackReportModal
          feedbackReportId={feedbackReportId}
          feedbackReportPeriodDates={feedbackReportPeriodDates}
          handleClose={handleCloseUpdatePaymentFeedbackReportModal}
          handleRefreshPage={props.handleRefreshPage}
        />
      </GenericDialog>
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  createAdHocFeedbackReport: async (formData) => await dispatch(createAdHocFeedbackReportAction(formData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FollowUpFeedbackReports);
