import React from "react";
import { connect } from "react-redux";
import { Container } from "@material-ui/core";

//import custom components
import OverviewSection from "views/admin/UserOverview/UserSingular/Sections/OverviewSection";
import GeneralDataSection from "views/admin/UserOverview/UserSingular/Sections/GeneralDataSection";
import ContactsSection from "views/admin/UserOverview/Components/Sections/ContactsSection";

function GeneralDataTab(props) {
  return (
    <>
      <Container maxWidth={false}>
        <OverviewSection userOverview={props?.userOverview} handleChangeTab={props.handleChangeTab} />
        <GeneralDataSection
          userOverview={props?.userOverview}
          userGeneralDataOverview={props?.userOverview?.userSingularGeneralDataOverview}
        />
        <ContactsSection
          userGeneralDataOverview={props?.userOverview?.userSingularGeneralDataOverview}
          isUserColective={false}
        />
      </Container>
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

export default connect(mapStateToProps)(GeneralDataTab);
