import React from "react";
import { connect } from "react-redux";
import "moment/locale/pt";

import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import Container from "@material-ui/core/Container";

import componentStylesGeneric from "assets/theme/views/admin/generic.js";
import SubmitedDocumentsSection from "components/FormFields/SubmitedDocumentsSection";
import { getUserDocumentAction } from "redux/actions/usersAction";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function DocumentsSection(props) {
  const classes = useStylesGeneric();

  return (
    <Container maxWidth={false}>
      <Card className={classes.cardRoot}>
        <CardHeader classes={{ title: classes.cardHeader }} title={"Documentos"} />
        <CardContent>
          <Grid container>
            {props?.documents?.length > 0 ? (
              props?.documents?.map((doc, key) => (
                <Grid item xs={12} md={4} className={classes.paddingRight0px} key={key}>
                  <FormGroup>
                    <FormLabel>{doc.documentType}</FormLabel>
                    <SubmitedDocumentsSection
                      files={[
                        {
                          documentId: doc.documentIndex,
                          documentName: doc.documentName,
                        },
                      ]}
                      getDocument={props.getUserDocument}
                    />
                  </FormGroup>
                </Grid>
              ))
            ) : (
              <Grid item xs={12} md={6} lg={4}>
                Sem dados para apresentar
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
      </Container>
      );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getUserDocument: (docId, docName) => dispatch(getUserDocumentAction(docId, docName)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsSection);
