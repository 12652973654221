import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getDashboardOpportunitiesAction } from "redux/actions/dashboardAction";
import { Link } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import LinkMaterialUi from "@material-ui/core/Link";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
// @material-ui/icons components
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import TableStateChip from "components/Cards/Tables/TableStateChip";
import { getNameInitials } from "utils/converters";
import { OPPORTUNITY_STATE_KEY, TABLE_STATE_CHIP_COLOR } from "utils/consts";

import componentStyles from "assets/theme/components/cards/dashboard/card-dashboard-generic.js";
import componentStylesGeneric from "assets/theme/views/admin/generic.js";

const useStyles = makeStyles(componentStyles);
const useStylesGeneric = makeStyles(componentStylesGeneric);

const tableHead = ["Tipo de Oferta", "", "Empresa", "Estado", ""];

function CardPendingOpportunities(props) {
  const theme = useTheme();
  const classes = { ...useStylesGeneric(), ...useStyles() };
  
  useEffect(() => {
    props.getDashboardOpportunities();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const mapOpportunityStateToTableChipColors = (stateKey) => {
    switch (stateKey) {
      case OPPORTUNITY_STATE_KEY.APROVADA:
        return TABLE_STATE_CHIP_COLOR.GREEN
      case OPPORTUNITY_STATE_KEY.REJEITADA:
        return TABLE_STATE_CHIP_COLOR.RED
      case OPPORTUNITY_STATE_KEY.SUBMETIDA:
        return TABLE_STATE_CHIP_COLOR.LIGHT_ORANGE
      case OPPORTUNITY_STATE_KEY.FECHADA:
        return TABLE_STATE_CHIP_COLOR.BLUE
      default:
        return <></>;
    }
  };
  
  return (
    <Card classes={{ root: classes.cardRoot }}>
      <div style={{position:"relative"}}>
        <img
            src={"/images/empregoAngolaBackground1.png"} //Designed by 0melapics / Freepik: http://www.freepik.com
            className={classes.backgroundImage}
            alt={""}
        />
      </div>
      <CardHeader
        classes={{
          root: classes.cardHeader + " " + classes.cardHeaderDashboard,
          action: classes.cardHeaderAction,
        }}
        subheader={
          <Grid
            container
            component={Box}
            alignItems="center"
            justifyContent="space-between"
          >
            <Box className={classes.lineHeader}> {""} </Box>
            <Grid item xs={12}>
              <Box
                component={Typography}
                variant="h3"
                marginBottom="0!important"
                classes={{ root: classes.cardTitle }}
              >
                <Box component="span">Ofertas a Aprovar</Box>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box
                component={Typography}
                variant="h1"
                classes={{ root: classes.cardHeaderCounter }}
                marginBottom="0!important"
              >
                <Box component="span">
                  {props.dashboardState?.dashboardOpportunitiesCount
                    ? props.dashboardState?.dashboardOpportunitiesCount
                    : 0}
                </Box>
              </Box>
            </Grid>
          </Grid>
        }
        action={
          <LinkMaterialUi
            component={Link}
            variant="h5"
            to={`/admin/opportunity-management/${5}`}
            className={classes.link}
          >
            Ver mais
          </LinkMaterialUi>
        }
      ></CardHeader>
      {props.dashboardState?.dashboardOpportunitiesInfo !== undefined &&
        props.dashboardState?.dashboardOpportunitiesInfo?.length > 0 && (
          <TableContainer>
            <Box
              component={Table}
              alignItems="center"
              marginBottom="0!important"
            >
              <TableHead>
                <TableRow>
                  {tableHead.map((prop, key) => (
                    <TableCell
                      key={key}
                      classes={{
                        root:
                          classes.tableCellRoot +
                          " " +
                          classes.tableCellRootHead,
                      }}
                    >
                      {prop}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {props.dashboardState?.dashboardOpportunitiesInfo?.map(
                  (prop, key) => (
                    <TableRow key={key}>
                      <TableCell classes={{ root: classes.tableCellRoot }}>
                        {prop.opportunityType}
                      </TableCell>
                      <TableCell
                        classes={{
                          root:
                            classes.tableCellRoot + " " + classes.actionsCell,
                        }}
                      >
                        <Box
                          paddingTop=".16rem"
                          paddingBottom=".35rem"
                          marginLeft="-20px"
                          marginRight="-10px"
                          alignItems="left"
                          display="flex"
                        >
                          {prop.userAvatar ? (
                            <Box
                              component={Avatar}
                              alt="..."
                              src={prop.userAvatar}
                              classes={{ root: classes.avatarRoot }}
                            />
                          ) : (
                            <Box
                              component={Avatar}
                              alt="..."
                              classes={{ root: classes.avatarRoot }}
                            >
                              {" "}
                              {getNameInitials(prop.name)}
                            </Box>
                          )}
                        </Box>
                      </TableCell>
                      <TableCell classes={{ root: classes.tableCellRoot + " " + classes.tableRowEllipsis}} style={{maxWidth: "170px"}}>
                        {prop.name}
                      </TableCell>
                      <TableCell
                        classes={{
                          root:
                            classes.tableCellRoot + " " + classes.actionsCell,
                        }}
                      >
                        <Box paddingTop=".35rem" paddingBottom=".35rem">
                          <TableStateChip
                            state={mapOpportunityStateToTableChipColors(prop.opportunityStateKey)}
                            message={prop.opportunityStateDescription}
                          />
                        </Box>
                      </TableCell>
                      <TableCell classes={{ root: classes.tableCellRoot }}>
                        <Tooltip title="Detalhes">
                          <IconButton
                            aria-label="details"
                            component={Link}
                            to={`/admin/opportunity-details/${prop.id}`}
                          >
                            <Box
                              component={ArrowForwardIosIcon}
                              width="9px!important"
                              height="9px!important"                              
                              color={theme.palette.primary.main}                              
                            />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            </Box>
          </TableContainer>
        )}
    </Card>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getDashboardOpportunities: () => dispatch(getDashboardOpportunitiesAction()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CardPendingOpportunities);
