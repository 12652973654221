import { React, useState } from "react";
import { connect } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
//import custom components
import componentStylesGeneric from "assets/theme/views/admin/generic.js";
import FilterSectionFo from "./Sections/FilterSectionFo";
import ResultSectionFo from "views/admin/UserManagement/FrontOffice/Sections/ResultSectionFo";
const useStylesGeneric = makeStyles(componentStylesGeneric);

function UsersFrontOffice() {
  const classes = useStylesGeneric();
  const [page, setPage] = useState(1);

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  return (
    <Grid container classes={{ root: classes.containerRoot }} style={{ marginTop: "11rem" }}>
      <Grid item xs={12} md={12}>
        <FilterSectionFo
          page={page}
          handleChangePage={(value) => {
            handleChangePage(undefined, value);
          }}
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <ResultSectionFo
          userColectiveDetailsPagePath={"/admin/frontOfficeUsers-enterprise-edit/"}
          userSingularDetailsPagePath={"/admin/frontOfficeUsers-individual-edit/"}
          page={page}
          handleChangePage={handleChangePage}
        />
      </Grid>
    </Grid>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(UsersFrontOffice);
