import React from "react";
import { connect } from "react-redux";
import { useForm, FormProvider } from "react-hook-form";
import { registerLossOrBreakAction } from "redux/actions/stockAction";
import { useIntl } from "react-intl";
// @material-ui/core components
import Box from "@material-ui/core/Box";
//import custom components
import GeneralData from "./GeneralData";
import GenericButton from "components/Buttons/GenericButton";
import registerLossBreakInWarehouse from "mappers/registerLossBreakInWarehouse";
import { labels } from "resources/resources";

function RegisterLossBreak(props) {
  const intl = useIntl();

  const methods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const {
    handleSubmit,
    getValues,
    setValue,
    trigger,
    unregister,
    formState: { errors },
  } = methods;

  const onSubmit = (data) => {
    props.registerLossOrBreak(
      registerLossBreakInWarehouse(data),
      props.stockState.RegisterLossBreakModal.onCloseAction
    );
    props.closeModal();
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <GeneralData
            getValues={getValues}
            errors={errors}
            setValue={setValue}
            isEditable={true}
            trigger={trigger}
            unregister={unregister}
          />
          <Box textAlign="center" marginTop="15px" marginBottom="15px">
            <GenericButton typeSubmit={true} color="primary">
              {intl.formatMessage(labels.Label_Generic_Submit_Button)}
            </GenericButton>
          </Box>
        </form>
      </FormProvider>
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  registerLossOrBreak: (formData, onCloseAction) => dispatch(registerLossOrBreakAction(formData, onCloseAction)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RegisterLossBreak);
