import React from "react";
import { connect } from "react-redux";
import { useIntl } from "react-intl";
import "moment/locale/pt";

import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";

import componentStylesGeneric from "assets/theme/views/admin/generic.js";
import { titles } from "resources/resources";
import SubmitedDocumentsSection from "components/FormFields/SubmitedDocumentsSection";
import { getUserDocumentAction } from "redux/actions/usersAction";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function DocumentsSection(props) {
  const classes = useStylesGeneric();
  const intl = useIntl();

  const getDocumentTypeName = (userDocumentId, documentTypesList) => {
    const doc = documentTypesList?.find((d) => d.documentTypeId === userDocumentId);
    if (doc)
      return doc.documentName;
    return undefined;
  }

  return (
    <>
      <Card className={classes.cardRoot}>
        <CardHeader
            classes={{ title: classes.cardHeader }}
            title= {intl.formatMessage(titles.Title_UserOverview_ProfessionTab_DocumentsSection)}
            data-testid="user-overview-documents-section-title"
        ></CardHeader>
        <CardContent>
            <Grid container>
              {props?.userDocuments?.length > 0 ? (
                props?.userDocuments?.map((userDocument, key) => (
                  <Grid item xs={12} md={4} className={classes.paddingRight0px} key={key}>
                    <FormGroup>
                      <FormLabel>
                        {getDocumentTypeName(userDocument.documentTypeId, props.userTypeDocuments)}
                      </FormLabel>
                      <SubmitedDocumentsSection
                        files={[{
                          documentId: userDocument.documentIndex,
                          documentName: userDocument.documentName
                        }]}
                        getDocument={props.getUserDocument}
                      />
                    </FormGroup>
                  </Grid>
                ))
              ): (
                <Grid item xs={12} md={6} lg={4} >
                  Sem dados para apresentar
                </Grid>
              )}
            </Grid>
          </CardContent>
      </Card>
    </> 
  ); 
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getUserDocument: (docId, docName) => dispatch(getUserDocumentAction(docId, docName))
});

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsSection);
