import { React, useState } from "react";
import { connect } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
//import custom components
import componentStylesGeneric from "assets/theme/views/admin/generic.js";
import FilterSection from "./Sections/FilterSection";
import ResultSection from "./Sections/ResultSection";
// import Card from "@material-ui/core/Card";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function InformationDissemination() {
  const classes = useStylesGeneric();
  // const theme = useTheme();

  const [page, setPage] = useState(1);

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  return (
    <Grid container classes={{ root: classes.containerRoot }} style={{marginTop: "11rem"}}>
      <Grid item xs={12} sm={6} md={6}>
      </Grid>
      <Grid item xs={12} md={12}>
        <FilterSection
          page={page}
          handleChangePage={(value) => { handleChangePage(undefined, value); }}
        />
      </Grid>
      <Grid item xs={12} md={12}>
          <ResultSection 
          page={page}
          handleChangePage={handleChangePage}
          />
      </Grid>
    </Grid>
  );
}

const mapStateToProps = (state) => ({ ...state });

export default connect(mapStateToProps)(InformationDissemination);
