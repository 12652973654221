import { React } from "react";
import { connect } from "react-redux";
import { useFormContext } from "react-hook-form";
import "moment/locale/pt";
import { useIntl } from "react-intl";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";
import { Card, CardHeader, CardContent } from "@material-ui/core";
//import custom components
import IndividualUserPersonalData from "./IndividualUserPersonalData";
import IndividualUserCompanyData from "./IndividualUserCompanyData";
import GenericButton from "components/Buttons/GenericButton";
import SubmitedDocumentsSection from "components/FormFields/SubmitedDocumentsSection";
import DropzoneField from "components/FormFields/DropzoneField";
import { getUserDocumentAction } from "redux/actions/usersAction";
import { labels } from "resources/resources";

import componentStylesGeneric from "assets/theme/views/admin/generic.js";
const useStylesGeneric = makeStyles(componentStylesGeneric);

function IndividualUserData(props) {
  const theme = useTheme();
  const classes = useStylesGeneric();
  const steps = getSteps();
  const intl = useIntl();
  const methods = useFormContext();

  function getSteps() {
    return ["Dados Pessoais", "Dados Profissionais"];
  }

  const getFileInfoByDocumentType = (documentTypeId) => {
    const doc = props.usersInfo.result?.userDocuments?.find((d) => d.documentTypeId === documentTypeId);
    if (doc)
      return [
        {
          documentId: doc.documentIndex,
          documentName: doc.documentName,
        },
      ];
    return undefined;
  };

  return (
    <>
      <Card classes={{ root: classes.cardRoot }}>
        <CardHeader
          title={steps[0]}
          classes={{ title: classes.cardHeader, action: classes.cardHeaderAction }}
          action={
            !props.isCreate && (
              <GenericButton
                typeSubmit={false}
                color="tertiary"
                size="small"
                onClick={() => {
                  props.handleEditSectionPersonalData();
                }}
              >
                {intl.formatMessage(labels.Label_Generic_Edit)}
              </GenericButton>
            )
          }
        ></CardHeader>
        <CardContent>
          <IndividualUserPersonalData
            userId={props.userId}
            getValues={methods.getValues}
            errors={methods.formState.errors}
            classes={classes}
            setValue={methods.setValue}
            theme={theme}
            trigger={methods.trigger}
            handleEditSection={props.handleEditSectionPersonalData}
            isEditable={props.isEditablePersonalData || props.isCreate}
            isCreate={props.isCreate}
          />
        </CardContent>
      </Card>

      <Card classes={{ root: classes.cardRoot }}>
        <CardHeader
          title={steps[1]}
          classes={{ title: classes.cardHeader, action: classes.cardHeaderAction }}
          action={
            !props.isCreate && (
              <GenericButton
                typeSubmit={false}
                color="tertiary"
                size="small"
                onClick={() => {
                  props.handleEditSectionProfessionalData();
                }}
              >
                {intl.formatMessage(labels.Label_Generic_Edit)}
              </GenericButton>
            )
          }
        ></CardHeader>

        <CardContent>
          <IndividualUserCompanyData
            userId={props.userId}
            getValues={methods.getValues}
            errors={methods.formState.errors}
            classes={classes}
            setValue={methods.setValue}
            theme={theme}
            handleEditSection={props.handleEditSectionProfessionalData}
            isEditable={props.isEditableProfessionalData || props.isCreate}
          />
        </CardContent>
      </Card>

      {props.usersInfo.result?.userTypeDocuments?.length > 0 && !props.isCreate && (
        <Card classes={{ root: classes.cardRoot }}>
          <CardHeader
            classes={{ title: classes.cardHeader, action: classes.cardHeaderAction }}
            title={"Documentos"}
            action={
              <GenericButton
                typeSubmit={false}
                color="tertiary"
                size="small"
                onClick={() => {
                  props.handleEditSectionUserDocuments();
                }}
              >
                {intl.formatMessage(labels.Label_Generic_Edit)}
              </GenericButton>
            }
          ></CardHeader>
          <CardContent>
            <Grid container>
              {props.usersInfo.result?.userTypeDocuments?.map((documentType, key) => (
                <Grid item xs={12} md={4} className={classes.paddingRight0px} key={key}>
                  <FormGroup>
                    <FormLabel>{documentType.documentName}</FormLabel>
                    {props.isEditableUserDocuments && (
                      <DropzoneField
                        name={documentType.documentTypeId.toString()}
                        multiple={false}
                        label={intl.formatMessage(labels.Label_Generic_Upload_Document)}
                        register={methods.register}
                        errors={methods.formState.errors}
                        required={false}
                        setValue={methods.setValue}
                        getValues={methods.getValues}
                        trigger={methods.trigger}
                        showFileNameOnPreview={true}
                      />
                    )}
                    <SubmitedDocumentsSection
                      files={getFileInfoByDocumentType(documentType.documentTypeId)}
                      getDocument={props.getUserDocument}
                    />
                  </FormGroup>
                </Grid>
              ))}
            </Grid>
          </CardContent>
        </Card>
      )}
    </>
  );
}
const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getUserDocument: (docId, docName) => dispatch(getUserDocumentAction(docId, docName)),
});

export default connect(mapStateToProps, mapDispatchToProps)(IndividualUserData);
