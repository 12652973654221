import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { useIntl } from "react-intl";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import TableStateChip from "components/Cards/Tables/TableStateChip";
import Pagination from "@material-ui/lab/Pagination";
import { ArrayUtils } from "utils/array_utils";
import { TABLE_STATE_CHIP_COLOR, SUBPROGRAM_STATES } from "utils/consts";

// @material-ui/icons components
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
// core componentssrc
import componentStylesGeneric from "assets/theme/views/admin/generic";
import { updateSubprogramStateAction } from "redux/actions/subprogramsAction";
import TableBackdrop from "components/Backdrops/TableBackdrop/TableBackdrop";
import GenericButton from "components/Buttons/GenericButton";
import { labels } from "resources/resources";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function ResultSection(props) {
  const theme = useTheme();
  const classes = useStylesGeneric();
  const intl = useIntl();
  const [subProgramList, setSubProgramList] = useState([]);

  const tableHead = [
    intl.formatMessage(labels.Label_Admin_Subprogram_GovernmentProgramField),
    intl.formatMessage(labels.Label_Admin_Subprogram_ProgramField),
    intl.formatMessage(labels.Label_Admin_Subprogram_SubprogramField),
    intl.formatMessage(labels.Label_Admin_Subprogram_ValidityDateField),
    intl.formatMessage(labels.Label_Admin_Subprogram_DepartmentField),
    intl.formatMessage(labels.Label_Admin_Subprogram_BenefitsTypeField),
    intl.formatMessage(labels.Label_Admin_Subprogram_StateField),
    "",
  ];

  useEffect(() => {
    if (!ArrayUtils.NullOrEmpty(props.subprogramState.result)) {
      setSubProgramList(props.subprogramState.result.data);
    } else {
      setSubProgramList([]);
    }
  }, [props.subprogramState.result]);

  const MapSubprogramStateToTableChipColors = (subprogramStateKeyValue) => {
    const greenChipArray = [SUBPROGRAM_STATES.APROVADO, SUBPROGRAM_STATES.CONCLUIDO, SUBPROGRAM_STATES.PUBLICADO];
    const yellowChipArray = [SUBPROGRAM_STATES.CRIADO];
    const orangeChipArray = [];
    const redChipArray = [];

    if (greenChipArray.includes(subprogramStateKeyValue)) {
      return TABLE_STATE_CHIP_COLOR.GREEN;
    }
    if (orangeChipArray.includes(subprogramStateKeyValue)) {
      return TABLE_STATE_CHIP_COLOR.DARK_ORANGE;
    }
    if (yellowChipArray.includes(subprogramStateKeyValue)) {
      return TABLE_STATE_CHIP_COLOR.YELLOW;
    }
    if (redChipArray.includes(subprogramStateKeyValue)) {
      return TABLE_STATE_CHIP_COLOR.RED;
    }
  };

  const handlePublishSubprogramClick = (subprogramNumber) => {
    let auxData = { subprogramId: subprogramNumber, subprogramStateKeyValue: 4 };
    props.updateSubprogramState(auxData);
  };

  return (
    <Card classes={{ root: classes.cardRoot }}>
      <CardHeader
        classes={{
          action: classes.cardHeaderAction,
          title: classes.cardHeader,
        }}
        title={
          <div>
            <div className={classes.sideBySideDiv}>Lista de Subprogramas</div>
            <div className={classes.sideBySideDiv + " " + classes.cardElementsCount}>
              {" ("}
              {props.subprogramState?.total_records}
              {")"}
            </div>
          </div>
        }
      />
      <TableContainer>
        <Box component={Table} alignItems="center" marginBottom="0!important">
          <TableHead>
            <TableRow>
              {tableHead.map((prop, key) => (
                <TableCell
                  key={key}
                  classes={{
                    root: classes.tableCellRoot + " " + classes.tableCellRootHead,
                  }}
                >
                  {prop}
                </TableCell>
              ))}
              <TableCell
                key="detailsCol"
                classes={{
                  root:
                    classes.tableCellRoot + " " + classes.tableCellRootHead + " " + classes.tableCellHeadStickyRight,
                }}
              >
                {intl.formatMessage(labels.Label_Generic_Details)}
              </TableCell>
            </TableRow>
          </TableHead>
          {subProgramList !== undefined && subProgramList.length > 0 && (
            <TableBody>
              {subProgramList?.map((prop, key) => (
                <TableRow key={key}>
                  <TableCell classes={{ root: classes.tableCellRoot }}>{prop.governmentProgramName}</TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>{prop.programName}</TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>{prop.subprogramName}</TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>{prop.vigencyDate}</TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>{prop.departmentName}</TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>{prop.benefitTypeName}</TableCell>
                  <TableCell
                    classes={{
                      root: classes.tableCellRoot + " " + classes.width10,
                    }}
                  >
                    <TableStateChip
                      state={MapSubprogramStateToTableChipColors(prop.subprogramStateKeyValue)}
                      message={prop.subprogramStateName}
                    />
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                    <Box justifyContent="center" display="flex">
                      <GenericButton
                        typeSubmit={false}
                        color="tertiary"
                        size="small"
                        disabled={prop.subprogramStateKeyValue !== SUBPROGRAM_STATES.APROVADO}
                        onClick={() => {
                          handlePublishSubprogramClick(prop.subprogramId);
                          prop.subprogramStateKeyValue = SUBPROGRAM_STATES.PUBLICADO;
                          prop.subprogramStateName = "Publicado";
                        }}
                      >
                        Publicar
                      </GenericButton>
                    </Box>
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot + " " + classes.tableCellBodyStickyRight }}>
                    <Tooltip title="Detalhes">
                      <IconButton
                        aria-label="details"
                        component={Link}
                        to={`/admin/subprograms-detail/${prop.subprogramId}`}
                      >
                        <Box
                          component={ArrowForwardIosIcon}
                          width="12px!important"
                          height="12px!important"
                          color={theme.palette.primary.main}
                        />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Box>

        {(subProgramList == null || subProgramList.length === 0) &&
          !props.subprogramState.searchSubprograms_loading && (
            <Box className={classes.noResultsInfoInTable}>Não existem resultados disponíveis.</Box>
          )}
      </TableContainer>

      <TableBackdrop open={props.subprogramState.searchSubprograms_loading} />

      <Pagination
        className={classes.alignPagination}
        count={props.subprogramState?.total_pages}
        page={props.page}
        onChange={props.handleChangePage}
        color="primary"
      ></Pagination>
    </Card>
  );
}
const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  updateSubprogramState: (data) => dispatch(updateSubprogramStateAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResultSection);
