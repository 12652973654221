const componentStyles = (theme) => ({
  menuTitle: {
    margin: "0!important",
    textTransform: "uppercase",
    display: "block",
    padding: ".5rem 1rem",
    whiteSpace: "nowrap",
  },
  menuRoot: {
    width: "100%",
    maxWidth: "20rem",
  },
  listItemRoot: {
    display: "flex",
    paddingTop: "0.25rem",
    paddingBottom: "0.25rem",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "19.07px",
    color: theme.palette.gray[900] + "!important",
    "&:hover": {
      color: theme.palette.white.main + "!important",
      backgroundColor: theme.palette.primary.greyBlue
    },
    "&:active": {
      color: theme.palette.white.main,
      backgroundColor: theme.palette.primary.darkBlue,
    },
  },
  listItemTextPrimary: {
    fontSize: "1rem",
  },
  nestedItem: {
    paddingLeft: "2rem",
    display: "flex",
    paddingTop: "0.2rem",
    paddingBottom: "0.2rem",
    "&:hover": {
      fontWeight: "900",
      color: theme.palette.white.main + "!important",
      backgroundColor: theme.palette.primary.greyBlue
    },
  },
  expandIcon: {
    marginLeft: "0.5rem",
    width: "20px",
    height: "20px",
  },
  dotIcon: {
    marginRight: "0.25rem",
    width: "10px",
    height: "10px",
  },
  menuButton: {
    color: theme.palette.gray[900],
    "&:hover": {
      fontWeight: "900",
      color: theme.palette.primary.greyBlue,
      backgroundColor: theme.palette.white.main,
      "& .MuiSvgIcon-root": {
        color: theme.palette.primary.greyBlue
      },
    },
    "&:active": {
      fontWeight: "900",
      color: theme.palette.primary.darkBlue,
      backgroundColor: theme.palette.white.main,
      "& .MuiSvgIcon-root": {
        color: theme.palette.primary.darkBlue
      },
    },
  }
});

export default componentStyles;
