import React from "react";
//redux
import { connect } from "react-redux";
import { scheduleKitDeliveryAction } from "redux/actions/stockAction";
//intl
import { useIntl } from "react-intl";
import { labels } from "resources/resources";
import { useForm, FormProvider } from "react-hook-form";
// @material-ui/core components
import Box from "@material-ui/core/Box";
import GenericButton from "components/Buttons/GenericButton";
//import custom components

import GeneralData from "./GeneralData";
import kitDeliveryMap from "mappers/kitDeliveryMap";


function DeliverKit(props) {
  const intl = useIntl();
  const methods = useForm({
    mode: "onChange",
  });
  const {
    handleSubmit,
    getValues,
    setValue,
    trigger,
    register,
    formState: { errors },
  } = methods;

  const onSubmit = (data) => {
    props.scheduleKitDelivery(
      kitDeliveryMap(
        data,
        props.stockState.kitDeliveryDetails?.totalKits,
        props.stockState.kitDeliveryDetails?.applicationId
      )
    );
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <GeneralData
            getValues={getValues}
            errors={errors}
            setValue={setValue}
            trigger={trigger}
            register={register}
            isEditable={true}
          />
          <Box textAlign="center" marginTop="15px" >
            <GenericButton 
            typeSubmit={true}
            color={"primary"}
            >
              {intl.formatMessage(labels.Label_Generic_Submit_Button)}
            </GenericButton>
          </Box>
        </form>
      </FormProvider>
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  scheduleKitDelivery: (formData) =>
    dispatch(scheduleKitDeliveryAction(formData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeliverKit);
