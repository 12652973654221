import React, { useState } from "react";
import { connect } from "react-redux";
import { useIntl } from "react-intl";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";

import componentStylesGeneric from "assets/theme/views/admin/generic";
import { titles, labels } from "resources/resources";
import GovernmentProgramsApplicationsTab from "views/admin/UserOverview/Components/Tabs/GovernmentProgramsApplicationsTab";
import OpportunityApplicationsTab from "views/admin/UserOverview/UserSingular/Tabs/ApplicationTypesTabs/OpportunityApplicationsTab";
import { OPPORTUNITY_TYPE_ID } from "utils/consts";
import GenericButton from "components/Buttons/GenericButton";
import GenericDialog from "components/Dialogs/GenericDialogV2";
import AddCandidateToOpportunitiesModal from "views/admin/UserOverview/UserSingular/Modals/AddCandidateToOpportunitiesModal";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function ApplicationsListSection(props) {
  const classes = useStylesGeneric();
  const intl = useIntl();
  const [tabStep, setTabStep] = useState(
    props.selectedTab === "internshipTab" || props.selectedTab === "employmentTab"
      ? props.selectedTab
      : "governmentProgramsTab"
  );
  const [showAddCandidateToOpportunitiesModal, setShowAddCandidateToOpportunitiesModal] = useState(false);
  const [refreshPages, setRefreshPages] = useState(false);

  const handleTabStep = (event, value) => {
    if (value !== null) {
      setTabStep(value);
    }
  };

  const handleOpenAddCandidateToOpportunitiesModal = () => {
    setShowAddCandidateToOpportunitiesModal(true);
  };

  const handleCloseAddCandidateToOpportunitiesModal = () => {
    setShowAddCandidateToOpportunitiesModal(false);
  };

  const refreshResults = () => {
    handleRefreshPages(true);
  };

  const handleRefreshPages = (newValue) => {
    setRefreshPages(newValue);
  };

  function loadTabContent() {
    switch (tabStep) {
      case "governmentProgramsTab":
        return <GovernmentProgramsApplicationsTab />;
      case "internshipTab":
        return (
          <OpportunityApplicationsTab
            opportunityTypeId={OPPORTUNITY_TYPE_ID.ESTAGIO_PROFISSIONAL}
            refreshPages={refreshPages}
            handleRefreshPages={handleRefreshPages}
          />
        );
      case "employmentTab":
        return (
          <OpportunityApplicationsTab
            opportunityTypeId={OPPORTUNITY_TYPE_ID.EMPREGO}
            refreshPages={refreshPages}
            handleRefreshPages={handleRefreshPages}
          />
        );
      default:
        return <GovernmentProgramsApplicationsTab />;
    }
  }

  return (
    <>
      <Card className={classes.cardRoot}>
        <CardHeader
          classes={{
            action: classes.cardHeaderAction,
            title: classes.cardHeader,
          }}
          title={intl.formatMessage(titles.Title_UserOverview_ApplicationsTab_ApplicationsSection)}
          action={
            <GenericButton
              typeSubmit={false}
              color="tertiary"
              size="medium"
              onClick={handleOpenAddCandidateToOpportunitiesModal}
              disabled={tabStep === "governmentProgramsTab"}
            >
              {intl.formatMessage(
                labels.Label_UserOverview_ApplicationsTab_ApplicationsSection_CreateOpportunityApplication
              )}
            </GenericButton>
          }
          data-testid="user-overview-applications-section-title"
        ></CardHeader>
        <CardContent>
          <Box component={Grid} container justifyContent="center">
            <ToggleButtonGroup
              value={tabStep}
              exclusive
              onChange={handleTabStep}
              aria-label="tab Step"
              className={classes.changeTabsGroup}
              style={{ marginLeft: "16px" }}
            >
              <ToggleButton
                value="governmentProgramsTab"
                className={classes.changeTabs}
                data-testid="user-overview-applications-section-government-program-tab"
              >
                {intl.formatMessage(
                  labels.Label_UserOverview_ApplicationsTab_ApplicationsSection_GovernmentProgramsTab
                )}
              </ToggleButton>
              <ToggleButton
                value="internshipTab"
                className={classes.changeTabs}
                data-testid="user-overview-applications-section-internship-tab"
              >
                {intl.formatMessage(labels.Label_UserOverview_ApplicationsTab_ApplicationsSection_InternshipsTab)}
              </ToggleButton>
              <ToggleButton
                value="employmentTab"
                className={classes.changeTabs}
                data-testid="user-overview-applications-section-employment-tab"
              >
                {intl.formatMessage(labels.Label_UserOverview_ApplicationsTab_ApplicationsSection_EmploymentTab)}
              </ToggleButton>
            </ToggleButtonGroup>

            <Grid item xs={12} lg={12} md={12}>
              {loadTabContent()}
            </Grid>
          </Box>
        </CardContent>
      </Card>

      {/* Add Candidate to Opportunity Modal */}
      <GenericDialog
        maxWidth={"lg"}
        open={showAddCandidateToOpportunitiesModal}
        onClose={handleCloseAddCandidateToOpportunitiesModal}
      >
        <AddCandidateToOpportunitiesModal
          userOverview={props?.usersState?.getFOUserOverview_result}
          handleClose={handleCloseAddCandidateToOpportunitiesModal}
          opportunityTypeKey={tabStep}
          onSuccess={refreshResults}
        />
      </GenericDialog>
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

export default connect(mapStateToProps)(ApplicationsListSection);
