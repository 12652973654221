import { MONETARY_DECIMAL_SEPARATOR, PAGINATION, REPORT_TYPES } from "utils/consts";

export function loansFilterMap(formData) {
  if (formData !== null && formData !== undefined) {
    return {
      nameOfUser: formData.nameOfUser,
      identityCardOrTaxIdNum: formData.identityCardOrTaxIdNum,
      provinceId: formData.province?.code,
      numApplication: formData.applicationNumber,
      governmentProgramId: formData.governmentProgramId?.code,
      loanStateId: formData.loanState?.code,
      loanTypeId: formData.loanType?.code,
      bankId: formData.bank?.code,
      pageIndex: PAGINATION.FIRST_PAGE,
      pageSize: PAGINATION.PAGE_SIZE,
    };
  }
}

export function loansExportReportMap(filterData) {
  if (filterData !== null && filterData !== undefined) {
    return {
      reportEnumKey: REPORT_TYPES.LOANS_SEARCH_RESULTS,
      parameters: JSON.stringify(filterData),
    };
  }
}

export function updateLoanMap(formData) {
  if (formData !== null && formData !== undefined) {
    return {
      id: formData.loanId,
      amountToBePaid: formData.amountToBePaid?.replace(MONETARY_DECIMAL_SEPARATOR.WEB, MONETARY_DECIMAL_SEPARATOR.API),
      isMissingPayments: formData.isMissingPayments,
    };
  }

  return formData;
}

export default loansFilterMap;
