import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import { connect } from "react-redux";
//lodash
import _ from "lodash";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import { Card, CardContent } from "@material-ui/core";
import { CardHeader } from "@material-ui/core";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
// @material-ui/icons components
//import custom components
import PageTitleHeader from "components/Headers/PageTitleHeader.js";
import LocationHeader from "components/Headers/LocationHeader.js";
import TableStateChip from "components/Cards/Tables/TableStateChip";
import GenericBackdrop from "components/Backdrops/GenericBackdrop";
import GenericButton from "components/Buttons/GenericButton";
import ApproveDialog from "views/admin/Loans/Dialogs/ApproveDialog";
import RejectDialog from "views/admin/Loans/Dialogs/RejectDialog";
import UpdateDialog from "views/admin/Loans/Dialogs/UpdateDialog";
import DisplayField from "components/Fields/DisplayField";
import { getLoanAction } from "redux/actions/loansAction.js";
import { UNITS, TABLE_STATE_CHIP_COLOR, LOAN_STATES } from "utils/consts";
import { labels, titles } from "resources/resources";
import useScrollDirection from "hooks/useScrollDirection";

import componentStylesGeneric from "assets/theme/views/admin/generic";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function LoanDetails(props) {
  const classes = useStylesGeneric();
  const intl = useIntl();
  const history = useHistory();
  const scrollDirection = useScrollDirection();
  const [loanNumber, setLoanNumber] = useState();
  const [data, setData] = useState({});

  //Dialogs Logic
  const DIALOG = { SHOW: true, HIDE: false };
  const [dialogs, setDialogs] = useState({
    approveModal: false,
    rejectModal: false,
    updateModal: false,
  });

  const showHideDialog = (dialog, op) => {
    let dialogs_temp = _.mapValues(dialogs, () => false);

    dialogs_temp[dialog] = op;

    setDialogs(dialogs_temp);
  };

  const MapStateToTableChipColors = (state) => {
    const greenChipArray = [LOAN_STATES.REEMBOLSADO];
    const lightOrangeChipArray = [LOAN_STATES.APROVADO];
    const orangeChipArray = [LOAN_STATES.REJEITADO];
    const whiteChipArray = [LOAN_STATES.EM_APROVACAO];

    if (greenChipArray.includes(state)) {
      return TABLE_STATE_CHIP_COLOR.GREEN;
    }
    if (orangeChipArray.includes(state)) {
      return TABLE_STATE_CHIP_COLOR.DARK_ORANGE;
    }
    if (lightOrangeChipArray.includes(state)) {
      return TABLE_STATE_CHIP_COLOR.LIGHT_ORANGE;
    }
    if (whiteChipArray.includes(state)) return TABLE_STATE_CHIP_COLOR.BLUE;
  };

  // Fetch Data
  useEffect(() => {
    setLoanNumber(props.match.params.loanId);
    props.getLoan(props.match.params.loanId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.match.params.loanId]);

  useEffect(() => {
    setData(props.loansState.loan_details);
  }, [props.loansState.loan_details]);

  function handleApprove() {
    showHideDialog("approveModal", DIALOG.SHOW);
  }

  function handleReject() {
    showHideDialog("rejectModal", DIALOG.SHOW);
  }

  function handleUpdate() {
    showHideDialog("updateModal", DIALOG.SHOW);
  }

  return (
    <>
      <GenericBackdrop open={props.loansState.loan_details_loading} />

      {scrollDirection === "up" && (
        <div className={classes.breadcrumbs}>
          <LocationHeader
            section={intl.formatMessage(titles.Title_Loans_LoanDetails_Section)}
            subsection={intl.formatMessage(titles.Title_Loans_LoanDetails_Subsection)}
            subsectionLink={"/admin/loans-management"}
          />
        </div>
      )}

      <Container maxWidth={false} component={Box} classes={{ root: classes.containerRootAfterBreadcrumbs }}>
        <PageTitleHeader title={data?.applicationNumber} />

        <Container maxWidth={false}>
          <Card classes={{ root: classes.cardRoot }}>
            <CardHeader
              classes={{ title: classes.cardHeader }}
              title={intl.formatMessage(titles.Title_Loans_LoanDetails_GeneralData_Card)}
            />
            <CardContent>
              <Grid container>
                {/* User Singular Details */}
                {data?.userSingularGeneralDataSection != null && (
                  <>
                    <DisplayField
                      xs={12}
                      sm={8}
                      md={6}
                      label={intl.formatMessage(labels.Label_Admin_GeneralDataSection_NameField)}
                      data={data.userSingularGeneralDataSection.nameOfUser}
                    />
                    <DisplayField
                      xs={12}
                      sm={4}
                      md={3}
                      label={intl.formatMessage(labels.Label_Admin_GeneralDataSection_GenderField)}
                      data={data.userSingularGeneralDataSection.gender}
                    />
                    <DisplayField
                      xs={12}
                      sm={6}
                      md={3}
                      label={intl.formatMessage(labels.Label_Admin_GeneralDataSection_NumBIField)}
                      data={data.userSingularGeneralDataSection.numBI}
                    />
                    <DisplayField
                      xs={12}
                      sm={6}
                      md={3}
                      label={intl.formatMessage(labels.Label_Admin_GeneralDataSection_BirthdayField)}
                      data={data.userSingularGeneralDataSection.birthDate}
                    />
                    <DisplayField
                      xs={12}
                      sm={4}
                      md={3}
                      label={intl.formatMessage(labels.Label_Admin_GeneralDataSection_ProvinceField)}
                      data={data.userSingularGeneralDataSection.province}
                    />
                    <DisplayField
                      xs={12}
                      sm={4}
                      md={3}
                      label={intl.formatMessage(labels.Label_Admin_GeneralDataSection_MunicipalityField)}
                      data={data.userSingularGeneralDataSection.municipality}
                    />
                    <DisplayField
                      xs={12}
                      sm={4}
                      md={3}
                      label={intl.formatMessage(labels.Label_Admin_GeneralDataSection_MobilePhoneField)}
                      data={data.userSingularGeneralDataSection.mobilePhone}
                    />
                  </>
                )}
                {/* User Colective Details */}

                {data?.userColectiveGeneralDataSection != null && (
                  <>
                    <DisplayField
                      xs={12}
                      sm={4}
                      md={6}
                      label={"Nome da Empresa"}
                      data={data.userColectiveGeneralDataSection.companyName}
                    />
                    <DisplayField
                      xs={12}
                      sm={4}
                      md={3}
                      label={"NIF da Empresa"}
                      data={data.userColectiveGeneralDataSection.companyTaxIdNumber}
                    />
                    <DisplayField
                      xs={12}
                      sm={4}
                      md={3}
                      label={"Telemóvel da Empresa"}
                      data={data.userColectiveGeneralDataSection.companyPhone}
                    />
                    <DisplayField
                      xs={12}
                      sm={4}
                      md={6}
                      label={"Email da Empresa"}
                      data={data.userColectiveGeneralDataSection.companyEmail}
                    />
                    <DisplayField
                      xs={12}
                      sm={4}
                      md={3}
                      label={"Província da Empresa"}
                      data={data.userColectiveGeneralDataSection.companyProvince}
                    />
                    <DisplayField
                      xs={12}
                      sm={4}
                      md={3}
                      label={"Município da Empresa"}
                      data={data.userColectiveGeneralDataSection.companyMunicipality}
                    />
                    <DisplayField
                      xs={12}
                      sm={4}
                      md={6}
                      label={"Nome do Responsável"}
                      data={data.userColectiveGeneralDataSection.responsibleName}
                    />
                    <DisplayField
                      xs={12}
                      sm={4}
                      md={3}
                      label={"Telemóvel do Responsável"}
                      data={data.userColectiveGeneralDataSection.responsibleMobilePhone}
                    />
                    <DisplayField
                      xs={12}
                      sm={4}
                      md={3}
                      label={"Email do Responsável"}
                      data={data.userColectiveGeneralDataSection.responsibleEmail}
                    />
                  </>
                )}
                {/* Loan Details */}
                <DisplayField
                  xs={12}
                  sm={4}
                  md={3}
                  label={intl.formatMessage(labels.Label_Admin_Loans_ApplicationNumber)}
                  data={data?.applicationNumber}
                />
                <DisplayField
                  xs={12}
                  sm={4}
                  md={3}
                  label={intl.formatMessage(labels.Label_Admin_Loans_GovernmentProgram)}
                  data={data?.governmentProgramName}
                />
                <Grid item xs={12} sm={4} md={3}>
                  <FormGroup>
                    <FormLabel className={classes.detailFormLabels}>
                      {intl.formatMessage(labels.Label_Admin_Loans_State)}
                    </FormLabel>
                    <Box width="90%">
                      <TableStateChip state={MapStateToTableChipColors(data?.state)} message={data?.stateDescription} />
                    </Box>
                  </FormGroup>
                </Grid>
                <DisplayField
                  xs={12}
                  sm={4}
                  md={3}
                  label={intl.formatMessage(labels.Label_Admin_Loans_LastUpdateDate)}
                  data={data?.lastInstallmentDate ?? intl.formatMessage(labels.Label_Not_Applicable)}
                />
                {/* New Line md */}
                <DisplayField
                  xs={12}
                  sm={4}
                  md={3}
                  label={intl.formatMessage(labels.Label_Admin_Loans_AmountToBePaid)}
                  data={
                    data?.amountToBePaid
                      ? intl.formatNumber(data?.amountToBePaid) + " " + UNITS.MONETARY
                      : intl.formatMessage(labels.Label_Not_Applicable)
                  }
                />
                <DisplayField
                  xs={12}
                  sm={4}
                  md={3}
                  label={intl.formatMessage(labels.Label_Admin_Loans_AmountApproved)}
                  data={intl.formatNumber(data?.amountApproved) + " " + UNITS.MONETARY}
                />
                <DisplayField
                  xs={12}
                  sm={4}
                  md={3}
                  label={intl.formatMessage(labels.Label_Admin_Loans_IsMissingPayments)}
                  data={
                    data?.isMissingPayments ? intl.formatMessage(labels.Label_Yes) : intl.formatMessage(labels.Label_No)
                  }
                />
                <DisplayField
                  xs={12}
                  sm={4}
                  md={3}
                  label={intl.formatMessage(labels.Label_Admin_Loans_BankName)}
                  data={data?.bankName}
                />
                {/* New Line md */}
                <DisplayField
                  xs={12}
                  sm={4}
                  md={3}
                  label={intl.formatMessage(labels.Label_Admin_Loans_LoanType)}
                  data={data?.loanTypeDescription}
                />

                {data?.state === LOAN_STATES.REJEITADO && (
                  <>
                    <DisplayField
                      xs={12}
                      sm={8}
                      md={3}
                      label={intl.formatMessage(labels.Label_Admin_Loans_RejectionMotive)}
                      data={data?.rejectMotive}
                    />
                    <DisplayField
                      xs={12}
                      sm={12}
                      md={6}
                      label={intl.formatMessage(labels.Label_Admin_Loans_RejectionObservations)}
                      data={data?.rejectMotiveObservations}
                    />
                  </>
                )}
              </Grid>
            </CardContent>
          </Card>

          <Grid container>
            <Grid item xs={12} lg={6} xl={8}></Grid>
            <Grid item xs={12} lg={3} xl={2}>
              <FormGroup>{showRejectCancelButton(data?.state)}</FormGroup>
            </Grid>
            <Grid item xs={12} lg={3} xl={2}>
              <FormGroup>{showApproveUpdateButton(data?.state)}</FormGroup>
            </Grid>
          </Grid>
        </Container>
      </Container>

      {/* Approve */}
      <ApproveDialog
        name="approve-dialog"
        maxWidth={"xl"}
        open={dialogs.approveModal}
        loanNumber={loanNumber}
        history={history}
        onClose={() => showHideDialog("approveModal", DIALOG.HIDE)}
      />

      {/* Reject */}
      <RejectDialog
        name="reject-dialog"
        maxWidth={"xl"}
        open={dialogs.rejectModal}
        loanNumber={loanNumber}
        history={history}
        onClose={() => showHideDialog("rejectModal", DIALOG.HIDE)}
      />

      {/* Update */}
      <UpdateDialog
        name="update-dialog"
        maxWidth={"xl"}
        open={dialogs.updateModal}
        loanNumber={loanNumber}
        history={history}
        onClose={() => showHideDialog("updateModal", DIALOG.HIDE)}
      />
    </>
  );

  function showApproveUpdateButton(state) {
    switch (state) {
      case LOAN_STATES.EM_APROVACAO:
        return (
          <GenericButton typeSubmit={false} color="primary" onClick={() => handleApprove()}>
            {intl.formatMessage(labels.Label_Approve_Submit)}
          </GenericButton>
        );
      case LOAN_STATES.APROVADO:
        return (
          <GenericButton typeSubmit={false} color="primary" onClick={() => handleUpdate()}>
            {intl.formatMessage(labels.Label_Update_Submit)}
          </GenericButton>
        );
      case LOAN_STATES.REEMBOLSADO:
      case LOAN_STATES.REJEITADO:
      default:
        return <></>;
    }
  }

  function showRejectCancelButton(state) {
    switch (state) {
      case LOAN_STATES.EM_APROVACAO:
        return (
          <GenericButton typeSubmit={false} color="secondary" onClick={() => handleReject()}>
            {intl.formatMessage(labels.Label_Reject_Submit)}
          </GenericButton>
        );
      case LOAN_STATES.APROVADO:
      case LOAN_STATES.REEMBOLSADO:
      case LOAN_STATES.REJEITADO:
        return (
          <GenericButton typeSubmit={false} color="secondary" onClick={() => history.goBack()}>
            {intl.formatMessage(labels.Label_GoBack_Button)}
          </GenericButton>
        );
      default:
        return;
    }
  }
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getLoan: (loanNumber) => dispatch(getLoanAction(loanNumber)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoanDetails);
