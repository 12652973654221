import React, { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import clsx from "clsx";
import { connect } from "react-redux";
import { useIntl } from "react-intl";
// @material-ui/core components
import { makeStyles, useTheme } from "@material-ui/core/styles";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormHelperText from "@material-ui/core/FormHelperText";
import { Card, CardHeader, CardContent, FilledInput } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import Tooltip from "@material-ui/core/Tooltip";
// @material-ui/icons components
import HelpIcon from "@material-ui/icons/Help";
//import custom components
import FormSelect from "components/FormFields/FormSelect";
import FormMultipleSelect from "components/FormFields/FormMultipleSelect";
import GenericButton from "components/Buttons/GenericButton";
import { getBackOfficeUserInfoAction } from "redux/actions/usersAction";
import { getDataSetByNameAction, getBackOfficeRolesAction } from "redux/actions/datasetsAction";
import {
  getSelectOptionsElementByCode,
  getMultipleSelectOptionsElementByLabel,
  getMultipleSelectOptionsElementByCode,
} from "utils/formSelect";
import { DATASET, ROLE_KEY_VALUE } from "utils/consts";
import { labels } from "resources/resources";

import componentStylesGeneric from "assets/theme/views/admin/generic.js";
const useStylesGeneric = makeStyles(componentStylesGeneric);

function PersonalForm(props) {
  const theme = useTheme();
  const classes = useStylesGeneric();
  const intl = useIntl();

  const [showBankSelection, setShowBankSelection] = useState(false);
  const [showEmploymentAndEntrepreneurshipSelection, setShowEmploymentAndEntrepreneurshipSelection] = useState(false);

  const {
    getValues,
    setValue,
    control,
    trigger,
    unregister,
    formState: { errors },
  } = useFormContext();

  useEffect(() => {
    if (props.dataSetState[DATASET.PROVINCES] === undefined || props.dataSetState[DATASET.PROVINCES] === []) {
      props.getDataSet(DATASET.PROVINCES);
    }

    if (
      props.dataSetState[DATASET.BACKOFFICE_ROLES] === undefined ||
      props.dataSetState[DATASET.BACKOFFICE_ROLES]?.length === 0
    ) {
      props.getBackOfficeRoles(DATASET.BACKOFFICE_ROLES);
    }

    if (props.dataSetState[DATASET.BANK] === undefined || props.dataSetState[DATASET.BANK] === []) {
      props.getDataSet(DATASET.BANK);
    }

    if (props.dataSetState[DATASET.LOAN_TYPE] === undefined || props.dataSetState[DATASET.LOAN_TYPE] === []) {
      props.getDataSet(DATASET.LOAN_TYPE);
    }

    if (
      props.dataSetState[DATASET.EMPLOYMENT_CENTERS] === undefined ||
      props.dataSetState[DATASET.EMPLOYMENT_CENTERS] === []
    ) {
      props.getDataSet(DATASET.EMPLOYMENT_CENTERS);
    }

    props.getBackOfficeUserInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.usersInfo.result !== undefined) setFormValuesOnMount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.usersInfo.result]);

  useEffect(() => {
    if (props.dataSetState) {
      var provinceList = props.dataSetState[DATASET.PROVINCES] ? props.dataSetState[DATASET.PROVINCES] : [];
      var provinceId = props.usersInfo.result !== undefined ? props.usersInfo.result.province : null;
      var selectedProvince = getSelectOptionsElementByCode(provinceList, provinceId);
      setValue("province", selectedProvince);

      if (selectedProvince) props.getDataSet(DATASET.MUNICIPALITIES, selectedProvince.keyValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dataSetState[DATASET.PROVINCES]]);

  useEffect(() => {
    if (props.dataSetState) {
      var municipalityList = props.dataSetState[DATASET.MUNICIPALITIES]
        ? props.dataSetState[DATASET.MUNICIPALITIES]
        : [];
      var municipalities = props.usersInfo.result !== undefined ? props.usersInfo.result.municipality : null;
      setValue("municipality", getSelectOptionsElementByCode(municipalityList, municipalities));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dataSetState[DATASET.MUNICIPALITIES]]);

  useEffect(() => {
    if (props.dataSetState) {
      var banksList = props.dataSetState[DATASET.BANK] ? props.dataSetState[DATASET.BANK] : [];
      var managerBankId = props.usersInfo.result !== undefined ? props.usersInfo.result.managerBankId : null;
      setValue("managerBank", getSelectOptionsElementByCode(banksList, managerBankId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dataSetState[DATASET.BANK]]);

  useEffect(() => {
    if (props.dataSetState) {
      var loanTypesList = props.dataSetState[DATASET.LOAN_TYPE] ? props.dataSetState[DATASET.LOAN_TYPE] : [];
      var managerLoanTypes = props.usersInfo.result !== undefined ? props.usersInfo.result.managerLoanTypes : null;
      setValue("managerLoanTypes", getMultipleSelectOptionsElementByCode(loanTypesList, managerLoanTypes));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dataSetState[DATASET.LOAN_TYPE]]);

  useEffect(() => {
    if (props.dataSetState) {
      var employmentCenterList = props.dataSetState[DATASET.EMPLOYMENT_CENTERS]
        ? props.dataSetState[DATASET.EMPLOYMENT_CENTERS]
        : [];
      var selectedEmploymentCenter = getSelectOptionsElementByCode(
        employmentCenterList,
        props.usersInfo?.result?.employmentCenterId
      );
      setValue("employmentCenter", selectedEmploymentCenter);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dataSetState[DATASET.EMPLOYMENT_CENTERS]]);

  const setFormValuesOnMount = () => {
    setValue("name", props.usersInfo.result !== undefined ? props.usersInfo.result.name : null);
    setValue("surname", props.usersInfo.result !== undefined ? props.usersInfo.result.surname : null);
    var provinceList = props.dataSetState[DATASET.PROVINCES] ? props.dataSetState[DATASET.PROVINCES] : [];

    var provinceId = props.usersInfo.result !== undefined ? props.usersInfo.result.province : null;

    var selectedProvince = getSelectOptionsElementByCode(provinceList, provinceId);
    setValue("province", selectedProvince);

    if (selectedProvince) props.getDataSet(DATASET.MUNICIPALITIES, selectedProvince.keyValue);

    var municipalityList = props.dataSetState[DATASET.MUNICIPALITIES] ? props.dataSetState[DATASET.MUNICIPALITIES] : [];

    var municipalities = props.usersInfo.result !== undefined ? props.usersInfo.result.municipality : null;

    setValue("municipality", getSelectOptionsElementByCode(municipalityList, municipalities));

    var active = props.usersInfo.result !== undefined ? props.usersInfo.result.active : null;

    setValue("active", getSelectOptionsElementByCode(DATASET.YES_OR_NO_DROPDOWN, active));

    setValue("email", props.usersInfo.result !== undefined ? props.usersInfo.result.email : null);

    var rolesList = props.dataSetState[DATASET.BACKOFFICE_ROLES] ? props.dataSetState[DATASET.BACKOFFICE_ROLES] : [];
    var roles = props.usersInfo.result !== undefined ? props.usersInfo.result.roles : null;
    setValue("function", getMultipleSelectOptionsElementByLabel(rolesList, roles));

    var banksList = props.dataSetState[DATASET.BANK] ? props.dataSetState[DATASET.BANK] : [];
    var managerBankId = props.usersInfo.result !== undefined ? props.usersInfo.result.managerBankId : null;
    setValue("managerBank", getSelectOptionsElementByCode(banksList, managerBankId));

    var loanTypesList = props.dataSetState[DATASET.LOAN_TYPE] ? props.dataSetState[DATASET.LOAN_TYPE] : [];
    var managerLoanTypes = props.usersInfo.result !== undefined ? props.usersInfo.result.managerLoanTypes : null;
    setValue("managerLoanTypes", getMultipleSelectOptionsElementByCode(loanTypesList, managerLoanTypes));

    var employmentCenterList = props.dataSetState[DATASET.EMPLOYMENT_CENTERS]
      ? props.dataSetState[DATASET.EMPLOYMENT_CENTERS]
      : [];
    var selectedEmploymentCenter = getSelectOptionsElementByCode(
      employmentCenterList,
      props.usersInfo?.result?.employmentCenterId
    );
    setValue("employmentCenter", selectedEmploymentCenter);
  };

  function handleChangePassword() {
    props.handleChangePassword();
  }

  function getShowExtraFields() {
    var selectedFunctions = getValues("function");

    if (selectedFunctions && selectedFunctions.length > 0) {
      if (selectedFunctions.some((op) => op.code === ROLE_KEY_VALUE.BANCOS)) {
        setShowBankSelection(true);
      } else {
        setShowBankSelection(false);
        unregister("managerBank");
        unregister("managerLoanTypes");
      }

      if (
        selectedFunctions.some(
          (op) =>
            op.code === ROLE_KEY_VALUE.EMPLOYMENT_AND_ENTREPRENEURSHIP_TECHNICAL_TEAM ||
            selectedFunctions.some((op) => op.code === ROLE_KEY_VALUE.EMPLOYMENT_AND_ENTREPRENEURSHIP_MANAGEMENT_TEAM)
        )
      ) {
        setShowEmploymentAndEntrepreneurshipSelection(true);
      } else {
        setShowEmploymentAndEntrepreneurshipSelection(false);
        unregister("employmentCenter");
      }
    } else {
      setShowBankSelection(false);
      unregister("managerBank");
      unregister("managerLoanTypes");

      setShowEmploymentAndEntrepreneurshipSelection(false);
      unregister("employmentCenter");
    }
  }

  useEffect(() => {
    getShowExtraFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getValues("managerBank")]);

  useEffect(() => {
    const provinceValue = getValues("province");
    const functionValue = getValues("function");

    getShowExtraFields();

    if (provinceValue && functionValue) {
      if (
        functionValue?.some(
          (op) =>
            op.code === ROLE_KEY_VALUE.EMPLOYMENT_AND_ENTREPRENEURSHIP_TECHNICAL_TEAM ||
            functionValue?.some((op) => op.code === ROLE_KEY_VALUE.EMPLOYMENT_AND_ENTREPRENEURSHIP_MANAGEMENT_TEAM)
        )
      ) {
        props.getDataSet(DATASET.EMPLOYMENT_CENTERS, provinceValue.keyValue);
      }
    } else if (!provinceValue && functionValue) {
      setValue("employmentCenter", undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getValues("province"), getValues("function")]);

  return (
    <>
      <Card>
        <CardHeader classes={{ title: classes.cardHeader }} title="Dados pessoais"></CardHeader>
        <CardContent>
          <Box>
            <Grid container>
              <Grid item xs={12} sm={4} md={4}>
                <FormGroup>
                  <FormLabel>Nome</FormLabel>
                  <Controller
                    name="name"
                    control={control}
                    component={Box}
                    defaultValue=""
                    rules={{
                      required: {
                        value: true,
                        message: "O campo é obrigatório",
                      },
                    }}
                    render={({ field: { onChange, name, value } }) => (
                      <OutlinedInput
                        name={name}
                        value={value}
                        onChange={onChange}
                        fullWidth
                        autoComplete="off"
                        type="text"
                        classes={{
                          notchedOutline: clsx({
                            [classes.borderWarning]: errors["name"] !== undefined,
                          }),
                        }}
                      />
                    )}
                  />
                  {errors["name"] !== undefined && (
                    <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                      {errors["name"].message}
                    </FormHelperText>
                  )}
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={5} md={5}>
                <FormGroup>
                  <FormLabel>Apelido</FormLabel>
                  <Controller
                    name="surname"
                    defaultValue=""
                    control={control}
                    component={Box}
                    rules={{
                      required: {
                        value: true,
                        message: "O campo é obrigatório",
                      },
                    }}
                    render={({ field }) => (
                      <OutlinedInput
                        {...field}
                        fullWidth
                        autoComplete="off"
                        type="text"
                        classes={{
                          notchedOutline: clsx({
                            [classes.borderWarning]: errors["lastname"] !== undefined,
                          }),
                        }}
                      />
                    )}
                  />
                  {errors["surname"] !== undefined && (
                    <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                      {errors["surname"].message}
                    </FormHelperText>
                  )}
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={3} md={3}>
                <FormGroup>
                  <FormLabel>Activo</FormLabel>
                  <FormSelect
                    control={control}
                    disabled={true}
                    fieldName="active"
                    selectOptions={DATASET.YES_OR_NO_DROPDOWN}
                    setValue={setValue}
                    getValues={getValues}
                    errors={errors}
                    classes={classes}
                  />
                  {errors["active"] !== undefined && (
                    <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                      {errors["active"].message}
                    </FormHelperText>
                  )}
                </FormGroup>
              </Grid>

              <Grid item xs={12} sm={4} md={4}>
                <FormGroup>
                  <FormLabel>Província</FormLabel>
                  <FormSelect
                    control={control}
                    fieldName="province"
                    selectOptions={props.dataSetState[DATASET.PROVINCES] ? props.dataSetState[DATASET.PROVINCES] : []}
                    disabled={true}
                    loadChildrenDataSet={props.getDataSet}
                    childrenDataSet={DATASET.MUNICIPALITIES}
                    childrenSelect="municipality"
                    getValues={getValues}
                    setValue={setValue}
                    errors={errors}
                    classes={classes}
                  />
                  {errors["province"] !== undefined && (
                    <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                      {errors["province"].message}
                    </FormHelperText>
                  )}
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <FormGroup>
                  <FormLabel>Município</FormLabel>
                  <FormSelect
                    control={control}
                    fieldName="municipality"
                    selectOptions={
                      props.dataSetState[DATASET.MUNICIPALITIES] ? props.dataSetState[DATASET.MUNICIPALITIES] : []
                    }
                    disabled={true}
                    getValues={getValues}
                    errors={errors}
                    classes={classes}
                  />
                  {errors["municipality"] !== undefined && (
                    <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                      {errors["municipality"].message}
                    </FormHelperText>
                  )}
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <FormGroup>
                  <FormLabel>Função</FormLabel>
                  <FormMultipleSelect
                    control={control}
                    fieldName="function"
                    selectOptions={
                      props.dataSetState[DATASET.BACKOFFICE_ROLES] ? props.dataSetState[DATASET.BACKOFFICE_ROLES] : []
                    }
                    disabled={true}
                    onOptionChange={getShowExtraFields}
                    limitTags={1}
                    getValues={getValues}
                    errors={errors}
                    classes={classes}
                  />
                  {errors["function"] !== undefined && (
                    <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                      {errors["function"].message}
                    </FormHelperText>
                  )}
                </FormGroup>
              </Grid>

              {showBankSelection && (
                <>
                  <Grid item xs={12} sm={12} md={4}>
                    <FormGroup>
                      <FormLabel>Banco a gerir</FormLabel>
                      <FormSelect
                        control={control}
                        fieldName="managerBank"
                        selectOptions={props.dataSetState[DATASET.BANK] ? props.dataSetState[DATASET.BANK] : []}
                        getValues={getValues}
                        disabled={true}
                        classes={classes}
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <FormGroup>
                      <FormLabel>Tipos de produtos bancários a gerir</FormLabel>
                      <FormMultipleSelect
                        control={control}
                        fieldName="managerLoanTypes"
                        selectOptions={
                          props.dataSetState[DATASET.LOAN_TYPE] ? props.dataSetState[DATASET.LOAN_TYPE] : []
                        }
                        limitTags={2}
                        getValues={getValues}
                        disabled={true}
                        classes={classes}
                      />
                    </FormGroup>
                  </Grid>
                </>
              )}

              {showEmploymentAndEntrepreneurshipSelection && (
                <Grid item xs={12} sm={12} md={4}>
                  <FormGroup>
                    <FormLabel>
                      Centro de Emprego
                      <Tooltip title="O centro de Emprego depende da província">
                        <HelpIcon className={classes.tooltipInfoIcon} />
                      </Tooltip>
                    </FormLabel>
                    <FormSelect
                      control={control}
                      fieldName="employmentCenter"
                      selectOptions={
                        props.dataSetState[DATASET.EMPLOYMENT_CENTERS]
                          ? props.dataSetState[DATASET.EMPLOYMENT_CENTERS]
                          : []
                      }
                      setValue={setValue}
                      getValues={getValues}
                      disabled={true}
                      classes={classes}
                    />
                  </FormGroup>
                </Grid>
              )}

              <Grid item xs={12} sm={12} md={12}>
                <FormGroup>
                  <FormLabel>E-mail</FormLabel>
                  <Controller
                    name="email"
                    defaultValue=""
                    control={control}
                    component={Box}
                    rules={{
                      validate: {
                        validEmail: (value) => {
                          if (value && !/\S+@\S+\.\S+/.test(value)) return "Formato inválido";
                          return true;
                        },
                      },
                    }}
                    render={({ field: { onChange, name, value } }) => (
                      <OutlinedInput
                        name={name}
                        value={value}
                        fullWidth
                        autoComplete="off"
                        type="email"
                        disabled={true}
                        onChange={(e) => {
                          onChange(e);
                          trigger("email");
                        }}
                        classes={{
                          notchedOutline: clsx({
                            [classes.borderWarning]: errors["email"] !== undefined,
                          }),
                        }}
                      />
                    )}
                  />
                  {errors["email"] !== undefined && (
                    <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                      {errors["email"].message}
                    </FormHelperText>
                  )}
                </FormGroup>
              </Grid>

              <Grid item xs={8} sm={8} md={8} className={classes.paddingRight0px}>
                <FormGroup>
                  <FormLabel>Palavra-passe</FormLabel>
                  <FilledInput disabled placeholder="**************"></FilledInput>
                </FormGroup>
              </Grid>

              <Grid item xs={4} sm={4} md={4} className={classes.paddingLeft0px}>
                <Box paddingTop="21px" paddingBottom="23px">
                  <GenericButton
                    typeSubmit={false}
                    color="tertiary"
                    style={{ width: "100%" }}
                    onClick={handleChangePassword}
                  >
                    Alterar palavra-Passe
                  </GenericButton>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </CardContent>
      </Card>
      <Grid container>
        <Grid item xs={12} lg={9} xl={10}></Grid>
        <Grid item xs={12} lg={3} xl={2}>
          <FormGroup>
            <GenericButton typeSubmit={true} color="primary" loading={props.usersInfo.updateProfile_loading}>
              {intl.formatMessage(labels.Label_Generic_Save)}
            </GenericButton>
          </FormGroup>
        </Grid>
      </Grid>
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getDataSet: (name, parentKeyValue) => dispatch(getDataSetByNameAction(name, parentKeyValue)),
  getBackOfficeUserInfo: (id) => dispatch(getBackOfficeUserInfoAction(id)),
  getBackOfficeRoles: (name) => dispatch(getBackOfficeRolesAction(name)),
});
export default connect(mapStateToProps, mapDispatchToProps)(PersonalForm);
