import axios from "axios";
import { INTERNAL_API_URL, EXTERNAL_API_URL } from "../index";

export const CREATE_NOTIFICATION_URL = "UserNotifications/create-notification";
export const GET_NOTIFICATIONS_URL = "UserNotifications/get-notifications";
export const POST_UPDATE_NOTIFICATION_URL = "UserNotifications/update-notification";
export const GET_UNREAD_NOTIFICATIONS_URL = "UserNotifications/get-notifications-unread";
export const GET_TOTAL_UNREAD_NOTIFICATIONS_URL = "UserNotifications/get-number-of-unread-notifications";


export function createNotification(data) {
  return axios.post(INTERNAL_API_URL + CREATE_NOTIFICATION_URL, data);
}

export function getNotifications(formData) {
  return axios.post(EXTERNAL_API_URL + GET_NOTIFICATIONS_URL, formData
  );
}

export function getUnreadNotifications(_message, _origin, _onlyUnread, _maxResult) {
  return axios.get(EXTERNAL_API_URL + GET_UNREAD_NOTIFICATIONS_URL, {
    params: {
      lastXNotifications: _maxResult,
      message: _message,
      origin: _origin,
      onlyUnread: _onlyUnread
    },
  });
}

export function getNumberOfUnreadNotifications() {
  return axios.get(EXTERNAL_API_URL + GET_TOTAL_UNREAD_NOTIFICATIONS_URL);
}

export function updateNotification(_notificationId) {
  return axios.post(EXTERNAL_API_URL + POST_UPDATE_NOTIFICATION_URL, {
    notificationId: _notificationId
  });
}
