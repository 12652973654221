import {
  createScheduling,
  searchSchedulings,
  getScheduling,
  updateScheduling,
  getReportDocument
} from "crud/scheduling.crud";
import { SCHEDULING, DIALOG } from "utils/actionsConsts";
import { convertByteArrayToBlobUrl } from "utils/converters";
import moment from "moment";

export const createSchedulingAction =
  (formData, successFunction) => async (dispatch) => {
    dispatch({
      type: SCHEDULING.CREATE_LOADING,
      payload: true,
    });
    createScheduling(formData)
      .then((res) => {
        dispatch({
          type: SCHEDULING.CREATE_LOADING,
          payload: false,
        });

        dispatch({
          type: DIALOG.SHOW,
          payload: {
            showGenericDialog: true,
            genericDialogData: {
              type: DIALOG.ONE_BUTTON_TYPE,
              goBack: false,
              isSuccess: res.data,
              message: res.data
                ? "Agendamento criado com sucesso. \r\n" +
                moment(formData.schedulingDatetime).format("DD/MM/YYYY HH:mm")
                : "Ocorreu um erro a criar o agendamento",
              okAction: res.data ? successFunction : null,
            },
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: SCHEDULING.CREATE_LOADING,
          payload: false,
        });

        console.log("error", error);
      });
  };

export const searchSchedulingsAction = (formData) => async (dispatch) => {
  dispatch({
    type: SCHEDULING.SEARCH_LOADING,
    payload: true,
  });
  searchSchedulings(formData)
    .then((res) => {
      const mappedList = res.data?.data.map((record) => {
        return {
          id: record.id,
          numApplication: record.applicationNumber,
          numBI: record.numBI,
          userCode: record.userCode,
          nameOfUser: record.nameOfUser,
          governmentProgram: record.governmentProgram,
          submissionDate: moment(record.schedulingDatetime).format("DD/MM/YYYY HH:mm"),
          scheduleState: record.scheduleState,
          scheduleStateKeyValue: record.scheduleStateKeyValue,
          motive: record.motive,
          provinceDescription: record.provinceDescription
        };
      });
      dispatch({
        type: SCHEDULING.SEARCH,
        result: true,
        payload: {
          data: mappedList,
          total_records: res.data.totalCount,
          total_pages: res.data.totalPages,
        },
      });
      dispatch({
        type: SCHEDULING.SEARCH_LOADING,
        payload: false,
      });
    })
    .catch((error) => {
      dispatch({
        type: SCHEDULING.SEARCH,
        result: false,
        payload: [],
      });
      dispatch({
        type: SCHEDULING.SEARCH_LOADING,
        payload: false,
      });
      console.log("error", error);
    });
};

export const getSchedulingAction = (schedulingNumber) => async (dispatch) => {
  dispatch({
    type: SCHEDULING.CLEAR_DETAIL,
  });
  dispatch({
    type: SCHEDULING.GET_LOADING,
    payload: true,
  });
  getScheduling(schedulingNumber)
    .then((res) => {
      dispatch({
        type: SCHEDULING.GET,
        result: true,
        payload: res.data,
      });
      dispatch({
        type: SCHEDULING.GET_LOADING,
        payload: false,
      });
    })
    .catch((error) => {
      dispatch({
        type: SCHEDULING.GET,
        result: false,
        payload: {},
      });
      dispatch({
        type: SCHEDULING.GET_LOADING,
        payload: false,
      });
      console.log("error", error);
    });
};

export const updateSchedulingAction =
  (formData, successFunction) => async (dispatch) => {
    console.log(formData, successFunction);
    dispatch({
      type: SCHEDULING.UPDATE_LOADING,
      payload: true,
    });
    updateScheduling(formData)
      .then((res) => {
        dispatch({
          type: SCHEDULING.UPDATE,
          payload: res.data,
        });

        dispatch({
          type: DIALOG.SHOW,
          payload: {
            showGenericDialog: true,
            genericDialogData: {
              type: DIALOG.ONE_BUTTON_TYPE,
              isSuccess: res.data !== null,
              message:
                res.data !== null
                  ? "Agendamento concluído com sucesso"
                  : "Ocorreu ao atualizar o agendamento.",
              okAction: successFunction,
            },
          },
        });
        dispatch({
          type: SCHEDULING.UPDATE_LOADING,
          payload: false,
        });
      })
      .catch((error) => {
        dispatch({
          type: SCHEDULING.UPDATE,
          payload: false,
        });
        dispatch({
          type: SCHEDULING.UPDATE_LOADING,
          payload: false,
        });
        console.log("error", error);
      });
  };

  export const getReportDocumentAction = (scheduleId, docName) => async (dispatch) => {
    getReportDocument(scheduleId)
      .then((res) => {
        const link = document.createElement("a");
        link.href = convertByteArrayToBlobUrl(res.data);
        link.setAttribute("download", docName);
        link.setAttribute("target", "_blank");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        dispatch({
          type: SCHEDULING.GET_REPORT_DOCUMENT_DATA_DOWNLOAD, //CRIAR!!!!
          result: false,
          payload: null,
        });
  
        console.log("error", error);
      });
  };

export default createSchedulingAction;
