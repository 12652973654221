import { React } from "react";
import { connect } from "react-redux";
import ReactHtmlParser from "react-html-parser";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import GenericButton from "components/Buttons/GenericButton";

import componentStylesGeneric from "assets/theme/views/admin/generic.js";
const useStylesGeneric = makeStyles(componentStylesGeneric);

function NewsModal(props) {
  const classes = useStylesGeneric();

  return (
    <Box>
      <Box className={classes.modalHeader}> Nova Versão do INEFOP 360! </Box>
      <Box>
        <Grid container>
          <Grid item xs={12} md={12}>
            <Box className={classes.subHeader} style={{ marginTop: "-40px" }}>
              {ReactHtmlParser(props.newsMessage)}
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box textAlign="center">
        <GenericButton typeSubmit={false} color="primary" onClick={props.onClose}>
          OK
        </GenericButton>
      </Box>
    </Box>
  );
}

const mapStateToProps = (state) => ({ ...state });

export default connect(mapStateToProps)(NewsModal);
