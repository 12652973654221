import React from "react";
//material-ui
import Box from "@material-ui/core/Box";
import { Typography } from "@material-ui/core";
//material-ui icons
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
//styles
import { makeStyles } from "@material-ui/core/styles";
import componentStylesButtons from "assets/theme/components/button.js";
import GenericButton from "components/Buttons/GenericButton";
const useStylesButtons = makeStyles(componentStylesButtons);

const useStyles = makeStyles((theme) => ({
  iconStyle: {
    width: "35%",
    height: "35%",
    color: theme.palette.secondary.skyBlue,
    margin: "0 auto",
    display: "block",
  },
  infoMessage: {
    textAlign: "center",
  },
}));

export default function InfoContent(props) {
  const classes = { ...useStyles(), ...useStylesButtons() };

  return (
    <>
      <InfoOutlinedIcon className={classes.iconStyle} />
      <Typography variant="h2" component="h2" className={classes.infoMessage}>
        {props.message}
      </Typography>
      <Box textAlign="center" marginTop="1.5rem" marginBottom="1.5rem">
        <GenericButton 
          typeSubmit={true}
          onClick={props.onClick}
          color="primary"
        >
          Entrar
        </GenericButton>
      </Box>
    </>
  );
}
