import React, { useState } from "react";
import { connect } from "react-redux";
import { useIntl } from "react-intl";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";

import componentStylesGeneric from "assets/theme/views/admin/generic";

import { FOLLOWUP_STATE_ID_QUERY } from "utils/consts.js";

import OpportunityApplicationsFollowUpsTab from "views/admin/UserOverview/UserColective/Tabs/ApplicationsContentTab/OpportunityApplicationFollowUpTab";
import { titles, labels } from "resources/resources";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function FollowUpsListSection(props) {
  const classes = useStylesGeneric();
  const intl = useIntl();
  const [tabStep, setTabStep] = useState(props.selectedTab  ?? "ongoing");

  const handleTabStep = (event, value) => {
    if (value !== null) {
      setTabStep(value);
    }
  };

  function loadTabContent() {
    switch (tabStep) {
      case "finished":
        return <OpportunityApplicationsFollowUpsTab followUpState={FOLLOWUP_STATE_ID_QUERY.FINISHED} />;
      case "ongoing":
      default:
        return <OpportunityApplicationsFollowUpsTab followUpState={FOLLOWUP_STATE_ID_QUERY.ONGOING} />;
    }
  }

  return (
    <Card className={classes.cardRoot}>
      <CardHeader
        classes={{ title: classes.cardHeader }}
        title={intl.formatMessage(titles.Title_UserOverview_ApplicationsTab_FollowUpsSection)}
        data-testid="user-overview-followups-section-title"
      ></CardHeader>
      <CardContent>
        <Box component={Grid} container justifyContent="center">
          <ToggleButtonGroup
            value={tabStep}
            exclusive
            onChange={handleTabStep}
            aria-label="tab Step"
            className={classes.changeTabsGroup}
            style={{ marginLeft: "16px" }}
          >
            <ToggleButton
              value="ongoing"
              className={classes.changeTabs}
              data-testid="user-overview-followups-section-ongoing-followups-tab"
            >
              {intl.formatMessage(labels.Label_UserOverview_ApplicationsTab_FollowUpsSection_OnGoing)}
            </ToggleButton>
            <ToggleButton
              value="finished"
              className={classes.changeTabs}
              data-testid="user-overview-followups-section-finished-followups-tab"
            >
              {intl.formatMessage(labels.Label_UserOverview_ApplicationsTab_FollowUpsSection_Finished)}
            </ToggleButton>
          </ToggleButtonGroup>

          <Grid item xs={12} lg={12} md={12}>
            {loadTabContent()}
          </Grid>
        </Box>
      </CardContent>
    </Card>
  );
}

const mapStateToProps = (state) => ({ ...state });

export default connect(mapStateToProps)(FollowUpsListSection);
