import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, makeStyles } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import CardTableContainer from "components/Cards/Tables/CardTableContainer";
import { useCallback, useEffect, useState } from "react";

import componentStylesGeneric from "assets/theme/views/admin/generic";
import GenericButton from "components/Buttons/GenericButton";
import { labels } from "resources/resources";
import { useIntl } from "react-intl";
import TableBackdrop from "components/Backdrops/TableBackdrop/TableBackdrop";
import { PAGINATION } from "utils/consts";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import GenericDialog from "components/Dialogs/GenericDialogV2";
import { getFoUSInterventionOtherAreaAction } from "redux/actions/usersAction";
import AddInterventionOtherAreaModal from "../Modals/AddInterventionOtherAreaModal";

const useStylesGeneric = makeStyles(componentStylesGeneric);

export default function InterventionOtherAreaSection() {
  const classes = useStylesGeneric();
  const intl = useIntl();
  const { userId } = useParams();

  const [page, setPage] = useState(1);
  const [modal, setModal] = useState({
    open: false,
    editing: false,
    data: {},
  });

  const dispatch = useDispatch();
  const { getFoUSInterventionOtherArea_result, getFoUSInterventionOtherArea_loading } = useSelector(
    (state) => state.usersState
  );

  const tableHead = [
    intl.formatMessage(labels.Label_InterventionOtherArea_Area),
    intl.formatMessage(labels.Label_Date),
    intl.formatMessage(labels.Label_InterventionOtherArea_ReasonForIntervention),
    intl.formatMessage(labels.Label_InterventionOtherArea_Results),
    "",
  ];

  const handleChangePage = (_, value) => {
    setPage(value);
  };

  const getData = useCallback(() => {
    const filter = {
      pageIndex: page <= 0 ? 1 : page,
      pageSize: PAGINATION.PAGE_SIZE,
      userId,
    };

    dispatch(getFoUSInterventionOtherAreaAction(filter));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    getData();
  }, [getData]);

  function handleToggleModal(data = {}, editing = false) {
    setModal((prevState) => ({
      open: !prevState.open,
      editing,
      data,
    }));
  }

  function handleOnCloseAction() {
    setModal(() => ({
      open: false,
    }));
  }

  function handleOnSuccessAction() {
    setPage(1);
    getData();
  }

  return (
    <>
      <CardTableContainer
        title={intl.formatMessage(labels.Label_InterventionOtherArea)}
        count={getFoUSInterventionOtherArea_result?.totalCount}
        actionButtonLabel={intl.formatMessage(labels.Label_Generic_AddRecord)}
        actionButtonOnClick={handleToggleModal}
      >
        <TableContainer>
          <Box component={Table} alignItems="center" marginBottom="0!important">
            <TableHead>
              <TableRow>
                {tableHead.map((prop, key) => (
                  <TableCell
                    key={key}
                    classes={{
                      root: classes.tableCellRoot + " " + classes.tableCellRootHead,
                    }}
                  >
                    {prop}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {getFoUSInterventionOtherArea_result?.data?.map(
                ({
                  interventionOtherAreaDescription,
                  interventionOtherAreaId,
                  date,
                  reasonForIntervention,
                  results,
                  id,
                }) => (
                  <TableRow key={id}>
                    <TableCell classes={{ root: classes.tableCellRoot }}>
                      <Box>{interventionOtherAreaDescription}</Box>
                    </TableCell>
                    <TableCell classes={{ root: classes.tableCellRoot }}>
                      <Box>{moment(date).format("DD/MM/YYYY")}</Box>
                    </TableCell>
                    <TableCell classes={{ root: classes.tableCellRoot }}>
                      <Box>{reasonForIntervention}</Box>
                    </TableCell>
                    <TableCell classes={{ root: classes.tableCellRoot }}>
                      <Box>{results}</Box>
                    </TableCell>

                    <TableCell classes={{ root: classes.tableCellRoot }}>
                      <GenericButton
                        typeSubmit={false}
                        color="tertiary"
                        size="small"
                        onClick={() =>
                          handleToggleModal({ interventionOtherAreaId, date, reasonForIntervention, results, id }, true)
                        }
                      >
                        {intl.formatMessage(labels.Label_Button_Edit)}
                      </GenericButton>
                    </TableCell>
                  </TableRow>
                )
              )}
            </TableBody>
          </Box>
          <TableBackdrop open={getFoUSInterventionOtherArea_loading} />
        </TableContainer>

        <Pagination
          className={classes.alignPagination}
          count={getFoUSInterventionOtherArea_result?.totalPages}
          onChange={handleChangePage}
          page={page}
          color="primary"
        ></Pagination>
      </CardTableContainer>

      <GenericDialog maxWidth={"md"} open={modal.open} onClose={handleToggleModal}>
        <AddInterventionOtherAreaModal data={modal.data} onCloseModal={handleOnCloseAction} onSuccess={handleOnSuccessAction} editing={modal.editing} />
      </GenericDialog>
    </>
  );
}
