const componentStyles = (theme) => ({
  photoItemDiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "50vh",
    backgroundColor: theme.palette.primary.lightBlue,
  },
  videoItemDiv: {
    display: "flex",
    justifyContent: "center",
    height: "50vh",
    backgroundColor: theme.palette.primary.lightBlue,
  },
  indicatorIcon: {
    width: "20px",
    height: "20px",
  },
  img: {
    height: 'inherit',
    maxHeight: "50vh",
    maxWidth: "100%",
    cursor: "pointer",
  },
  previewDialogDiv: {
    height: "min-content",
    maxWidth: "100%",
    display: "flex",
    justifyContent: "center",
    backgroundColor: theme.palette.white.main,
  },
});

export default componentStyles;
