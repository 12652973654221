import React from "react";
import clsx from "clsx";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useTheme } from "@material-ui/core/styles";
import { useIntl } from "react-intl";
import { Controller } from "react-hook-form";
import { messages } from "resources/resources";

export default function GenericFormSelect({
  control,
  fieldName,
  selectOptions,
  initialLabel,
  getValues,
  required,
  requiredMessage,
  errors,
  classes,
  disabled,
  onOptionChange,
  filled,
  showInitialLabel = true,
  handleSetFilter,
  filterListKey
}) {
  const intl = useIntl();
  const theme = useTheme();

  const checkErrors = (errors, name) => {
    const nameSplit = name?.split(".");

    let hasErrors = false

    nameSplit?.map((nameElement) => {
      if(errors[nameElement] !== undefined){
        errors = errors[nameElement]
        hasErrors = true
      }
      else{
        hasErrors = false
      }

      return hasErrors
    })

    return hasErrors
  }

  return (
    <Controller
      name={fieldName}
      control={control}
      rules={{
        required: {
          value: required,
          message:
            requiredMessage !== undefined
              ? requiredMessage
              : intl.formatMessage(messages.Message_Generic_MandatoryField),
        },
      }}
      render={({ field: { onChange, name } }) => (
        <Autocomplete
          noOptionsText={'Sem opções'}
          name={name}
          options={selectOptions ? selectOptions : { code: "", label: "" }}
          value={getValues(name) ? getValues(name) : null}
          getOptionLabel={(option) => option.label}
          getOptionSelected={(option, value) => option.code === value.code}
          disabled={disabled !== undefined ? disabled : false}
          data-testid={"generic-form-select-" + name} 
          classes={{
            inputRoot: clsx({
              [classes.selectBorderWarningInputRoot]: errors !== undefined && checkErrors(errors, name),
            }),
          }}
          ListboxProps={{
            style: {
              fontWeight: "normal",
              fontSize: "14px",
            },
          }}
          style={filled ? { backgroundColor: theme.palette.white.main, borderRadius: "5px" } : {}}
          renderInput={(params) => (
            <TextField
              {...params}
              label={showInitialLabel ? (!initialLabel ? "Escolha uma opção" : initialLabel) : ""}
              variant="outlined"
              InputLabelProps={{
                style: {
                  fontWeight: "normal",
                  fontSize: "14px",
                },
              }}
            />
          )}
          onChange={(_, data) => {
            onChange(data);
            if (onOptionChange !== undefined) {
              onOptionChange(data);
            }

            if (handleSetFilter){
              handleSetFilter(filterListKey, data ? data.code : null)
            }
          }}
        />
      )}
    />
  );
}
