import { React } from "react";
import { connect } from "react-redux";
//@material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { Grid } from "@material-ui/core";
import FormGroup from "@material-ui/core/FormGroup";
//@material-ui/icons
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
//custom components
import GenericDialog from "components/Dialogs/GenericDialogV2";
import GenericButton from "components/Buttons/GenericButton";
//styles
import componentStylesGeneric from "assets/theme/views/admin/generic.js";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function UpdatePaymentFeedbackReportDialog(props) {
  const classes = useStylesGeneric();

  return (
    <GenericDialog name={props.name} open={props.open} onClose={props.onClose}>
      <Box className={classes.modalHelpIconBox}>
        <HelpOutlineIcon className={classes.modalHelpIconRoot} />
      </Box>
      <Box className={classes.modalHeader}>
        Não poderá editar o pagamento após a submissão. Tem a certeza que pretende atualizar o pagamento com o valor
        indicado?
      </Box>
      <Grid container>
        <Grid item xs={12} sm={1} md={2}></Grid>
        <Grid item xs={12} sm={4} md={3}>
          <FormGroup>
            <GenericButton typeSubmit={false} color="secondary" onClick={props.onClose}>
              Não
            </GenericButton>
          </FormGroup>
        </Grid>
        <Grid item xs={12} sm={2} md={2}></Grid>
        <Grid item xs={12} sm={4} md={3}>
          <FormGroup>
            <GenericButton
              typeSubmit={false}
              color="primary"
              onClick={props.handleSubmit}
              loading={props.opportunitiesState.validateFeedbackReportLoading}
            >
              Sim
            </GenericButton>
          </FormGroup>
        </Grid>
      </Grid>
    </GenericDialog>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(UpdatePaymentFeedbackReportDialog);
