import React from "react";
import clsx from "clsx";
import { Controller, useForm } from "react-hook-form";
//redux
import { connect } from "react-redux";
//material-ui
import Box from "@material-ui/core/Box";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import { useTheme } from "@material-ui/core/styles";
import FormHelperText from "@material-ui/core/FormHelperText";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons components
import Email from "@material-ui/icons/Email";
//import custom components
import GenericButton from "components/Buttons/GenericButton";
import { recoverPasswordAction } from "redux/actions/auth/recoverPasswordAction";

import componentStylesGeneric from "assets/theme/views/admin/generic";
const useStylesGeneric = makeStyles(componentStylesGeneric);

export function RecoverDialogContent(props) {
  const classes = useStylesGeneric();
  const theme = useTheme();

  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
  });

  const onSubmit = (data) => {
    if (isValid) {
      props.recoverPassword(data.recoverEmail);
    }
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12} lg={12} md={12}>
          <Box className={classes.modalHeader} marginBottom="30px!important">Recuperar Palavra-passe</Box>
          <Box className={classes.subHeader}>
            Irá ser enviado um e-mail com uma palavra-passe provisória.
          </Box>
          <Box className={classes.subHeader} marginTop="1rem">
            Após autenticação, e por motivos de segurança, deverá proceder à substituição da palavra-passe.
          </Box>

          <Box
            color={theme.palette.gray[600]}
            textAlign="center"
            marginBottom="2rem"
            marginTop=".5rem"
            fontSize="1rem"
          ></Box>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormControl variant="filled" component={Box} width="100%" marginBottom="1rem!important">
              <Controller
                name="recoverEmail"
                control={control}
                defaultValue=""
                rules={{
                  required: {
                    value: true,
                    message: "O campo é obrigatório",
                  },
                }}
                render={({ field: { onChange, name, value } }) => (
                  <OutlinedInput
                    name={name}
                    value={value}
                    onChange={onChange}
                    fullWidth
                    autoComplete="off"
                    type="email"
                    placeholder=" Email"
                    startAdornment={
                      <InputAdornment position="start">
                        <Email />
                      </InputAdornment>
                    }
                    classes={{
                      notchedOutline: clsx({
                        [classes.borderWarning]: errors["recoverEmail"] !== undefined,
                      }),
                    }}
                  />
                )}
              />
              {errors["recoverEmail"] !== undefined && (
                <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                  {errors["recoverEmail"].message}
                </FormHelperText>
              )}
            </FormControl>
            <Box textAlign="center" marginTop="1.5rem" marginBottom="1.5rem">
              <GenericButton typeSubmit={true} loading={props.loading} color={"primary"}>
                Enviar
              </GenericButton>
            </Box>
          </form>
        </Grid>
      </Grid>
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  recoverPassword: (email) => dispatch(recoverPasswordAction(email)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RecoverDialogContent);
