import { useEffect } from "react";

const isNullOrUndefined = (object) => {
  return object === null || object === undefined;
};

export const fetchComponentDatasets = (action, state, datasetsToFetch) => {
  if (!isNullOrUndefined(datasetsToFetch !== null)) {
    datasetsToFetch.forEach((dataset) => {
      if (isNullOrUndefined(state) || isNullOrUndefined(state[dataset])) action(dataset.name, dataset.addEmptyField);
    });
  }
};
export const FetchDatasetsOnMount = (
  datasetsAction,
  geographicdataAction,
  nationalitiesAction,
  state,
  datasetsToFetch
) => {
  useEffect(() => {
    if (datasetsAction !== undefined) fetchComponentDatasets(datasetsAction, state, datasetsToFetch);
    if (geographicdataAction !== undefined) fetchComponentDatasets(geographicdataAction, state, [{ name: "Province" }]);
    if (nationalitiesAction !== undefined)
      fetchComponentDatasets(nationalitiesAction, state, [{ name: "Nationality" }]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export const getDatasetStateByName = (props, name) => {
  if (!isNullOrUndefined(props.dataSetState) && !isNullOrUndefined(props.dataSetState[name]))
    return props.dataSetState[name];
  else return [];
};

export const getDatasetStateById = (props, id) => {
  if (!isNullOrUndefined(props.dataSetState) && !isNullOrUndefined(props.dataSetState[id]))
    return props.dataSetState[id];
  else return [];
};

export const getDatasetDataByCode = (dataset, code) => {
  if (code && dataset) {
    const result = dataset?.find((r) => r.code === code);

    return result;
  }

  return null;
};
