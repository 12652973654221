import { defineMessages } from "react-intl";

export const errorMessages = defineMessages({
  ErrorMessage_FieldRequired: {
    id: "ErrorMessage_FieldRequired",
    defaultMessage: "O campo é obrigatório",
    description: "Mensagem de erro quando um campo obrigatório do formulário não é preenchido",
  },
  ErrorMessage_FieldPercentage: {
    id: "ErrorMessage_FieldPercentage",
    defaultMessage: "O valor mínimo da % deve ser maior que 0 e menor que 100.",
    description: "Mensagem de erro quando ocorre um erro a % é incorrecta",
  },
  ErrorMessage_GenericError: {
    id: "ErrorMessage_GenericError",
    defaultMessage: "Ocorreu um erro a efetuar o seu pedido, por favor tente mais tarde.",
    description: "Mensagem de erro quando ocorre um erro genérico na API",
  },
  ErrorMessage_InvalidFormat: {
    id: "ErrorMessage_InvalidFormat",
    defaultMessage: "Formato inválido",
    description: "Mensagem de erro quando um campo do formulário tem um formato inválido",
  },
  ErrorMessage_Invalid_Value: {
    id: "ErrorMessage_Invalid_Value",
    defaultMessage: "Valor inválido.",
    description: "Mensagem de erro quando o valor inserido é inválido.",
  },
  ErrorMessage_User_Colective_NIF_Invalid_Format: {
    id: "ErrorMessage_User_Colective_NIF_Invalid_Format",
    defaultMessage: "O NIF deve ter 10 digitos",
    description: "Mensagem de erro quando o valor inserido no campo NIF tem um formato inválido.",
  },
  ErrorMessage_User_Colective_PermitCode_Invalid_Format: {
    id: "ErrorMessage_User_Colective_PermitCode_Invalid_Format",
    defaultMessage: "O código de alvará tem tamanho máximo de 25 caracteres",
    description: "Mensagem de erro quando o valor inserido no campo Código de Alvará tem um formato inválido.",
  },
  ErrorMessage_Birthdate_MaxDate: {
    id: "ErrorMessage_Birthdate",
    defaultMessage: "A data deve ser inferior à data atual",
    description: "Mensagem de erro quando um campo de data de nascimento tem uma data superior à atual",
  },
  ErrorMessage_Birthdate_MinDate: {
    id: "ErrorMessage_Birthdate_MinDate",
    defaultMessage: "A data deve ser superior a {minDate}",
    description: "Mensagem de erro quando um campo de data de nascimento tem uma data inferior à data mínima",
  },
  ErrorMessage_MinRegisterDate: {
    id: "ErrorMessage_MinRegisterDate",
    defaultMessage: "A idade mínima para registo é de 17 anos.",
    description: "Mensagem de erro quando a data de nascimento para registo não perfaz 17 anos.",
  },
  ErrorMessage_ID_Card_InvalidFormat: {
    id: "ErrorMessage_ID_Card_InvalidFormat",
    defaultMessage: "O número de BI tem tamanho máximo de 14 caracteres",
    description: "Mensagem de erro quando um campo do formulário tem um formato inválido",
  },
  ErrorMessage_Generic_ValueGreaterThanZeroField: {
    id: "ErrorMessage_Generic_ValueGreaterThanZeroField",
    defaultMessage: "O valor tem de ser superior a zero",
    description: "Mensagem genérica para qualquer campo que o valor tenha de ser superior a zero",
  },
  ErrorMessage_MinimumAge: {
    id: "ErrorMessage_MinimumAge",
    defaultMessage: "A idade mínima é de 18 anos.",
    description: "Mensagem de erro quando a idade inserida no filtro é inferior a 18 anos",
  },
  ErrorMessage_MinAgeShouldBeLowerThanMaxAge: {
    id: "ErrorMessage_MinAgeShouldBeLowerThanMaxAge",
    defaultMessage: "A idade mínima deve ser igual ou inferior à idade máxima.",
    description: "A idade mínima deve ser igual ou inferior à idade máxima",
  },
  ErrorMessage_MaxAgeShouldBeGreaterThanMinAge: {
    id: "ErrorMessage_MaxAgeShouldBeGreaterThanMinAge",
    defaultMessage: "A idade máxima deve ser igual ou superior à idade mínima.",
    description: "A idade máxima deve ser igual ou superior à idade mínima",
  },
});

export const labels = defineMessages({
  //General
  Label_Not_Applicable: {
    id: "Label_Not_Applicable",
    defaultMessage: "N/A",
    description: "Not Applicable",
  },
  Label_Yes: {
    id: "Label_Yes",
    defaultMessage: "Sim",
    description: "When you want to display 'Yes'",
  },
  Label_No: {
    id: "Label_No",
    defaultMessage: "Não",
    description: "When you want to display 'No'",
  },
  Label_Years: {
    id: "Label_Years",
    defaultMessage: "anos",
    description: "Years",
  },
  Label_Details: {
    id: "Label_Details",
    defaultMessage: "Detalhes",
    description: "Details",
  },
  Label_Total: {
    id: "Label_Total",
    defaultMessage: "Total",
    description: "Total",
  },
  Label_Generic_Active: {
    id: "Label_Generic_Active",
    defaultMessage: "Activo",
    description: "Label 'Activo' que representa o estado do professional kit",
  },
  Label_Generic_Inactive: {
    id: "Label_Generic_Inactive",
    defaultMessage: "Inactivo",
    description: "Label 'Inactivo' que representa o estado do professional kit",
  },
  Label_SeeMore: {
    id: "Label_SeeMore",
    defaultMessage: "Ver mais",
    description: "See More",
  },
  Label_SeeDetails: {
    id: "Label_SeeDetailsId",
    defaultMessage: "Ver Detalhes",
    description: "See Details",
  },
  Label_Download: {
    id: "Label_Download",
    defaultMessage: "Descarregar",
    description: "Download",
  },
  Label_Select: {
    id: "Label_Select",
    defaultMessage: "Seleccionar",
    description: "Select",
  },
  Label_NoResultsFound: {
    id: "Label_NoResultsFound",
    defaultMessage: "Não existem resultados disponíveis.",
    description: "No Results Found",
  },
  Label_SelectMultipleOptions: {
    id: "Label_SelectMultipleOptions",
    defaultMessage: "Seleccione opções aplicáveis",
    description: "Label SelectMultipleOptions",
  },
  Label_Undefined: {
    id: "Label_Undefined",
    defaultMessage: "Indefinido",
    description: "Undefined",
  },
  Label_Date: {
    id: "Label_Date",
    defaultMessage: "Data",
    description: "Date",
  },

  // General Filters
  Label_ExportButton: {
    id: "Label_ExportButton",
    defaultMessage: "Exportar",
    description: "Export button used in some filters",
  },
  Label_ClearFilters: {
    id: "Label_ClearFilters",
    defaultMessage: "Limpar Filtros",
    description: "Clear filters button",
  },
  Label_FilterSection_SearchButton: {
    id: "Label_FilterSection_SearchButton",
    defaultMessage: "Pesquisar",
    description: "Search button for filter sections",
  },
  Label_Search_Filters_Placeholder: {
    id: "Label_Search_Filters_Placeholder",
    defaultMessage: "Pesquisar",
    description: "Search filters placeholder",
  },

  // General Buttons
  Label_Button_Cancel: {
    id: "Label_Button_Cancel",
    defaultMessage: "Cancelar",
    description: "Label de botºoes cancelar",
  },
  Label_Button_Submit: {
    id: "Label_Button_Submit",
    defaultMessage: "Submeter",
    description: "Label de botões submeter",
  },
  Label_Button_Edit: {
    id: "Label_Button_Edit",
    defaultMessage: "Editar",
    description: "Label do botão de edição",
  },
  Label_Recruit_Submit: {
    id: "Label_Recruit_Submit",
    defaultMessage: "Recrutar",
    description: "Label de botões Recrutar",
  },
  Label_Reject_Submit: {
    id: "Label_Reject_Submit",
    defaultMessage: "Rejeitar",
    description: "Label de botões Rejeitar",
  },
  Label_Approve_Submit: {
    id: "Label_Approve_Submit",
    defaultMessage: "Aprovar",
    description: "Label de botões Aprovar",
  },
  Label_Generic_Withdraw: {
    id: "Label_Generic_Withdraw",
    defaultMessage: "Desistência",
    description: "Label de botões Desistência",
  },
  Label_Generic_RevertWithdraw: {
    id: "Label_Generic_RevertWithdraw",
    defaultMessage: "Anular Desistência",
    description: "Label de botões Anular Desistência",
  },
  Label_Update_Submit: {
    id: "Label_Update_Submit",
    defaultMessage: "Actualizar",
    description: "Update button label",
  },
  Label_GoBack_Button: {
    id: "Label_GoBack_Button",
    defaultMessage: "Voltar",
    description: "GoBack button label",
  },
  Label_Generic_Submit_Button: {
    id: "Label_Generic_Submit_Button",
    defaultMessage: "Submeter",
    description: "Label geral de botões submeter",
  },
  Label_Generic_Upload_Document: {
    id: "Label_Generic_Upload_Document",
    defaultMessage: "Carregar documento",
    description: "Label do campo",
  },
  Label_Generic_Details: {
    id: "Label_Generic_Details",
    defaultMessage: "Detalhes",
    description: "Details label",
  },
  Label_Generic_Add: {
    id: "Label_Generic_AddId",
    defaultMessage: "Adicionar",
    description: "Add button label",
  },
  Label_Generic_Cancel: {
    id: "Label_Generic_CancelId",
    defaultMessage: "Cancelar",
    description: "Cancel button label",
  },
  Label_Generic_Save: {
    id: "Label_Generic_Save",
    defaultMessage: "Guardar",
    description: "Save button label",
  },
  Label_Generic_Edit: {
    id: "Label_Generic_Edit",
    defaultMessage: "Editar",
    description: "Edit button label",
  },
  Label_Generic_Conclude: {
    id: "Label_Generic_Conclude",
    defaultMessage: "Concluir",
    description: "Conclude button label",
  },
  Label_Generic_AddRecord: {
    id: "Label_Generic_AddRecord",
    defaultMessage: "Adicionar Registo",
    description: "AddRecord button label",
  },

  // Dashboard cards and stuff
  Label_GovernmentProgramField_short: {
    id: "Label_GovernmentProgramField_short",
    defaultMessage: "Prog. Governo",
    description: "Government program field, short version",
  },

  Label_UserCodeField_short: {
    id: "Label_UserCodeField_short",
    defaultMessage: "Cód. Utilizador",
    description: "User code field, short version",
  },

  // Subprograms
  Label_Admin_Subprogram_Create_CancelButton: {
    id: "Label_Admin_Subprogram_Create_CancelButton",
    defaultMessage: "Cancelar",
    description: "Label de botões Cancelar",
  },
  Label_Admin_Subprogram_GovernmentProgramField: {
    id: "Label_Admin_Subprogram_GovernmentProgramField",
    defaultMessage: "Programa do Governo",
    description: "label do campo",
  },
  Label_Admin_Subprogram_ProgramField: {
    id: "Label_Admin_Subprogram_ProgramField",
    defaultMessage: "Programa",
    description: "label do campo",
  },
  Label_Admin_Subprogram_SubprogramField: {
    id: "Label_Admin_Subprogram_SubprogramField",
    defaultMessage: "Subprograma",
    description: "label do campo",
  },
  Label_Admin_Subprogram_ValidityDateField: {
    id: "Label_Admin_Subprogram_ValidityDateField",
    defaultMessage: "Data de Vigência",
    description: "label do campo",
  },
  Label_Admin_Subprogram_ValidityDateFieldTo: {
    id: "Label_Admin_Subprogram_ValidityDateFieldTo",
    defaultMessage: "Até",
    description: "label do campo",
  },
  Label_Admin_Subprogram_ValidityDateFieldStart: {
    id: "Label_Admin_Subprogram_ValidityDateFieldStart",
    defaultMessage: "Data de Vigência (Início)",
    description: "label do campo",
  },
  Label_Admin_Subprogram_ValidityDateFieldEnd: {
    id: "Label_Admin_Subprogram_ValidityDateFieldEnd",
    defaultMessage: "Data de Vigência (Fim)",
    description: "label do campo",
  },
  Label_Admin_Subprogram_DescriptionField: {
    id: "Label_Admin_Subprogram_DescriptionField",
    defaultMessage: "Descrição",
    description: "label do campo",
  },
  Label_Admin_Subprogram_ObjectivesField: {
    id: "Label_Admin_Subprogram_ObjectivesField",
    defaultMessage: "Objectivos",
    description: "label do campo",
  },
  Label_Admin_Subprogram_NumVacanciesField: {
    id: "Label_Admin_Subprogram_NumVacanciesField",
    defaultMessage: "Nº de Benefícios a atribuir",
    description: "label do campo",
  },
  Label_Admin_Subprogram_DepartmentField: {
    id: "Label_Admin_Subprogram_DepartmentField",
    defaultMessage: "Pelouro Responsável",
    description: "label do campo",
  },
  Label_Admin_Subprogram_BenefitsTypeField: {
    id: "Label_Admin_Subprogram_BenefitsTypeField",
    defaultMessage: "Tipo de Benefício",
    description: "label do campo",
  },
  Label_Admin_Subprogram_StateField: {
    id: "Label_Admin_Subprogram_StateField",
    defaultMessage: "Estado",
    description: "State of Subprogram in Subprogram FilterSection and GeneralData section",
  },

  // Applications
  Label_Admin_ApplicationsManagementFilter_NumApplicationField: {
    id: "Label_Admin_ApplicationsManagementFilter_NumApplicationField",
    defaultMessage: "Nº Candidatura",
    description: "Label do campo de pesquisa por Nº Candidatura",
  },
  Label_Admin_ApplicationsManagementFilter_NumBIField: {
    id: "Label_Admin_ApplicationsManagementFilter_NumBIField",
    defaultMessage: "Nº BI / NIF",
    description: "Label do campo de pesquisa por Nº BI",
  },
  Label_Admin_ApplicationsManagementFilter_UserCodeField: {
    id: "Label_Admin_ApplicationsManagementFilter_UserCodeField",
    defaultMessage: "Código do utilizador",
    description: "Label do campo de pesquisa por Código do utilizador",
  },
  Label_Admin_ApplicationsManagementFilter_UserNameField: {
    id: "Label_Admin_ApplicationsManagementFilter_UserNameField",
    defaultMessage: "Nome",
    description: "Label do campo de pesquisa por Nome",
  },
  Label_Admin_ApplicationsManagementFilter_StatusField: {
    id: "Label_Admin_ApplicationsManagementFilter_StatusField",
    defaultMessage: "Estado",
    description: "Label do campo de pesquisa por Estado",
  },
  Label_Admin_ApplicationsManagementFilter_GovernmentProgram: {
    id: "Label_Admin_ApplicationsManagementFilter_GovernmentProgram",
    defaultMessage: "Programa do Governo",
    description: "Label do campo de pesquisa por Programa do Governo",
  },
  Label_Admin_ApplicationsManagementFilter_ProgramField: {
    id: "Label_Admin_ApplicationsManagementFilter_ProgramField",
    defaultMessage: "Programa",
    description: "Label do campo de pesquisa por Programa",
  },
  Label_Admin_ApplicationsManagementFilter_SubprogramField: {
    id: "Label_Admin_ApplicationsManagementFilter_SubprogramField",
    defaultMessage: "Subprograma",
    description: "Label do campo de pesquisa por Subrograma",
  },
  Label_Admin_ApplicationsManagementFilter_SubmissionDate: {
    id: "Label_Admin_ApplicationsManagementFilter_SubmissionDate",
    defaultMessage: "Data de Submissão",
    description: "Label do campo de pesquisa por Data de submissão",
  },
  Label_Admin_ApplicationsManagementFilter_SubmissionDateFrom: {
    id: "Label_Admin_ApplicationsManagementFilter_SubmissionDateFrom",
    defaultMessage: "Data de Submissão (desde)",
    description: "Label do campo de pesquisa por Data de submissão (desde)",
  },
  Label_Admin_ApplicationsManagementFilter_SubmissionDateTo: {
    id: "Label_Admin_ApplicationsManagementFilter_SubmissionDateTo",
    defaultMessage: "Data de Submissão (até)",
    description: "Label do campo de pesquisa por Data de submissão (até)",
  },
  Label_Admin_ApplicationsManagementFilter_ApplicationProvince: {
    id: "Label_Admin_ApplicationsManagementFilter_ApplicationProvince",
    defaultMessage: "Província da Candidatura",
    description: "Label do campo de pesquisa por Província",
  },
  Label_Admin_ApplicationsManagementFilter_CandidateProvince: {
    id: "Label_Admin_ApplicationsManagementFilter_CandidateProvince",
    defaultMessage: "Província do Candidato",
    description: "Label do campo de pesquisa por Província",
  },
  Label_Admin_ApplicationsManagementFilter_CandidateMunicipality: {
    id: "Label_Admin_ApplicationsManagementFilter_CandidateMunicipality",
    defaultMessage: "Município do Candidato",
    description: "Label do campo de pesquisa por Município",
  },
  Label_Admin_ApplicationsManagementFilter_PhoneNumber: {
    id: "Label_Admin_ApplicationsManagementFilter_PhoneNumber",
    defaultMessage: "Telemóvel",
    description: "Label do campo de pesquisa por Telefone",
  },
  Label_Admin_ApplicationsManagementFilter_EntrepreneurshipTraining: {
    id: "Label_Admin_ApplicationsManagementFilter_EntrepreneurshipTraining",
    defaultMessage: "Formação em Empreendedorismo",
    description: "Label do campo de pesquisa por Formação em Empreendedorismo",
  },
  Label_Admin_ApplicationsManagementFilter_InternshipArea: {
    id: "Label_Admin_ApplicationsManagementFilter_InternshipArea",
    defaultMessage: "Área Profissional do Estágio",
    description: "Label do campo de pesquisa por Área Profissional do Estágio",
  },

  Label_Admin_ApplicationDetail_ApplicationStateField: {
    id: "Label_Admin_ApplicationDetail_ApplicationStateField",
    defaultMessage: "Estado da candidatura",
    description: "label do campo",
  },

  //General Data Section
  Label_Admin_GeneralDataSection_NameField: {
    id: "Label_Admin_GeneralDataSection_NameField",
    defaultMessage: "Nome",
    description: "Label do campo de Dados Gerais do candidato",
  },
  Label_Admin_GeneralDataSection_NumBIField: {
    id: "Label_Admin_GeneralDataSection_NumBIField",
    defaultMessage: "Nº BI",
    description: "Label do campo de Dados Gerais do candidato",
  },
  Label_Admin_GeneralDataSection_GenderField: {
    id: "Label_Admin_GeneralDataSection_GenderField",
    defaultMessage: "Género",
    description: "Label do campo de Dados Gerais do candidato",
  },
  Label_Admin_GeneralDataSection_MaritalStatusField: {
    id: "Label_Admin_GeneralDataSection_MaritalStatusField",
    defaultMessage: "Estado Civil",
    description: "Label do campo de Dados Gerais do candidato",
  },
  Label_Admin_GeneralDataSection_OtherApplicationsField: {
    id: "Label_Admin_GeneralDataSection_OtherApplicationsField",
    defaultMessage: "Outras candidaturas",
    description: "Label do campo de Dados Gerais do candidato",
  },
  Label_Admin_GeneralDataSection_SpecialNeedsField: {
    id: "Label_Admin_GeneralDataSection_SpecialNeedsField",
    defaultMessage: "Necessidades Especiais",
    description: "Label do campo de Dados Gerais do candidato",
  },
  Label_Admin_GeneralDataSection_SNPercentageField: {
    id: "Label_Admin_GeneralDataSection_SNPercentageField",
    defaultMessage: "Percentagem",
    description: "Label do campo de Dados Gerais do candidato",
  },
  Label_Admin_GeneralDataSection_AgeField: {
    id: "Label_Admin_GeneralDataSection_AgeField",
    defaultMessage: "Idade",
    description: "Label do campo de Dados Gerais do candidato",
  },
  Label_Admin_GeneralDataSection_BirthdayField: {
    id: "Label_Admin_GeneralDataSection_BirthdayField",
    defaultMessage: "Data de Nascimento",
    description: "Label do campo de Dados Gerais do candidato",
  },
  Label_Admin_GeneralDataSection_ProvinceField: {
    id: "Label_Admin_GeneralDataSection_ProvinceField",
    defaultMessage: "Província",
    description: "Label do campo de Dados Gerais do candidato",
  },
  Label_Admin_GeneralDataSection_MunicipalityField: {
    id: "Label_Admin_GeneralDataSection_MunicipalityField",
    defaultMessage: "Município",
    description: "Label do campo de Dados Gerais do candidato",
  },
  Label_Admin_GeneralDataSection_MobilePhoneField: {
    id: "Label_Admin_GeneralDataSection_MobilePhoneField",
    defaultMessage: "Telemóvel",
    description: "Label do campo de Dados Gerais do candidato",
  },

  Label_Admin_ProgramSubprogramDataSection_WhichProvinceField: {
    id: "Label_Admin_ProgramSubprogramDataSection_WhichProvinceField",
    defaultMessage: "Onde pretende candidatar-se?",
    description: "Label do campo de Dados do Programa e Subprograma",
  },
  Label_Admin_ProgramSubprogramDataSection_GovernmentProgramsField: {
    id: "Label_Admin_ProgramSubprogramDataSection_GovernmentProgramsField",
    defaultMessage: "Programa do Governo",
    description: "Label do campo de Dados do Programa e Subprograma",
  },
  Label_Admin_ProgramSubprogramDataSection_ProgramsField: {
    id: "Label_Admin_ProgramSubprogramDataSection_ProgramsField",
    defaultMessage: "Programas",
    description: "Label do campo de Dados do Programa e Subprograma",
  },
  Label_Admin_ProgramSubprogramDataSection_SubprogramsField: {
    id: "Label_Admin_ProgramSubprogramDataSection_SubprogramsField",
    defaultMessage: "Subprogramas",
    description: "Label do campo de Dados do Programa e Subprograma",
  },
  Label_Admin_ProgramSubprogramDataSection_BenefitTypeField: {
    id: "Label_Admin_ProgramSubprogramDataSection_BenefitTypeField",
    defaultMessage: "Tipo de benefício",
    description: "Label do campo de Dados do Programa e Subprograma",
  },
  Label_Admin_ProgramSubprogramDataSection_MicrocreditAmountField: {
    id: "Label_Admin_ProgramSubprogramDataSection_MicrocreditAmountField",
    defaultMessage: "Valor microcrédito pretendido",
    description: "Label do campo de Dados do Programa e Subprograma",
  },
  Label_Admin_ProgramSubprogramDataSection_ConcededMicrocreditAmountField: {
    id: "Label_Admin_ProgramSubprogramDataSection_ConcededMicrocreditAmountField",
    defaultMessage: "Valor microcrédito a conceder",
    description: "Label do campo de Dados do Programa e Subprograma",
  },
  Label_Admin_ProgramSubprogramDataSection_ProfessionalKitsNumberField: {
    id: "Label_Admin_ProgramSubprogramDataSection_ProfessionalKitsNumberField",
    defaultMessage: "Nº de Kits pretendidos",
    description: "Label do campo de Dados do Programa e Subprograma",
  },
  Label_Admin_ProgramSubprogramDataSection_ConcededProfessionalKitsNumberField: {
    id: "Label_Admin_ProgramSubprogramDataSection_ConcededProfessionalKitsNumberField",
    defaultMessage: "Nº de Kits a conceder",
    description: "Label do campo de Dados do Programa e Subprograma",
  },
  Label_Admin_ProgramSubprogramDataSection_ProfessionalKitsValueField: {
    id: "Label_Admin_ProgramSubprogramDataSection_ProfessionalKitsValueField",
    defaultMessage: "Valor",
    description: "Label do campo de Dados do Programa e Subprograma",
  },
  Label_Admin_ProgramSubprogramDataSection_IntendedCourseNameField: {
    id: "Label_Admin_ProgramSubprogramDataSection_IntendedCourseNameField",
    defaultMessage: "Curso pretendido",
    description: "Label do campo de Dados do Programa e Subprograma",
  },
  Label_Admin_ProgramSubprogramDataSection_IntendedWalletNameField: {
    id: "Label_Admin_ProgramSubprogramDataSection_IntendedWalletNameField",
    defaultMessage: "Carteira pretendida",
    description: "Label do campo de Dados do Programa e Subprograma",
  },
  Label_Admin_ProgramSubprogramDataSection_TrainingCenterField: {
    id: "Label_Admin_ProgramSubprogramDataSection_TrainingCenterField",
    defaultMessage: "Centro de formação pretendido",
    description: "Label do campo de Dados do Programa e Subprograma",
  },
  Label_Admin_ProgramSubprogramDataSection_InternshipAreaField: {
    id: "Label_Admin_ProgramSubprogramDataSection_InternshipAreaField",
    defaultMessage: "Área onde pretende fazer o estágio",
    description: "Label do campo de Dados do Programa e Subprograma",
  },
  Label_Admin_ProgramSubprogramDataSection_Bank: {
    id: "Label_Admin_ProgramSubprogramDataSection_Bank",
    defaultMessage: "Qual o banco para o crédito?",
    description: "Label do campo de seleção de banco nos detalhde de uma candidatura a um subprograma de microcrédito",
  },
  Label_Admin_ProgramSubprogramDataSection_Bank_Placeholder: {
    id: "Label_Admin_ProgramSubprogramDataSection_Bank_Placeholder",
    defaultMessage: "Seleccione o banco",
    description: "Label do campo de seleção de banco nos detalhde de uma candidatura a um subprograma de microcrédito",
  },

  // Schedules
  Label_Admin_SchedulingManagementFilter_SchedulingDateFrom: {
    id: "Label_Admin_SchedulingManagementFilter_SchedulingDateFrom",
    defaultMessage: "Data de agendamento (desde)",
    description: "Label do campo de pesquisa por Data de agendamento (desde)",
  },
  Label_Admin_SchedulingManagementFilter_SchedulingDateTo: {
    id: "Label_Admin_SchedulingManagementFilter_SchedulingDateTo",
    defaultMessage: "Data de agendamento (até)",
    description: "Label do campo de pesquisa por Data de agendamento (até)",
  },
  Label_Admin_SchedulingTab_MotiveField: {
    id: "Label_Admin_SchedulingTab_MotiveField",
    defaultMessage: "Motivo",
    description: "Label do campo",
  },
  Label_Admin_SchedulingTab_SchedulingDateField: {
    id: "Label_Admin_SchedulingTab_SchedulingDateField",
    defaultMessage: "Data e Hora de Agendamento",
    description: "Label do campo",
  },
  Label_Admin_SchedulingTab_SchedulingHourField: {
    id: "Label_Admin_SchedulingTab_SchedulingHourField",
    defaultMessage: "Horas",
    description: "Label do campo",
  },
  Label_Admin_SchedulingTab_ObservationsField: {
    id: "Label_Admin_SchedulingTab_ObservationsField",
    defaultMessage: "Observações",
    description: "Label do campo",
  },

  // Notifications
  Label_Admin_NotificationsTab_CandidateField: {
    id: "Label_Admin_NotificationsTab_CandidateField",
    defaultMessage: "Candidato",
    description: "Label do campo de Candidato no tab de notificações",
  },
  Label_Admin_NotificationsTab_RecipientField: {
    id: "Label_Admin_NotificationsTab_RecipientField",
    defaultMessage: "Destinatário",
    description: "Label do campo",
  },
  Label_Admin_NotificationsTab_BackofficeUserField: {
    id: "Label_Admin_NotificationsTab_BackofficeUserField",
    defaultMessage: "Utilizador de backoffice",
    description: "Label do campo",
  },
  Label_Admin_NotificationsTab_BackofficeGroupField: {
    id: "Label_Admin_NotificationsTab_BackofficeGroupField",
    defaultMessage: "Grupo de backoffice",
    description: "Label do campo de Grupo de backoffice no tab de notificações",
  },
  Label_Admin_NotificationsTab_MotiveField: {
    id: "Label_Admin_NotificationsTab_MotiveField",
    defaultMessage: "Motivo",
    description: "Label do campo de Motivo no tab de notificações",
  },
  Label_Admin_NotificationsTab_SubjectField: {
    id: "Label_Admin_NotificationsTab_SubjectField",
    defaultMessage: "Assunto",
    description: "Label do campo",
  },
  Label_Admin_NotificationsTab_ObservationsField: {
    id: "Label_Admin_NotificationsTab_ObservationsField",
    defaultMessage: "Mensagem",
    description: "Label do campo",
  },

  Label_Component_ModalFormsContent_Stocks_RegisterLossBreak_CategoryField: {
    id: "Label_Component_ModalFormsContent_Stocks_RegisterLossBreak_CategoryField",
    defaultMessage: "Especialidade",
    description: "Label do campo",
  },
  Label_Component_ModalFormsContent_Stocks_RegisterLossBreak_QuantityField: {
    id: "Label_Component_ModalFormsContent_Stocks_RegisterLossBreak_QuantityField",
    defaultMessage: "Quantidade de perda",
    description: "Label do campo",
  },
  Label_Component_ModalFormsContent_Stocks_RegisterLossBreak_BatchNumberField: {
    id: "Label_Component_ModalFormsContent_Stocks_RegisterLossBreak_BatchNumberField",
    defaultMessage: "Referência do Lote",
    description: "Label do campo",
  },
  Label_Component_ModalFormsContent_Stocks_RegisterLossBreak_ValueField: {
    id: "Label_Component_ModalFormsContent_Stocks_RegisterLossBreak_ValueField",
    defaultMessage: "Valor Total",
    description: "Label do campo",
  },
  Label_Component_ModalFormsContent_Stocks_RegisterLossBreak_MotiveField: {
    id: "Label_Component_ModalFormsContent_Stocks_RegisterLossBreak_MotiveField",
    defaultMessage: "Motivo",
    description: "Label do campo",
  },

  Label_Admin_ScheduleDetails_ReportField: {
    id: "Label_Admin_ScheduleDetails_ReportField",
    defaultMessage: "Relatório",
    description: "Label da caixa de texto para o relatório do agendamento",
  },
  Label_Admin_EditIndividualUserPersonalData_Field_isAvailableForInternshipOpportunities: {
    id: "Label_Admin_EditIndividualUserPersonalData_Field_isAvailableForInternshipOpportunities",
    defaultMessage: "Quero aparecer nas listas de candidatos a estágios",
    description:
      "Label do campo 'Disponível para Oportunidades de Estágio' do ecrã de edição de informação pessoal do utilizador",
  },
  Label_Admin_EditIndividualUserPersonalData_Field_isAvailableForEmploymentOpportunities: {
    id: "Label_Admin_EditIndividualUserPersonalData_Field_isAvailableForEmploymentOpportunities",
    defaultMessage: "Quero aparecer nas listas de candidatos a emprego",
    description:
      "Label do campo 'Disponível para Oportunidades de Emprego' do ecrã de edição de informação pessoal do utilizador",
  },
  Label_Admin_UserManagement_CreateEnterpriseUser_Button: {
    id: "Label_Admin_UserManagement_CreateEnterpriseUser_Button",
    defaultMessage: "Criar utilizador empresa",
    description: "Label do botão que redireciona o utilizador para o ecrã de criação de utilizadores coletivos",
  },
  Label_Admin_UserManagement_CreateIndividualUser_Button: {
    id: "Label_Admin_UserManagement_CreateIndividualUser_Button",
    defaultMessage: "Criar utilizador cidadão",
    description: "Label do botão que redireciona o utilizador para o ecrã de criação de utilizadores individuais",
  },

  // Loans
  Label_Admin_Loans_GovernmentProgram: {
    id: "Label_Admin_Loans_GovernmentProgram",
    defaultMessage: "Programa do Governo",
    description: "ApplicationNumber field in LoanDetails page",
  },
  Label_Admin_Loans_ApplicationNumber: {
    id: "Label_Admin_Loans_ApplicationNumber",
    defaultMessage: "Nº de Candidatura",
    description: "ApplicationNumber field in LoanDetails page",
  },
  Label_Admin_Loans_State: {
    id: "Label_Admin_Loans_State",
    defaultMessage: "Estado do Empréstimo",
    description: "State field in LoanDetails page",
  },
  Label_Admin_Loans_State_short: {
    id: "Label_Admin_Loans_State_short",
    defaultMessage: "Estado",
    description: "State field in LoanDetails page, short version",
  },
  Label_Admin_Loans_LastUpdateDate: {
    id: "Label_Admin_Loans_LastUpdateDate",
    defaultMessage: "Data da Última Actualização",
    description: "LastUpdateDate field in LoanDetails page",
  },
  Label_Admin_Loans_LastUpdateDate_short: {
    id: "Label_Admin_Loans_LastUpdateDate_short",
    defaultMessage: "Última Actualização",
    description: "LastUpdateDate field in LoanDetails page, short version",
  },
  Label_Admin_Loans_AmountToBePaid: {
    id: "Label_Admin_Loans_AmountToBePaid",
    defaultMessage: "Valor da Dívida",
    description: "LastUpdateDate field in LoanDetails page",
  },
  Label_Admin_Loans_AmountApproved: {
    id: "Label_Admin_Loans_AmountApproved",
    defaultMessage: "Valor Aprovado",
    description: "LastUpdateDate field in LoanDetails page",
  },
  Label_Admin_Loans_IsMissingPayments: {
    id: "Label_Admin_Loans_IsMissingPayments",
    defaultMessage: "Em incumprimento",
    description: "LastUpdateDate field in LoanDetails page",
  },
  Label_Admin_Loans_BankName: {
    id: "Label_Admin_Loans_BankName",
    defaultMessage: "Banco",
    description: "LastUpdateDate field in LoanDetails page",
  },
  Label_Admin_Loans_LoanType: {
    id: "Label_Admin_Loans_LoanType",
    defaultMessage: "Produto Bancário",
    description: "LastUpdateDate field in LoanDetails page",
  },
  Label_Admin_Loans_LoanType_short: {
    id: "Label_Admin_Loans_LoanType_short",
    defaultMessage: "Produto",
    description: "LastUpdateDate field in LoanDetails page, short version",
  },
  Label_Admin_Loans_RejectionMotive: {
    id: "Label_Admin_Loans_RejectionMotive",
    defaultMessage: "Motivo da Rejeição",
    description: "LastUpdateDate field in LoanDetails page",
  },
  Label_Admin_Loans_RejectionObservations: {
    id: "Label_Admin_Loans_RejectionObservations",
    defaultMessage: "Observações da Rejeição",
    description: "LastUpdateDate field in LoanDetails page",
  },

  // InformationDissemination
  Label_Admin_InformationDissemination_InformationData: {
    id: "Label_Admin_InformationDissemination_InformationData",
    defaultMessage: "Dados da Informação",
    description: "InformationData card label",
  },
  Label_Admin_InformationDissemination_Published: {
    id: "Label_Admin_InformationDissemination_Published",
    defaultMessage: "Publicado",
    description: "Information published label",
  },
  Label_Admin_InformationDissemination_NotPublished: {
    id: "Label_Admin_InformationDissemination_NotPublished",
    defaultMessage: "Não Publicado",
    description: "Information published label",
  },
  Label_Admin_InformationDissemination_InformationCategory: {
    id: "Label_Admin_InformationDissemination_InformationCategory",
    defaultMessage: "Categoria",
    description: "Information Category label",
  },
  Label_Admin_InformationDissemination_Authentication: {
    id: "Label_Admin_InformationDissemination_Authentication",
    defaultMessage: "Autenticação",
    description: "Authentication label",
  },
  Label_Admin_InformationDissemination_AuthenticatedUsers: {
    id: "Label_Admin_InformationDissemination_AuthenticatedUsers",
    defaultMessage: "Utilizadores autenticados",
    description: "Authenticated Users label",
  },
  Label_Admin_InformationDissemination_AuthenticationPublic: {
    id: "Label_Admin_InformationDissemination_AuthenticationPublic",
    defaultMessage: "Público",
    description: "Authentication Public label",
  },
  Label_Admin_InformationDissemination_Highlight: {
    id: "Label_Admin_InformationDissemination_Highlight",
    defaultMessage: "Destaque da informação",
    description: "InformationHighlight label",
  },
  Label_Admin_InformationDissemination_Highlighted: {
    id: "Label_Admin_InformationDissemination_Highlighted",
    defaultMessage: "Em Destaque",
    description: "Information Highlighted label",
  },
  Label_Admin_InformationDissemination_NotHighlighted: {
    id: "Label_Admin_InformationDissemination_NotHighlighted",
    defaultMessage: "Sem Destaque",
    description: "Information not Highlighted label",
  },
  Label_Admin_InformationDissemination_Title: {
    id: "Label_Admin_InformationDissemination_Title",
    defaultMessage: "Título",
    description: "Information title label",
  },
  Label_Admin_InformationDissemination_Description: {
    id: "Label_Admin_InformationDissemination_Description",
    defaultMessage: "Texto",
    description: "Information description label",
  },
  Label_Admin_InformationDissemination_Images: {
    id: "Label_Admin_InformationDissemination_Images",
    defaultMessage: "Imagens",
    description: "Information images section label",
  },
  Label_Admin_InformationDissemination_Videos: {
    id: "Label_Admin_InformationDissemination_Videos",
    defaultMessage: "Vídeos",
    description: "Information videos section label",
  },
  Label_Admin_InformationDissemination_VideoInputFieldInfoIcon: {
    id: "Label_Admin_InformationDissemination_VideoInputFieldInfoIcon",
    defaultMessage:
      "Colocar aqui as ligações exportadas (opção Embed) dos vídeos de redes sociais (Facebook e Youtube)",
    description: "Information videos section label",
  },
  Label_Admin_InformationDissemination_CreatedBy: {
    id: "Label_Admin_InformationDissemination_CreatedBy",
    defaultMessage: "Criado por",
    description: "Information details created by label",
  },
  Label_Admin_InformationDissemination_PublishedBy: {
    id: "Label_Admin_InformationDissemination_PublishedBy",
    defaultMessage: "Publicado por",
    description: "Information details published by label",
  },
  Label_Admin_InformationDissemination_PublishedDate: {
    id: "Label_Admin_InformationDissemination_PublishedDate",
    defaultMessage: "Data Publicação",
    description: "Information details published date label",
  },
  Label_Admin_InformationDissemination_SocialMediaLink: {
    id: "Label_Admin_InformationDissemination_SocialMediaLink",
    defaultMessage: "Endereço",
    description: "Information details social media link label",
  },

  //Search Bar
  Label_Admin_AdvancedSearch_AdvancedSearch: {
    id: "Label_Admin_AdvancedSearch_AdvancedSearch",
    defaultMessage: "Pesquisa Avançada",
    description: "Advanced search page title",
  },
  Label_Admin_SearchBarNavbar_SearchBarPlaceholder: {
    id: "Label_Admin_SearchBarNavbar_SearchBarPlaceholder",
    defaultMessage: "Pesquisa de utilizador - Introduza um Nome, NIF (empresa), Nº de Identificação ou Nº de Telefone",
    description: "Search bar placeholder",
  },
  Label_Admin_SearchBarNavbar_IdemtificationNumber: {
    id: "Label_Admin_SearchBarNavbar_IdemtificationNumber",
    defaultMessage: "Nº Identificação",
    description: "Identification number label",
  },
  Label_Admin_SearchBarNavbar_NIF: {
    id: "Label_Admin_SearchBarNavbar_NIF",
    defaultMessage: "NIF",
    description: "NIF label",
  },
  Label_Admin_SearchBarNavbar_PhoneNumber: {
    id: "Label_Admin_SearchBarNavbar_PhoneNumber",
    defaultMessage: "Telemóvel",
    description: "Phone number label",
  },
  Label_Admin_SearchBarNavbar_NoResults: {
    id: "Label_Admin_SearchBarNavbar_NoResults",
    defaultMessage: "Sem resultados",
    description: "No results label",
  },

  //UserOverview
  Label_UserOverview_General_Data_Tab: {
    id: "Label_UserOverview_General_Data_Tab",
    defaultMessage: "Dados Gerais",
    description: "General data tab",
  },
  Label_UserOverview_Training_And_Profession_Tab: {
    id: "Label_UserOverview_Training_And_Profession_Tab",
    defaultMessage: "Formação e Profissão",
    description: "Training and profession tab",
  },
  Label_UserOverview_Applications_Tab: {
    id: "Label_UserOverview_Applications_Tab",
    defaultMessage: "Candidaturas",
    description: "Applications tab",
  },
  Label_UserOverview_EmploymentAndUnemploymentHistory_Tab: {
    id: "Label_UserOverview_EmploymentAndUnemploymentHistory_Tab",
    defaultMessage: "Histórico de Emprego",
    description: "History tab",
  },
  Label_UserOverview_History_Tab: {
    id: "Label_UserOverview_History_Tab",
    defaultMessage: "Histórico Geral",
    description: "History tab",
  },
  //UserOverview - GeneralData Tab - Contacts Section
  Label_UserOverview_GeneralDataTab_ContactsSection_MobilePhone: {
    id: "Label_UserOverview_GeneralDataTab_ContactsSection_MobilePhone",
    defaultMessage: "Telemóvel",
    description: "Mobile phone in the contacts section of the user overview screen",
  },
  Label_UserOverview_GeneralDataTab_ContactsSection_AlternativeMobilePhone: {
    id: "Label_UserOverview_GeneralDataTab_ContactsSection_AlternativeMobilePhone",
    defaultMessage: "Telemóvel Alternativo",
    description: "Alternative mobile phone in the contacts section of the user overview screen",
  },
  Label_UserOverview_GeneralDataTab_ContactsSection_Email: {
    id: "Label_UserOverview_GeneralDataTab_ContactsSection_Email",
    defaultMessage: "E-mail",
    description: "Email in the contacts section of the user overview screen",
  },
  Label_UserOverview_GeneralDataTab_ContactsSection_ContactName: {
    id: "Label_UserOverview_GeneralDataTab_ContactsSection_ContactName",
    defaultMessage: "Nome do Responsável",
    description: "Contact Name in the contacts section of the user overview screen",
  },
  Label_UserOverview_GeneralDataTab_ContactsSection_ContactMobilePhone: {
    id: "Label_UserOverview_GeneralDataTab_ContactsSection_ContactMobilePhone",
    defaultMessage: "Telemóvel do Responsável",
    description: "Contact Mobile Phone in the contacts section of the user overview screen",
  },
  Label_UserOverview_GeneralDataTab_ContactsSection_ContactAlternativeMobilePhone: {
    id: "Label_UserOverview_GeneralDataTab_ContactsSection_ContactAlternativeMobilePhone",
    defaultMessage: "Telemóvel Alternativo do Responsável",
    description: "Contact Alternative Mobile Phone in the contacts section of the user overview screen",
  },
  Label_UserOverview_GeneralDataTab_ContactsSection_ContactEmail: {
    id: "Label_UserOverview_GeneralDataTab_ContactsSection_ContactEmail",
    defaultMessage: "E-mail do Responsável",
    description: "ContactEmail in the contacts section of the user overview screen",
  },
  //UserOverview - GeneralData Tab - GeneralData Section
  Label_UserOverview_GeneralDataTab_GeneralDataSection_FullName: {
    id: "Label_UserOverview_GeneralDataTab_GeneralDataSection_FullName",
    defaultMessage: "Nome completo",
    description: "Full Name in the general data section of the user overview screen",
  },
  Label_UserOverview_GeneralDataTab_GeneralDataSection_CompanyName: {
    id: "Label_UserOverview_GeneralDataTab_GeneralDataSection_CompanyName",
    defaultMessage: "Nome da Empresa",
    description: "Company Name in the general data section of the user overview screen",
  },
  Label_UserOverview_GeneralDataTab_GeneralDataSection_IdentificationNumber: {
    id: "Label_UserOverview_GeneralDataTab_GeneralDataSection_IdentificationNumber",
    defaultMessage: "Nº do BI",
    description: "Identification number in the general data section of the user overview screen",
  },
  Label_UserOverview_GeneralDataTab_GeneralDataSection_CompanySocialSecurityNumber: {
    id: "Label_UserOverview_GeneralDataTab_GeneralDataSection_CompanySocialSecurityNumber",
    defaultMessage: "NISS Individual",
    description: "Company Social Security Number in the general data section of the user overview screen",
  },
  Label_UserOverview_GeneralDataTab_GeneralDataSection_BirthDate: {
    id: "Label_UserOverview_GeneralDataTab_GeneralDataSection_BirthDate",
    defaultMessage: "Data de Nascimento",
    description: "Birth Date in the general data section of the user overview screen",
  },
  Label_UserOverview_GeneralDataTab_GeneralDataSection_Gender: {
    id: "Label_UserOverview_GeneralDataTab_GeneralDataSection_Gender",
    defaultMessage: "Género",
    description: "Gender in the general data section of the user overview screen",
  },
  Label_UserOverview_GeneralDataTab_GeneralDataSection_MaritalStatus: {
    id: "Label_UserOverview_GeneralDataTab_GeneralDataSection_MaritalStatus",
    defaultMessage: "Estado Civil",
    description: "Marital Status in the general data section of the user overview screen",
  },
  Label_UserOverview_GeneralDataTab_GeneralDataSection_Nationality: {
    id: "Label_UserOverview_GeneralDataTab_GeneralDataSection_Nationality",
    defaultMessage: "Nacionalidade",
    description: "Nationality in the general data section of the user overview screen",
  },
  Label_UserOverview_GeneralDataTab_GeneralDataSection_Province: {
    id: "Label_UserOverview_GeneralDataTab_GeneralDataSection_Province",
    defaultMessage: "Província",
    description: "Province in the general data section of the user overview screen",
  },
  Label_UserOverview_GeneralDataTab_GeneralDataSection_Municipality: {
    id: "Label_UserOverview_GeneralDataTab_GeneralDataSection_Municipality",
    defaultMessage: "Município",
    description: "Municipality in the general data section of the user overview screen",
  },
  Label_UserOverview_GeneralDataTab_GeneralDataSection_District: {
    id: "Label_UserOverview_GeneralDataTab_GeneralDataSection_District",
    defaultMessage: "Bairro",
    description: "District in the general data section of the user overview screen",
  },
  Label_UserOverview_GeneralDataTab_GeneralDataSection_SpecialNeeds: {
    id: "Label_UserOverview_GeneralDataTab_GeneralDataSection_SpecialNeeds",
    defaultMessage: "Necessidades Especiais",
    description: "Special Needs in the general data section of the user overview screen",
  },
  Label_UserOverview_GeneralDataTab_GeneralDataSection_SpecialNeedsPercentage: {
    id: "Label_UserOverview_GeneralDataTab_GeneralDataSection_SpecialNeedsPercentage",
    defaultMessage: "Percentagem %",
    description: "Special Needs Percentage in the general data section of the user overview screen",
  },
  Label_UserOverview_GeneralDataTab_GeneralDataSection_TaxIdNumber: {
    id: "Label_UserOverview_GeneralDataTab_GeneralDataSection_TaxIdNumber",
    defaultMessage: "NIF",
    description: "Tax Id Number in the general data section of the user overview screen",
  },
  Label_UserOverview_GeneralDataTab_GeneralDataSection_RegistrationNumber: {
    id: "Label_UserOverview_GeneralDataTab_GeneralDataSection_RegistrationNumber",
    defaultMessage: "Matrícula",
    description: "Registration Number in the general data section of the user overview screen",
  },
  Label_UserOverview_GeneralDataTab_GeneralDataSection_PermitCode: {
    id: "Label_UserOverview_GeneralDataTab_GeneralDataSection_PermitCode",
    defaultMessage: "Código do Alvará",
    description: "Permit Code in the general data section of the user overview screen",
  },
  Label_UserOverview_GeneralDataTab_GeneralDataSection_PermitCodeExpirationDate: {
    id: "Label_UserOverview_GeneralDataTab_GeneralDataSection_PermitCodeExpirationDate",
    defaultMessage: "Data de Validade do Alvará",
    description: "Permit Code Expiration Date in the general data section of the user overview screen",
  },
  Label_UserOverview_GeneralDataTab_GeneralDataSection_Address: {
    id: "Label_UserOverview_GeneralDataTab_GeneralDataSection_Address",
    defaultMessage: "Endereço",
    description: "Address in the general data section of the user overview screen",
  },
  Label_UserOverview_GeneralDataTab_GeneralDataSection_Household: {
    id: "Label_UserOverview_GeneralDataTab_GeneralDataSection_Household",
    defaultMessage: "Pessoas a Cargo",
    description: "Address in the general data section of the user overview screen",
  },
  Label_UserOverview_GeneralDataTab_GeneralDataSection_FamilyIncome: {
    id: "Label_UserOverview_GeneralDataTab_GeneralDataSection_FamilyIncome",
    defaultMessage: "Rendimento Familiar",
    description: "Address in the general data section of the user overview screen",
  },
  //UserOverview - GeneralData Tab - Overview Section
  Label_UserOverview_GeneralDataTab_OverviewSection_GovernmentProgramApplications: {
    id: "Label_UserOverview_GeneralDataTab_GeneralDataSection_GovernmentProgramApplications",
    defaultMessage: "Candidaturas a Programas",
    description: "Government Program Applications in the overview section of the user overview screen",
  },
  Label_UserOverview_GeneralDataTab_OverviewSection_OpportunityApplications: {
    id: "Label_UserOverview_GeneralDataTab_GeneralDataSection_OpportunityApplications",
    defaultMessage: "Candidaturas a Emprego/Estágio",
    description: "Opportunity Applications in the overview section of the user overview screen",
  },
  Label_UserOverview_GeneralDataTab_OverviewSection_ProfessionalTraining: {
    id: "Label_UserOverview_GeneralDataTab_GeneralDataSection_ProfessionalTraining",
    defaultMessage: "Cursos Profissionais Frequentados",
    description: "Professional Training in the overview section of the user overview screen",
  },
  Label_UserOverview_GeneralDataTab_OverviewSection_ProfessionalWallets: {
    id: "Label_UserOverview_GeneralDataTab_GeneralDataSection_ProfessionalWallet",
    defaultMessage: "Carteira Profissional",
    description: "Professional Wallet in the overview section of the user overview screen",
  },
  Label_UserOverview_GeneralDataTab_OverviewSection_MicrocreditDebt: {
    id: "Label_UserOverview_GeneralDataTab_OverviewSection_MicrocreditDebt",
    defaultMessage: "Dívida de Microcrédito",
    description: "Microcredit Debt Paymentsin the overview section of the user overview screen",
  },
  Label_UserOverview_GeneralDataTab_OverviewSection_MicrocreditDebtWithMissingPayments: {
    id: "Label_UserOverview_GeneralDataTab_OverviewSection_MicrocreditDebtWithMissingPayments",
    defaultMessage: "em Incumprimento",
    description: "Microcredit Debt With Missing Paymentsin the overview section of the user overview screen",
  },
  Label_UserOverview_GeneralDataTab_OverviewSection_MicrocreditDebtWithoutMissingPayments: {
    id: "Label_UserOverview_GeneralDataTab_OverviewSection_MicrocreditDebtWithoutMissingPayments",
    defaultMessage: "em Cumprimento",
    description: "Microcredit Debt Without Missing Paymentsin the overview section of the user overview screen",
  },
  Label_UserOverview_GeneralDataTab_OverviewSection_OpportunityFollowUpOverallEvaluation: {
    id: "Label_UserOverview_GeneralDataTab_GeneralDataSection_OpportunityFollowUpOverallEvaluation",
    defaultMessage: "Pontuação Média de Estágios",
    description: "Opportunity FollowUp Overall Evaluation in the overview section of the user overview screen",
  },
  Label_UserOverview_GeneralDataTab_OverviewSection_FinishedInterships: {
    id: "Label_UserOverview_GeneralDataTab_GeneralDataSection_FinishedInterships",
    defaultMessage: "Estágios Concluídos",
    description: "Finished Interships in the overview section of the user overview screen",
  },
  Label_UserOverview_GeneralDataTab_OverviewSection_GrantedMicrocredits: {
    id: "Label_UserOverview_GeneralDataTab_GeneralDataSection_GrantedMicrocredits",
    defaultMessage: "Microcréditos Concedidos",
    description: "Granted Microcredits in the overview section of the user overview screen",
  },
  Label_UserOverview_GeneralDataTab_OverviewSection_GrantedProfessionalKits: {
    id: "Label_UserOverview_GeneralDataTab_GeneralDataSection_GrantedProfessionalKits",
    defaultMessage: "Kits Profissionais Atribuídos",
    description: "Granted Professional Kits in the overview section of the user overview screen",
  },
  Label_UserOverview_GeneralDataTab_OverviewSection_AllSchedules: {
    id: "Label_UserOverview_GeneralDataTab_GeneralDataSection_AllSchedules",
    defaultMessage: "Todos os Agendamentos",
    description: "All Schedules in the overview section of the user overview screen",
  },
  Label_UserOverview_GeneralDataTab_OverviewSection_JoinedOn: {
    id: "Label_UserOverview_GeneralDataTab_GeneralDataSection_JoinedIn",
    defaultMessage: "Aderiu em",
    description: "Joined In in the overview section of the user overview screen",
  },
  Label_UserOverview_GeneralDataTab_OverviewSection_NoJoinedOnData: {
    id: "Label_UserOverview_GeneralDataTab_OverviewSection_NoJoinedOnData",
    defaultMessage: "Data de adesão indisponível",
    description: "No Joined On Data in the overview section of the user overview screen",
  },
  Label_UserOverview_GeneralDataTab_OverviewSection_NoProfessionalData: {
    id: "Label_UserOverview_GeneralDataTab_OverviewSection_NoProfessionalData",
    defaultMessage: "Sem informação profissional",
    description: "No Professional Data in the overview section of the user overview screen",
  },
  Label_UserOverview_GeneralDataTab_OverviewSection_NoProvinceData: {
    id: "Label_UserOverview_GeneralDataTab_OverviewSection_NoProvinceData",
    defaultMessage: "Sem informação de localização",
    description: "No Province Data in the overview section of the user overview screen",
  },
  Label_UserOverview_GeneralDataTab_OverviewSection_EmploymentAndInternshipOpportunities: {
    id: "Label_UserOverview_GeneralDataTab_GeneralDataSection_EmploymentAndInternshipOpportunities",
    defaultMessage: "Ofertas de Emprego/Estágio",
    description: "Employment And Internship Opportunities In in the overview section of the user overview screen",
  },
  Label_UserOverview_GeneralDataTab_OverviewSection_ReceivedApplications: {
    id: "Label_UserOverview_GeneralDataTab_GeneralDataSection_ReceivedApplications",
    defaultMessage: "Candidaturas Recebidas",
    description: "Received Applications in the overview section of the user overview screen",
  },
  //UserOverview - Profession Tab - Professional Data Section
  Label_UserOverview_ProfessionTab_ProfessionalDataSection_AcademicLevel: {
    id: "Label_UserOverview_ProfessionTab_ProfessionalDataSection_AcademicLevel",
    defaultMessage: "Nível Académico",
    description: "Academic Level in the professional data section of the user overview screen",
  },
  Label_UserOverview_ProfessionTab_ProfessionalDataSection_AcademicDegree: {
    id: "Label_UserOverview_ProfessionTab_ProfessionalDataSection_AcademicDegree",
    defaultMessage: "Curso",
    description: "Academic Degree in the professional data section of the user overview screen",
  },
  Label_UserOverview_ProfessionTab_ProfessionalDataSection_ProfessionalCategory: {
    id: "Label_UserOverview_ProfessionTab_ProfessionalDataSection_ProfessionalCategory",
    defaultMessage: "Categoria Profissional",
    description: "Professional Category in the professional data section of the user overview screen",
  },
  Label_UserOverview_ProfessionTab_ProfessionalDataSection_ProfessionalTraining: {
    id: "Label_UserOverview_ProfessionTab_ProfessionalDataSection_ProfessionalTraining",
    defaultMessage: "Formação Profissional",
    description: "Professional Training in the professional data section of the user overview screen",
  },
  Label_UserOverview_ProfessionTab_ProfessionalDataSection_EntrepreneurshipTraining: {
    id: "Label_UserOverview_ProfessionTab_ProfessionalDataSection_EntrepreneurshipTraining",
    defaultMessage: "Formação de Empreendedorismo",
    description: "Entrepreneurship Training in the professional data section of the user overview screen",
  },
  Label_UserOverview_ProfessionTab_ProfessionalDataSection_SmallBusinessManagementTraining: {
    id: "Label_UserOverview_ProfessionTab_ProfessionalDataSection_SmallBusinessManagementTraining",
    defaultMessage: "Formação de Gestão de Pequenos Negócios",
    description: "Small Business Management Training in the professional data section of the user overview screen",
  },
  Label_UserOverview_ProfessionTab_ProfessionalDataSection_Profession: {
    id: "Label_UserOverview_ProfessionTab_ProfessionalDataSection_Profession",
    defaultMessage: "Profissão Principal",
    description: "Profession in the professional data section of the user overview screen",
  },
  Label_UserOverview_ProfessionTab_ProfessionalDataSection_YearsOfExperience: {
    id: "Label_UserOverview_ProfessionTab_ProfessionalDataSection_YearsOfExperience",
    defaultMessage: "Anos de Experiência",
    description: "Years Of Experience in the professional data section of the user overview screen",
  },
  Label_UserOverview_ProfessionTab_ProfessionalDataSection_MainYearsOfExperience: {
    id: "Label_UserOverview_ProfessionTab_ProfessionalDataSection_MainYearsOfExperience",
    defaultMessage: "Experiência Profissional Principal",
    description: "Years Of Experience in the professional data section of the user overview screen",
  },
  Label_UserOverview_ProfessionTab_ProfessionalDataSection_BusinessPurpose: {
    id: "Label_UserOverview_ProfessionTab_ProfessionalDataSection_BusinessPurpose",
    defaultMessage: "Setor Económico",
    description: "Business Purpose in the professional data section of the user overview screen",
  },
  Label_UserOverview_ProfessionTab_ProfessionalDataSection_CompanyEACDescription: {
    id: "Label_UserOverview_ProfessionTab_ProfessionalDataSection_CompanyEACDescription",
    defaultMessage: "CAE da Empresa",
    description: "UserOverview",
  },
  Label_UserOverview_ProfessionTab_ProfessionalDataSection_PropertyType: {
    id: "Label_UserOverview_ProfessionTab_ProfessionalDataSection_PropertyType",
    defaultMessage: "Tipo Propriedade",
    description: "UserOverview",
  },
  Label_UserOverview_ProfessionTab_ProfessionalDataSection_SubsidiaryCompany: {
    id: "Label_UserOverview_ProfessionTab_ProfessionalDataSection_SubsidiaryCompany",
    defaultMessage: "Filial (opcional)",
    description: "UserOverview",
  },
  Label_UserOverview_ProfessionTab_ProfessionalDataSection_SubsidiaryCompanyPostalCode: {
    id: "Label_UserOverview_ProfessionTab_ProfessionalDataSection_SubsidiaryCompanyPostalCode",
    defaultMessage: "Caixa Postal da Filial (opcional)",
    description: "UserOverview",
  },
  Label_UserOverview_ProfessionTab_ProfessionalDataSection_SubsidiaryCompanyActivity: {
    id: "Label_UserOverview_ProfessionTab_ProfessionalDataSection_SubsidiaryCompanyActivity",
    defaultMessage: "Actividade da Filial (opcional)",
    description: "UserOverview",
  },
  Label_UserOverview_ProfessionTab_ProfessionalDataSection_SubsidiaryCompanyEACDescription: {
    id: "Label_UserOverview_ProfessionTab_ProfessionalDataSection_SubsidiaryCompanyEACDescription",
    defaultMessage: "CAE da Filial (opcional)",
    description: "UserOverview",
  },
  Label_UserOverview_ProfessionTab_ProfessionalDataSection_MaleWorkersNumber: {
    id: "Label_UserOverview_ProfessionTab_ProfessionalDataSection_MaleWorkersNumber",
    defaultMessage: "Nº de Trabalhadores Homens (opcional)",
    description: "UserOverview",
  },
  Label_UserOverview_ProfessionTab_ProfessionalDataSection_FemaleWorkersNumber: {
    id: "Label_UserOverview_ProfessionTab_ProfessionalDataSection_FemaleWorkersNumber",
    defaultMessage: "Nº de Trabalhadores Mulheres (opcional)",
    description: "UserOverview",
  },
  Label_UserOverview_ProfessionTab_ProfessionalDataSection_TechnicalCapability: {
    id: "Label_UserOverview_ProfessionTab_ProfessionalDataSection_TechnicalCapability",
    defaultMessage: "Capacidade Técnica",
    description: "TechnicalCapability in the professional data section of the user overview screen",
  },
  Label_UserOverview_ProfessionTab_ProfessionalDataSection_Has_Professional_Wallet: {
    id: "Label_UserOverview_ProfessionTab_ProfessionalDataSection_Has_Professional_Wallet",
    defaultMessage: "Carteira Profissional",
    description: "Professional Wallet in the professional data section of the user overview screen",
  },
  Label_UserOverview_ProfessionTab_ProfessionalDataSection_Professional_Wallet_Number: {
    id: "Label_UserOverview_ProfessionTab_ProfessionalDataSection_Professional_Wallet_Number",
    defaultMessage: "Nº Carteira Profissional",
    description: "Professional Wallet Number in the professional data section of the user overview screen",
  },
  Label_UserOverview_ProfessionTab_ProfessionalDataSection_Professional_Wallet_Class: {
    id: "Label_UserOverview_ProfessionTab_ProfessionalDataSection_Professional_Wallet_Class",
    defaultMessage: "Classe Carteira Profissional",
    description: "Professional Wallet Class in the professional data section of the user overview screen",
  },
  Label_UserOverview_ProfessionTab_ProfessionalDataSection_Professional_Wallet_Specialty: {
    id: "Label_UserOverview_ProfessionTab_ProfessionalDataSection_Professional_Wallet_Specialty",
    defaultMessage: "Especialidade Carteira Profissional",
    description: "Professional Wallet Category in the professional data section of the user overview screen",
  },
  Label_UserOverview_ProfessionTab_ProfessionalDataSection_IntendedWorkplaceProvince: {
    id: "Label_UserOverview_ProfessionTab_ProfessionalDataSection_IntendedWorkplaceProvince",
    defaultMessage: "Local Trabalho Pretendido",
    description: "Professional Wallet in the professional data section of the user overview screen",
  },
  Label_UserOverview_ProfessionTab_ProfessionalDataSection_ProfessionalQualification: {
    id: "Label_UserOverview_ProfessionTab_ProfessionalDataSection_ProfessionalQualification",
    defaultMessage: "Qualificação Profissional (conhecimentos máquinas, etc.)",
    description: "Professional Wallet in the professional data section of the user overview screen",
  },
  Label_UserOverview_ProfessionTab_ProfessionalDataSection_OtherProfessionalExperience: {
    id: "Label_UserOverview_ProfessionTab_ProfessionalDataSection_OtherProfessionalExperience",
    defaultMessage: "Outras Experiências Profissionais ",
    description: "Professional Wallet in the professional data section of the user overview screen",
  },
  Label_UserOverview_ProfessionTab_ProfessionalDataSection_OccupationalCategory: {
    id: "Label_UserOverview_ProfessionTab_ProfessionalDataSection_OccupationalCategory",
    defaultMessage: "Categoria Ocupacional",
    description: "Professional Wallet in the professional data section of the user overview screen",
  },
  Label_UserOverview_ProfessionTab_ProfessionalDataSection_SecondaryProfessionalExperience: {
    id: "Label_UserOverview_ProfessionTab_ProfessionalDataSection_SecondaryProfessionalExperience",
    defaultMessage: "Profissão Subsidiária",
    description: "Professional Wallet in the professional data section of the user overview screen",
  },
  Label_UserOverview_ProfessionTab_ProfessionalDataSection_SecondaryExperience: {
    id: "Label_UserOverview_ProfessionTab_ProfessionalDataSection_SecondaryExperience",
    defaultMessage: "Experiência Profissional Subsidiária",
    description: "Professional Wallet in the professional data section of the user overview screen",
  },
  Label_UserOverview_ProfessionTab_ProfessionalDataSection_StudiedLanguages: {
    id: "Label_UserOverview_ProfessionTab_ProfessionalDataSection_StudiedLanguages",
    defaultMessage: "Línguas Estudadas",
    description: "Professional Wallet in the professional data section of the user overview screen",
  },
  //UserOverview - Applications Tab - Applications Section
  Label_UserOverview_ApplicationsTab_ApplicationsSection_GovernmentProgramsTab: {
    id: "Label_UserOverview_ApplicationsTab_ApplicationsSection_GovernmentProgramsTab",
    defaultMessage: "Programas do Governo",
    description: "Government Programs Tab in the applications section of the user overview screen",
  },
  Label_UserOverview_ApplicationsTab_ApplicationsSection_InternshipsTab: {
    id: "Label_UserOverview_ApplicationsTab_ApplicationsSection_InternshipsTab",
    defaultMessage: "Estágios Profissionais",
    description: "Internships Tab in the applications section of the user overview screen",
  },
  Label_UserOverview_ApplicationsTab_ApplicationsSection_EmploymentTab: {
    id: "Label_UserOverview_ApplicationsTab_ApplicationsSection_EmploymentTab",
    defaultMessage: "Emprego",
    description: "Employment Tab in the applications section of the user overview screen",
  },
  Label_UserOverview_ApplicationsTab_ApplicationsSection_GovernmentProgram: {
    id: "Label_UserOverview_ApplicationsTab_ApplicationsSection_GovernmentProgram",
    defaultMessage: "Programa do Governo",
    description: "Government Program in the applications section of the user overview screen",
  },
  Label_UserOverview_ApplicationsTab_ApplicationsSection_Program: {
    id: "Label_UserOverview_ApplicationsTab_ApplicationsSection_Program",
    defaultMessage: "Programa",
    description: "Program in the applications section of the user overview screen",
  },
  Label_UserOverview_ApplicationsTab_ApplicationsSection_Subprogram: {
    id: "Label_UserOverview_ApplicationsTab_ApplicationsSection_Subprogram",
    defaultMessage: "Subprograma",
    description: "Subprogram in the applications section of the user overview screen",
  },
  Label_UserOverview_ApplicationsTab_ApplicationsSection_ApplicationCode: {
    id: "Label_UserOverview_ApplicationsTab_ApplicationsSection_ApplicationCode",
    defaultMessage: "Nº Candidatura",
    description: "Application Code in the applications section of the user overview screen",
  },
  Label_UserOverview_ApplicationsTab_ApplicationsSection_ApplicationState: {
    id: "Label_UserOverview_ApplicationsTab_ApplicationsSection_ApplicationState",
    defaultMessage: "Estado",
    description: "Application State in the applications section of the user overview screen",
  },
  Label_UserOverview_ApplicationsTab_ApplicationsSection_CreationDate: {
    id: "Label_UserOverview_ApplicationsTab_ApplicationsSection_CreationDate",
    defaultMessage: "Data da Candidatura",
    description: "Creation Date in the applications section of the user overview screen",
  },
  Label_UserOverview_ApplicationsTab_ApplicationsSection_Company: {
    id: "Label_UserOverview_ApplicationsTab_ApplicationsSection_Company",
    defaultMessage: "Empresa",
    description: "Company in the applications section of the user overview screen",
  },
  Label_UserOverview_ApplicationsTab_ApplicationsSection_Candidate: {
    id: "Label_UserOverview_ApplicationsTab_ApplicationsSection_Candidate",
    defaultMessage: "Candidato",
    description: "Candidate in the applications section of the user collective overview screen",
  },
  Label_UserOverview_ApplicationsTab_ApplicationsSection_OpportunityType: {
    id: "Label_UserOverview_ApplicationsTab_ApplicationsSection_OpportunityType",
    defaultMessage: "Tipo de Oferta",
    description: "Opportunity Type in the applications section of the user overview screen",
  },
  Label_UserOverview_ApplicationsTab_ApplicationsSection_CreateOpportunityApplication: {
    id: "Label_UserOverview_ApplicationsTab_ApplicationsSection_CreateOpportunityApplication",
    defaultMessage: "Criar Candidatura",
    description: "Create Opportunity Application in the applications section of the user overview screen",
  },
  //UserOverview - Applications Tab - FollowUps Section
  Label_UserOverview_ApplicationsTab_FollowUpsSection_OnGoing: {
    id: "Label_UserOverview_ApplicationsTab_FollowUpsSection_OnGoing",
    defaultMessage: "Em Curso",
    description: "On Going Tab in the follow ups section of the user overview screen",
  },
  Label_UserOverview_ApplicationsTab_FollowUpsSection_Finished: {
    id: "Label_UserOverview_ApplicationsTab_FollowUpsSection_Finished",
    defaultMessage: "Concluídos",
    description: "Finished Tab in the follow ups section of the user overview screen",
  },
  Label_UserOverview_ApplicationsTab_FollowUpsSection_ApplicationCode: {
    id: "Label_UserOverview_ApplicationsTab_FollowUpsSection_ApplicationCode",
    defaultMessage: "Nº Candidatura",
    description: "Application Code in the follow ups section of the user overview screen",
  },
  Label_UserOverview_ApplicationsTab_FollowUpsSection_Company: {
    id: "Label_UserOverview_ApplicationsTab_FollowUpsSection_Company",
    defaultMessage: "Empresa",
    description: "Company in the follow ups section of the user overview screen",
  },
  Label_UserOverview_ApplicationsTab_FollowUpsSection_Candidate: {
    id: "Label_UserOverview_ApplicationsTab_FollowUpsSection_Candidate",
    defaultMessage: "Candidato",
    description: "Candidate in the follow ups section of the user collective overview screen",
  },
  Label_UserOverview_ApplicationsTab_FollowUpsSection_OpportunityType: {
    id: "Label_UserOverview_ApplicationsTab_FollowUpsSection_OpportunityType",
    defaultMessage: "Tipo de Oferta",
    description: "Opportunity Type in the follow ups section of the user overview screen",
  },
  Label_UserOverview_ApplicationsTab_FollowUpsSection_State: {
    id: "Label_UserOverview_ApplicationsTab_ApplicationsSection_State",
    defaultMessage: "Estado",
    description: "State in the follow ups section of the user overview screen",
  },
  Label_UserOverview_ApplicationsTab_FollowUpsSection_OverallEvaluation: {
    id: "Label_UserOverview_ApplicationsTab_ApplicationsSection_OverallEvaluation",
    defaultMessage: "Avaliação Geral",
    description: "OverallEvaluation in the follow ups section of the user overview screen",
  },
  //UserOverview - Applications Tab - AddCandidateToOpportunitiesModal
  Label_UserOverview_ApplicationsTab_AddCandidateToOpportunitiesModal_AddCandidatesTitle: {
    id: "Label_UserOverview_ApplicationsTab_AddCandidateToOpportunitiesModal_AddCandidatesTitle",
    defaultMessage: "Adicionar Candidato a Ofertas de Emprego e Estágio",
    description: "AddCandidateToOpportunitiesModal",
  },
  Label_UserOverview_ApplicationsTab_AddCandidateToOpportunitiesModal_CompanyNameOrTaxId: {
    id: "Label_UserOverview_ApplicationsTab_AddCandidateToOpportunitiesModal_CompanyNameOrTaxId",
    defaultMessage: "Nome da Empresa ou NIF",
    description: "AddCandidateToOpportunitiesModal",
  },
  Label_UserOverview_ApplicationsTab_AddCandidateToOpportunitiesModal_Province: {
    id: "Label_UserOverview_ApplicationsTab_AddCandidateToOpportunitiesModal_Province",
    defaultMessage: "Província",
    description: "AddCandidateToOpportunitiesModal",
  },
  Label_UserOverview_ApplicationsTab_AddCandidateToOpportunitiesModal_Municipality: {
    id: "Label_UserOverview_ApplicationsTab_AddCandidateToOpportunitiesModal_Municipality",
    defaultMessage: "Município",
    description: "AddCandidateToOpportunitiesModal",
  },
  Label_UserOverview_ApplicationsTab_AddCandidateToOpportunitiesModal_ProfessionalCategory: {
    id: "Label_UserOverview_ApplicationsTab_AddCandidateToOpportunitiesModal_ProfessionalCategory",
    defaultMessage: "Área Profissional",
    description: "AddCandidateToOpportunitiesModal",
  },
  Label_UserOverview_ApplicationsTab_AddCandidateToOpportunitiesModal_OpportunityType: {
    id: "Label_UserOverview_ApplicationsTab_AddCandidateToOpportunitiesModal_OpportunityType",
    defaultMessage: "Tipo de Oferta",
    description: "AddCandidateToOpportunitiesModal",
  },
  Label_UserOverview_ApplicationsTab_AddCandidateToOpportunitiesModal_Company: {
    id: "Label_UserOverview_ApplicationsTab_AddCandidateToOpportunitiesModal_Company",
    defaultMessage: "Empresa",
    description: "AddCandidateToOpportunitiesModal",
  },
  Label_UserOverview_ApplicationsTab_AddCandidateToOpportunitiesModal_Candidates: {
    id: "Label_UserOverview_ApplicationsTab_AddCandidateToOpportunitiesModal_Candidates",
    defaultMessage: "Candidatos",
    description: "AddCandidateToOpportunitiesModal",
  },
  Label_UserOverview_ApplicationsTab_AddCandidateToOpportunitiesModal_Vacancies: {
    id: "Label_UserOverview_ApplicationsTab_AddCandidateToOpportunitiesModal_Vacancies",
    defaultMessage: "Vagas",
    description: "AddCandidateToOpportunitiesModal",
  },
  Label_UserOverview_ApplicationsTab_AddCandidateToOpportunitiesModal_Reference: {
    id: "Label_UserOverview_ApplicationsTab_AddCandidateToOpportunitiesModal_Reference",
    defaultMessage: "Nº Oferta",
    description: "AddCandidateToOpportunitiesModal",
  },
  Label_UserOverview_ApplicationsTab_AddCandidateToOpportunitiesModal_AcademicLevel: {
    id: "Label_UserOverview_ApplicationsTab_AddCandidateToOpportunitiesModal_AcademicLevel",
    defaultMessage: "Nível de Escolaridade",
    description: "AddCandidateToOpportunitiesModal",
  },
  Label_UserOverview_ApplicationsTab_AddCandidateToOpportunitiesModal_Languages: {
    id: "Label_UserOverview_ApplicationsTab_AddCandidateToOpportunitiesModal_Languages",
    defaultMessage: "Conhecimento de Línguas",
    description: "AddCandidateToOpportunitiesModal",
  },

  //Quick Guides
  Label_QuickGuides_360Vision: {
    id: "Label_QuickGuides_360Vision",
    defaultMessage: "Visão 360º",
    description: "Quick Guides label",
  },
  Label_QuickGuides_CheckUserData: {
    id: "Label_QuickGuides_CheckUserData",
    defaultMessage: "Como consultar informação do utilizador",
    description: "Quick Guides label",
  },
  Label_QuickGuides_InternshipsFollowup: {
    id: "Label_QuickGuides_InternshipsFollowup",
    defaultMessage: "Acompanhamento de Estágios",
    description: "Quick Guides label",
  },
  Label_QuickGuides_InternshipsFollowup_Citizen: {
    id: "Label_QuickGuides_InternshipsFollowup_Citizen",
    defaultMessage: "Como gerar e consultar formulários de cidadão",
    description: "Quick Guides label",
  },
  Label_QuickGuides_InternshipsFollowup_Company: {
    id: "Label_QuickGuides_InternshipsFollowup_Company",
    defaultMessage: "Como gerar, consultar e validar formulários de empresa",
    description: "Quick Guides label",
  },

  //Opportunity - Opportunity Data
  Label_Opportunity_OpportunityData_EmploymentCenter: {
    id: "Label_Opportunity_OpportunityData_EmploymentCenter",
    defaultMessage: "Centro de Emprego",
    description: "Opportunity_OpportunityData",
  },
  Label_Opportunity_OpportunityData_SocialPerks: {
    id: "Label_Opportunity_OpportunityData_SocialPerks",
    defaultMessage: "Regalias Sociais Oferecidas",
    description: "Opportunity_OpportunityData",
  },
  Label_Opportunity_OpportunityData_SocialPerks_Examples: {
    id: "Label_Opportunity_OpportunityData_SocialPerks_Examples",
    defaultMessage: "Transportes, casa, alimentação, etc.",
    description: "Opportunity_OpportunityData",
  },
  Label_Opportunity_OpportunityData_OtherPerks: {
    id: "Label_Opportunity_OpportunityData_OtherPerks",
    defaultMessage: "Outras Regalias",
    description: "Opportunity_OpportunityData",
  },
  Label_Opportunity_OpportunityData_RequiredAcademicLevel: {
    id: "Label_Opportunity_OpportunityData_RequiredAcademicLevel",
    defaultMessage: "Nível de Escolaridade",
    description: "Opportunity_OpportunityData",
  },
  Label_Opportunity_OpportunityData_Workplace: {
    id: "Label_Opportunity_OpportunityData_Workplace",
    defaultMessage: "Local de Trabalho",
    description: "Opportunity_OpportunityData",
  },
  Label_Opportunity_OpportunityData_Commune: {
    id: "Label_Opportunity_OpportunityData_Commune",
    defaultMessage: "Comuna",
    description: "Opportunity_OpportunityData",
  },
  Label_Opportunity_OpportunityData_RequiredKnowledge: {
    id: "Label_Opportunity_OpportunityData_RequiredKnowledge",
    defaultMessage: "Conhecimentos Profissionais",
    description: "Opportunity_OpportunityData",
  },
  Label_Opportunity_OpportunityData_RequiredKnowledge_Examples: {
    id: "Label_Opportunity_OpportunityData_RequiredKnowledge_Examples",
    defaultMessage: "Máquinas, ferramentas, etc.",
    description: "Opportunity_OpportunityData",
  },
  Label_Opportunity_OpportunityData_ActiveMonths: {
    id: "Label_Opportunity_OpportunityData_RequiredKnowledge_ActiveMonths",
    defaultMessage: "Tempo de Prática (anos)",
    description: "Opportunity_OpportunityData",
  },
  Label_Opportunity_OpportunityData_Observations: {
    id: "Label_Opportunity_OpportunityData_RequiredKnowledge_Observations",
    defaultMessage: "Observações (opcional)",
    description: "Opportunity_OpportunityData",
  },
  Label_Opportunity_OpportunityData_AcceptWorkersFromOtherRegions: {
    id: "Label_Opportunity_OpportunityData_AcceptWorkersFromOtherRegions",
    defaultMessage: "Trabalhadores Doutras Regiões",
    description: "Opportunity_OpportunityData",
  },
  Label_Opportunity_OpportunityData_RequiredLanguages: {
    id: "Label_Opportunity_OpportunityData_RequiredLanguages",
    defaultMessage: "Conhecimentos de Línguas",
    description: "Opportunity_OpportunityData",
  },
  Label_Opportunity_OpportunityData_Gender: {
    id: "Label_Opportunity_OpportunityData_Gender",
    defaultMessage: "Género (opcional)",
    description: "Opportunity_OpportunityData",
  },
  Label_Opportunity_OpportunityData_MinAge: {
    id: "Label_Opportunity_OpportunityData_MinAge",
    defaultMessage: "Idade Mínima (opcional)",
    description: "Opportunity_OpportunityData",
  },
  Label_Opportunity_OpportunityData_MaxAge: {
    id: "Label_Opportunity_OpportunityData_MaxAge",
    defaultMessage: "Idade Máxima (opcional)",
    description: "Opportunity_OpportunityData",
  },

  //UserProfile - UserCollective - CompanyData
  Label_UserProfile_UserCollective_CompanyData_CompanyEACId: {
    id: "Label_UserProfile_UserCollective_CompanyData_CompanyEACId",
    defaultMessage: "CAE da Empresa",
    description: "UserProfile - UserCollective - CompanyData",
  },
  Label_UserProfile_UserCollective_CompanyData_PropertyType: {
    id: "Label_UserProfile_UserCollective_CompanyData_PropertyType",
    defaultMessage: "Tipo Propriedade",
    description: "UserProfile - UserCollective - CompanyData",
  },
  Label_UserProfile_UserCollective_CompanyData_SubsidiaryCompany: {
    id: "Label_UserProfile_UserCollective_CompanyData_SubsidiaryCompany",
    defaultMessage: "Filial (opcional)",
    description: "UserProfile - UserCollective - CompanyData",
  },
  Label_UserProfile_UserCollective_CompanyData_SubsidiaryCompanyPostalCode: {
    id: "Label_UserProfile_UserCollective_CompanyData_SubsidiaryCompanyPostalCode",
    defaultMessage: "Caixa Postal da Filial (opcional)",
    description: "UserProfile - UserCollective - CompanyData",
  },
  Label_UserProfile_UserCollective_CompanyData_SubsidiaryCompanyActivity: {
    id: "Label_UserProfile_UserCollective_CompanyData_SubsidiaryCompanyActivity",
    defaultMessage: "Actividade da Filial (opcional)",
    description: "UserProfile - UserCollective - CompanyData",
  },
  Label_UserProfile_UserCollective_CompanyData_SubsidiaryCompanyEAC: {
    id: "Label_UserProfile_UserCollective_CompanyData_SubsidiaryCompanyEAC",
    defaultMessage: "CAE da Filial (opcional)",
    description: "UserProfile - UserCollective - CompanyData",
  },

  //UserProfile - UserSingular
  Label_UserProfile_UserSingular_PersonalAndProfessionalData: {
    id: "Label_UserProfile_UserSingular_PersonalAndProfessionalData",
    defaultMessage: "Dados Pessoais e Profissionais",
    description: "UserProfile - UserSingular",
  },
  Label_UserProfile_UserSingular_EmploymentAndUnemploymentHistory: {
    id: "Label_UserProfile_UserSingular_EmploymentAndUnemploymentHistory",
    defaultMessage: "Histórico de Emprego e Desemprego",
    description: "UserProfile - UserSingular",
  },

  //UserProfile - BO User
  Label_BO_UserProfile_PersonallData: {
    id: "Label_BO_UserProfile_PersonallData",
    defaultMessage: "Dados Pessoais",
    description: "UserProfile - BO User",
  },
  Label_BO_UserProfile_Logins: {
    id: "Label_BO_UserProfile_Logins",
    defaultMessage: "Histórico de acessos",
    description: "UserProfile - BO User",
  },

  //Opportunity - Add Opportunity Candidate
  Label_AddOpportunityCandidate_Gender: {
    id: "Label_AddOpportunityCandidate_Gender",
    defaultMessage: "Género",
    description: "Opportunity_AddOpportunityCandidate",
  },
  Label_AddOpportunityCandidate_MinAge: {
    id: "Label_AddOpportunityCandidate_MinAge",
    defaultMessage: "Idade Mínima",
    description: "Opportunity_AddOpportunityCandidate",
  },
  Label_AddOpportunityCandidate_MaxAge: {
    id: "Label_AddOpportunityCandidate_MaxAge",
    defaultMessage: "Idade Máxima",
    description: "Opportunity_AddOpportunityCandidate",
  },

  //UserProfile - UserSingular - ProfessionalData
  Label_UserProfile_UserSingular_ProfessionalData_MainProfessionalExperience: {
    id: "Label_UserProfile_UserSingular_ProfessionalData_MainProfessionalExperience",
    defaultMessage: "Profissão Principal",
    description: "UserProfile - UserSingular - ProfessionalData",
  },
  Label_UserProfile_UserSingular_ProfessionalData_SecondaryProfessionalExperience: {
    id: "Label_UserProfile_UserSingular_ProfessionalData_SecondaryProfessionalExperience",
    defaultMessage: "Profissão Subsidiária (opcional)",
    description: "UserProfile - UserSingular - ProfessionalData",
  },
  Label_UserProfile_UserSingular_ProfessionalData_YearsOfMainProfessionalExperience: {
    id: "Label_UserProfile_UserSingular_ProfessionalData_YearsOfMainProfessionalExperience",
    defaultMessage: "Experiência Profissional Principal",
    description: "UserProfile - UserSingular - ProfessionalData",
  },
  Label_UserProfile_UserSingular_ProfessionalData_YearsOfSecondaryProfessionalExperience: {
    id: "Label_UserProfile_UserSingular_ProfessionalData_YearsOfSecondaryProfessionalExperience",
    defaultMessage: "Experiência Profissional Subsidiária (opcional)",
    description: "UserProfile - UserSingular - ProfessionalData",
  },
  Label_UserProfile_UserSingular_ProfessionalData_ProfessionalQualification: {
    id: "Label_UserProfile_UserSingular_ProfessionalData_ProfessionalQualification",
    defaultMessage: "Qualificação Profissional (conhecimentos máquinas, etc.) (opcional)",
    description: "UserProfile - UserSingular - ProfessionalData",
  },
  Label_UserProfile_UserSingular_ProfessionalData_OtherProfessionalExperience: {
    id: "Label_UserProfile_UserSingular_ProfessionalData_OtherProfessionalExperience",
    defaultMessage: "Outras Experiências Profissionais (opcional)",
    description: "UserProfile - UserSingular - ProfessionalData",
  },
  Label_UserProfile_UserSingular_ProfessionalData_Household: {
    id: "Label_UserProfile_UserSingular_ProfessionalData_Household",
    defaultMessage: "Pessoas a Cargo (opcional)",
    description: "UserProfile - UserSingular - ProfessionalData",
  },
  Label_UserProfile_UserSingular_ProfessionalData_FamilyIncome: {
    id: "Label_UserProfile_UserSingular_ProfessionalData_FamilyIncome",
    defaultMessage: "Rendimento Familiar (opcional)",
    description: "UserProfile - UserSingular - ProfessionalData",
  },
  Label_UserProfile_UserSingular_ProfessionalData_StudiedLanguages: {
    id: "Label_UserProfile_UserSingular_ProfessionalData_StudiedLanguages",
    defaultMessage: "Línguas Estudadas (opcional)",
    description: "UserProfile - UserSingular - ProfessionalData",
  },
  Label_UserProfile_UserSingular_ProfessionalData_IntendedWorkplaceProvinceId: {
    id: "Label_UserProfile_UserSingular_ProfessionalData_IntendedWorkplaceProvinceId",
    defaultMessage: "Local Trabalho Pretendido (opcional)",
    description: "UserProfile - UserSingular - ProfessionalData",
  },
  Label_UserProfile_UserSingular_ProfessionalData_IntendedWorkplaceProvinceId_Details: {
    id: "Label_UserProfile_UserSingular_ProfessionalData_IntendedWorkplaceProvinceId_Details",
    defaultMessage: "Preencher caso esteja à procura de emprego",
    description: "UserProfile - UserSingular - ProfessionalData",
  },
  Label_UserProfile_UserSingular_ProfessionalData_OccupationalCategory: {
    id: "Label_UserProfile_UserSingular_ProfessionalData_OccupationalCategory",
    defaultMessage: "Categoria Ocupacional (opcional)",
    description: "UserProfile - UserSingular - ProfessionalData",
  },

  //UserProfile - UserSingular - EmploymentAndUnemploymentHistory
  Label_UserProfile_UserSingular_EmploymentAndUnemploymentHistory_StartDate: {
    id: "Label_UserProfile_UserSingular_EmploymentAndUnemploymentHistory_StartDate",
    defaultMessage: "Data Início",
    description: "UserProfile - UserSingular - EmploymentAndUnemploymentHistory",
  },
  Label_UserProfile_UserSingular_EmploymentAndUnemploymentHistory_EndDate: {
    id: "Label_UserProfile_UserSingular_EmploymentAndUnemploymentHistory_EndDate",
    defaultMessage: "Data Fim",
    description: "UserProfile - UserSingular - EmploymentAndUnemploymentHistory",
  },
  Label_UserProfile_UserSingular_EmploymentAndUnemploymentHistory_EmploymentHistory: {
    id: "Label_UserProfile_UserSingular_EmploymentAndUnemploymentHistory_EmploymentHistory",
    defaultMessage: "Histórico de Emprego",
    description: "UserProfile - UserSingular - EmploymentAndUnemploymentHistory",
  },
  Label_UserProfile_UserSingular_EmploymentAndUnemploymentHistory_OpportunityNumber: {
    id: "Label_UserProfile_UserSingular_EmploymentAndUnemploymentHistory_OpportunityNumber",
    defaultMessage: "Nº Oferta de Emprego",
    description: "UserProfile - UserSingular - EmploymentAndUnemploymentHistory",
  },
  Label_UserProfile_UserSingular_EmploymentAndUnemploymentHistory_Company: {
    id: "Label_UserProfile_UserSingular_EmploymentAndUnemploymentHistory_Company",
    defaultMessage: "Empresa/Filial",
    description: "UserProfile - UserSingular - EmploymentAndUnemploymentHistory",
  },
  Label_UserProfile_UserSingular_EmploymentAndUnemploymentHistory_Profession: {
    id: "Label_UserProfile_UserSingular_EmploymentAndUnemploymentHistory_Profession",
    defaultMessage: "Profissão",
    description: "UserProfile - UserSingular - EmploymentAndUnemploymentHistory",
  },
  Label_UserProfile_UserSingular_EmploymentAndUnemploymentHistory_OpportunityType: {
    id: "Label_UserProfile_UserSingular_EmploymentAndUnemploymentHistory_OpportunityType",
    defaultMessage: "Tipo",
    description: "UserProfile - UserSingular - EmploymentAndUnemploymentHistory",
  },
  Label_UserProfile_UserSingular_EmploymentAndUnemploymentHistory_WorkTimeFrame: {
    id: "Label_UserProfile_UserSingular_EmploymentAndUnemploymentHistory_WorkTimeFrame",
    defaultMessage: "Período de Trabalho",
    description: "UserProfile - UserSingular - EmploymentAndUnemploymentHistory",
  },
  Label_UserProfile_UserSingular_EmploymentAndUnemploymentHistory_Salary: {
    id: "Label_UserProfile_UserSingular_EmploymentAndUnemploymentHistory_Salary",
    defaultMessage: "Salário (Kz)",
    description: "UserProfile - UserSingular - EmploymentAndUnemploymentHistory",
  },
  Label_UserProfile_UserSingular_EmploymentAndUnemploymentHistory_Observations: {
    id: "Label_UserProfile_UserSingular_EmploymentAndUnemploymentHistory_Observations",
    defaultMessage: "Observações",
    description: "UserProfile - UserSingular - EmploymentAndUnemploymentHistory",
  },
  Label_UserProfile_UserSingular_EmploymentAndUnemploymentHistory_UnemploymentHistory: {
    id: "Label_UserProfile_UserSingular_EmploymentAndUnemploymentHistory_UnemploymentHistory",
    defaultMessage: "Histórico de Desemprego",
    description: "UserProfile - UserSingular - EmploymentAndUnemploymentHistory",
  },
  Label_UserProfile_UserSingular_EmploymentAndUnemploymentHistory_AddRecord: {
    id: "Label_UserProfile_UserSingular_EmploymentAndUnemploymentHistory_AddRecord",
    defaultMessage: "Adicionar Registo",
    description: "UserProfile - UserSingular - EmploymentAndUnemploymentHistory",
  },
  Label_UserProfile_UserSingular_EmploymentAndUnemploymentHistory_Edit: {
    id: "Label_UserProfile_UserSingular_EmploymentAndUnemploymentHistory_Edit",
    defaultMessage: "Editar",
    description: "UserProfile - UserSingular - EmploymentAndUnemploymentHistory",
  },
  Label_UserProfile_UserSingular_EmploymentAndUnemploymentHistory_Add: {
    id: "Label_UserProfile_UserSingular_EmploymentAndUnemploymentHistory_Add",
    defaultMessage: "Adicionar",
    description: "UserProfile - UserSingular - EmploymentAndUnemploymentHistory",
  },
  Label_UserProfile_UserSingular_EmploymentAndUnemploymentHistory_UnemploymentRecord: {
    id: "Label_UserProfile_UserSingular_EmploymentAndUnemploymentHistory_UnemploymentRecord",
    defaultMessage: "Registo de Desemprego",
    description: "UserProfile - UserSingular - EmploymentAndUnemploymentHistory",
  },
  Label_UserProfile_UserSingular_EmploymentAndUnemploymentHistory_EmploymentRecord: {
    id: "Label_UserProfile_UserSingular_EmploymentAndUnemploymentHistory_EmploymentRecord",
    defaultMessage: "Registo de Emprego",
    description: "UserProfile - UserSingular - EmploymentAndUnemploymentHistory",
  },

  //UserProfile - BO User - LoginHistory
  Label_BO_UserProfile_LoginHistory_Date: {
    id: "Label_BO_UserProfile_LoginHistory_Date",
    defaultMessage: "Data",
    description: "UserProfile - BO User - LoginHistory",
  },
  Label_BO_UserProfile_LoginHistory_Hour: {
    id: "Label_BO_UserProfile_LoginHistory_Hour",
    defaultMessage: "Hora",
    description: "UserProfile - BO User - LoginHistory",
  },
  Label_BO_UserProfile_LoginHistory: {
    id: "Label_BO_UserProfile_LoginHistory",
    defaultMessage: "Histórico de acessos do utilizador",
    description: "UserProfile - BO User - LoginHistory",
  },

  // Other Areas Intervetion
  Label_InterventionOtherArea: {
    id: "Label_InterventionOtherArea",
    defaultMessage: "Intervenção de Outras Áreas",
    description: "InterventionOtherArea section title",
  },
  Label_InterventionOtherArea_Area: {
    id: "Label_InterventionOtherArea_Area",
    defaultMessage: "Área",
    description: "InterventionOtherArea area field",
  },
  Label_InterventionOtherArea_ReasonForIntervention: {
    id: "Label_InterventionOtherArea_ReasonForIntervention",
    defaultMessage: "Motivo da Intervenção",
    description: "InterventionOtherArea intervention motive",
  },
  Label_InterventionOtherArea_Results: {
    id: "Label_InterventionOtherArea_Results",
    defaultMessage: "Resultados",
    description: "InterventionOtherArea intervention results",
  },

  //Opportunity Applications Filter
  Label_OpportunityApplicationsFilter_MinDate: {
    id: "Label_OpportunityApplicationsFilter_MinDate",
    defaultMessage: "Idade Mínima",
    description: "Idade mínima do candidato",
  },
  Label_OpportunityApplicationsFilter_MaxDate: {
    id: "Label_OpportunityApplicationsFilter_MaxDate",
    defaultMessage: "Idade Máxima",
    description: "Idade máxima do candidato",
  },
});

export const titles = defineMessages({
  // General

  // Navbar
  Title_AdminNavBar_NavBarMenu: {
    id: "Title_AdminNavBar_NavBarMenu",
    defaultMessage: "Menu",
    description: "Titulo do botão do menu em lista da NavBar",
  },
  Title_AdminNavBar_NavBarListMenu: {
    id: "Title_AdminNavBar_NavBarListMenu",
    defaultMessage: "Menu de Navegação",
    description: "Titulo do menu em lista da NavBar",
  },
  Title_AdminNavBar_GestaoCandidaturasMenu: {
    id: "Title_AdminNavBar_GestaoCandidaturasMenu",
    defaultMessage: "Gestão de Candidaturas",
    description: "Titulo do menu Gestão de Candidaturas",
  },
  Title_AdminNavBar_ListaDeCandidaturasMenuItem: {
    id: "Title_AdminNavBar_ListaDeCandidaturasMenuItem",
    defaultMessage: "Lista de Candidaturas",
    description: "Titulo de opção de item do menu Gestão de Candidaturas",
  },
  Title_AdminNavBar_AgendamentosMenuItem: {
    id: "Title_AdminNavBar_AgendamentosMenuItem",
    defaultMessage: "Agendamentos",
    description: "Titulo de opção de item do menu Gestão de Candidaturas",
  },
  Title_AdminNavBar_ProfessionalKitsMenu: {
    id: "Title_AdminNavBar_ProfessionalKitsMenu",
    defaultMessage: "Kits Profissionais",
    description: "Titulo do menu Kits Profissionais",
  },
  Title_AdminNavBar_ProfessionalKitsMenu_KitsListMenuItem: {
    id: "Title_AdminNavBar_ProfessionalKitsMenu_KitsListMenuItem",
    defaultMessage: "Lista Kits",
    description: "Titulo de opção de item do menu Kits Profissionais",
  },
  Title_AdminNavBar_ProfessionalKitsMenu_CharacterizeKitMenuItem: {
    id: "Title_AdminNavBar_ProfessionalKitsMenu_CharacterizeKitMenuItem",
    defaultMessage: "Caracterizar Kit",
    description: "Titulo de opção de item do menu Kits Profissionais",
  },
  Title_AdminNavBar_ProfessionalKitsMenu_RegisterLossMenuItem: {
    id: "Title_AdminNavBar_ProfessionalKitsMenu_RegisterLossMenuItem",
    defaultMessage: "Registar Perda",
    description: "Titulo de opção de item do menu Kits Profissionais",
  },
  Title_AdminNavBar_ProfessionalKitsMenu_RegisterInWarehouseMenuItem: {
    id: "Title_AdminNavBar_ProfessionalKitsMenu_RegisterInWarehouseMenuItem",
    defaultMessage: "Registo em Armazém",
    description: "Titulo de opção de item do menu Kits Profissionais",
  },
  Title_AdminNavBar_ProfessionalKitsMenu_StocksMenuItem: {
    id: "Title_AdminNavBar_ProfessionalKitsMenu_StocksMenuItem",
    defaultMessage: "Stocks",
    description: "Titulo de opção de item do menu Kits Profissionais",
  },
  Title_AdminNavBar_ProfessionalKitsMenu_KitDeliveryMenuItem: {
    id: "Title_AdminNavBar_ProfessionalKitsMenu_KitDeliveryMenuItem",
    defaultMessage: "Entrega de kit",
    description: "Titulo de opção de item do menu Kits Profissionais",
  },
  Title_AdminNavBar_SubprogramasMenu: {
    id: "Title_AdminNavBar_SubprogramasMenu",
    defaultMessage: "Subprogramas",
    description: "Titulo do menu Subprogramas",
  },
  Title_AdminNavBar_ListaSubprogramasMenuItem: {
    id: "Title_AdminNavBar_ListaSubprogramasMenuItem",
    defaultMessage: "Lista Subprogramas",
    description: "Titulo de opção de item de menu Subprogramas",
  },
  Title_AdminNavBar_CharacterizeSubprogramMenuItem: {
    id: "Title_AdminNavBar_CharacterizeSubprogramMenuItem",
    defaultMessage: "Caracterizar subprograma",
    description: "Titulo de opção de item de menu Subprogramas",
  },

  Title_AdminNavBar_ListaInformationDisseminationMenuItem: {
    id: "Title_AdminNavBar_ListaInformationDisseminationMenuItem",
    defaultMessage: "Lista Informação",
    description: "Titulo de opção de item de menu Criação Informação",
  },
  Title_AdminNavBar_CreateInformationDisseminationMenuItem: {
    id: "Title_AdminNavBar_CreateInformationDisseminationMenuItem",
    defaultMessage: "Criar Informação",
    description: "Titulo de opção de item de menu Criação Informação",
  },
  Title_AdminNavBar_ListCandidatesMenuItem: {
    id: "Title_AdminNavBar_ListCandidatesMenuItem",
    defaultMessage: "Lista de Candidatos",
    description: "Titulo de opção de item de menu Lista Candidatos",
  },
  Title_AdminNavBar_ListOpportunitiesMenuItem: {
    id: "Title_AdminNavBar_ListOpportunitiesMenuItem",
    defaultMessage: "Lista de Ofertas",
    description: "Titulo de opção de item de menu Emprego e Estágio",
  },
  Title_AdminNavBar_ListOpportunitiesApplicationsMenuItem: {
    id: "Title_AdminNavBar_ListOpportunitiesApplicationsMenuItem",
    defaultMessage: "Lista de Candidaturas",
    description: "Titulo de opção de item de menu Emprego e Estágio",
  },
  Title_AdminNavBar_ListOpportunitiesApplicationsFollowUpMenuItem: {
    id: "Title_AdminNavBar_ListOpportunitiesApplicationsFollowUpMenuItem",
    defaultMessage: "Lista de Estágios",
    description: "Titulo de opção de item de menu Emprego e Estágio",
  },
  Title_AdminNavBar_OpportunityCreateMenuItem: {
    id: "Title_AdminNavBar_OpportunityCreateMenuItem",
    defaultMessage: "Criar Oferta",
    description: "Titulo de opção de item de menu Emprego e Estágio",
  },

  Title_AdminNavBar_Bancos: {
    id: "Title_AdminNavBar_Bancos",
    defaultMessage: "Bancos",
    description: "Titulo de opção Bancos",
  },
  Title_AdminNavBar_InformationDissemination: {
    id: "Title_AdminNavBar_InformationDissemination",
    defaultMessage: "Informação",
    description: "Titulo de opção de Informação",
  },
  Title_AdminNavBar_Opportunity: {
    id: "Title_AdminNavBar_Opportunity",
    defaultMessage: "Emprego e Estágio",
    description: "Titulo de opção Emprego e Estágio",
  },
  Title_AdminNavBar_ManageUsersMenu: {
    id: "Title_AdminNavBar_ManageUsersMenu",
    defaultMessage: "Gestão de Utilizadores",
    description: "Titulo do menu de Gestão de Utilizadores",
  },
  Title_AdminNavBar_ManageUsersMenuItemBackOffice: {
    id: "Title_AdminNavBar_ManageUsersMenuItemBackOffice",
    defaultMessage: "Utilizadores Backoffice",
    description: "Titulo de opção de item de menu de Gestão Utilizadores",
  },
  Title_AdminNavBar_ManageUsersMenuItemFrontOffice: {
    id: "Title_AdminNavBar_ManageUsersMenuItemFrontOffice",
    defaultMessage: "Utilizadores Frontoffice",
    description: "Titulo de opção de item de menu de Gestão Utilizadores",
  },
  Title_Admin_Subprogram_GeneralDataSection: {
    id: "Title_Admin_Subprogram_GeneralDataSection",
    defaultMessage: "Dados Gerais",
    description: "Titulo da secção de dados gerais",
  },
  Title_Admin_Subprogram_BenefitsSection: {
    id: "Title_Admin_Subprogram_BenefitsSection",
    defaultMessage: "Benefícios",
    description: "Titulo da secção de benefícios",
  },
  Title_Admin_Subprogram_DocumentsSection: {
    id: "Title_Admin_Subprogram_DocumentsSection",
    defaultMessage: "Documentos",
    description: "Titulo da secção de documentos",
  },

  // Dashboard cards
  Title_Admin_Dashboard_CardOnGoingApplications: {
    id: "Title_Admin_Dashboard_CardOnGoingApplications",
    defaultMessage: "Candidaturas em Curso",
    description: "Title of CardOnGoingApplications in Dashboard",
  },

  // Applications
  Title_Admin_ApplicationsManagement: {
    id: "Title_Admin_ApplicationsManagement",
    defaultMessage: "Pesquisar Candidaturas",
    description: "Titulo do cartão de filtro de pesquisa na página de Gestão de Candidaturas",
  },
  Title_Admin_ApplicationsManagement_ResultSection: {
    id: "Title_Admin_ApplicationsManagement_ResultSection",
    defaultMessage: "Lista de Candidaturas",
    description: "Titulo da tabela de resultados na página de Gestão de Candidaturas",
  },

  Title_Admin_ApplicationDetail_GeneralDataSectionButton: {
    id: "Title_Admin_ApplicationDetail_GeneralDataSectionButton",
    defaultMessage: "Dados Gerais",
    description: "Titulo do botão para a secção de Dados Gerais do candidato",
  },
  Title_Admin_ApplicationDetail_GeneralDataSection: {
    id: "Title_Admin_ApplicationDetail_GeneralDataSection",
    defaultMessage: "Dados Gerais do Candidato",
    description: "Titulo da secção de Dados Gerais do candidato",
  },
  Title_Admin_ApplicationDetail_ProgramSubprogramDataSection: {
    id: "Title_Admin_ApplicationDetail_ProgramSubprogramDataSection",
    defaultMessage: "Dados do Programa e Subprograma",
    description: "Titulo da secção de Dados do Programa e Subprograma",
  },
  Title_Admin_ApplicationDetail_ComplementaryDataSection: {
    id: "Title_Admin_ApplicationDetail_ComplementaryDataSection",
    defaultMessage: "Dados Complementares",
    description: "Titulo da secção de Dados Complementares",
  },
  Title_Admin_ApplicationDetail_DocumentsSection: {
    id: "Title_Admin_ApplicationDetail_DocumentsSection",
    defaultMessage: "Documentos",
    description: "Titulo da secção de Documentos",
  },
  Title_Admin_ApplicationDetail_SchedulingSection: {
    id: "Title_Admin_ApplicationDetail_SchedulingSection",
    defaultMessage: "Agendamento",
    description: "Titulo do botão e da secção de Agendamento",
  },
  Title_Admin_ApplicationDetail_ReportsSection: {
    id: "Title_Admin_ApplicationDetail_ReportsSection",
    defaultMessage: "Relatórios",
    description: "Titulo da secção de Agendamento",
  },
  Title_Admin_ApplicationDetail_NotificationsSection: {
    id: "Title_Admin_ApplicationDetail_NotificationsSection",
    defaultMessage: "Notificações",
    description: "Titulo do botão e da secção de Notificações",
  },
  Title_Admin_ApplicationDetail_HistorySectionButton: {
    id: "Title_Admin_ApplicationDetail_HistorySectionButton",
    defaultMessage: "Histórico",
    description: "Titulo do botão para a secção de Histórico",
  },
  Title_Admin_ApplicationDetail_HistorySection: {
    id: "Title_Admin_ApplicationDetail_HistorySection",
    defaultMessage: "Linha do Tempo",
    description: "Titulo da secção de Histórico",
  },
  Title_Admin_ApplicationDetail_HistoryNotesSection: {
    id: "Title_Admin_ApplicationDetail_HistoryNotesSection",
    defaultMessage: "Notas / Comentários",
    description: "Titulo da secção de Histórico",
  },

  // Schedules
  Title_Admin_SchedulesManagement: {
    id: "Title_Admin_SchedulesManagement",
    defaultMessage: "Pesquisa de Agendamentos",
    description: "Titulo do cartão de filtro de pesquisa na página de Agendamentos",
  },
  Title_Admin_SchedulesManagement_ResultSection: {
    id: "Title_Admin_SchedulesManagement_ResultSection",
    defaultMessage: "Lista de Agendamentos",
    description: "Titulo da tabela de resultados na página de Agendamentos",
  },
  Title_Admin_SchedulesManagement_ScheduleDetails_RegistrationData: {
    id: "Title_Admin_SchedulesManagement_ScheduleDetails_RegistrationData",
    defaultMessage: "Dados do Registo",
    description: "Titulo da secção da página de detalhes de agendamentos",
  },

  // Professional Kits
  Title_Admin_ProfessionalKitsManagement: {
    id: "Title_Admin_ProfessionalKitsManagement",
    defaultMessage: "Pesquisa de Kits",
    description: "Titulo do cartão de filtro de pesquisa na página de Gestão de Kits",
  },
  Title_Admin_ProfessionalKits_ResultSection: {
    id: "Title_Admin_ProfessionalKits_ResultSection",
    defaultMessage: "Lista de Kits Profissionais",
    description: "Titulo da tabela de resultados na página de Kits Profissionais",
  },
  Title_Admin_ProfessionalKits_StocksManagement: {
    id: "Title_Admin_ProfessionalKits_StocksManagement",
    defaultMessage: "Pesquisa de Stocks",
    description: "Titulo do cartão de filtro de pesquisa na página de Gestão de Stocks",
  },
  Title_Admin_ProfessionalKits_StocksManagement_ResultSection: {
    id: "Title_Admin_ProfessionalKits_StocksManagement_ResultSection",
    defaultMessage: "Lista de Stocks",
    description: "Titulo da tabela de resultados na página de Gestão de Stocks",
  },
  Title_Component_ModalFormsContent_Stocks_RegisterLossBreak: {
    id: "Title_Component_ModalFormsContent_Stocks_RegisterLossBreak",
    defaultMessage: "Registo de Perda",
    description: "Titulo da modal de registo de quebra/perda de kits",
  },

  // Opportunities
  Title_Opportunity_Employment_Data_Card: {
    id: "Title_Opportunity_Employment_Data_Card",
    defaultMessage: "Dados da oferta de emprego",
    description: "Título do cartão de definição dos dados da oferta de emprego",
  },
  Title_Opportunity_Internship_Data_Card: {
    id: "Title_Opportunity_Internship_Data_Card",
    defaultMessage: "Dados da oferta de estágio profissional",
    description: "Título do cartão de definição dos dados da oferta de estágio",
  },
  Title_Opportunity_Employment_RejectMotive_Card: {
    id: "Title_Opportunity_Employment_RejectMotive_Card",
    defaultMessage: "Motivo de rejeição",
    description: "Título do cartão com o motivo da rejeição da oferta",
  },
  Title_Opportunity_Employment_CloseMotive_Card: {
    id: "Title_Opportunity_Employment_CloseMotive_Card",
    defaultMessage: "Motivo de fecho",
    description: "Título do cartão com o motivo do fecho da oferta",
  },
  Title_Opportunity_Candidate_Data_Card: {
    id: "Title_Opportunity_Candidate_Data_Card",
    defaultMessage: "Dados do candidato",
    description: "Título do cartão de definição dos dados do candidato",
  },

  // Loans
  Title_Loans_LoanDetails_GeneralData_Card: {
    id: "Title_Loans_LoanDetails_GeneralData_Card",
    defaultMessage: "Dados Gerais",
    description: "General Data card title of Loan Details page",
  },
  Title_Loans_LoanDetails_Section: {
    id: "Title_Loans_LoanDetails_Section",
    defaultMessage: "Detalhes do empréstimo",
    description: "Title of section in Loan Details page",
  },
  Title_Loans_LoanDetails_Subsection: {
    id: "Title_Loans_LoanDetails_Subsection",
    defaultMessage: "Bancos",
    description: "Title of subsection in Loan Details page",
  },

  // InformationDissemination
  Title_InformationDissemination_CreateInformation: {
    id: "Title_InformationDissemination_CreateInformation",
    defaultMessage: "Criar Informação",
    description: "Section title of Create Information page",
  },
  Title_InformationDissemination_InformationManagement: {
    id: "Title_InformationDissemination_InformationManagement",
    defaultMessage: "Gestão de Informação",
    description: "Subsection title of Create Information page",
  },
  Title_InformationDissemination_EditInformation: {
    id: "Title_InformationDissemination_EditInformation",
    defaultMessage: "Editar Informação",
    description: "Section title of Create Information page",
  },

  //UserOverview
  Title_UserOverview_Advanced_Search_Title: {
    id: "Title_UserOverview_Advanced_Search_Title",
    defaultMessage: "Pesquisa Avançada",
    description: "Advanced Search Title",
  },
  Title_UserOverview_User_Overview_Title: {
    id: "Title_UserOverview_User_Overview_Title",
    defaultMessage: "Visão Geral do Utilizador",
    description: "User Overview Title",
  },
  //UserOverview - GeneralData Tab
  Title_UserOverview_GeneralDataTab_ContactsSection: {
    id: "Title_UserOverview_GeneralDataTab_ContactsSection",
    defaultMessage: "Dados de Contacto",
    description: "Contacts section in the user overview screen",
  },
  Title_UserOverview_GeneralDataTab_GeneralDataSection: {
    id: "Title_UserOverview_GeneralDataTab_GeneralDataSection",
    defaultMessage: "Dados Gerais",
    description: "General Data section in the user overview screen",
  },
  //UserOverview - Profession Tab
  Title_UserOverview_ProfessionTab_ProfessionalDataSection: {
    id: "Title_UserOverview_ProfessionTab_ProfessionalDataSection",
    defaultMessage: "Dados Profissionais",
    description: "Professional Data section in the user overview screen",
  },
  Title_UserOverview_ProfessionTab_TrainingSection: {
    id: "Title_UserOverview_ProfessionTab_TrainingSection",
    defaultMessage: "Formação",
    description: "Training section in the user overview screen",
  },
  Title_UserOverview_ProfessionTab_DocumentsSection: {
    id: "Title_UserOverview_ProfessionTab_DocumentsSection",
    defaultMessage: "Documentos",
    description: "Documents section in the user overview screen",
  },
  //UserOverview - ApplicationsTab
  Title_UserOverview_ApplicationsTab_ApplicationsSection: {
    id: "Title_UserOverview_ApplicationsTab_ApplicationsSection",
    defaultMessage: "Candidaturas",
    description: "Applications section in the user overview screen",
  },
  Title_UserOverview_ApplicationsTab_GovernmentProgramsApplicationsSection: {
    id: "Title_UserOverview_ApplicationsTab_GovernmentProgramsApplicationsSection",
    defaultMessage: "Candidaturas a Programas do Governo",
    description: "Government Programs Applications section in the user overview screen",
  },
  Title_UserOverview_ApplicationsTab_EmploymentAndInternshipApplicationsSection: {
    id: "Title_UserOverview_ApplicationsTab_EmploymentAndInternshipApplicationsSection",
    defaultMessage: "Candidaturas a",
    description: "Employment And Internship Applications section in the user overview screen",
  },
  Title_UserOverview_ApplicationsTab_FollowUpsSection: {
    id: "Title_UserOverview_ApplicationsTab_FollowUpsSection",
    defaultMessage: "Acompanhamentos",
    description: "FollowUps section in the user overview screen",
  },

  Title_QuickGuides: {
    id: "Title_QuickGuides",
    defaultMessage: "Guias de ajuda ao Utilizador",
    description: "Quick Guides title",
  },
});

export const messages = defineMessages({
  Message_Generic_MandatoryField: {
    id: "Message_Generic_MandatoryField",
    defaultMessage: "O campo é obrigatório",
    description: "Mensagem genérica para qualquer campo que seja obrigatório",
  },
  Message_Generic_ValueGreaterThanOrEqualToZeroField: {
    id: "Message_Generic_ValueGreaterThanOrEqualToZeroField",
    defaultMessage: "O valor tem de ser igual ou superior a zero",
    description: "Mensagem genérica para qualquer campo que o valor tenha de ser igual ou superior a zero",
  },
  Message_Generic_ValueGreaterThanZeroField: {
    id: "Message_Generic_ValueGreaterThanZeroField",
    defaultMessage: "O valor tem de ser superior a zero",
    description: "Mensagem genérica para qualquer campo que o valor tenha de ser superior a zero",
  },
  Message_Generic_DateToGreaterThanOrEqualToTodayField: {
    id: "Message_Generic_DateToGreaterThanOrEqualToTodayField",
    defaultMessage: "A data deve ser igual ou superior à data actual",
    description: "Mensagem genérica para qualquer campo em que a data tenha de ser igual ou superior à data actual",
  },
  Message_Generic_DateLessThanTodayField: {
    id: "Message_Generic_DateLessThanTodayField",
    defaultMessage: "A data deve ser inferior à data atual",
    description: "Mensagem genérica para qualquer campo em que a data tenha de ser inferior à data actual",
  },
  Message_Generic_DateLessThanOrEqualToTodayField: {
    id: "Message_Generic_DateLessThanOrEqualToTodayField",
    defaultMessage: "A data deve ser igual ou inferior à data actual",
    description: "Mensagem genérica para qualquer campo em que a data tenha de ser igual ou inferior à data actual",
  },
  Message_Generic_DateToGreaterThanDateFromField: {
    id: "Message_Generic_DateToGreaterThanDateFromField",
    defaultMessage: "A data deve ser superior à data de início",
    description:
      "Mensagem genérica para qualquer campo de intervalo em que a data tenha de ser superior à data de início",
  },
  Message_Generic_DateToGreaterThanOrEqualToDateFromField: {
    id: "Message_Generic_DateToGreaterThanOrEqualToDateFromField",
    defaultMessage: "A data deve ser igual ou superior à data de início",
    description:
      "Mensagem genérica para qualquer campo de intervalo em que a data tenha de ser igual ou superior à data de início",
  },
  Message_Generic_DateFromLessThanDateToField: {
    id: "Message_Generic_DateFromLessThanDateToField",
    defaultMessage: "A data deve ser inferior à data de fim",
    description: "Mensagem genérica para qualquer campo de intervalo em que a data tenha de ser superior à data de fim",
  },
  Message_Generic_DateFromLessThanOrEqualToDateToField: {
    id: "Message_Generic_DateFromLessThanOrEqualToDateToField",
    defaultMessage: "A data deve ser igual ou inferior à data de fim",
    description:
      "Mensagem genérica para qualquer campo de intervalo em que a data tenha de ser igual ou inferior à data de fim",
  },
  Message_Generic_InvalidField: {
    id: "Message_Generic_InvalidField",
    defaultMessage: "Formato incorrecto",
    description: "Mensagem genérica para qualquer campo que não cumpra o formato indicado",
  },
  Message_Generic_TextFieldLengthTooShort: {
    id: "Message_Generic_TextFieldLengthTooShort",
    defaultMessage: "O texto é de tamanho insuficiente",
    description: "Generic message when a text field is too short in length",
  },
  Message_Generic_InvalidUrl: {
    id: "Message_Generic_InvalidUrl",
    defaultMessage: "O URL introduzido não é válido",
    description: "Generic message when the text in URL field is not a valid URL",
  },
  Message_Generic_MaxNumberOfVideosExceeded: {
    id: "Message_Generic_MaxNumberOfVideosExceeded",
    defaultMessage: "O número máximo de vídeos foi excedido, não pode ser superior a ",
    description: "Generic message when the maximum number of videos selected is exceeded",
  },

  // DropzoneField
  Message_DropzoneField_InvalidFileFormat: {
    id: "Message_DropzoneField_InvalidFileFormat",
    defaultMessage: "O ficheiro só pode ter formato .jpeg ou .png.",
    description: "Message when file's format is not supported",
  },
  Message_DropzoneField_MaxFileSizeExceeded: {
    id: "Message_DropzoneField_MaxFileSizeExceeded",
    defaultMessage: "O tamanho do ficheiro não pode ser superior a ",
    description: "Message when file is too big",
  },
  Message_DropzoneField_MaxNumberOfFilesExceeded: {
    id: "Message_DropzoneField_MaxNumberOfFilesExceeded",
    defaultMessage: "O número máximo de ficheiros foi excedido, não pode ser superior a ",
    description: "Generic message when the maximum number of files selected is exceeded",
  },

  Message_Admin_HistoryTab_AdicionalCommentsField: {
    id: "Message_Admin_HistoryTab_AdicionalCommentsField",
    defaultMessage: "Deixe aqui os seus comentários adicionais",
    description: "Mensagem default do campo",
  },
  Message_Auth_Password_Rules_1: {
    id: "Message_Auth_Password_Rules_1",
    defaultMessage: "A palavra-passe tem de conter:",
    description: "Label do botão de navegação do formulário que permite navegar para a página anterior",
  },
  Message_Auth_Password_Rules_2: {
    id: "Message_Auth_Password_Rules_2",
    defaultMessage: "Pelo menos 10 caracteres",
    description: "Label do botão de navegação do formulário que permite navegar para a página anterior",
  },
  Message_Auth_Password_Rules_3: {
    id: "Message_Auth_Password_Rules_3",
    defaultMessage: "Pelo menos 1 letra maiúscula",
    description: "Label do botão de navegação do formulário que permite navegar para a página anterior",
  },
  Message_Auth_Password_Rules_4: {
    id: "Message_Auth_Password_Rules_4",
    defaultMessage: "Pelo menos 1 letra minúscula",
    description: "Label do botão de navegação do formulário que permite navegar para a página anterior",
  },
  Message_Auth_Password_Rules_5: {
    id: "Message_Auth_Password_Rules_5",
    defaultMessage: "Pelo menos 1 número",
    description: "Label do botão de navegação do formulário que permite navegar para a página anterior",
  },
  Message_Auth_Password_Rules_6: {
    id: "Message_Auth_Password_Rules_6",
    defaultMessage: "Pelo menos 1 caracter especial",
    description: "Label do botão de navegação do formulário que permite navegar para a página anterior",
  },
});

export const tooltips = defineMessages({
  Tooltip_Social_Security_Number_Description: {
    id: "Tooltip_Social_Security_Number_Description",
    defaultMessage: "Número de Identificação da Segurança Social",
    description: "Tooltip Social Security Number Description",
  },
});

export const alerts = defineMessages({
  Alert_Cannot_Add_Candidates_To_Opportunity_Because_Passed_ValidTo_Date: {
    id: "Alert_Cannot_Add_Candidates_To_Opportunity_Because_Passed_ValidTo_Date",
    defaultMessage:
      "Aviso: Não é possível adicionar novos candidatos a esta oferta, pois foi ultrapassada a data de vigência. Para que volte a ser possível, altere a data de fim de vigência.",
    description: "Alert_Cannot_Add_Candidates_To_Opportunity_Because_Passed_ValidTo_Date",
  },
  Alert_Cannot_Add_Candidates_To_Opportunity_Because_Before_ValidFrom_Date: {
    id: "Alert_Cannot_Add_Candidates_To_Opportunity_Because_Before_ValidFrom_Date",
    defaultMessage:
      "Aviso: Não é possível adicionar novos candidatos a esta oferta, pois ainda não foi atingida a data de início de vigência. Para que passe a ser possível, altere a data de início de vigência.",
    description: "Alert_Cannot_Add_Candidates_To_Opportunity_Because_Passed_ValidTo_Date",
  },
});

export default errorMessages;
