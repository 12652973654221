import { React } from "react";
import { connect } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
//MaterialUI
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import IconButton from "@material-ui/core/IconButton";
import CardHeader from "@material-ui/core/CardHeader";
import Tooltip from "@material-ui/core/Tooltip";
import Pagination from "@material-ui/lab/Pagination";
// core componentssrc
import TableStateChip from "components/Cards/Tables/TableStateChip";
import limitaString from "utils/string";
import TableBackdrop from "components/Backdrops/TableBackdrop/TableBackdrop";
import { OPPORTUNITY_STATE_KEY, TABLE_STATE_CHIP_COLOR } from "utils/consts";
import componentStylesGeneric from "assets/theme/views/admin/generic";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function ResultSection(props) {
  const theme = useTheme();
  const classes = useStylesGeneric();

  const tableHead = [
    "Nº Oferta",
    "Tipo de Oferta",
    "Empresa",
    "Candidatos",
    "Vagas",
    "Província",
    "Data Vigência",
    "Estado",
  ];

  const mapOpportunityStateToTableChipColors = (stateKey) => {
    switch (stateKey) {
      case OPPORTUNITY_STATE_KEY.APROVADA:
        return TABLE_STATE_CHIP_COLOR.GREEN;
      case OPPORTUNITY_STATE_KEY.REJEITADA:
        return TABLE_STATE_CHIP_COLOR.RED;
      case OPPORTUNITY_STATE_KEY.SUBMETIDA:
        return TABLE_STATE_CHIP_COLOR.LIGHT_ORANGE;
      case OPPORTUNITY_STATE_KEY.FECHADA:
        return TABLE_STATE_CHIP_COLOR.BLUE;
      default:
        return <></>;
    }
  };

  return (
    <Card classes={{ root: classes.cardRoot }}>
      <CardHeader
        classes={{
          action: classes.cardHeaderAction,
          title: classes.cardHeader,
        }}
        title={
          <div>
            <div className={classes.sideBySideDiv}>Lista de Ofertas de Emprego e Estágio Profissional</div>
            <div className={classes.sideBySideDiv + " " + classes.cardElementsCount}>
              {"("}
              {props.opportunitiesState?.total_records}
              {")"}
            </div>
          </div>
        }
      />

      <TableContainer>
        <Box component={Table} alignItems="center" marginBottom="0!important">
          <TableHead>
            <TableRow>
              {tableHead.map((prop, key) => (
                <TableCell
                  key={key}
                  classes={{
                    root: classes.tableCellRoot + " " + classes.tableCellRootHead,
                  }}
                >
                  {prop}
                </TableCell>
              ))}
              <TableCell
                key="detailsCol"
                classes={{
                  root:
                    classes.tableCellRoot + " " + classes.tableCellRootHead + " " + classes.tableCellHeadStickyRight,
                }}
              >
                Detalhes
              </TableCell>
            </TableRow>
          </TableHead>

          {props.opportunitiesState?.result?.data?.length > 0 && (
            <TableBody>
              {props.opportunitiesState.result.data.map((prop, key) => (
                <TableRow key={key}>
                  <TableCell classes={{ root: classes.tableCellRoot }} title={prop.reference ?? ""}>
                    {limitaString(prop.reference, 60)}
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }} title={prop.title ?? ""}>
                    <Box fontWeight={"bold"}>{prop.opportunityTypeDescription}</Box>
                    <Box>{limitaString(prop.title, 60)}</Box>
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }} title={prop.title ?? ""}>
                    <Box fontWeight={"bold"}>{prop.userTaxIdNumber}</Box>
                    <Box>{limitaString(prop.userName, 60)}</Box>
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>{prop.totalCandidates}</TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>{prop.numVacanciesToCandidatesRatio}</TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>{prop.provinceDescription}</TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>{prop.validPeriod}</TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                    <TableStateChip
                      state={mapOpportunityStateToTableChipColors(prop.opportunityStateKey)}
                      message={prop.opportunityStateDescription}
                    />
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot + " " + classes.tableCellBodyStickyRight }}>
                    <Tooltip title="Detalhes">
                      <IconButton aria-label="details" component={Link} to={`/admin/opportunity-details/${prop.id}`}>
                        <Box
                          component={ArrowForwardIosIcon}
                          width="12px!important"
                          height="12px!important"
                          color={theme.palette.primary.main}
                        />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Box>

        {props.opportunitiesState?.result?.data?.length === 0 &&
          !props.opportunitiesState?.searchOpportunities_loading && (
            <Box className={classes.noResultsInfoInTable}>Não existem resultados disponíveis.</Box>
          )}
      </TableContainer>

      <TableBackdrop open={props.opportunitiesState?.searchOpportunities_loading} />

      <Pagination
        className={classes.alignPagination}
        count={props.opportunitiesState?.total_pages}
        page={props.page}
        onChange={props.handleChangePage}
        color="primary"
      ></Pagination>
    </Card>
  );
}
const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ResultSection);
