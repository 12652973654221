import axios from "axios";
import { INTERNAL_API_URL } from "../index";

export const POST_BACKOFFICE_USER_REGISTER_URL = "account/register";


export function backOfficeUserRegister(user) {
  return axios.post(INTERNAL_API_URL + POST_BACKOFFICE_USER_REGISTER_URL, {
    user
  });
}
