import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import "moment/locale/pt";
import moment from "moment";
import { useIntl } from "react-intl";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import FormGroup from "@material-ui/core/FormGroup";
import Grid from "@material-ui/core/Grid";

import GenericButton from "components/Buttons/GenericButton";
import PageTitleHeader from "components/Headers/PageTitleHeader.js";
import LocationHeader from "components/Headers/LocationHeader.js";
import GenericBackdrop from "components/Backdrops/GenericBackdrop";
import IndividualUserData from "./Sections/IndividualUserData";
import { getUserInfoAction, updateIndividualUserAction, getUserDocumentAction } from "redux/actions/usersAction";
import { getDataSetByNameAction } from "redux/actions/datasetsAction";
import { showGenericTwoButtonDialogAction } from "redux/actions/dialogsAction";
import frontOfficeIndividualUserUpdateMap from "mappers/frontOfficeIndividualUserUpdateMap";
import { getSelectOptionsElementByCode, getMultipleSelectOptionsElementByCode } from "utils/formSelect";
import { DATASET } from "utils/consts";
import { labels } from "resources/resources";
import useScrollDirection from "hooks/useScrollDirection";
import EmploymentAndUnemploymentHistory from "./EmploymentAndUnemploymentHistory";

import componentStylesGeneric from "assets/theme/views/admin/generic.js";
import InterventionOtherAreaSection from "./Sections/InterventionOtherAreaSection";
import Tabs from "components/Tabs/Tabs";
const useStylesGeneric = makeStyles(componentStylesGeneric);

function EditIndividualUser(props) {
  const history = useHistory();
  const classes = useStylesGeneric();
  const intl = useIntl();
  const scrollDirection = useScrollDirection();

  const TABS = {
    USER_DATA: "userData",
    EMPLOYMENT_AND_UNEMPLOYMENT_HISTORY: "employmentAndUnemploymentHistory",
    INTERVENTION_FROM_OTHERS_AREAS: "interventionFromOthersAreasTab",
  };

  const [oldEmail, setOldEmail] = useState("");
  const [oldMobilePhone, setOldMobilePhone] = useState("");
  const [isEditablePersonalData, setIsEditablePersonalData] = useState(false);
  const [isEditableProfessionalData, setIsEditableProfessionalData] = useState(false);
  const [isEditableUserDocuments, setIsEditableUserDocuments] = useState(false);
  const [tabType, setTabType] = React.useState(TABS.USER_DATA);

  const methods = useForm({
    mode: "onChange",
  });

  useEffect(() => {
    props.getUserInfo(props.match.params.userId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.match.params.userId]);

  useEffect(() => {
    if (props.usersInfo.result !== undefined) {
      setFormProfessionalDataValuesOnMount();
      setPersonalDataFormValuesOnMount();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.usersInfo.result]);

  //#region ACADEMIC_LEVEL
  useEffect(() => {
    if (props.dataSetState && props.dataSetState[DATASET.ACADEMIC_LEVEL] && props.usersInfo.result) {
      var academicLevelList = props.dataSetState[DATASET.ACADEMIC_LEVEL]
        ? props.dataSetState[DATASET.ACADEMIC_LEVEL]
        : [];

      var academicLevels =
        props.usersInfo.result?.userSingular !== undefined ? props.usersInfo.result?.userSingular?.academicLevel : null;
      var selectedAcademicLevel = getSelectOptionsElementByCode(academicLevelList, academicLevels);

      methods.setValue("academicLevel", selectedAcademicLevel);
      if (selectedAcademicLevel) props.getDataSet(DATASET.ACADEMIC_DEGREE, selectedAcademicLevel.keyValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dataSetState[DATASET.ACADEMIC_LEVEL]]);
  //#endregion

  //#region ACADEMIC_DEGREE
  useEffect(() => {
    if (props.dataSetState && props.usersInfo.result) {
      methods.setValue(
        "degree",
        getSelectOptionsElementByCode(
          props.dataSetState[DATASET.ACADEMIC_DEGREE],
          props.usersInfo.result?.userSingular?.degree
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dataSetState[DATASET.ACADEMIC_DEGREE]]);
  //#endregion

  useEffect(() => {
    if (props.dataSetState && props.dataSetState[DATASET.LANGUAGES]) {
      methods.setValue(
        "studiedLanguages",
        getMultipleSelectOptionsElementByCode(
          props.dataSetState[DATASET.LANGUAGES],
          props.usersInfo.result?.userSingular?.studiedLanguagesList
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dataSetState[DATASET.LANGUAGES]]);

  useEffect(() => {
    if (props.dataSetState && props.dataSetState[DATASET.OCCUPATIONAL_CATEGORIES]) {
      methods.setValue(
        "occupationalCategory",
        getSelectOptionsElementByCode(
          props.dataSetState[DATASET.OCCUPATIONAL_CATEGORIES],
          props.usersInfo.result?.userSingular?.occupationalCategoryId
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dataSetState[DATASET.OCCUPATIONAL_CATEGORIES]]);

  useEffect(() => {
    if (props.dataSetState && props.dataSetState[DATASET.PROFESSIONAL_CATEGORY]) {
      methods.setValue(
        "secondaryProfessionalExperience",
        getMultipleSelectOptionsElementByCode(
          props.dataSetState[DATASET.PROFESSIONAL_CATEGORY],
          props.usersInfo.result?.userSingular?.secondaryProfessionalExperienceList
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dataSetState[DATASET.PROFESSIONAL_CATEGORY]]);

  const setFormProfessionalDataValuesOnMount = () => {
    if (props.dataSetState && props.dataSetState[DATASET.ACADEMIC_LEVEL] && props.usersInfo.result) {
      var academicLevelList = props.dataSetState[DATASET.ACADEMIC_LEVEL]
        ? props.dataSetState[DATASET.ACADEMIC_LEVEL]
        : [];

      var academicLevels =
        props.usersInfo.result?.userSingular !== undefined ? props.usersInfo.result?.userSingular?.academicLevel : null;
      var selectedAcademicLevel = getSelectOptionsElementByCode(academicLevelList, academicLevels);

      methods.setValue("academicLevel", selectedAcademicLevel);
      if (selectedAcademicLevel) props.getDataSet(DATASET.ACADEMIC_DEGREE, selectedAcademicLevel.keyValue);
    }

    var professionalCategoryList = props.dataSetState[DATASET.PROFESSIONAL_CATEGORY]
      ? props.dataSetState[DATASET.PROFESSIONAL_CATEGORY]
      : [];

    var professionalCategories =
      props.usersInfo.result?.userSingular !== undefined
        ? props.usersInfo.result?.userSingular?.professionalCategory
        : null;

    methods.setValue(
      "professionalCategory",
      getSelectOptionsElementByCode(professionalCategoryList, professionalCategories)
    );

    var entrepreneurshipTrainingList = props.dataSetState[DATASET.ENTREPRENEURSHIP_TRAINNING]
      ? props.dataSetState[DATASET.ENTREPRENEURSHIP_TRAINNING]
      : [];

    var entrepreneurshipTrainings =
      props.usersInfo.result?.userSingular !== undefined
        ? props.usersInfo.result?.userSingular?.entrepreneurshipTraining
        : null;

    methods.setValue(
      "entrepreneurshipTraining",
      getSelectOptionsElementByCode(entrepreneurshipTrainingList, parseInt(entrepreneurshipTrainings))
    );

    var professionalExperienceList = props.dataSetState[DATASET.PROFESSIONAL_CATEGORY]
      ? props.dataSetState[DATASET.PROFESSIONAL_CATEGORY]
      : [];

    var professionalExperiences =
      props.usersInfo.result?.userSingular !== undefined
        ? props.usersInfo.result?.userSingular?.professionalExperience
        : null;

    methods.setValue(
      "professionalExperience",
      getMultipleSelectOptionsElementByCode(professionalExperienceList, professionalExperiences)
    );

    methods.setValue(
      "secondaryProfessionalExperience",
      getMultipleSelectOptionsElementByCode(
        props.dataSetState[DATASET.PROFESSIONAL_CATEGORY],
        props.usersInfo.result?.userSingular?.secondaryProfessionalExperienceList
      )
    );

    var professionalTrainingList = props.dataSetState[DATASET.PROFESSIONAL_TRAINNING]
      ? props.dataSetState[DATASET.PROFESSIONAL_TRAINNING]
      : [];

    var professionalTrainings =
      props.usersInfo.result?.userSingular !== undefined
        ? props.usersInfo.result?.userSingular?.professionalTraining
        : null;

    methods.setValue(
      "professionalTraining",
      getSelectOptionsElementByCode(professionalTrainingList, parseInt(professionalTrainings))
    );

    var smallBusinessMgmtTrainingList = props.dataSetState[DATASET.SMALL_BUSINESS_MGMT_TRAINNING]
      ? props.dataSetState[DATASET.SMALL_BUSINESS_MGMT_TRAINNING]
      : [];

    var smallBusinessMgmtTrainings =
      props.usersInfo.result?.userSingular !== undefined
        ? props.usersInfo.result?.userSingular?.smallBusinessMgmtTraining
        : null;

    methods.setValue(
      "smallBusinessMgmtTraining",
      getSelectOptionsElementByCode(smallBusinessMgmtTrainingList, parseInt(smallBusinessMgmtTrainings))
    );

    methods.setValue(
      "experience",
      props.usersInfo.result?.userSingular !== undefined ? props.usersInfo.result?.userSingular?.experience : null
    );

    if (props.dataSetState[DATASET.LANGUAGES]) {
      methods.setValue(
        "studiedLanguages",
        getMultipleSelectOptionsElementByCode(
          props.dataSetState[DATASET.LANGUAGES],
          props.usersInfo.result?.userSingular?.studiedLanguagesList
        )
      );
    }

    if (props.dataSetState[DATASET.OCCUPATIONAL_CATEGORIES]) {
      methods.setValue(
        "occupationalCategory",
        getSelectOptionsElementByCode(
          props.dataSetState[DATASET.OCCUPATIONAL_CATEGORIES],
          props.usersInfo.result?.userSingular?.occupationalCategoryId
        )
      );
    }

    if (props.dataSetState[DATASET.PROVINCES]) {
      methods.setValue(
        "intendedWorkplaceProvince",
        getSelectOptionsElementByCode(
          props.dataSetState[DATASET.PROVINCES],
          props.usersInfo.result?.userSingular?.intendedWorkplaceProvinceId
        )
      );
    }

    methods.setValue("secondaryExperience", props.usersInfo.result?.userSingular?.secondaryExperience);
    methods.setValue("professionalQualification", props.usersInfo.result?.userSingular?.professionalQualification);
    methods.setValue("otherProfessionalExperience", props.usersInfo.result?.userSingular?.otherProfessionalExperience);
  };

  //#region PROVINCES
  useEffect(() => {
    if (props.dataSetState && props.dataSetState[DATASET.PROVINCES]) {
      var provinceList = props.dataSetState[DATASET.PROVINCES] ? props.dataSetState[DATASET.PROVINCES] : [];

      var provinces =
        props.usersInfo.result !== undefined ? props.usersInfo.result?.userSingular?.compositeAddress?.province : null;
      var selectedProvince = getSelectOptionsElementByCode(provinceList, provinces);
      methods.setValue("province", selectedProvince);
      if (selectedProvince) props.getDataSet(DATASET.MUNICIPALITIES, selectedProvince.keyValue);

      methods.setValue(
        "intendedWorkplaceProvince",
        getSelectOptionsElementByCode(
          props.dataSetState[DATASET.PROVINCES],
          props.usersInfo.result?.userSingular?.intendedWorkplaceProvinceId
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dataSetState[DATASET.PROVINCES]]);
  //#endregion

  //#region MUNICIPALITIES
  useEffect(() => {
    if (props.dataSetState) {
      methods.setValue(
        "municipality",
        getSelectOptionsElementByCode(
          props.dataSetState[DATASET.MUNICIPALITIES],
          props.usersInfo.result?.userSingular?.compositeAddress?.municipality
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dataSetState[DATASET.MUNICIPALITIES]]);
  //#endregion

  useEffect(() => {
    if (props.dataSetState) {
      methods.setValue(
        "employmentCenter",
        getSelectOptionsElementByCode(
          props.dataSetState[DATASET.EMPLOYMENT_CENTERS],
          props.usersInfo.result?.employmentCenterId
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dataSetState[DATASET.MUNICIPALITIES]]);

  const setPersonalDataFormValuesOnMount = () => {
    methods.setValue("name", props.usersInfo.result !== undefined ? props.usersInfo.result?.name : null);
    methods.setValue(
      "lastname",
      props.usersInfo.result !== undefined ? props.usersInfo.result?.userSingular?.lastName : null
    );

    methods.setValue(
      "isActive",
      DATASET.YES_OR_NO_DROPDOWN.find((t) => t.code === props.usersInfo.result?.isActive)
    );

    methods.setValue(
      "birthdate",
      props.usersInfo.result !== undefined ? moment(props.usersInfo.result?.userSingular?.dateOfBirth) : null
    );
    methods.setValue(
      "identityCard",
      props.usersInfo.result !== undefined ? props.usersInfo.result?.userSingular?.identityCard : null
    );

    var specialNeedsList = props.dataSetState[DATASET.SPECIAL_NEEDS] ? props.dataSetState[DATASET.SPECIAL_NEEDS] : [];
    var specialNeeds =
      props.usersInfo.result !== undefined ? props.usersInfo.result?.userSingular?.specialNeedsId : null;
    methods.setValue("specialNeeds", getSelectOptionsElementByCode(specialNeedsList, specialNeeds));

    methods.setValue(
      "specialNeedsPercent",
      props.usersInfo.result !== undefined ? props.usersInfo.result?.userSingular?.specialNeedsPercent : null
    );

    var genderList = props.dataSetState[DATASET.GENDER] ? props.dataSetState[DATASET.GENDER] : [];

    var gender = props.usersInfo.result !== undefined ? props.usersInfo.result?.userSingular?.gender : null;

    methods.setValue("gender", getSelectOptionsElementByCode(genderList, gender));

    var maritalList = props.dataSetState[DATASET.MARITAL_STATUS] ? props.dataSetState[DATASET.MARITAL_STATUS] : [];

    var marital = props.usersInfo.result !== undefined ? props.usersInfo.result?.userSingular?.maritalStatus : null;

    methods.setValue("maritalStatus", getSelectOptionsElementByCode(maritalList, marital));

    var nationalityList = props.dataSetState[DATASET.NATIONALITIES] ? props.dataSetState[DATASET.NATIONALITIES] : [];

    var nationalities = props.usersInfo.result !== undefined ? props.usersInfo.result?.userSingular?.nationality : null;

    methods.setValue("nationality", getSelectOptionsElementByCode(nationalityList, nationalities));

    if (props.dataSetState && props.dataSetState[DATASET.PROVINCES]) {
      var provinceList = props.dataSetState[DATASET.PROVINCES] ? props.dataSetState[DATASET.PROVINCES] : [];

      var provinces =
        props.usersInfo.result !== undefined ? props.usersInfo.result?.userSingular?.compositeAddress?.province : null;
      var selectedProvince = getSelectOptionsElementByCode(provinceList, provinces);
      methods.setValue("province", selectedProvince);
      if (selectedProvince) props.getDataSet(DATASET.MUNICIPALITIES, selectedProvince.keyValue);

      if (props.getDataSet !== undefined) {
        props.getDataSet(DATASET.EMPLOYMENT_CENTERS, selectedProvince?.keyValue, true);
      }

      var employmentCentersList = props.dataSetState[DATASET.EMPLOYMENT_CENTERS]
        ? props.dataSetState[DATASET.EMPLOYMENT_CENTERS]
        : [];

      methods.setValue(
        "employmentCenter",
        getSelectOptionsElementByCode(employmentCentersList, props?.usersInfo.result?.employmentCenterId)
      );
    }

    methods.setValue(
      "district",
      props.usersInfo.result !== undefined ? props.usersInfo.result?.userSingular?.compositeAddress.district : null
    );
    methods.setValue("niss", props.usersInfo.result !== undefined ? props.usersInfo.result?.userSingular?.niss : null);
    methods.setValue(
      "hasNISS",
      props.usersInfo.result !== undefined ? props.usersInfo.result?.userSingular?.hasNISS : null
    );
    methods.setValue(
      "address",
      props.usersInfo.result !== undefined ? props.usersInfo.result?.userSingular?.compositeAddress.address : null
    );
    methods.setValue("mobilePhone", props.usersInfo.result !== undefined ? props.usersInfo.result?.mobilePhone : null);
    setOldMobilePhone(props.usersInfo.result?.mobilePhone);
    methods.setValue(
      "mobilePhoneConfirmation",
      props.usersInfo.result !== undefined ? props.usersInfo.result?.mobilePhone : null
    );
    methods.setValue(
      "alternativeMobilePhone",
      props.usersInfo.result !== undefined ? props.usersInfo.result?.alternativeMobilePhone : null
    );
    methods.setValue("email", props.usersInfo.result !== undefined ? props.usersInfo.result?.email : null);
    setOldEmail(props.usersInfo.result?.email);
    methods.setValue("emailConfirmation", props.usersInfo.result !== undefined ? props.usersInfo.result?.email : null);
    methods.setValue(
      "isAvailableForInternshipOpportunities",
      props.usersInfo.result !== undefined
        ? props.usersInfo.result?.userSingular?.isAvailableForInternshipOpportunities
        : false
    );
    methods.setValue(
      "isAvailableForEmploymentOpportunities",
      props.usersInfo.result !== undefined
        ? props.usersInfo.result?.userSingular?.isAvailableForEmploymentOpportunities
        : false
    );

    methods.setValue("household", props.usersInfo.result?.userSingular?.household);
    methods.setValue("familyIncome", props.usersInfo.result?.userSingular?.familyIncome);
  };

  const cancelOnClick = () => {
    history.goBack();
  };

  const handleEditSectionPersonalData = () => {
    setIsEditablePersonalData(!isEditablePersonalData);
    if (isEditablePersonalData) {
      methods.clearErrors();
    }
  };

  const handleEditSectionProfessionalData = () => {
    setIsEditableProfessionalData(!isEditableProfessionalData);
    if (isEditableProfessionalData) {
      methods.clearErrors();
    }
  };

  const handleEditSectionUserDocuments = () => {
    setIsEditableUserDocuments(!isEditableUserDocuments);
    if (isEditableUserDocuments) {
      methods.clearErrors();
    }
  };

  const handleTabType = (event, value) => {
    if (value !== null) {
      setTabType(value);
    }
  };

  const onSubmit = (data) => {
    const email = data.email;
    const mobilePhone = data.mobilePhone;

    if (email !== oldEmail || mobilePhone !== oldMobilePhone)
      props.showDialog(
        "Pretende continuar?",
        "Esta ação vai forçar a confirmação do telemóvel/email do utilizador.",
        function () {
          return props.updateIndividualUser(
            frontOfficeIndividualUserUpdateMap(
              data,
              props.usersInfo.result?.userTypeDocuments,
              props.match.params.userId
            )
          );
        }
      );
    else
      props.updateIndividualUser(
        frontOfficeIndividualUserUpdateMap(data, props.usersInfo.result?.userTypeDocuments, props.match.params.userId)
      );
  };

  return (
    <>
      <GenericBackdrop open={props.usersInfo.getUserInfo_loading} />

      {scrollDirection === "up" && (
        <div className={classes.breadcrumbs}>
          <LocationHeader
            section={"Detalhes Utilizador FrontOffice"}
            subsection={"Gestão de Utilizadores FrontOffice"}
            subsectionLink={"/admin/frontofficeusers-management"}
          />
        </div>
      )}

      <Container maxWidth={false} component={Box} classes={{ root: classes.containerRootAfterBreadcrumbs }}>
        <PageTitleHeader title={"Detalhes Utilizador FrontOffice"} />

        <Box component={Grid} container justifyContent="center">
          <Tabs
            tabs={[
              {
                value: TABS.USER_DATA,
                testId: "user-overview-user-data-tab",
                text: intl.formatMessage(labels.Label_UserProfile_UserSingular_PersonalAndProfessionalData),
              },
              {
                value: TABS.EMPLOYMENT_AND_UNEMPLOYMENT_HISTORY,
                testId: "user-overview-employment-and-unemployment-history-tab",
                text: intl.formatMessage(labels.Label_UserProfile_UserSingular_EmploymentAndUnemploymentHistory),
              },
              {
                value: TABS.INTERVENTION_FROM_OTHERS_AREAS,
                testId: "user-overview-other-areas-intervention-tab",
                text: intl.formatMessage(labels.Label_InterventionOtherArea),
              },
            ]}
            onChange={handleTabType}
            step={tabType}
          />

          <Grid item xs={12}>
            <Container maxWidth={false} component={Box}>
              {tabType === TABS.EMPLOYMENT_AND_UNEMPLOYMENT_HISTORY && (
                <EmploymentAndUnemploymentHistory userId={props.match.params.userId} />
              )}

              {tabType === TABS.USER_DATA && (
                <FormProvider {...methods}>
                  <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <IndividualUserData
                      userId={props.match?.params?.userId}
                      isEditablePersonalData={isEditablePersonalData}
                      isEditableProfessionalData={isEditableProfessionalData}
                      isEditableUserDocuments={isEditableUserDocuments}
                      handleEditSectionPersonalData={handleEditSectionPersonalData}
                      handleEditSectionProfessionalData={handleEditSectionProfessionalData}
                      handleEditSectionUserDocuments={handleEditSectionUserDocuments}
                    />

                    {(isEditableProfessionalData || isEditablePersonalData || isEditableUserDocuments) && (
                      <Grid container>
                        <Grid item xs={12} lg={6} xl={8}></Grid>
                        <Grid item xs={12} lg={3} xl={2}>
                          <FormGroup>
                            <GenericButton typeSubmit={false} color="secondary" onClick={() => cancelOnClick()}>
                              {intl.formatMessage(labels.Label_Generic_Cancel)}
                            </GenericButton>
                          </FormGroup>
                        </Grid>
                        <Grid item xs={12} lg={3} xl={2}>
                          <FormGroup>
                            <GenericButton
                              typeSubmit={true}
                              color="primary"
                              loading={props.usersInfo.updateIndividualUser_loading}
                            >
                              {intl.formatMessage(labels.Label_Update_Submit)}
                            </GenericButton>
                          </FormGroup>
                        </Grid>
                      </Grid>
                    )}
                  </form>
                </FormProvider>
              )}

              {tabType === TABS.INTERVENTION_FROM_OTHERS_AREAS && <InterventionOtherAreaSection />}
            </Container>
          </Grid>
        </Box>
      </Container>
    </>
  );
}
const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  updateIndividualUser: (data) => dispatch(updateIndividualUserAction(data)),
  getUserInfo: (id) => dispatch(getUserInfoAction(id)),
  getDataSet: (name, parentKeyValue) => dispatch(getDataSetByNameAction(name, parentKeyValue)),
  getUserDocument: (docId, docName) => dispatch(getUserDocumentAction(docId, docName)),
  showDialog: (message, yesAction, noAction) =>
    dispatch(showGenericTwoButtonDialogAction(message, yesAction, noAction)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditIndividualUser);
