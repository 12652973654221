import { React, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
//import custom components
import componentStylesGeneric from "assets/theme/views/admin/generic.js";
import "react-quill/dist/quill.snow.css";
//Header
import PageTitleHeader from "components/Headers/PageTitleHeader.js";
import LocationHeader from "components/Headers/LocationHeader.js";
import GenericBackdrop from "components/Backdrops/GenericBackdrop";
import CreateEditSection from "./Sections/CreateEditSection";
import { getInformationDetailsActions } from "redux/actions/informationDisseminationAction";
import { titles } from "resources/resources";
import useScrollDirection from "hooks/useScrollDirection";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function Edit(props) {
  const classes = useStylesGeneric();
  const history = useHistory();
  const intl = useIntl();
  const scrollDirection = useScrollDirection();

  useEffect(() => {
    if (
      props.informationDisseminationState.informationDetailsData === undefined ||
      props.informationDisseminationState.informationDetailsData.alternateKey !== props.match.params.infoKey
    ) {
      props.getInformationDetails(props.match.params.infoKey, history);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.match.params.infoKey]);

  return (
    <>
      {scrollDirection === 'up' && (
        <div className={classes.breadcrumbs}>
          <LocationHeader
            section={intl.formatMessage(titles.Title_InformationDissemination_EditInformation)}
            subsection={intl.formatMessage(titles.Title_InformationDissemination_InformationManagement)}
            subsectionLink={"/admin/informationdissemination-management"}
          />
        </div>
      )}

      <GenericBackdrop
        open={props.informationDisseminationState.get_information_details_loading}
      />
      <Container maxWidth={false} classes={{ root: classes.containerRootAfterBreadcrumbs }}>
        <PageTitleHeader title={intl.formatMessage(titles.Title_InformationDissemination_EditInformation)} />

        <CreateEditSection
          isEditable={true}
          informationDetailsData={props.informationDisseminationState.informationDetailsData}
        />
      </Container>
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getInformationDetails: (infoId, history) => dispatch(getInformationDetailsActions(infoId, history)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Edit);
