import { React } from "react";
import { useIntl } from "react-intl";
import { Controller } from "react-hook-form";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Rating from "@material-ui/lab/Rating";
import Box from "@material-ui/core/Box";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormGroup from "@material-ui/core/FormGroup";

import { messages } from "resources/resources";

import componentStylesGeneric from "assets/theme/views/admin/generic.js";
const useStylesGeneric = makeStyles(componentStylesGeneric);

export default function RatingField(props) {
  const intl = useIntl();
  const classes = useStylesGeneric();

  return (
    <FormGroup 
      classes={{
        root: classes.formRatingInTableCell,
      }}
    >
      {props.isEditable ? (
        <>
          <Controller
            name={props.name}
            control={props.control}
            setValue={props.setValue}
            component={Box}
            defaultValue=""
            rules={{
              required: {
                value: props.isRequired,
                message: intl.formatMessage(messages.Message_Generic_MandatoryField),
              },
              validate: {
                isGreaterThanZero: (value) => {
                  if (!props.isRequired || value > 0) return true;
                  return (
                    intl.formatMessage(messages.Message_Generic_MandatoryField)
                  );
                },
              },
            }}
            render={({ field: { onChange, name, value } }) => (
              <Rating
                name={name}
                value={Number(value)}
                onChange={onChange}
              />
            )}
          />

          {props.errors[props.name] !== undefined && (
            <FormHelperText
              component={Box}
              color={props.theme.palette.warning.main + "!important"}
            >
              {props.errors[props.name].message}
            </FormHelperText>
          )}
        </>
      ) : 
        <Rating
          name={props.name} 
          value={Number(props.answerValue)}
          disabled
        />
      }
    </FormGroup>
  );
}
