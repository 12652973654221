import { React, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import ReactQuill from "react-quill";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Grid from "@material-ui/core/Grid";
import Switch from "@material-ui/core/Switch";
import LinkMaterialUi from "@material-ui/core/Link";
import Tooltip from "@material-ui/core/Tooltip";
// @material-ui/icons components
import StarIcon from "@material-ui/icons/Star";
import StarBorderIcon from "@material-ui/icons/StarBorder";
// @material-ui/icons components
import componentStyles from "assets/theme/views/admin/generic-card.js";
import { updateInformationPublishAction } from "redux/actions/informationDisseminationAction";

const useStyles = makeStyles(componentStyles);

function InformationDisseminationCard(props) {
  const classes = { ...useStyles() };
  const [statePublished, setStatePublished] = useState(false);

  const handleChangePublished = (data, event) => {
    let publishData = { alternateKey: data, published: event.target.checked };

    props.updateInformationPublish(publishData);
  };

  useEffect(() => {
    setStatePublished(props.published);
  }, [props.published]);

  return (
    <>
      <Card className={classes.cardRoot}>
        <img 
          src={props.photo !== null ? props.photo : "/images/infoDefaultImage.jpg"} 
          className={classes.cardImgTop} 
          alt={""}
        />

        <Tooltip
          title={props.isHighlighted ? "Em Destaque" : "Sem Destaque"}
          placement="top"
          arrow
          classes={{ tooltip: classes.tooltip, arrow: classes.tooltipArrow }}
        >
          {props.isHighlighted ? (
            <StarIcon className={classes.topLeftIcon} />
          ) : (
            <StarBorderIcon className={classes.topLeftIcon} />
          )}
        </Tooltip>

        <Button
          component={Link}
          size="small"
          to={`/admin/informationdissemination-edit/${props.alternateKey}`}
          classes={{ root: classes.cardButtons }}
          style={{ position: "absolute", right: "10px", top: "10px" }}
        >
          Editar
        </Button>

        <List disablePadding>
          <ListItem className={classes.listItemRoot}>
            <Grid container>
              <Grid item xs={6} sm={6} md={6} className={classes.cardLabelTitle}>
                Categoria
              </Grid>
              <Grid item xs={6} sm={6} md={6} className={classes.alignEnd}>
                {props.informationDisseminationTypeDescription}
              </Grid>
            </Grid>
          </ListItem>
          <ListItem className={classes.listItemRoot}>
            <Grid container>
              <Grid item xs={8} sm={8} md={8} className={classes.cardLabelTitle}>
                Autenticação
              </Grid>
              <Grid item xs={4} sm={4} md={4} className={classes.alignEnd}>
                {props.isPublic ? "Não" : "Sim"}
              </Grid>
            </Grid>
          </ListItem>
          <ListItem className={classes.listItemRoot}>
            <Grid container>
              <Grid item xs={6} sm={6} md={6} className={classes.cardLabelTitle}>
                Publicação
              </Grid>
              <Grid item xs={6} sm={6} md={6} className={classes.alignEnd}>
                {props.publishedOn ?? "N/A"}
              </Grid>
            </Grid>
          </ListItem>
        </List>

        <CardContent>
          <Tooltip
            title={props.title}
            placement="bottom"
            arrow
            interactive
            enterDelay={300}
            enterNextDelay={300}
            classes={{ tooltip: classes.tooltip, arrow: classes.tooltipArrow }}
          >
            <Typography variant="h3" className={classes.doubleLineTitleEllipsis}>
              {props.title}
            </Typography>
          </Tooltip>

          <ReactQuill
            readOnly={true}
            className={classes.descriptionSize + " " + classes.quillOverflowHidden}
            theme="bubble"
            value={props.description}
          />

          <Grid container>
            <Grid item xs={6} sm={9} md={8}>
              <Typography component="div" className={classes.switchText}>
                <Grid component="label" container alignItems="center" spacing={1}>
                  <Grid item>
                    <Switch
                      name="infoPublish"
                      checked={statePublished}
                      onChange={(e) => {
                        handleChangePublished(props.alternateKey, e);
                      }}
                      color="primary"
                    />
                  </Grid>
                  <Grid item>{statePublished ? "Publicado" : "Não Publicado"}</Grid>
                </Grid>
              </Typography>
            </Grid>
            <Grid item xs={6} sm={3} md={4} className={classes.alignEnd}>
              <LinkMaterialUi component={Link} variant="h5" to={`/admin/informationdissemination-details/${props.alternateKey}`}>
                Ver mais
              </LinkMaterialUi>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  updateInformationPublish: (data) => dispatch(updateInformationPublishAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InformationDisseminationCard);
