const componentStyles = (theme) => ({
  userProfileTitle: {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "26px",
    lineHeight: "35px",
    color: theme.palette.primary.main,
    textAlign: "center",
  },

  avatar: {
    width: "230px",
    height: "230px",
    marginTop: "30px",
    [theme.breakpoints.down("md")]: {
      width: "180px",
      height: "180px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "200px",
      height: "200px",
    },
  },
  nameInitialsAvatar: {
    width: "230px",
    height: "230px",
    marginTop: "30px",
    fontSize: "120px",
    [theme.breakpoints.down("md")]: {
      width: "180px",
      height: "180px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "200px",
      height: "200px",
    },
  },
  updatePhotoButton: {
    padding: "12px 20px",
    background: theme.palette.white.main,
    boxShadow:
      "0px 4px 6px rgba(50, 50, 93, 0.11), 0px 1px 3px rgba(0, 0, 0, 0.08)",
    borderRadius: "4px",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "19px",
    alignItems: "center",
    textAlign: "center",
    letterSpacing: "0.02em",
    width: "11rem",
    backgroundColor: theme.palette.white.main,
    color: theme.palette.primary.darkBlue,
    border: "1px solid " + theme.palette.primary.darkBlue,
    textTransform: "uppercase",
    "&:hover": {
      backgroundColor: theme.palette.primary.greyBlue,
      color: theme.palette.white.main,
      borderColor: theme.palette.primary.greyBlue,
    },
    "&:active": {
        backgroundColor: theme.palette.primary.darkBlue,
        color: theme.palette.white.main,
        borderColor: theme.palette.primary.darkBlue,
    },
    "&:focus": {
        color: theme.palette.primary.darkBlue,
        border: "2px solid " + theme.palette.secondary.skyBlue,
    },
  },
});

export default componentStyles;
