import axios from "axios";
import { INTERNAL_API_URL } from "../index";
import { EXTERNAL_API_URL } from "../index";

export const CREATE_INFORMATION_URL = "InformationDissemination/create";
export const GET_FILTERED_INFORMATION_URL = "InformationDissemination/get-filtered-information";
export const GET_INFORMATION_DETAILS_URL = "InformationDissemination/get-information-details";
export const UPDATE_INFORMATION_PUBLISH_URL = "InformationDissemination/publish-information";
export const UPDATE_INFORMATION_HIGHLIGHT_URL = "InformationDissemination/highlight-information";
export const UPDATE_INFORMATION_URL = "InformationDissemination/update";


export function createInformation(data) {
  return axios.post(INTERNAL_API_URL + CREATE_INFORMATION_URL, data);
}

export function getFilteredInformation(data) {
  return axios.post(EXTERNAL_API_URL + GET_FILTERED_INFORMATION_URL, data);
}

export function getInformationDetails(data) {
  return axios.get(EXTERNAL_API_URL + GET_INFORMATION_DETAILS_URL, {
    params: {
      alternateKey: data,
    },
  });
}

export function updateInformation(data) {
  return axios.post(INTERNAL_API_URL + UPDATE_INFORMATION_URL, data);
}

export function updateInformationPublish(data) {
  return axios.post(INTERNAL_API_URL + UPDATE_INFORMATION_PUBLISH_URL, data);
}

export function updateInformationHighlighted(data) {
  return axios.post(INTERNAL_API_URL + UPDATE_INFORMATION_HIGHLIGHT_URL, data);
}
