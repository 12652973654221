import React from "react";
import clsx from "clsx";
import { Controller } from "react-hook-form";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function FormMultipleSelect({
  control,
  fieldName,
  selectOptions,
  initialLabel,
  getValues,
  required,
  requiredMessage,
  limitTags,
  errors,
  classes,
  disabled,
  onOptionChange,
}) {
  const theme = useTheme();
  const useStyles = makeStyles(() => ({
    root: {
      width: "100%",
    }
  }));
  const formMultipleClass = useStyles();

  return (
    <Controller
      render={({ field: { onChange, name } }) => (
        <Autocomplete
          noOptionsText={'Sem opções'}
          disabled={disabled !== undefined ? disabled : false}
          data-testid={"form-multiple-select-" + name}
          multiple
          limitTags={limitTags}
          name={name}
          onChange={(e, data) => {
            onChange(data);
            if(onOptionChange)
              onOptionChange(data);
          }}
          value={getValues(name) ? getValues(name) : []}
          options={selectOptions ? selectOptions : { code: "", label: "" }}
          disableCloseOnSelect
          getOptionLabel={(option) => option.label}
          getOptionSelected={(option, value) => option.code === value.code}
          classes={{
            root: formMultipleClass.root,
            inputRoot: clsx({
              [classes.selectBorderWarningInputRoot]: errors !== undefined && errors[name] !== undefined,
            }),
          }}
          ListboxProps={{
            style: {
              fontWeight: "normal",
              fontSize: "14px",
            },
          }}
          renderOption={(option, { selected }) => (
            <React.Fragment>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.label}
            </React.Fragment>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label={!initialLabel ? "Seleccione opção" : initialLabel}
              InputLabelProps={{
                style: {
                  fontWeight: "normal",
                  fontSize: "14px",
                  color: theme.palette.gray[600],
                },
              }}
            />
          )}
        />
      )}
      name={fieldName}
      control={control}
      rules={{
        required: {
          value: required,
          message: requiredMessage,
        },
      }}
    />
  );
}
