import { FORM_FIELDS_PREFIX, REPORT_TYPES, COMPLEMENTARY_DATA_ANSWER_TYPE, MONETARY_DECIMAL_SEPARATOR } from "utils/consts";

export function updateGeneralDataMap(formData, documentTypes) {
  const data = new FormData();
  if (formData !== null && formData !== undefined) {
    const listComplementaryDataItems = formData.complementaryDataList?.map((compleData) => {
      return {
        complementaryDataId: compleData.complementaryDataId,
        answer:
          compleData.answerTypeId === COMPLEMENTARY_DATA_ANSWER_TYPE.DATASET
            ? formData[FORM_FIELDS_PREFIX.COMPLEMENTARY_DATA + compleData.complementaryDataId]?.code?.toString()
            : formData[FORM_FIELDS_PREFIX.COMPLEMENTARY_DATA + compleData.complementaryDataId] ?? "",
      };
    });

    const application = {
      applicationCode: formData.applicationNumber ? formData.applicationNumber : 0,
      applicationStateId: formData.applicationStateId !== undefined ? formData.applicationStateId.code : 0,
      bankPreferenceId: formData.bank?.code ? formData.bank?.code : "",
      concededMicrocreditAmount:
        formData.concededMicrocreditAmount !== undefined ? formData.concededMicrocreditAmount?.toString().replace(MONETARY_DECIMAL_SEPARATOR.WEB, MONETARY_DECIMAL_SEPARATOR.API) : "",
      concededProfessionalKitsNumber:
        formData.concededProfessionalKitsNumber !== undefined ? formData.concededProfessionalKitsNumber : "",
      trainingCenterId: formData.trainingCenterId !== undefined ? formData.trainingCenterId?.code : "",
      intendedCourseCode: formData.intendedCourseCode !== undefined ? formData.intendedCourseCode?.code : "",
      numBI: formData.numBI !== undefined ? formData.numBI : null,
      provinceId: formData.provinceId?.code ? formData.provinceId?.code : "",
      internshipAreaId: formData.internshipAreaId?.code ? formData.internshipAreaId?.code : "",
      internshipOpportunityId: formData.internshipOpportunityId?.code ? formData.internshipOpportunityId?.code : "",
    };

    //Append data
    data.append("isBackOffice", true);
    data.append("rejectMotiveId", formData.rejectMotiveId !== undefined ? formData.rejectMotiveId : "");
    data.append(
      "rejectMotiveObservations",
      formData.rejectMotiveObservations !== undefined ? formData.rejectMotiveObservations : ""
    );

    for (const key in application) {
      data.append("application." + key, application[key]);
    }

    let countAnswers = 0;
    listComplementaryDataItems?.forEach((element) => {
      data.append(`application.answers[${countAnswers}].complementaryDataId`, element.complementaryDataId);
      data.append(`application.answers[${countAnswers}].answer`, element.answer);
      countAnswers++;
    });

    let countDoc = 0;
    documentTypes?.forEach((dt) => {
      if (
        formData[FORM_FIELDS_PREFIX.DOCUMENT + dt.documentTypeId] &&
        formData[FORM_FIELDS_PREFIX.DOCUMENT + dt.documentTypeId][0]
      ) {
        data.append(`application.applicationDocuments[${countDoc}].documentTypeId`, dt.documentTypeId);
        data.append(`application.applicationDocuments[${countDoc}].documentId`, countDoc);
        data.append("ApplicationDocuments", formData[FORM_FIELDS_PREFIX.DOCUMENT + dt.documentTypeId][0]);
        countDoc++;
      }
    });
  }

  return data;
}

export function createSchedulingMap(formData) {
  if (formData !== null && formData !== undefined) {
    return {
      applicationNumber: formData.applicationNumber ? formData.applicationNumber : 0,
      motiveId: formData.schedulingMotive.code ? formData.schedulingMotive.code : "",
      schedulingDatetime: formData.schedulingDate ? formData.schedulingDate.format("YYYY-MM-DDTHH:mmZ") : null,
      message: formData.schedulingObjectives ? formData.schedulingObjectives : null,
    };
  }

  return formData;
}

export function createNotificationsMap(formData) {
  if (formData !== null && formData) {
    return {
      userId: formData.notificationBackOfficeUser ? formData.notificationBackOfficeUser.code : null,
      userGroupKeyValue: formData.notificationBackOfficeGroup ? formData.notificationBackOfficeGroup.code : null,
      applicationNumber: formData.applicationNumber ? formData.applicationNumber : null,
      motiveId: formData.notificationMotive ? formData.notificationMotive.code : 0,
      subjectId: formData.notificationSubject ? formData.notificationSubject.code : 0,
      message: formData.notificationObservations ? formData.notificationObservations : null,
      notificationDate: null,
      originKey: "OriginBO",
    };
  }

  return formData;
}

export function addHistoryEntryMap(formData) {
  if (formData !== null && formData !== undefined) {
    return {
      applicationNumber: formData.applicationNumber ? formData.applicationNumber : 0,
      message: formData.historyComment ? formData.historyComment : null,
    };
  }

  return formData;
}

export function applicationsFilterMap(formData) {
  if (formData !== null && formData !== undefined) {
    return {
      numApplication: formData.applicationNumber ?? "",
      identityCardOrTaxIdNum: formData.identityCardOrTaxIdNum ?? "",
      userCode: formData.userCode ?? "",
      nameOfUser: formData.nameOfUser ?? "",
      stateId: formData.statusId?.code,
      governmentProgramId: formData.governmentProgramId?.code,
      programId: formData.programId?.code,
      subprogramId: formData.subprogramId?.code,
      internshipAreaId: formData.internshipAreaId?.code,
      startDate: formData.startDate ? formData.startDate.format("YYYY-MM-DD") : null,
      endDate: formData.endDate ? formData.endDate.format("YYYY-MM-DD") : null,
      provinceId: formData.province?.code,
      candidateProvinceId: formData.candidateProvince?.code,
      candidateMunicipalityId: formData.candidateMunicipality?.code,
      phoneNumber: formData.phoneNumber,
      hasEntrepreneurshipTraining: formData.hasEntrepreneurshipTraining?.code,
      genderId: formData.genderId?.code,
      academicLevelId: formData.academicLevelId?.code,
      degreeId: formData.degreeId?.code,
    };
  }
}

export function applicationExportReportMap(applicationNumber) {
  if (applicationNumber !== null && applicationNumber !== undefined) {
    return {
      reportEnumKey: REPORT_TYPES.APPLICATION_DETAILS,
      parameters: JSON.stringify({ ApplicationNumber: applicationNumber }),
    };
  }
}

export function applicationsFilterExportReportMap(filterData) {
  if (filterData !== null && filterData !== undefined) {
    return {
      reportEnumKey: REPORT_TYPES.APPLICATIONS_SEARCH_RESULTS,
      parameters: JSON.stringify(filterData),
    };
  }
}

export default updateGeneralDataMap;
