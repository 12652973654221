import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useIntl } from "react-intl";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
//MaterialUI
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import IconButton from "@material-ui/core/IconButton";
import { Typography } from "@material-ui/core";
import CardHeader from "@material-ui/core/CardHeader";
import limitaString from "utils/string";
import TableBackdrop from "components/Backdrops/TableBackdrop/TableBackdrop";
import Tooltip from "@material-ui/core/Tooltip";
import Pagination from "@material-ui/lab/Pagination";

import TableStateChip from "components/Cards/Tables/TableStateChip";
import { OPPORTUNITY_CANDIDATE_STATE_KEY, OPPORTUNITY_TYPE_ID, TABLE_STATE_CHIP_COLOR, PAGINATION } from "utils/consts";
import { getAllOpportunityApplicationsAction } from "redux/actions/opportunitiesAction";
import { titles, labels } from "resources/resources";

import componentStylesGeneric from "assets/theme/views/admin/generic";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function OpportunityApplicationsTab(props) {
  const theme = useTheme();
  const classes =  useStylesGeneric();
  const intl = useIntl();
  const [page, setPage] = useState(1);

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const tableHead = [
    intl.formatMessage(labels.Label_UserOverview_ApplicationsTab_ApplicationsSection_ApplicationCode),
    intl.formatMessage(labels.Label_UserOverview_ApplicationsTab_ApplicationsSection_Candidate),
    intl.formatMessage(labels.Label_UserOverview_ApplicationsTab_ApplicationsSection_OpportunityType),
    intl.formatMessage(labels.Label_UserOverview_ApplicationsTab_ApplicationsSection_ApplicationState)
  ];

  const mapOpportunityCandidateStateToTableChipColors = (stateKey) => {
    switch (stateKey) {
      case OPPORTUNITY_CANDIDATE_STATE_KEY.APROVADA:
        return TABLE_STATE_CHIP_COLOR.GREEN;
      case OPPORTUNITY_CANDIDATE_STATE_KEY.REJEITADA:
        return TABLE_STATE_CHIP_COLOR.RED;
      case OPPORTUNITY_CANDIDATE_STATE_KEY.EMRECRUTAMENTO:
        return TABLE_STATE_CHIP_COLOR.LIGHT_ORANGE;
      case OPPORTUNITY_CANDIDATE_STATE_KEY.DESISTENCIA:
        return TABLE_STATE_CHIP_COLOR.BLUE;
      default:
        return <></>;
    }
  };

  useEffect(() => {
    let filterData = {
      opportunityTypeId: props.opportunityTypeId,
      pageIndex: page <= 0 ? 1 : page,
      pageSize: PAGINATION.PAGE_SIZE,
      companyTaxIdNumber: props?.usersState?.getFOUserOverview_result?.userColectiveGeneralDataOverview?.taxIdNumber
    };

    props.getAllOpportunityApplications(filterData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, props.opportunityTypeId]);

  useEffect(() => {
    handleChangePage(undefined, PAGINATION.FIRST_PAGE);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.opportunityTypeId]);

  return (
    <>
      <Card classes={{ root: classes.cardRoot }}>
        <CardHeader
          classes={{
            root: classes.cardHeader,
            action: classes.cardHeaderAction,
          }}
          title={
            <Typography style={{ fontWeight: "bold" }}
            >
              {intl.formatMessage(titles.Title_UserOverview_ApplicationsTab_EmploymentAndInternshipApplicationsSection)}{" "}
              {props.opportunityTypeId === OPPORTUNITY_TYPE_ID.ESTAGIO_PROFISSIONAL && intl.formatMessage(labels.Label_UserOverview_ApplicationsTab_ApplicationsSection_InternshipsTab)}
              {props.opportunityTypeId === OPPORTUNITY_TYPE_ID.EMPREGO && intl.formatMessage(labels.Label_UserOverview_ApplicationsTab_ApplicationsSection_EmploymentTab)}
            </Typography>
          }
          action={
            <Typography
              variant="h4"
              className={classes.cardHeaderActionTypography}
            >
              {intl.formatMessage(labels.Label_Total)}: {props.opportunitiesState?.opportunityApplications_total_records}
            </Typography>
          }
        ></CardHeader>

        <TableContainer>
          <Box component={Table} alignItems="center" marginBottom="0!important">
            <TableHead>
              <TableRow>
                {tableHead.map((prop, key) => (
                  <TableCell
                    key={key}
                    classes={{
                      root: classes.tableCellRoot + " " + classes.tableCellRootHead,
                    }}
                    data-testid={"user-overview-internship-and-employment-applications-section-table-header-" + key}
                  >
                    {prop}
                  </TableCell>
                ))}
                <TableCell
                  key="detailsCol"
                  classes={{
                    root:
                      classes.tableCellRoot + " " + classes.tableCellRootHead + " " + classes.tableCellHeadStickyRight,
                  }}
                  data-testid="user-overview-internship-and-employment-applications-section-details"
                >
                  {intl.formatMessage(labels.Label_Details)}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props?.opportunitiesState?.opportunityApplications_result?.data?.map((prop, key) => (
                <TableRow key={key}>
                  <TableCell classes={{ root: classes.tableCellRoot }} title={prop.opportunityTitle ?? ""}>
                    <Box>{prop.candidateCode}</Box>
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                    <Box fontWeight={"bold"}>Nº do BI: {prop.userIdentityCard}</Box>
                    <Box>{limitaString(prop.userName, 60)}</Box>
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                    <Box fontWeight={"bold"}>{prop.opportunityTypeDescription}</Box>
                    <Box>{limitaString(prop.opportunityTitle, 60)}</Box>
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                    <TableStateChip
                      state={mapOpportunityCandidateStateToTableChipColors(prop.candidateStateKey)}
                      message={prop.candidateStateDescription}
                    />
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot + " " + classes.tableCellBodyStickyRight }}>
                    <Tooltip title="Detalhes">
                      <IconButton
                        aria-label="details"
                        component={Link}
                        to={`/admin/candidate-details/${prop.candidateCode}`}
                        data-testid="user-overview-internship-and-employment-applications-section-details-button"
                      >
                        <Box
                          component={ArrowForwardIosIcon}
                          width="12px!important"
                          height="12px!important"
                          color={theme.palette.primary.main}
                        />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Box>
          <TableBackdrop open={props.opportunitiesState?.getAllOpportunityApplications_loading} />
        </TableContainer>

        <Box classes={{ root: classes.cardActionsRoot }} component={CardActions} justifyContent="flex-end"></Box>
      </Card>

      <Pagination
        className={classes.alignPagination}
        count={props.opportunitiesState?.opportunityApplications_total_pages}
        page={page}
        onChange={handleChangePage}
        color="primary"
      ></Pagination>
    </>
  );
}
const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getAllOpportunityApplications: (data) => dispatch(getAllOpportunityApplicationsAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OpportunityApplicationsTab);
