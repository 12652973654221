import { React, useEffect } from "react";
import clsx from "clsx";
import { Controller, useFormContext } from "react-hook-form";
import { connect } from "react-redux";
import ReactDatetime from "react-datetime";
import { useIntl } from "react-intl";
import "moment/locale/pt";
import moment from "moment";
// @material-ui/core components
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Tooltip from "@material-ui/core/Tooltip";
import Switch from "@material-ui/core/Switch";
import Typography from "@material-ui/core/Typography";
// @material-ui/icons components
import DateRange from "@material-ui/icons/DateRange";
import HelpIcon from "@material-ui/icons/Help";
import ErrorIcon from "@material-ui/icons/Error";
//import custom components
import FormSelect from "components/FormFields/FormSelect";
import { getDataSetByNameAction, clearDataSetByNameAction } from "redux/actions/datasetsAction";
import { getUserInfoAction } from "redux/actions/usersAction";
import { DATASET, SPECIAL_NEEDS_KEY, REGISTRATION_CONSTS, REGEX_PATTERNS } from "utils/consts";
import { labels, errorMessages, messages } from "resources/resources";
import FormMonetaryInput from "components/FormFields/FormMonetaryInput";
import FormInput from "components/FormFields/FormInput";

import componentStylesGeneric from "assets/theme/views/admin/generic.js";
const useStylesGeneric = makeStyles(componentStylesGeneric);

function IndividualUserPersonalData(props) {
  const { control } = useFormContext();
  const classes = useStylesGeneric();
  const intl = useIntl();
  const theme = useTheme();

  const handleValidDate = (date, onChange) => {
    if (date === "" || REGEX_PATTERNS.DATE.test(date)) {
      onChange(date);
    }
  };

  useEffect(() => {
    if (props.isCreate) props.clearDataSetByName(DATASET.MUNICIPALITIES);

    if (props.dataSetState[DATASET.PROVINCES] === undefined || props.dataSetState[DATASET.PROVINCES].length === 0) {
      props.getDataSet(DATASET.PROVINCES);
    }
    if (props.dataSetState[DATASET.GENDER] === undefined || props.dataSetState[DATASET.GENDER].length === 0) {
      props.getDataSet(DATASET.GENDER);
    }
    if (
      props.dataSetState[DATASET.MARITAL_STATUS] === undefined ||
      props.dataSetState[DATASET.MARITAL_STATUS].length === 0
    ) {
      props.getDataSet(DATASET.MARITAL_STATUS);
    }
    if (
      props.dataSetState[DATASET.NATIONALITIES] === undefined ||
      props.dataSetState[DATASET.NATIONALITIES].length === 0
    ) {
      props.getDataSet(DATASET.NATIONALITIES);
    }
    if (
      props.dataSetState[DATASET.SPECIAL_NEEDS] === undefined ||
      props.dataSetState[DATASET.SPECIAL_NEEDS].length === 0
    ) {
      props.getDataSet(DATASET.SPECIAL_NEEDS);
    }
    if (
      props.dataSetState[DATASET.EMPLOYMENT_CENTERS] === undefined ||
      props.dataSetState[DATASET.EMPLOYMENT_CENTERS].length === 0
    ) {
      props.getDataSet(DATASET.EMPLOYMENT_CENTERS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={6} md={props.isCreate ? 6 : 4} xl={props.isCreate ? 6 : 5}>
          <FormGroup>
            <FormLabel>Nome</FormLabel>
            <Controller
              name="name"
              control={control}
              component={Box}
              defaultValue=""
              rules={{
                required: {
                  value: true,
                  message: intl.formatMessage(errorMessages.ErrorMessage_FieldRequired),
                },
              }}
              render={({ field: { onChange, name, value } }) => (
                <OutlinedInput
                  name={name}
                  value={value}
                  onChange={onChange}
                  fullWidth
                  autoComplete="off"
                  type="text"
                  disabled={!props.isEditable}
                  classes={{
                    notchedOutline: clsx({
                      [classes.borderWarning]: props.errors["name"] !== undefined,
                    }),
                  }}
                />
              )}
            />
            {props.errors["name"] !== undefined && (
              <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                {props.errors["name"].message}
              </FormHelperText>
            )}
          </FormGroup>
        </Grid>
        <Grid item xs={12} sm={6} md={props.isCreate ? 6 : 4}>
          <FormGroup>
            <FormLabel>Sobrenome</FormLabel>
            <Controller
              name="lastname"
              defaultValue=""
              control={control}
              component={Box}
              rules={{
                required: {
                  value: true,
                  message: intl.formatMessage(errorMessages.ErrorMessage_FieldRequired),
                },
              }}
              render={({ field }) => (
                <OutlinedInput
                  {...field}
                  fullWidth
                  autoComplete="off"
                  type="text"
                  disabled={!props.isEditable}
                  classes={{
                    notchedOutline: clsx({
                      [classes.borderWarning]: props.errors["lastname"] !== undefined,
                    }),
                  }}
                />
              )}
            />
            {props.errors["lastname"] !== undefined && (
              <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                {props.errors["lastname"].message}
              </FormHelperText>
            )}
          </FormGroup>
        </Grid>

        {!props.isCreate && (
          <Grid item xs={12} sm={6} md={4} xl={3}>
            <FormGroup>
              <FormLabel>Activo</FormLabel>
              <FormSelect
                control={control}
                fieldName="isActive"
                disabled={!props.isEditable}
                selectOptions={DATASET.YES_OR_NO_DROPDOWN}
                setValue={props.setValue}
                getValues={props.getValues}
                required={props.isEditable}
                requiredMessage={intl.formatMessage(errorMessages.ErrorMessage_FieldRequired)}
                errors={props.errors}
                classes={classes}
              />
              {props.errors["isActive"] !== undefined && (
                <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                  {props.errors["isActive"].message}
                </FormHelperText>
              )}
            </FormGroup>
          </Grid>
        )}
      </Grid>
      <Grid container>
        <Grid item xs={12} sm={6} md={4} xl={3}>
          <FormGroup>
            <FormLabel>Data de nascimento</FormLabel>
            <Controller
              name="birthdate"
              control={control}
              component={Box}
              defaultValue=""
              rules={{
                required: {
                  value: true,
                  message: intl.formatMessage(errorMessages.ErrorMessage_FieldRequired),
                },
                validate: {
                  maxDate: (value) => {
                    return (
                      value < moment().startOf("day") ||
                      intl.formatMessage(errorMessages.ErrorMessage_Birthdate_MaxDate)
                    );
                  },
                  minDate: (value) => {
                    return (
                      value >
                        moment(REGISTRATION_CONSTS.MIN_BIRTHDATE, REGISTRATION_CONSTS.DATE_TIME_FORMAT).startOf(
                          "day"
                        ) ||
                      intl.formatMessage(errorMessages.ErrorMessage_Birthdate_MinDate, {
                        minDate: REGISTRATION_CONSTS.MIN_BIRTHDATE,
                      })
                    );
                  },
                  dateHasSlash: (value) => {
                    if (value._isAMomentObject !== true) {
                      if (!value.includes("/")) {
                        return intl.formatMessage(errorMessages.ErrorMessage_InvalidFormat);
                      }
                    }
                  },
                  isAdult: (value) => {
                    if (moment().diff(value, "years") < 17) {
                      return intl.formatMessage(errorMessages.ErrorMessage_MinRegisterDate);
                    }
                  },
                },
              }}
              render={({ field: { ref, ...field } }) => (
                <ReactDatetime
                  {...field}
                  locale="pt"
                  dateFormat={"DD/MM/YYYY"}
                  timeFormat={false}
                  isValidDate={(currentDate, _) => {
                    return currentDate < moment().startOf("day");
                  }}
                  inputProps={{
                    placeholder: "DD/MM/AAAA",
                    className: "",
                  }}
                  renderInput={(dateInputProps, open) => (
                    <OutlinedInput
                      ref={ref}
                      fullWidth
                      disabled={!props.isEditable}
                      onFocus={open}
                      {...dateInputProps}
                      onChange={(e) => {
                        handleValidDate(e.target.value, dateInputProps.onChange);
                      }}
                      startAdornment={
                        <InputAdornment position="start">
                          <Box component={DateRange} marginRight=".5rem" color={theme.palette.primary.main} />
                        </InputAdornment>
                      }
                      classes={{
                        notchedOutline: clsx({
                          [classes.borderWarning]: props.errors["birthdate"] !== undefined,
                        }),
                      }}
                    />
                  )}
                />
              )}
            />
            {props.errors["birthdate"] !== undefined && (
              <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                {props.errors["birthdate"].message}
              </FormHelperText>
            )}
          </FormGroup>
        </Grid>
        <Grid item xs={12} sm={6} md={4} xl={3}>
          <FormGroup>
            <FormLabel>
              Nº do BI
              {!props.usersInfo.result?.userSingular?.canModifyIdentityCard && !props.isCreate && (
                <Tooltip title="Nº do BI bloqueado por alteração recente">
                  <ErrorIcon className={classes.tooltipInfoIcon} />
                </Tooltip>
              )}
            </FormLabel>
            <Controller
              name="identityCard"
              defaultValue=""
              control={control}
              component={Box}
              rules={{
                required: {
                  value: true,
                  message: intl.formatMessage(errorMessages.ErrorMessage_FieldRequired),
                },
                validate: {
                  maxLength: (value) => {
                    return (
                      value.length <= REGISTRATION_CONSTS.MAX_LENGTH_ID_CARD ||
                      intl.formatMessage(errorMessages.ErrorMessage_ID_Card_InvalidFormat)
                    );
                  },
                },
              }}
              render={({ field }) => (
                <OutlinedInput
                  disabled={
                    !props.isEditable ||
                    (!props.usersInfo.result?.userSingular?.canModifyIdentityCard && !props.isCreate)
                  }
                  {...field}
                  fullWidth
                  autoComplete="off"
                  type="text"
                  inputProps={{ maxLength: REGISTRATION_CONSTS.MAX_LENGTH_ID_CARD }}
                  classes={{
                    notchedOutline: clsx({
                      [classes.borderWarning]: props.errors["identityCard"] !== undefined,
                    }),
                  }}
                />
              )}
            />
            {props.errors["identityCard"] !== undefined && (
              <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                {props.errors["identityCard"].message}
              </FormHelperText>
            )}
          </FormGroup>
        </Grid>

        <Grid item xs={12} sm={6} md={4} xl={3}>
          <FormGroup>
            <FormLabel>Necessidades Especiais</FormLabel>
            <FormSelect
              control={control}
              fieldName="specialNeeds"
              disabled={!props.isEditable}
              selectOptions={props.dataSetState[DATASET.SPECIAL_NEEDS] ? props.dataSetState[DATASET.SPECIAL_NEEDS] : []}
              getValues={props.getValues}
              required={true}
              requiredMessage={intl.formatMessage(errorMessages.ErrorMessage_FieldRequired)}
              errors={props.errors}
              classes={classes}
              onOptionChange={() => props.trigger("specialNeedsPercent")}
            />
            {props.errors["specialNeeds"] !== undefined && (
              <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                {props.errors["specialNeeds"].message}
              </FormHelperText>
            )}
          </FormGroup>
        </Grid>
        <Grid item xs={12} sm={6} md={4} xl={3}>
          <FormGroup>
            <FormLabel>Percentagem (%)</FormLabel>
            <Controller
              name="specialNeedsPercent"
              defaultValue=""
              control={control}
              component={Box}
              rules={{
                validate: {
                  requiredIf: (value) => {
                    if (props.getValues("specialNeeds")?.keyValue !== SPECIAL_NEEDS_KEY.NAO && !value) {
                      return intl.formatMessage(errorMessages.ErrorMessage_FieldRequired);
                    }
                  },
                  greaterThanZero: (value) => {
                    if (
                      props.getValues("specialNeeds")?.keyValue !== SPECIAL_NEEDS_KEY.NAO &&
                      (value <= REGISTRATION_CONSTS.MIN_LENGTH_PERCENTAGE ||
                        value > REGISTRATION_CONSTS.MAX_LENGTH_PERCENTAGE)
                    ) {
                      return intl.formatMessage(errorMessages.ErrorMessage_FieldPercentage);
                    }
                  },
                },
              }}
              render={({ field: { onChange, name, value } }) => (
                <OutlinedInput
                  name={name}
                  value={value}
                  fullWidth
                  autoComplete="off"
                  type="number"
                  onChange={(e) => {
                    onChange(e);
                  }}
                  disabled={!props.isEditable}
                  classes={{
                    notchedOutline: clsx({
                      [classes.borderWarning]: props.errors["specialNeedsPercent"] !== undefined,
                    }),
                  }}
                />
              )}
            />
            {props.errors["specialNeedsPercent"] !== undefined && (
              <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                {props.errors["specialNeedsPercent"].message}
              </FormHelperText>
            )}
          </FormGroup>
        </Grid>

        <Grid item xs={12} sm={6} md={4} xl={3}>
          <FormGroup>
            <FormLabel>Género</FormLabel>
            <FormSelect
              control={control}
              disabled={!props.isEditable}
              fieldName="gender"
              selectOptions={props.dataSetState[DATASET.GENDER] ? props.dataSetState[DATASET.GENDER] : []}
              setValue={props.setValue}
              getValues={props.getValues}
              required={props.isEditable}
              requiredMessage={intl.formatMessage(errorMessages.ErrorMessage_FieldRequired)}
              errors={props.errors}
              classes={classes}
            />
            {props.errors["gender"] !== undefined && (
              <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                {props.errors["gender"].message}
              </FormHelperText>
            )}
          </FormGroup>
        </Grid>
        <Grid item xs={12} sm={6} md={4} xl={3}>
          <FormGroup>
            <FormLabel>Estado Civil</FormLabel>
            <FormSelect
              control={control}
              disabled={!props.isEditable}
              fieldName="maritalStatus"
              selectOptions={
                props.dataSetState[DATASET.MARITAL_STATUS] ? props.dataSetState[DATASET.MARITAL_STATUS] : []
              }
              setValue={props.setValue}
              getValues={props.getValues}
              required={props.isEditable}
              requiredMessage={intl.formatMessage(errorMessages.ErrorMessage_FieldRequired)}
              errors={props.errors}
              classes={classes}
            />
            {props.errors["maritalStatus"] !== undefined && (
              <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                {props.errors["maritalStatus"].message}
              </FormHelperText>
            )}
          </FormGroup>
        </Grid>
        <Grid item xs={12} sm={6} md={4} xl={3}>
          <FormInput
            name="household"
            label={intl.formatMessage(labels.Label_UserProfile_UserSingular_ProfessionalData_Household)}
            numberType={true}
            disabled={!props.isEditable}
            control={control}
            errors={props.errors}
            rules={{
              validate: {
                minValue: (value) => {
                  if (value < 0) return intl.formatMessage(messages.Message_Generic_ValueGreaterThanOrEqualToZeroField);
                  return true;
                },
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} xl={3}>
          <FormMonetaryInput
            name="familyIncome"
            label={intl.formatMessage(labels.Label_UserProfile_UserSingular_ProfessionalData_FamilyIncome)}
            disabled={!props.isEditable}
            control={control}
            errors={props.errors}
            defaultValue={props.usersInfo.result?.userSingular?.familyIncome}
            rules={{
              pattern: {
                value: REGEX_PATTERNS.MONETARY,
                message: intl.formatMessage(messages.Message_Generic_InvalidField),
              },
              validate: {
                minValue: (value) => {
                  if (value === "0" || value === "0,00" || value === "0,0")
                    return intl.formatMessage(errorMessages.ErrorMessage_Generic_ValueGreaterThanZeroField);
                  return true;
                },
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} xl={3}>
          <FormGroup>
            <FormLabel>Nacionalidade</FormLabel>
            <FormSelect
              disabled={!props.isEditable}
              control={control}
              fieldName="nationality"
              selectOptions={props.dataSetState[DATASET.NATIONALITIES] ? props.dataSetState[DATASET.NATIONALITIES] : []}
              setValue={props.setValue}
              getValues={props.getValues}
              required={props.isEditable}
              requiredMessage={intl.formatMessage(errorMessages.ErrorMessage_FieldRequired)}
              errors={props.errors}
              classes={classes}
            />
            {props.errors["nationality"] !== undefined && (
              <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                {props.errors["nationality"].message}
              </FormHelperText>
            )}
          </FormGroup>
        </Grid>
        <Grid item xs={12} sm={6} md={4} xl={3}>
          <FormGroup>
            <FormLabel>Província</FormLabel>
            <FormSelect
              disabled={!props.isEditable}
              control={control}
              fieldName="province"
              selectOptions={props.dataSetState[DATASET.PROVINCES] ? props.dataSetState[DATASET.PROVINCES] : []}
              setValue={props.setValue}
              getValues={props.getValues}
              loadChildrenDataSet={props.getDataSet}
              childrenDataSet={DATASET.MUNICIPALITIES}
              childrenSelect="municipality"
              childrenList={[{ name: "employmentCenter", dataset: DATASET.EMPLOYMENT_CENTERS }]}
              required={true}
              requiredMessage={intl.formatMessage(errorMessages.ErrorMessage_FieldRequired)}
              errors={props.errors}
              classes={classes}
            />
            {props.errors["province"] !== undefined && (
              <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                {props.errors["province"].message}
              </FormHelperText>
            )}
          </FormGroup>
        </Grid>
        <Grid item xs={12} sm={6} md={4} xl={3}>
          <FormGroup>
            <FormLabel>Município</FormLabel>
            <FormSelect
              disabled={!props.isEditable}
              control={control}
              fieldName="municipality"
              selectOptions={
                props.dataSetState[DATASET.MUNICIPALITIES] ? props.dataSetState[DATASET.MUNICIPALITIES] : []
              }
              setValue={props.setValue}
              getValues={props.getValues}
              required={props.isEditable}
              requiredMessage={intl.formatMessage(errorMessages.ErrorMessage_FieldRequired)}
              errors={props.errors}
              classes={classes}
            />
            {props.errors["municipality"] !== undefined && (
              <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                {props.errors["municipality"].message}
              </FormHelperText>
            )}
          </FormGroup>
        </Grid>
        <Grid item xs={12} sm={6} md={4} xl={3}>
          <FormGroup>
            <FormLabel>Bairro</FormLabel>
            <Controller
              name="district"
              defaultValue=""
              control={control}
              component={Box}
              rules={{
                required: {
                  value: true,
                  message: intl.formatMessage(errorMessages.ErrorMessage_FieldRequired),
                },
              }}
              render={({ field }) => (
                <OutlinedInput
                  disabled={!props.isEditable}
                  {...field}
                  fullWidth
                  autoComplete="off"
                  type="text"
                  classes={{
                    notchedOutline: clsx({
                      [classes.borderWarning]: props.errors["district"] !== undefined,
                    }),
                  }}
                />
              )}
            />
            {props.errors["district"] !== undefined && (
              <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                {props.errors["district"].message}
              </FormHelperText>
            )}
          </FormGroup>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} sm={3} md={3}>
          <FormGroup>
            <FormLabel>
              NISS Individual
              <Tooltip title="Número de Identificação da Segurança Social">
                <HelpIcon className={classes.tooltipInfoIcon} />
              </Tooltip>
            </FormLabel>
            <Controller
              name="niss"
              control={control}
              component={Box}
              defaultValue=""
              rules={{
                validate: {
                  requiredIf: (value) => {
                    if (!props.getValues("hasNISS") && !value)
                      return intl.formatMessage(errorMessages.ErrorMessage_FieldRequired);
                    return true;
                  },
                },
              }}
              render={({ field: { onChange, name, value } }) => (
                <OutlinedInput
                  disabled={!props.isEditable}
                  name={name}
                  value={value}
                  fullWidth
                  autoComplete="off"
                  type="text"
                  onChange={(e) => {
                    onChange(e);
                    props.trigger("hasNISS");
                  }}
                  classes={{
                    notchedOutline: clsx({
                      [classes.borderWarning]: props.errors["niss"] !== undefined,
                    }),
                  }}
                />
              )}
            />
            {props.errors["niss"] !== undefined && (
              <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                {props.errors["niss"].message}
              </FormHelperText>
            )}
          </FormGroup>
        </Grid>
        <Grid item xs={12} sm={3} md={3}>
          <FormGroup>
            <Controller
              name="hasNISS"
              control={control}
              component={Box}
              defaultValue={false}
              rules={{
                validate: {
                  requiredIf: (value) => {
                    if (!props.getValues("niss") && !value)
                      return intl.formatMessage(errorMessages.ErrorMessage_FieldRequired);
                    if (props.getValues("niss") && props.getValues("hasNISS"))
                      return "Preencha apenas um dos campos (NISS Individual ou Sem NISS individual)";
                    return true;
                  },
                },
              }}
              render={({ field: { onChange, name, value } }) => (
                <FormControlLabel
                  disabled={!props.isEditable}
                  name={name}
                  onChange={(e) => {
                    onChange(e);
                    props.trigger("niss");
                  }}
                  value="end"
                  control={<Checkbox color="primary" checked={value} />}
                  label={
                    <Box fontSize={14} component="div">
                      Sem NISS individual
                    </Box>
                  }
                  labelPlacement="end"
                  classes={{
                    root: classes.formControlCheckboxLabelRoot,
                    label: classes.formControlCheckboxLabelLabel,
                  }}
                />
              )}
            />
            {props.errors["hasNISS"] !== undefined && (
              <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                {props.errors["hasNISS"].message}
              </FormHelperText>
            )}
          </FormGroup>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <FormGroup>
            <FormLabel>Endereço (opcional)</FormLabel>
            <Controller
              name="address"
              defaultValue=""
              control={control}
              component={Box}
              rules={{
                required: {
                  value: false,
                  message: intl.formatMessage(errorMessages.ErrorMessage_FieldRequired),
                },
              }}
              render={({ field }) => (
                <OutlinedInput
                  disabled={!props.isEditable}
                  {...field}
                  fullWidth
                  autoComplete="off"
                  type="text"
                  classes={{
                    notchedOutline: clsx({
                      [classes.borderWarning]: props.errors["address"] !== undefined,
                    }),
                  }}
                />
              )}
            />
            {props.errors["address"] !== undefined && (
              <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                {props.errors["address"].message}
              </FormHelperText>
            )}
          </FormGroup>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} sm={4} md={4}>
          <FormGroup>
            <FormLabel>Telemóvel</FormLabel>
            <Controller
              name="mobilePhone"
              defaultValue=""
              control={control}
              component={Box}
              rules={{
                required: {
                  value: true,
                  message: intl.formatMessage(errorMessages.ErrorMessage_FieldRequired),
                },
                validate: {
                  validPhoneNumber: (value) => {
                    if (
                      value &&
                      (parseInt(value) < REGISTRATION_CONSTS.MIN_LENGTH_PHONE_NUMBER ||
                        parseInt(value) > REGISTRATION_CONSTS.MAX_LENGTH_PHONE_NUMBER)
                    )
                      return "Formato inválido";
                    return true;
                  },
                  //   mobilePhoneisUnique: async () => await fetch()
                },
              }}
              render={({ field: { onChange, name, value } }) => (
                <OutlinedInput
                  disabled={!props.isEditable}
                  name={name}
                  value={value}
                  fullWidth
                  autoComplete="off"
                  type="number"
                  onChange={(e) => {
                    onChange(e);
                    props.trigger("mobilePhoneConfirmation");
                  }}
                  classes={{
                    notchedOutline: clsx({
                      [classes.borderWarning]: props.errors["mobilePhone"] !== undefined,
                    }),
                  }}
                />
              )}
            />
            {props.errors["mobilePhone"] !== undefined && (
              <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                {props.errors["mobilePhone"].message}
              </FormHelperText>
            )}
          </FormGroup>
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <FormGroup>
            <FormLabel>Confirmação telemóvel</FormLabel>
            <Controller
              name="mobilePhoneConfirmation"
              defaultValue=""
              control={control}
              component={Box}
              rules={{
                required: {
                  value: true,
                  message: intl.formatMessage(errorMessages.ErrorMessage_FieldRequired),
                },
                validate: {
                  validPhoneNumber: (value) => {
                    if (
                      value &&
                      (parseInt(value) < REGISTRATION_CONSTS.MIN_LENGTH_PHONE_NUMBER ||
                        parseInt(value) > REGISTRATION_CONSTS.MAX_LENGTH_PHONE_NUMBER)
                    )
                      return "Formato inválido";
                    return true;
                  },
                  phoneNumberMatch: (value) => {
                    return (
                      value === props.getValues("mobilePhone") ||
                      "O número inserido deve ser igual ao campo 'Telemóvel'"
                    );
                  },
                },
              }}
              render={({ field: { onChange, name, value } }) => (
                <OutlinedInput
                  disabled={!props.isEditable}
                  name={name}
                  value={value}
                  fullWidth
                  autoComplete="off"
                  type="number"
                  onChange={(e) => {
                    onChange(e);
                    props.trigger("mobilePhone");
                  }}
                  classes={{
                    notchedOutline: clsx({
                      [classes.borderWarning]: props.errors["mobilePhoneConfirmation"] !== undefined,
                    }),
                  }}
                />
              )}
            />
            {props.errors["mobilePhoneConfirmation"] !== undefined && (
              <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                {props.errors["mobilePhoneConfirmation"].message}
              </FormHelperText>
            )}
          </FormGroup>
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <FormGroup>
            <FormLabel>Telemóvel alternativo</FormLabel>
            <Controller
              name="alternativeMobilePhone"
              defaultValue=""
              control={control}
              component={Box}
              rules={{
                validate: {
                  validPhoneNumber: (value) => {
                    if (
                      value &&
                      (parseInt(value) < REGISTRATION_CONSTS.MIN_LENGTH_PHONE_NUMBER ||
                        parseInt(value) > REGISTRATION_CONSTS.MAX_LENGTH_PHONE_NUMBER)
                    )
                      return "Formato inválido";
                    return true;
                  },
                },
              }}
              render={({ field }) => (
                <OutlinedInput
                  disabled={!props.isEditable}
                  {...field}
                  fullWidth
                  autoComplete="off"
                  type="number"
                  classes={{
                    notchedOutline: clsx({
                      [classes.borderWarning]: props.errors["alternativeMobilePhone"] !== undefined,
                    }),
                  }}
                />
              )}
            />
            {props.errors["alternativeMobilePhone"] !== undefined && (
              <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                {props.errors["alternativeMobilePhone"].message}
              </FormHelperText>
            )}
          </FormGroup>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} sm={6} md={6}>
          <FormGroup>
            <FormLabel>E-mail (opcional)</FormLabel>
            <Controller
              name="email"
              defaultValue=""
              control={control}
              component={Box}
              rules={{
                validate: {
                  validEmail: (value) => {
                    if (value && !REGEX_PATTERNS.EMAIL.test(value)) return "Formato inválido";
                    return true;
                  },
                },
              }}
              render={({ field: { onChange, name, value } }) => (
                <OutlinedInput
                  disabled={!props.isEditable}
                  name={name}
                  value={value}
                  fullWidth
                  autoComplete="off"
                  type="email"
                  onChange={(e) => {
                    onChange(e);
                    props.trigger("emailConfirmation");
                  }}
                  classes={{
                    notchedOutline: clsx({
                      [classes.borderWarning]: props.errors["email"] !== undefined,
                    }),
                  }}
                />
              )}
            />
            {props.errors["email"] !== undefined && (
              <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                {props.errors["email"].message}
              </FormHelperText>
            )}
          </FormGroup>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <FormGroup>
            <FormLabel>Confirmação email</FormLabel>
            <Controller
              name="emailConfirmation"
              defaultValue=""
              control={control}
              component={Box}
              rules={{
                validate: {
                  validEmail: (value) => {
                    if (value && !REGEX_PATTERNS.EMAIL.test(value)) return "Formato inválido";
                    return true;
                  },
                  EmailMatch: (value) => {
                    return value === props.getValues("email") || "O e-mail inserido deve ser igual ao campo 'E-mail'";
                  },
                },
              }}
              render={({ field: { onChange, name, value } }) => (
                <OutlinedInput
                  disabled={!props.isEditable}
                  name={name}
                  value={value}
                  fullWidth
                  autoComplete="off"
                  type="email"
                  onChange={(e) => {
                    onChange(e);
                    props.trigger("email");
                  }}
                  classes={{
                    notchedOutline: clsx({
                      [classes.borderWarning]: props.errors["emailConfirmation"] !== undefined,
                    }),
                  }}
                />
              )}
            />
            {props.errors["emailConfirmation"] !== undefined && (
              <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                {props.errors["emailConfirmation"].message}
              </FormHelperText>
            )}
          </FormGroup>
        </Grid>

        <Grid item xs={12} md={6}>
          <FormGroup>
            <Typography component="div" variant="body2">
              <Grid component="label" container alignItems="center" spacing={1}>
                <Grid item>
                  <Controller
                    name="isAvailableForInternshipOpportunities"
                    control={control}
                    defaultValue={false}
                    render={({ field: { onChange, name, value } }) => (
                      <Switch
                        name={name}
                        checked={value}
                        disabled={!props.isEditable}
                        onChange={(e) => {
                          onChange(e.target.checked);
                        }}
                        color="primary"
                      />
                    )}
                  />
                </Grid>
                <Grid item>
                  <FormLabel>
                    {intl.formatMessage(
                      labels.Label_Admin_EditIndividualUserPersonalData_Field_isAvailableForInternshipOpportunities
                    )}
                  </FormLabel>
                </Grid>
              </Grid>
            </Typography>
          </FormGroup>
        </Grid>

        <Grid item xs={12} md={6}>
          <FormGroup>
            <Typography component="div" variant="body2">
              <Grid component="label" container alignItems="center" spacing={1}>
                <Grid item>
                  <Controller
                    name="isAvailableForEmploymentOpportunities"
                    control={control}
                    defaultValue={false}
                    render={({ field: { onChange, name, value } }) => (
                      <Switch
                        name={name}
                        checked={value}
                        disabled={!props.isEditable}
                        onChange={(e) => {
                          onChange(e.target.checked);
                        }}
                        color="primary"
                      />
                    )}
                  />
                </Grid>
                <Grid item>
                  <FormLabel>
                    {intl.formatMessage(
                      labels.Label_Admin_EditIndividualUserPersonalData_Field_isAvailableForEmploymentOpportunities
                    )}
                  </FormLabel>
                </Grid>
              </Grid>
            </Typography>
          </FormGroup>
        </Grid>
      </Grid>
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getDataSet: (name, parentKeyValue) => dispatch(getDataSetByNameAction(name, parentKeyValue)),
  getUserInfo: (id) => dispatch(getUserInfoAction(id)),
  clearDataSetByName: (name) => dispatch(clearDataSetByNameAction(name)),
});

export default connect(mapStateToProps, mapDispatchToProps)(IndividualUserPersonalData);
