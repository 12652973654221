import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Grid from "@material-ui/core/Grid";
import { Card, CardHeader, CardContent } from "@material-ui/core";

//import custom components
import GenericButton from "components/Buttons/GenericButton";
import componentStylesGeneric from "assets/theme/views/admin/generic";
import GenericFormSelect from "components/FormFields/GenericFormSelect";
import FormSelect from "components/FormFields/FormSelect";
import { labels, titles } from "resources/resources";
import { DATASET, PAGINATION } from "utils/consts";
import {
  getDataSetByNameAction,
  getGovernmentProgramsAction,
  getProgramsAction,
  getSubprogramsAction,
  getSubprogramStatesAction,
  getDepartmentsAction,
  getSubprogramByProgramIdAction,
  clearDataSetByNameAction,
} from "redux/actions/datasetsAction";
import { getFilteredSubprogramsActions } from "redux/actions/subprogramsAction";
import { subprogramFilterMap } from "mappers/subprogramsMap";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function FilterSection(props) {
  const classes = useStylesGeneric();
  const intl = useIntl();
  const history = useHistory();

  const [filter, setFilter] = useState({
    benefitTypeId: null,
    departmentId: null,
    governmentProgramId: null,
    programId: null,
    subprogramStateId: null,
    subProgramId: null,
    pageIndex: PAGINATION.FIRST_PAGE,
    pageSize: PAGINATION.PAGE_SIZE,
  });
  const [filterCounter, setFilterCounter] = useState(0);

  const {
    handleSubmit,
    getValues,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  useEffect(() => {
    let count = 0;

    Object.keys(filter).map((key) => {
      if (filter[key] && key !== "pageIndex" && key !== "pageSize") {
        count += 1;
      }

      return count;
    });

    setFilterCounter(count);
  }, [filter]);

  //Refresh Results
  const refreshResults = () => {
    let temp_filter = { ...filter, pageIndex: props.page };
    setFilter(temp_filter);
    props.getFilterSubprograms(temp_filter);
  };

  const cleanFilter = () => {
    setValue("governmentProgramId", undefined);
    setValue("programId", undefined);
    setValue("subProgramId", undefined);
    setValue("benefitTypeId", undefined);
    setValue("departmentId", undefined);
    setValue("statusId", undefined);
    props.clearDataSetByName(DATASET.SUBPROGRAMS);

    setFilterCounter(0);
    setFilter(0);
  };

  const redirectToSubProgramCreation = (event) => {
    event.preventDefault();
    history.push("/admin/subprograms-create");
  };

  const onSubmit = (data) => {
    let filter = subprogramFilterMap(data);
    setFilter(filter);
    if (props.page === PAGINATION.FIRST_PAGE) {
      props.getFilterSubprograms(filter);
    } else {
      props.handleChangePage(PAGINATION.FIRST_PAGE);
    }
  };

  const handleSetFilter = (filterKey, value, clearChildKeyList) => {
    const newFilter = { ...filter, [filterKey]: value };

    clearChildKeyList?.forEach((child) => {
      newFilter[child] = null;
    });

    setFilter(newFilter);
  };

  useEffect(() => {
    if (
      props.dataSetState[DATASET.GOVERNMENT_PROGRAMS] === undefined ||
      props.dataSetState[DATASET.GOVERNMENT_PROGRAMS]?.length === 0
    ) {
      props.getGovernmentPrograms(DATASET.GOVERNMENT_PROGRAMS);
    }

    props.getPrograms(DATASET.PROGRAMS); // Get all programs on first load
    props.getSubprograms(DATASET.SUBPROGRAMS); // Get all subprograms on first load

    if (
      props.dataSetState[DATASET.SUBPROGRAM_STATES] === undefined ||
      props.dataSetState[DATASET.SUBPROGRAM_STATES]?.length === 0
    ) {
      props.getSubprogramStates(DATASET.SUBPROGRAM_STATES);
    }
    if (props.dataSetState[DATASET.BENEFITS] === undefined || props.dataSetState[DATASET.BENEFITS]?.length === 0) {
      props.getDataSet(DATASET.BENEFITS);
    }
    if (
      props.dataSetState[DATASET.DEPARTMENTS] === undefined ||
      props.dataSetState[DATASET.DEPARTMENTS]?.length === 0
    ) {
      props.getDepartments(DATASET.DEPARTMENTS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Refresh Results
  useEffect(() => {
    refreshResults();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.page]);

  return (
    <Card classes={{ root: classes.cardRoot }}>
      <CardHeader
        classes={{
          action: classes.cardHeaderAction,
          title: classes.cardHeader,
        }}
        title={
          <div>
            <div className={classes.sideBySideDiv}>Pesquisa de Subprogramas</div>
            <div className={classes.sideBySideDiv + " " + classes.cardElementsCount}>
              Filtros {"("}
              {filterCounter}
              {")"}
            </div>
          </div>
        }
        action={
          <GenericButton typeSubmit={false} color="tertiary" onClick={redirectToSubProgramCreation}>
            {intl.formatMessage(titles.Title_AdminNavBar_CharacterizeSubprogramMenuItem)}
          </GenericButton>
        }
      ></CardHeader>
      <CardContent>
        <Box>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container>
              <Grid item xs={12} sm={6} md={4}>
                <FormGroup>
                  <FormLabel>
                    {intl.formatMessage(labels.Label_Admin_Subprogram_GovernmentProgramField)}
                  </FormLabel>
                  <FormSelect
                    control={control}
                    fieldName="governmentProgramId"
                    selectOptions={
                      props.dataSetState[DATASET.GOVERNMENT_PROGRAMS] !== undefined
                        ? props.dataSetState[DATASET.GOVERNMENT_PROGRAMS]
                        : []
                    }
                    getValues={getValues}
                    setValue={setValue}
                    loadChildrenDataSet={props.getPrograms}
                    childrenDataSet={DATASET.PROGRAMS}
                    childrenSelect="programId"
                    grandchildrenSelect="subprogramId"
                    onOptionChange={(data) => {
                      data ? props.clearDataSetByName(DATASET.SUBPROGRAMS) : props.getSubprograms(DATASET.SUBPROGRAMS);
                    }}
                    required={false}
                    errors={errors}
                    classes={classes}
                    filled={true}
                    handleSetFilter={handleSetFilter}
                    filterList={filter}
                    filterListKey={"governmentProgramId"}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormGroup>
                  <FormLabel>
                    {intl.formatMessage(labels.Label_Admin_Subprogram_ProgramField)}
                  </FormLabel>
                  <FormSelect
                    control={control}
                    fieldName="programId"
                    selectOptions={
                      props.dataSetState[DATASET.PROGRAMS] !== undefined ? props.dataSetState[DATASET.PROGRAMS] : []
                    }
                    getValues={getValues}
                    setValue={setValue}
                    loadChildrenDataSet={props.getSubprogramByProgramId}
                    childrenDataSet={DATASET.SUBPROGRAMS}
                    childrenSelect="subProgramId"
                    required={false}
                    errors={errors}
                    classes={classes}
                    filled={true}
                    handleSetFilter={handleSetFilter}
                    filterList={filter}
                    filterListKey={"programId"}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormGroup>
                  <FormLabel>
                    {intl.formatMessage(labels.Label_Admin_Subprogram_SubprogramField)}
                  </FormLabel>
                  <GenericFormSelect
                    control={control}
                    fieldName="subProgramId"
                    selectOptions={
                      props.dataSetState[DATASET.SUBPROGRAMS] !== undefined
                        ? props.dataSetState[DATASET.SUBPROGRAMS]
                        : []
                    }
                    getValues={getValues}
                    disabled={getValues("governmentProgramId") && !getValues("programId")}
                    required={false}
                    errors={errors}
                    classes={classes}
                    filled={true}
                    handleSetFilter={handleSetFilter}
                    filterList={filter}
                    filterListKey={"subprogramId"}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormGroup>
                  <FormLabel>
                    {intl.formatMessage(labels.Label_Admin_Subprogram_BenefitsTypeField)}
                  </FormLabel>
                  <GenericFormSelect
                    control={control}
                    fieldName="benefitTypeId"
                    selectOptions={
                      props.dataSetState[DATASET.BENEFITS] !== undefined ? props.dataSetState[DATASET.BENEFITS] : []
                    }
                    getValues={getValues}
                    required={false}
                    errors={errors}
                    classes={classes}
                    filled={true}
                    handleSetFilter={handleSetFilter}
                    filterList={filter}
                    filterListKey={"benefitTypeId"}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormGroup>
                  <FormLabel>
                    {intl.formatMessage(labels.Label_Admin_Subprogram_DepartmentField)}
                  </FormLabel>
                  <GenericFormSelect
                    control={control}
                    fieldName="departmentId"
                    selectOptions={
                      props.dataSetState[DATASET.DEPARTMENTS] !== undefined
                        ? props.dataSetState[DATASET.DEPARTMENTS]
                        : []
                    }
                    getValues={getValues}
                    required={false}
                    errors={errors}
                    classes={classes}
                    filled={true}
                    handleSetFilter={handleSetFilter}
                    filterList={filter}
                    filterListKey={"departmentId"}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormGroup>
                  <FormLabel>
                    {intl.formatMessage(labels.Label_Admin_Subprogram_StateField)}
                  </FormLabel>
                  <GenericFormSelect
                    control={control}
                    fieldName="statusId"
                    selectOptions={
                      props.dataSetState[DATASET.SUBPROGRAM_STATES] !== undefined
                        ? props.dataSetState[DATASET.SUBPROGRAM_STATES]
                        : []
                    }
                    getValues={getValues}
                    required={false}
                    errors={errors}
                    classes={classes}
                    filled={true}
                    handleSetFilter={handleSetFilter}
                    filterList={filter}
                    filterListKey={"statusId"}
                  />
                </FormGroup>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} md={12} classes={{ root: classes.alignRight }}>
                <GenericButton
                  typeSubmit={false}
                  color="ghost"
                  onClick={() => {
                    cleanFilter();
                  }}
                >
                  {intl.formatMessage(labels.Label_ClearFilters)}
                </GenericButton>
                <GenericButton
                  typeSubmit={true}
                  color="primary"
                  loading={props.subprogramState.searchSubprograms_loading}
                >
                  {intl.formatMessage(labels.Label_FilterSection_SearchButton)}
                </GenericButton>
              </Grid>
            </Grid>
          </form>
        </Box>
      </CardContent>
    </Card>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getSubprogramStates: (name) => dispatch(getSubprogramStatesAction(name)),
  getGovernmentPrograms: () => dispatch(getGovernmentProgramsAction()),
  getPrograms: (name, governmentProgramId) => dispatch(getProgramsAction(name, governmentProgramId)),
  getSubprograms: (name) => dispatch(getSubprogramsAction(name)),
  getFilterSubprograms: (data) => dispatch(getFilteredSubprogramsActions(data)),
  getDataSet: (name, parentKeyValue) => dispatch(getDataSetByNameAction(name, parentKeyValue)),
  getDepartments: (name) => dispatch(getDepartmentsAction(name)),
  getSubprogramByProgramId: (name, program) => dispatch(getSubprogramByProgramIdAction(name, program)),
  clearDataSetByName: (name) => dispatch(clearDataSetByNameAction(name)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FilterSection);
