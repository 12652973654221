import { React, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import clsx from "clsx";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
// @material-ui/icons components
import SearchIcon from "@material-ui/icons/Search";
//import custom components
import GenericButton from "components/Buttons/GenericButton";
import FormSelect from "components/FormFields/FormSelect";
import userBOFilterMap from "mappers/userBOFilterMap";
import componentStylesGeneric from "assets/theme/views/admin/generic";
import { DATASET, PAGINATION } from "utils/consts";
import { Card, CardHeader, CardContent } from "@material-ui/core";

import {
  getDataSetByNameAction,
  getWarehousesByProvinceAction,
  getBackOfficeRolesAction,
  clearDataSetByNameAction,
} from "redux/actions/datasetsAction";

import { getFilteredBackOfficeUsersActions } from "redux/actions/usersAction";
import { getSelectOptionsElementByCode } from "utils/formSelect";
import { labels } from "resources/resources";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function FilterSectionBo(props) {
  const classes = useStylesGeneric();
  const intl = useIntl();
  const history = useHistory();
  const [filter, setFilter] = useState({
    name: "",
    email: "",
    province: props.authState?.province ?? null,
    municipality: null,
    userStatus: "",
    profile: null,
  });

  const {
    handleSubmit,
    getValues,
    setValue,
    control,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
  });
  const [filterCounter, setFilterCounter] = useState(0);

  const cleanFilter = () => {
    setValue("name", "");
    if (!props.authState?.province) setValue("province", undefined);
    setValue("municipality", undefined);
    setValue("userStatus", undefined);
    setValue("email", "");
    setValue("profile", undefined);
    props.clearDataSetByName(DATASET.MUNICIPALITIES);

    setFilter(0);
    setFilterCounter(0);
  };

  useEffect(() => {
    let count = 0;

    Object.keys(filter).map((key) => {
      if (filter[key] && key !== "pageIndex" && key !== "pageSize") {
        count += 1;
      }

      return count;
    });

    setFilterCounter(count);
  }, [filter]);

  useEffect(() => {
    if (props.dataSetState[DATASET.PROVINCES] === undefined || props.dataSetState[DATASET.PROVINCES] === []) {
      props.getDataSet(DATASET.PROVINCES);
    }
    if (
      props.dataSetState[DATASET.BACKOFFICE_ROLES] === undefined ||
      props.dataSetState[DATASET.BACKOFFICE_ROLES]?.length === 0
    ) {
      props.getBackOfficeRoles(DATASET.BACKOFFICE_ROLES);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      props.authState?.province &&
      props.dataSetState[DATASET.PROVINCES] !== undefined &&
      props.dataSetState[DATASET.PROVINCES] !== []
    ) {
      var selectedProvince = getSelectOptionsElementByCode(
        props.dataSetState[DATASET.PROVINCES],
        props.authState.province
      );
      setValue("province", selectedProvince);
      props.getDataSet(DATASET.MUNICIPALITIES, selectedProvince.keyValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dataSetState[DATASET.PROVINCES]]);

  const onSubmit = (data) => {
    if (isValid) {
      let filter = userBOFilterMap(data);
      filter["pageIndex"] = 1;
      filter["pageSize"] = PAGINATION.PAGE_SIZE;
      setFilter(filter);
      if (props.page === 1) props.getUsers(filter);
      else props.handleChangePage(1);
    }
  };

  const handleSetFilter = (filterKey, value, clearChildKeyList) => {
    const newFilter = { ...filter, [filterKey]: value };

    clearChildKeyList?.forEach((child) => {
      newFilter[child] = null;
    });

    setFilter(newFilter);
  };

  const redirectToCreateBOUser = (event) => {
    event.preventDefault();
    history.push("/admin/backofficeusers-create");
  };

  useEffect(() => {
    let temp_filter = filter;
    Object.assign(temp_filter, filter);
    temp_filter["pageIndex"] = props.page;
    temp_filter["pageSize"] = PAGINATION.PAGE_SIZE;
    props.getUsers(temp_filter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.page]);

  return (
    <Card classes={{ root: classes.cardRoot }}>
      <CardHeader
        classes={{
          action: classes.cardHeaderAction,
          title: classes.cardHeader,
        }}
        title={
          <div>
            <div className={classes.sideBySideDiv}>Pesquisa de Utilizadores Backoffice</div>
            <div className={classes.sideBySideDiv + " " + classes.cardElementsCount}>
              Filtros {"("}
              {filterCounter}
              {")"}
            </div>
          </div>
        }
        action={
          <GenericButton typeSubmit={false} color="tertiary" onClick={redirectToCreateBOUser}>
            Criar Utilizador Backoffice
          </GenericButton>
        }
      ></CardHeader>
      <CardContent>
        <Box>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container>
              <Grid item xs={12} sm={6} md={4}>
                <FormGroup>
                  <FormLabel>Nome</FormLabel>
                  <Controller
                    name="name"
                    control={control}
                    component={Box}
                    render={({ field: { onChange, name, value } }) => (
                      <OutlinedInput
                        name={name}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          handleSetFilter("name", e.target.value);
                        }}
                        fullWidth
                        autoComplete="off"
                        type="text"
                        placeholder="Pesquisar"
                        classes={{
                          notchedOutline: clsx({
                            [classes.borderWarning]: errors["name"] !== undefined,
                          }),
                        }}
                        endAdornment={
                          <InputAdornment position="end">
                            <SearchIcon />
                          </InputAdornment>
                        }
                      />
                    )}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <FormGroup>
                  <FormLabel>Província</FormLabel>
                  <FormSelect
                    control={control}
                    fieldName="province"
                    selectOptions={props.dataSetState[DATASET.PROVINCES] ? props.dataSetState[DATASET.PROVINCES] : []}
                    disabled={props.authState?.province !== null}
                    loadChildrenDataSet={props.getDataSet}
                    childrenDataSet={DATASET.MUNICIPALITIES}
                    childrenSelect="municipality"
                    setValue={setValue}
                    getValues={getValues}
                    required={false}
                    errors={props.errors}
                    classes={classes}
                    handleSetFilter={handleSetFilter}
                    filterList={filter}
                    filterListKey={"provinceId"}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <FormGroup>
                  <FormLabel>Município</FormLabel>
                  <FormSelect
                    control={control}
                    fieldName="municipality"
                    selectOptions={
                      props.dataSetState[DATASET.MUNICIPALITIES] ? props.dataSetState[DATASET.MUNICIPALITIES] : []
                    }
                    disabled={!getValues("province")}
                    setValue={setValue}
                    getValues={getValues}
                    required={false}
                    errors={props.errors}
                    classes={classes}
                    handleSetFilter={handleSetFilter}
                    filterList={filter}
                    filterListKey={"municipalityId"}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={5} sm={6} md={2}>
                <FormGroup>
                  <FormLabel>Estado</FormLabel>
                  <FormSelect
                    control={control}
                    fieldName="userStatus"
                    selectOptions={[
                      { code: true, label: "Activo" },
                      { code: false, label: "Inactivo" },
                    ]}
                    getValues={getValues}
                    required={false}
                    errors={errors}
                    classes={classes}
                    handleSetFilter={handleSetFilter}
                    filterList={filter}
                    filterListKey={"userStatus"}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={7} sm={6} md={4}>
                <FormGroup>
                  <FormLabel>E-mail</FormLabel>
                  <Controller
                    name="email"
                    control={control}
                    component={Box}
                    render={({ field: { onChange, name, value } }) => (
                      <OutlinedInput
                        name={name}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          handleSetFilter("email", e.target.value);
                        }}
                        fullWidth
                        autoComplete="off"
                        type="text"
                        placeholder="Pesquisar"
                        classes={{
                          notchedOutline: clsx({
                            [classes.borderWarning]: errors["email"] !== undefined,
                          }),
                        }}
                        endAdornment={
                          <InputAdornment position="end">
                            <SearchIcon />
                          </InputAdornment>
                        }
                      />
                    )}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} md={8}>
                <FormGroup>
                  <FormLabel>Função</FormLabel>
                  <FormSelect
                    control={control}
                    fieldName="profile"
                    selectOptions={
                      props.dataSetState[DATASET.BACKOFFICE_ROLES] ? props.dataSetState[DATASET.BACKOFFICE_ROLES] : []
                    }
                    getValues={getValues}
                    required={false}
                    errors={errors}
                    classes={classes}
                    handleSetFilter={handleSetFilter}
                    filterListKey={"profile"}
                  />
                </FormGroup>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} md={12} classes={{ root: classes.alignRight }}>
                <GenericButton
                  typeSubmit={false}
                  color="ghost"
                  onClick={() => {
                    cleanFilter();
                  }}
                >
                  {intl.formatMessage(labels.Label_ClearFilters)}
                </GenericButton>
                <GenericButton
                  typeSubmit={true}
                  color="primary"
                  loading={props.usersState.backoffice_users_search_loading}
                >
                  {intl.formatMessage(labels.Label_FilterSection_SearchButton)}
                </GenericButton>
              </Grid>
            </Grid>
          </form>
        </Box>
      </CardContent>
    </Card>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getDataSet: (name, parentKeyValue) => dispatch(getDataSetByNameAction(name, parentKeyValue)),
  getUsers: (name) => dispatch(getFilteredBackOfficeUsersActions(name)),
  getBackOfficeRoles: (name) => dispatch(getBackOfficeRolesAction(name)),
  getWarehousesByProvince: (name, province) => dispatch(getWarehousesByProvinceAction(name, province)),
  clearDataSetByName: (name) => dispatch(clearDataSetByNameAction(name)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FilterSectionBo);
