import { getApplicationVersion } from "crud/applicationconfiguration.crud";

import { USERS } from "utils/actionsConsts";

export const getApplicationVersionAction =
  (userType, date) => async (dispatch) => {
    dispatch({
      type: USERS.GET_APPLICATION_VERSION_LOADING,
      payload: true,
    });
    getApplicationVersion(userType, date)
      .then((res) => {
        dispatch({
          type: USERS.LAST_LOGIN,
          payload: {
            lastLoginDate: res.data.lastLoginDate,
            showNews: res.data.showNews,
            news: res.data.versionMessage,
          },
        });
      })
      .catch(() => {
        dispatch({
          type: USERS.GET_APPLICATION_VERSION_LOADING,
          payload: false,
        });
      });
  };
