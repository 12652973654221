const componentStyles = (theme) => ({
  modalHeader: {
    
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: "600",
    color: theme.palette.primary.darkBlue,
    textAlign: "center",
    marginBottom: "1rem",
    marginTop: ".5rem",
  },
  boxHeader: {
    color: theme.palette.primary.darkBlue,
    
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "19px",
    letterSpacing: "0em",
    textAlign: "left",
  },
  footerLinks: {
    color: theme.palette.primary.main,
  },
  centerBoxItems: {
    width: "46%", 
    position: "absolute", 
    top: "50%", 
    transform: "translateY(-50%)", 
    display: "flex", 
    alignItems: "center", 
    justifyContent: "center"
  },
  centerBoxHorizontally: {
    display: "flex", 
    alignItems: "center", 
    justifyContent: "center"
  },
  loginBox: {
    width: "50%", 
    float: "right", 
    padding: "20px",
  },
  backgroundImage: {
    height: "100%", 
    width: "50%", 
    objectFit: "cover",
    position: "absolute"
  }
});

export default componentStyles;
