import React from "react";
import clsx from "clsx";
// import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  hideKitCharacterizationResultModalAction,
  hideRegisterStockInWarehouseResultModalAction,
  hideDeliverKitResultModalAction,
  showRegisterLossBreakModalAction,
  showRegisterInWareHouseModalAction,
  showDeliverKitModalAction,
} from "redux/actions/stockAction";
import { useIntl } from "react-intl";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import ListItem from "@material-ui/core/ListItem";
import MenuItem from "@material-ui/core/MenuItem";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuList from "@material-ui/core/MenuList";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import CardContent from "@material-ui/core/CardContent";

// @material-ui/icons components
import CloseIcon from "@material-ui/icons/Close";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";

// core components
import NotificationsDropdown from "components/Dropdowns/NotificationsDropdown.js";
import UserDropdown from "components/Dropdowns/UserDropdown.js";
import NavbarMenuDropdown from "components/Dropdowns/NavbarMenuDropdown.js";

import componentStyles from "assets/theme/components/navbars/admin-navbar.js";
import { Dialog, ListItemIcon } from "@material-ui/core";

import RegisterStockInWarehouse from "views/admin/ProfessionalKits/Modals/RegisterStockInWarehouse/RegisterStockInWarehouse";
import RegisterLossBreak from "views/admin/ProfessionalKits/Modals/RegisterLossBreak/RegisterLossBreak";
import GenericDialog from "components/Dialogs/GenericDialogV2";
import CustomDialog from "components/Dialogs/GenericDialog";
import { titles } from "resources/resources";
import DeliverKit from "views/admin/ProfessionalKits/DeliverKit/DeliverKit";
import { checkOperationPermission } from "utils/permission";
import { OPERATIONS } from "utils/consts";
import SearchBarNavbar from "components/Navbars/Sections/SearchbarNavbar";
import GenericButton from "components/Buttons/GenericButton";

const useStyles = makeStyles(componentStyles);

function AdminNavbar(props) {
  const classes = useStyles();
  const intl = useIntl();
  const [openApplication, setOpenApplication] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [openSub, setOpenSub] = React.useState(false);
  const [openUsers, setOpenUsers] = React.useState(false);
  const [openInfoDissemination, setOpenInfoDissemination] = React.useState(false);
  const [openOpportunity, setOpenOpportunity] = React.useState(false);
  const showSearch = false;

  const anchorRefApplication = React.useRef(null);
  const anchorRef = React.useRef(null);
  const anchorRefSub = React.useRef(null);
  const anchorRefUsers = React.useRef(null);
  const anchorRefInfoDissemination = React.useRef(null);
  const anchorRefOpportunity = React.useRef(null);

  const handleRegisterKitInWarehouseResulClose = () => {
    props.hideRegisterStockInWarehouseResultModal();
  };

  const getShowRegisterKitInWarehouseResultModal = () => {
    return props.stockState.showRegisterStockInWarehouseResultModal !== undefined
      ? props.stockState.showRegisterStockInWarehouseResultModal
      : false;
  };
  const getShowRegisterLossBreakModal = () => {
    return props.stockState.RegisterLossBreakModal !== undefined ? props.stockState.RegisterLossBreakModal.show : false;
  };
  const getShowRegisterInWareHouseModal = () => {
    return props.stockState.RegisterInWareHouseModal !== undefined
      ? props.stockState.RegisterInWareHouseModal.show
      : false;
  };

  const getShowDeliverKitModal = () => {
    return props.stockState.DeliverKitModal !== undefined ? props.stockState.DeliverKitModal.show : false;
  };

  const handleRegisterLoss = () => {
    props.showRegisterLossBreakModal(true);
  };
  const handleCloseLoss = () => {
    props.showRegisterLossBreakModal(false);
  };
  const handleRegisterWarehouse = () => {
    props.showRegisterInWareHouseModal(true);
  };
  const handleCloseWarehouse = () => {
    props.showRegisterInWareHouseModal(false);
  };
  const handleDeliverKit = () => {
    props.showDeliverKitModal(true);
  };
  const handleCloseDeliverKit = () => {
    props.showDeliverKitModal(false);
  };

  const handleToggleApplication = () => {
    setOpenApplication((prevOpenApplication) => !prevOpenApplication);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleToggleSub = () => {
    setOpenSub((prevOpenSub) => !prevOpenSub);
  };

  const handleToggleUsers = () => {
    setOpenUsers((prevOpenUsers) => !prevOpenUsers);
  };

  const handleToggleInfoDissemination = () => {
    setOpenInfoDissemination((prevOpenInfoDissemination) => !prevOpenInfoDissemination);
  };

  const handleToggleOpportunity = () => {
    setOpenOpportunity((prevOpenOpportunity) => !prevOpenOpportunity);
  };

  const handleCloseApplication = (event) => {
    if (anchorRefApplication.current && anchorRefApplication.current.contains(event.target)) {
      return;
    }
    setOpenApplication(false);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleCloseSub = (event) => {
    if (anchorRefSub.current && anchorRefSub.current.contains(event.target)) {
      return;
    }
    setOpenSub(false);
  };

  const handleCloseUsers = (event) => {
    if (anchorRefUsers.current && anchorRefUsers.current.contains(event.target)) {
      return;
    }
    setOpenUsers(false);
  };

  const handleCloseInfoDissemination = (event) => {
    if (anchorRefInfoDissemination.current && anchorRefInfoDissemination.current.contains(event.target)) {
      return;
    }
    setOpenInfoDissemination(false);
  };

  const handleCloseOpportunity = (event) => {
    if (anchorRefOpportunity.current && anchorRefOpportunity.current.contains(event.target)) {
      return;
    }
    setOpenOpportunity(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  return (
    <>
      <AppBar position="relative" elevation={0} classes={{ root: classes.appBarRoot }}>
        <Toolbar disableGutters>
          <Container maxWidth={false} component={Box} classes={{ root: classes.containerRoot }}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="100%"
              marginTop="1rem"
              marginBottom="1rem"
            >
              <Link to="/admin/dashboard">
                <img 
                  className={classes.logo} 
                  src={require("assets/img/brand/INEFOP_azul.png").default} 
                  alt={""}
                />
              </Link>

              {/* Navbar Menu for lower resolutions */}
              <Hidden xlUp implementation="css">
                <Box display="flex" justifyContent="center" alignItems="center">
                  <NavbarMenuDropdown
                    handleRegisterLoss={handleRegisterLoss}
                    handleRegisterWarehouse={handleRegisterWarehouse}
                    handleDeliverKit={handleDeliverKit}
                  />
                </Box>
              </Hidden>

              {/* The following items use the Hidden component to not be displayed on lower resolutions,
              instead the NavbarMenuDropdown will display all the items. */}

              {/* Candidaturas */}
              <Hidden lgDown implementation="css">
                <Box>
                  {checkOperationPermission(props.authState.operationsList, OPERATIONS.APPLICATIONS_MENU) && (
                    <ListItem
                      classes={{
                        root: classes.listItemRoot,
                      }}
                      ref={anchorRefApplication}
                      aria-controls={openApplication ? "menu-list-grow" : undefined}
                      aria-haspopup="true"
                      onClick={handleToggleApplication}
                    >
                      {intl.formatMessage(titles.Title_AdminNavBar_GestaoCandidaturasMenu)}
                      <ListItemIcon className={classes.dropIcon}>
                        <ExpandMoreIcon />
                      </ListItemIcon>
                      <Popper
                        open={openApplication}
                        anchorEl={anchorRefApplication.current}
                        role={undefined}
                        transition
                        disablePortal
                        className={classes.dropMenu}
                      >
                        {({ TransitionProps, placement }) => (
                          <Grow
                            {...TransitionProps}
                            style={{
                              transformOrigin: placement === "bottom" ? "center top" : "center bottom",
                            }}
                          >
                            <Paper>
                              <ClickAwayListener onClickAway={handleCloseApplication}>
                                <MenuList
                                  autoFocusItem={openApplication}
                                  id="menu-list-grow"
                                  onKeyDown={handleListKeyDown}
                                >
                                  {checkOperationPermission(
                                    props.authState.operationsList,
                                    OPERATIONS.APPLICATIONS_LIST_ITEM
                                  ) && (
                                    <MenuItem
                                      className={classes.dropItems}
                                      onClick={handleClose}
                                      component={Link}
                                      to="/admin/applications-management"
                                    >
                                      {intl.formatMessage(titles.Title_AdminNavBar_ListaDeCandidaturasMenuItem)}
                                    </MenuItem>
                                  )}
                                </MenuList>
                              </ClickAwayListener>
                            </Paper>
                          </Grow>
                        )}
                      </Popper>
                    </ListItem>
                  )}
                </Box>
              </Hidden>
              {/* Agendamentos */}
              <Hidden lgDown implementation="css">
                <Box>
                  {checkOperationPermission(props.authState.operationsList, OPERATIONS.SCHEDULES_ITEM) && (
                    <ListItem
                      component={Link}
                      to="/admin/schedules-management"
                      classes={{
                        root: classes.listItemRoot,
                      }}
                    >
                      {intl.formatMessage(titles.Title_AdminNavBar_AgendamentosMenuItem)}
                    </ListItem>
                  )}
                </Box>
              </Hidden>
              {/* Kits Profissionais */}
              <Hidden lgDown implementation="css">
                <Box>
                  {checkOperationPermission(props.authState.operationsList, OPERATIONS.PROFESSIONAL_KITS_MENU) && (
                    <ListItem
                      classes={{
                        root: classes.listItemRoot,
                      }}
                      ref={anchorRef}
                      aria-controls={open ? "menu-list-grow" : undefined}
                      aria-haspopup="true"
                      onClick={handleToggle}
                    >
                      {intl.formatMessage(titles.Title_AdminNavBar_ProfessionalKitsMenu)}
                      <ListItemIcon className={classes.dropIcon}>
                        <ExpandMoreIcon />
                      </ListItemIcon>
                      <Popper 
                        open={open} 
                        anchorEl={anchorRef.current} 
                        role={undefined} 
                        transition 
                        disablePortal
                        className={classes.dropMenu}
                      >
                        {({ TransitionProps, placement }) => (
                          <Grow
                            {...TransitionProps}
                            style={{
                              transformOrigin: placement === "bottom" ? "center top" : "center bottom",
                            }}
                          >
                            <Paper>
                              <ClickAwayListener onClickAway={handleClose}>
                                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                  {checkOperationPermission(
                                    props.authState.operationsList,
                                    OPERATIONS.PROFESSIONAL_KITS_LIST_ITEM
                                  ) && (
                                    <MenuItem
                                      className={classes.dropItems}
                                      onClick={handleClose}
                                      component={Link}
                                      to="/admin/kit-management"
                                    >
                                      {intl.formatMessage(
                                        titles.Title_AdminNavBar_ProfessionalKitsMenu_KitsListMenuItem
                                      )}
                                    </MenuItem>
                                  )}
                                  {checkOperationPermission(props.authState.operationsList, OPERATIONS.PROFESSIONAL_KITS_CHARACTERIZE_KIT_ITEM) && (
                                    <MenuItem
                                      className={classes.dropItems}
                                      onClick={handleClose}
                                      component={Link}
                                      to="/admin/characterize-kit"
                                    >
                                      {intl.formatMessage(
                                        titles.Title_AdminNavBar_ProfessionalKitsMenu_CharacterizeKitMenuItem
                                      )}
                                    </MenuItem>
                                  )}
                                  {checkOperationPermission(props.authState.operationsList, OPERATIONS.PROFESSIONAL_KITS_REGISTER_LOSS_KIT_ITEM) && (
                                    <MenuItem className={classes.dropItems} onClick={handleRegisterLoss}>
                                      {intl.formatMessage(
                                        titles.Title_AdminNavBar_ProfessionalKitsMenu_RegisterLossMenuItem
                                      )}
                                    </MenuItem>
                                  )}
                                  {checkOperationPermission(
                                    props.authState.operationsList,
                                    OPERATIONS.PROFESSIONAL_KITS_REGISTER_IN_WAREHOUSE_ITEM
                                  ) && (
                                    <MenuItem className={classes.dropItems} onClick={handleRegisterWarehouse}>
                                      {intl.formatMessage(
                                        titles.Title_AdminNavBar_ProfessionalKitsMenu_RegisterInWarehouseMenuItem
                                      )}
                                    </MenuItem>
                                  )}
                                  {checkOperationPermission(props.authState.operationsList, OPERATIONS.PROFESSIONAL_KITS_STOCKS_ITEM) && (
                                    <MenuItem
                                      className={classes.dropItems}
                                      onClick={handleClose}
                                      component={Link}
                                      to="/admin/stocks-management"
                                    >
                                      {intl.formatMessage(titles.Title_AdminNavBar_ProfessionalKitsMenu_StocksMenuItem)}
                                    </MenuItem>
                                  )}
                                  {checkOperationPermission(props.authState.operationsList, OPERATIONS.PROFESSIONAL_KITS_DELIVERY_ITEM) && (
                                    <MenuItem className={classes.dropItems} onClick={handleDeliverKit}>
                                      {intl.formatMessage(
                                        titles.Title_AdminNavBar_ProfessionalKitsMenu_KitDeliveryMenuItem
                                      )}
                                    </MenuItem>
                                  )}
                                </MenuList>
                              </ClickAwayListener>
                            </Paper>
                          </Grow>
                        )}
                      </Popper>
                    </ListItem>
                  )}
                </Box>
              </Hidden>

              {/* Subprogramas */}
              <Hidden lgDown implementation="css">
                <Box>
                  {checkOperationPermission(props.authState.operationsList, OPERATIONS.SUBPROGRAMS_MENU) && (
                    <ListItem
                      classes={{
                        root: classes.listItemRoot,
                      }}
                      ref={anchorRefSub}
                      aria-controls={openSub ? "menu-list-grow" : undefined}
                      aria-haspopup="true"
                      onClick={handleToggleSub}
                    >
                      {intl.formatMessage(titles.Title_AdminNavBar_SubprogramasMenu)}
                      <ListItemIcon className={classes.dropIcon}>
                        <ExpandMoreIcon />
                      </ListItemIcon>
                      <Popper 
                        open={openSub} 
                        anchorEl={anchorRefSub.current} 
                        role={undefined} 
                        transition 
                        disablePortal
                        className={classes.dropMenu}
                      >
                        {({ TransitionProps, placement }) => (
                          <Grow
                            {...TransitionProps}
                            style={{
                              transformOrigin: placement === "bottom" ? "center top" : "center bottom",
                            }}
                          >
                            <Paper>
                              <ClickAwayListener onClickAway={handleCloseSub}>
                                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                  {checkOperationPermission(
                                    props.authState.operationsList,
                                    OPERATIONS.SUBPROGRAMS_CHARACTERIZE_SUBPROGRAM_ITEM
                                  ) && (
                                    <MenuItem
                                      component={Link}
                                      to="/admin/subprograms-create"
                                      className={classes.dropItems}
                                      onClick={handleCloseSub}
                                    >
                                      {intl.formatMessage(titles.Title_AdminNavBar_CharacterizeSubprogramMenuItem)}
                                    </MenuItem>
                                  )}
                                  {checkOperationPermission(props.authState.operationsList, OPERATIONS.SUBPROGRAMS_LIST_ITEM) && (
                                    <MenuItem
                                      component={Link}
                                      to="/admin/subprograms-management"
                                      className={classes.dropItems}
                                      onClick={handleCloseSub}
                                    >
                                      {intl.formatMessage(titles.Title_AdminNavBar_ListaSubprogramasMenuItem)}
                                    </MenuItem>
                                  )}
                                </MenuList>
                              </ClickAwayListener>
                            </Paper>
                          </Grow>
                        )}
                      </Popper>
                    </ListItem>
                  )}
                </Box>
              </Hidden>

              {/* Emprego e Estágio */}
              <Hidden lgDown implementation="css">
                <Box>
                  {checkOperationPermission(props.authState.operationsList, OPERATIONS.OPPORTUNITIES_MENU) && (
                    <ListItem
                      classes={{
                        root: classes.listItemRoot,
                      }}
                      ref={anchorRefOpportunity}
                      aria-controls={openOpportunity ? "menu-list-grow" : undefined}
                      aria-haspopup="true"
                      onClick={handleToggleOpportunity}
                    >
                      {intl.formatMessage(titles.Title_AdminNavBar_Opportunity)}
                      <ListItemIcon className={classes.dropIcon}>
                        <ExpandMoreIcon />
                      </ListItemIcon>
                      <Popper
                        open={openOpportunity}
                        anchorEl={anchorRefOpportunity.current}
                        role={undefined}
                        transition
                        disablePortal
                        className={classes.dropMenu}
                      >
                        {({ TransitionProps, placement }) => (
                          <Grow
                            {...TransitionProps}
                            style={{
                              transformOrigin: placement === "bottom" ? "center top" : "center bottom",
                            }}
                          >
                            <Paper>
                              <ClickAwayListener onClickAway={handleCloseOpportunity}>
                                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                  {checkOperationPermission(props.authState.operationsList, OPERATIONS.OPPORTUNITIES_CREATE_ITEM) && (
                                    <MenuItem
                                      component={Link}
                                      to="/admin/opportunity-create"
                                      className={classes.dropItems}
                                      onClick={handleCloseOpportunity}
                                    >
                                      {intl.formatMessage(titles.Title_AdminNavBar_OpportunityCreateMenuItem)}
                                    </MenuItem>
                                  )}
                                  {checkOperationPermission(props.authState.operationsList, OPERATIONS.OPPORTUNITIES_CANDIDATES_LIST_ITEM) && (
                                    <MenuItem
                                      component={Link}
                                      to="/admin/opportunity-management"
                                      className={classes.dropItems}
                                      onClick={handleCloseOpportunity}
                                    >
                                      {intl.formatMessage(titles.Title_AdminNavBar_ListOpportunitiesMenuItem)}
                                    </MenuItem>
                                  )}
                                  {checkOperationPermission(props.authState.operationsList, OPERATIONS.OPPORTUNITIES_APPLICATIONS_LIST_ITEM) && (
                                    <MenuItem
                                      component={Link}
                                      to="/admin/opportunity-applications-management"
                                      className={classes.dropItems}
                                      onClick={handleCloseOpportunity}
                                    >
                                      {intl.formatMessage(titles.Title_AdminNavBar_ListOpportunitiesApplicationsMenuItem)}
                                    </MenuItem>
                                  )}
                                  {checkOperationPermission(props.authState.operationsList, OPERATIONS.OPPORTUNITIES_APPLICATIONS_FOLLOWUP_LIST_ITEM) && (
                                    <MenuItem
                                      component={Link}
                                      to="/admin/opportunity-applications-followup-management"
                                      className={classes.dropItems}
                                      onClick={handleCloseOpportunity}
                                    >
                                      {intl.formatMessage(titles.Title_AdminNavBar_ListOpportunitiesApplicationsFollowUpMenuItem)}
                                    </MenuItem>
                                  )}
                                </MenuList>
                              </ClickAwayListener>
                            </Paper>
                          </Grow>
                        )}
                      </Popper>
                    </ListItem>
                  )}
                </Box>
              </Hidden>

              {/* Bancos */}
              <Hidden lgDown implementation="css">
                <Box>
                  {checkOperationPermission(props.authState.operationsList, OPERATIONS.BANKS_MENU) && (
                    <ListItem
                      component={Link}
                      to="/admin/loans-management"
                      classes={{
                        root: classes.listItemRoot,
                      }}
                    >
                      {intl.formatMessage(titles.Title_AdminNavBar_Bancos)}
                    </ListItem>
                  )}
                </Box>
              </Hidden>

              {/* Gestão de Utilizadores */}
              <Hidden lgDown implementation="css">
                <Box>
                  {checkOperationPermission(props.authState.operationsList, OPERATIONS.USER_MANAGEMENT_MENU) && (
                    <ListItem
                      classes={{
                        root: classes.listItemRoot,
                      }}
                      ref={anchorRefUsers}
                      aria-controls={openUsers ? "menu-list-grow" : undefined}
                      aria-haspopup="true"
                      onClick={handleToggleUsers}
                    >
                      {intl.formatMessage(titles.Title_AdminNavBar_ManageUsersMenu)}
                      <ListItemIcon className={classes.dropIcon}>
                        <ExpandMoreIcon />
                      </ListItemIcon>
                      <Popper
                        open={openUsers}
                        anchorEl={anchorRefUsers.current}
                        role={undefined}
                        transition
                        disablePortal
                        className={classes.dropMenu}
                      >
                        {({ TransitionProps, placement }) => (
                          <Grow
                            {...TransitionProps}
                            style={{
                              transformOrigin: placement === "bottom" ? "center top" : "center bottom",
                            }}
                          >
                            <Paper>
                              <ClickAwayListener onClickAway={handleCloseUsers}>
                                <MenuList autoFocusItem={openUsers} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                  {checkOperationPermission(props.authState.operationsList, OPERATIONS.USER_MANAGEMENT_BACKOFFICE_ITEM) && (
                                    <MenuItem
                                      component={Link}
                                      to="/admin/backofficeusers-management"
                                      className={classes.dropItems}
                                      onClick={handleCloseUsers}
                                    >
                                      {intl.formatMessage(titles.Title_AdminNavBar_ManageUsersMenuItemBackOffice)}
                                    </MenuItem>
                                  )}
                                  {checkOperationPermission(props.authState.operationsList, OPERATIONS.USER_MANAGEMENT_FRONTOFFICE_ITEM) && (
                                    <MenuItem
                                      component={Link}
                                      to="/admin/frontofficeusers-management"
                                      className={classes.dropItems}
                                      onClick={handleCloseUsers}
                                    >
                                      {intl.formatMessage(titles.Title_AdminNavBar_ManageUsersMenuItemFrontOffice)}
                                    </MenuItem>
                                  )}
                                </MenuList>
                              </ClickAwayListener>
                            </Paper>
                          </Grow>
                        )}
                      </Popper>
                    </ListItem>
                  )}
                </Box>
              </Hidden>

              {/* Informação */}
              <Hidden lgDown implementation="css">
                <Box>
                  {checkOperationPermission(props.authState.operationsList, OPERATIONS.INFORMATION_MENU) && (
                    <ListItem
                      classes={{
                        root: classes.listItemRoot,
                      }}
                      ref={anchorRefInfoDissemination}
                      aria-controls={openInfoDissemination ? "menu-list-grow" : undefined}
                      aria-haspopup="true"
                      onClick={handleToggleInfoDissemination}
                    >
                      {intl.formatMessage(titles.Title_AdminNavBar_InformationDissemination)}
                      <ListItemIcon className={classes.dropIcon}>
                        <ExpandMoreIcon />
                      </ListItemIcon>
                      <Popper
                        open={openInfoDissemination}
                        anchorEl={anchorRefInfoDissemination.current}
                        role={undefined}
                        transition
                        disablePortal
                        className={classes.dropMenu}
                      >
                        {({ TransitionProps, placement }) => (
                          <Grow
                            {...TransitionProps}
                            style={{
                              transformOrigin: placement === "bottom" ? "center top" : "center bottom",
                            }}
                          >
                            <Paper>
                              <ClickAwayListener onClickAway={handleCloseInfoDissemination}>
                                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                  {checkOperationPermission(
                                    props.authState.operationsList,
                                    OPERATIONS.INFORMATION_CREATE_ITEM
                                  ) && (
                                    <MenuItem
                                      component={Link}
                                      to="/admin/informationdissemination-create"
                                      className={classes.dropItems}
                                      onClick={handleCloseInfoDissemination}
                                    >
                                      {intl.formatMessage(
                                        titles.Title_AdminNavBar_CreateInformationDisseminationMenuItem
                                      )}
                                    </MenuItem>
                                  )}
                                  {checkOperationPermission(props.authState.operationsList, OPERATIONS.INFORMATION_LIST_ITEM) && (
                                    <MenuItem
                                      component={Link}
                                      to="/admin/informationdissemination-management"
                                      className={classes.dropItems}
                                      onClick={handleCloseInfoDissemination}
                                    >
                                      {intl.formatMessage(
                                        titles.Title_AdminNavBar_ListaInformationDisseminationMenuItem
                                      )}
                                    </MenuItem>
                                  )}
                                </MenuList>
                              </ClickAwayListener>
                            </Paper>
                          </Grow>
                        )}
                      </Popper>
                    </ListItem>
                  )}
                </Box>
              </Hidden>

              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                marginLeft="auto"
                className={clsx(classes.marginLeftAuto, {
                  [classes.displayNone]: showSearch,
                })}
              >
                <NotificationsDropdown />
              </Box>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                className={clsx(classes.marginLeftNone, {
                  [classes.displayNone]: showSearch,
                })}
              >
                <UserDropdown />
              </Box>
            </Box>

            {/* Registar Perda */}
            <GenericDialog maxWidth={"xl"} open={getShowRegisterLossBreakModal()} onClose={handleCloseLoss}>
              <RegisterLossBreak closeModal={handleCloseLoss} />
            </GenericDialog>

            {/* Registo em Armazém */}
            <GenericDialog maxWidth={"xl"} open={getShowRegisterInWareHouseModal()} onClose={handleCloseWarehouse}>
              <RegisterStockInWarehouse closeModal={handleCloseWarehouse} />
            </GenericDialog>

            {/* Entrega de Kit */}
            <GenericDialog maxWidth={"xl"} open={getShowDeliverKitModal()} onClose={handleCloseDeliverKit}>
              <DeliverKit closeModal={handleCloseDeliverKit} />
            </GenericDialog>

            {/* Register kit in warehouse Result Modal Start*/}
            <Dialog
              open={getShowRegisterKitInWarehouseResultModal()}
              classes={{ paper: classes.modalResult }}
              onClose={handleRegisterKitInWarehouseResulClose}
              aria-labelledby="form-dialog-title"
            >
              <IconButton
                edge="start"
                onClick={handleRegisterKitInWarehouseResulClose}
                classes={{ root: classes.modalResultClose }}
              >
                <CloseIcon />
              </IconButton>
              <CardContent classes={{ root: classes.cardContentKitCharacterizationResult }}>
                <Box component={Grid} alignItems="center">
                  {props.stockState.registerInWarehouseResult ? (
                    <>
                      <CheckCircleOutlinedIcon classes={{ root: classes.modalResultIcon }} />
                      <Typography classes={{ root: classes.modalResultHeader }}>
                        O seu registo foi realizado com sucesso
                      </Typography>
                      <GenericButton
                        typeSubmit={false}
                        color="primary"
                        onClick={function () {
                          handleRegisterKitInWarehouseResulClose();
                        }}
                        classes={classes.centeredButton}
                      >
                        OK
                      </GenericButton>
                    </>
                  ) : (
                    <>
                      <CancelOutlinedIcon classes={{ root: classes.modalErrorResultIcon }} />
                      <Typography classes={{ root: classes.modalResultHeader }}>
                        Erro no registo, por favor verifique a informação introduzida e volte a tentar.
                      </Typography>
                      <GenericButton
                        typeSubmit={false}
                        color="primary"
                        onClick={function () {
                          handleRegisterKitInWarehouseResulClose();
                        }}
                        classes={classes.centeredButton}
                      >
                        OK
                      </GenericButton>
                    </>
                  )}
                </Box>
              </CardContent>
            </Dialog>
            {/* Register kit in warehouse Result Modal End*/}
            {/* Generic dialog START*/}
            <CustomDialog />
            {/* Generic dialog END*/}
          </Container>
        </Toolbar>
        <SearchBarNavbar/>
      </AppBar>
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  hideKitCharacterizationResultModal: () => dispatch(hideKitCharacterizationResultModalAction()),
  hideRegisterStockInWarehouseResultModal: () => dispatch(hideRegisterStockInWarehouseResultModalAction()),
  showRegisterLossBreakModal: (toShow) => dispatch(showRegisterLossBreakModalAction(toShow)),
  showRegisterInWareHouseModal: (toShow) => dispatch(showRegisterInWareHouseModalAction(toShow)),
  hideDeliverKitResultModal: () => dispatch(hideDeliverKitResultModalAction()),
  showDeliverKitModal: (toShow) => dispatch(showDeliverKitModalAction(toShow)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminNavbar);
