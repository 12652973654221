const componentStyles = (theme) => ({
    searchBar: {
        width: "100%", 
        height: "60px",
        padding: "20px 15px 20px 15px",
        position: "fixed",
        zIndex: 100,
    },
    searchIcon: {
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'right',
    },
    searchIconRoot: {
        height: "25px",
        width: "25px",
        padding: "2px"
    },
    searchIconButton: {
        padding: "5px",
        marginTop: "5px"
    },
    inputRoot: {
        color: 'inherit',
        width: "100%",
        "& .MuiInputBase-input": {
            border: "none"
        },
    },
    searchFieldDiv: {
        overflow: "hidden",
    },
    searchIconDiv: {
        float: "right",
        width: "190px",
        marginLeft: "10px"
    },
    advancedSearchDiv: {
        float: "right",
        width: "155px",
    },
    searchResults: {
        backgroundColor: theme.palette.white.main,
        marginTop: "1px", 
        borderRadius: "0.375rem",
        borderStyle: "solid",
        borderColor: theme.palette.transparent.main,
    },
    searchResultsButton: {
        width: "100%", 
        display: "flex", 
        justifyContent: "left", 
        border: "none", 
        padding: "5px 10px 5px 10px",
        borderRadius: "0.375rem",
        borderStyle: "solid",
        borderColor: theme.palette.transparent.main,
        backgroundColor: theme.palette.white.main,
        "&:hover": {
            backgroundColor: theme.palette.primary.greyBlue,
            color: theme.palette.white.main
        },
        "&:focus": {
            backgroundColor: theme.palette.primary.darkBlue,
            color: theme.palette.white.main
        },
        fontSize: "14px",
    },
    circularProgress: {
        color: theme.palette.gray[300],
        position: "absolute",
        marginTop: '0.43rem',
        marginLeft: '-2.39rem',
    },
    advancedSearchButton: {
        backgroundColor: theme.palette.transparent.main,
        border: "none",
        color: theme.palette.white.main,
        fontSize: "14px",
        float: "right",
        marginTop: "0.65rem",
        marginRight: "0.4rem",
        "&:hover": {
            fontWeight: "600"
        },
    }
  });
  
  export default componentStyles;
  