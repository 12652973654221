import { React, useEffect, useState } from "react";
import { connect } from "react-redux";
import clsx from "clsx";
import { useForm, Controller } from "react-hook-form";
import { Link,useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import ReactQuill from "react-quill";
// @material-ui/core components
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Container from "@material-ui/core/Container";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Grid from "@material-ui/core/Grid";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Switch from "@material-ui/core/Switch";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
// @material-ui/icons components
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
//import custom components
import GenericFormSelect from "components/FormFields/GenericFormSelect";
import GenericButton from "components/Buttons/GenericButton";
import DropzoneField from "components/FormFields/DropzoneField";
import EmbeddedVideosField from "components/FormFields/EmbeddedVideosField";
import { informationDisseminationMap } from "mappers/informationDisseminationMap";
import { getDataSetByNameAction } from "redux/actions/datasetsAction";
import { createInformationAction, updateInformationAction } from "redux/actions/informationDisseminationAction";
import { DATASET, INFORMATION_DISSEMINATION_TYPE_KEYVALUE } from "utils/consts";
import { messages, labels } from "resources/resources";

import componentStylesGeneric from "assets/theme/views/admin/generic.js";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function CreateEditSection(props) {
  const classes = useStylesGeneric();
  const theme = useTheme();
  const history = useHistory();
  const intl = useIntl();
  const [statePublic, setStatePublic] = useState(false);
  const [statePublished, setStatePublished] = useState(false);
  const [stateHighlighted, setStateHighlighted] = useState(false);
  const [photosList, setPhotosList] = useState();
  const [videoUrlList, setVideoUrlList] = useState();
  const [mediaToDeleteList, setMediaToDeleteList] = useState([]);
  const {
    handleSubmit,
    trigger,
    register,
    getValues,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  const socialMediaPrefix = 'socialMedia_'

  useEffect(() => {
    if (
      props.dataSetState[DATASET.INFORMATION_DISSEMINATION_TYPE] === undefined ||
      props.dataSetState[DATASET.INFORMATION_DISSEMINATION_TYPE]?.length === 0
    ) {
      props.getDataSet(DATASET.INFORMATION_DISSEMINATION_TYPE);
    }

    if (
      props.dataSetState[DATASET.SOCIAL_MEDIA_TYPE] === undefined ||
      props.dataSetState[DATASET.SOCIAL_MEDIA_TYPE]?.length === 0
    ) {
      props.getDataSet(DATASET.SOCIAL_MEDIA_TYPE);
    }

    setValue("informationDisseminationIsPublic", false);
    setValue("informationDisseminationDescription", null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.isEditable && props.informationDetailsData?.alternateKey !== undefined) {
      setValue(
        "informationDisseminationType",
        props.dataSetState[DATASET.INFORMATION_DISSEMINATION_TYPE] !== undefined
          ? props.dataSetState[DATASET.INFORMATION_DISSEMINATION_TYPE].find(
              (r) => r.code === props.informationDetailsData.informationDisseminationTypeId
            )
          : []
      );
      setValue("informationDisseminationTitle", props.informationDetailsData.title);
      setValue("informationDisseminationDescription", props.informationDetailsData.description);
      setValue("informationDisseminationIsPublic", props.informationDetailsData.isPublic);
      setValue("informationDisseminationPublished", props.informationDetailsData.published);
      setValue("informationDisseminationHighlighted", props.informationDetailsData.isHighlighted);

      setPhotosList(
        props.informationDetailsData.mediaList
          .filter((media) => media.photo !== null)
          .map((photoObj) => getDataPhoto(photoObj))
      );
      setVideoUrlList(
        props.informationDetailsData.mediaList
          .filter((media) => media.url !== null)
          .map((videoObj) => getDataVideo(videoObj))
      );

      props.dataSetState?.SocialMediaType?.forEach((item) => {
        const url = props.informationDetailsData?.socialMediaList?.find((socialMedia) => socialMedia.sociaMediaTypeKeyValue === item.keyValue)?.url
        setValue(socialMediaPrefix + item.keyValue, url === undefined ? '' : url);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.informationDetailsData]);

  function getDataPhoto(object) {
    return { id: object.id, photo: object.photo };
  }

  function getDataVideo(object) {
    return { id: object.id, url: object.url };
  }

  useEffect(() => {
    setStatePublished(
      props.informationDetailsData !== undefined && props.informationDetailsData?.published !== undefined
        ? props.informationDetailsData.published
        : false
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.informationDetailsData?.published]);

  useEffect(() => {
    setStatePublic(
      props.informationDetailsData !== undefined && props.informationDetailsData?.isPublic !== undefined
        ? props.informationDetailsData.isPublic
        : false
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.informationDetailsData?.isPublic]);

  useEffect(() => {
    setStateHighlighted(
      props.informationDetailsData !== undefined && props.informationDetailsData?.isHighlighted !== undefined
        ? props.informationDetailsData.isHighlighted
        : false
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.informationDetailsData?.isHighlighted]);

  const handleCancelOnClickEvent = () => {
    history.goBack();
  };

  const handleChangePublic = (event) => {
    setStatePublic(event.target.checked);
  };

  const handleChangePublished = (event) => {
    setStatePublished(event.target.checked);
  };

  const handleChangeHighlighted = (event) => {
    setStateHighlighted(event.target.checked);
  };

  const handleMediaDelete = (mediaId) => {
    setMediaToDeleteList((prevState) => {
      return [...prevState, mediaId];
    });
  };

  function handleRequiredPhoto() {
    if (getValues("informationDisseminationType") !== undefined) {
      if (getValues("informationDisseminationType")?.keyValue === INFORMATION_DISSEMINATION_TYPE_KEYVALUE.LEGISLATION) {
        return false;
      }
    }
    if (
      props.informationDetailsData?.mediaList !== null &&
      props.informationDetailsData?.mediaList !== undefined &&
      props.informationDetailsData?.mediaList?.length > 0
    ) {
      return false;
    }
    return true;
  }

  function validateDescriptionContent(desc) {
    let span = document.createElement("span");
    span.innerHTML = desc;
    let res = span.textContent.replace(/\s/g, "") !== "";

    return res;
  }

  function isInformationType() {
    if (getValues("informationDisseminationType")?.keyValue === INFORMATION_DISSEMINATION_TYPE_KEYVALUE.INFORMATION)
      return true;
    return false;
  }

  const onSubmit = (data) => {
    data["informationDisseminationAlternateKey"] = props.informationDetailsData?.alternateKey;

    let socialMediaList = [];

    Object.keys(data).forEach(key => {
      if(key.includes(socialMediaPrefix)) 
        socialMediaList.push({ 
          sociaMediaTypeKeyValue: props.dataSetState?.SocialMediaType?.find(element => element.keyValue === key.replace(socialMediaPrefix, ''))?.keyValue, 
          url: data[key]
      });
    });

    data["socialMediaList"] = socialMediaList;

    if (props.isEditable) {
      props.updateInformation(informationDisseminationMap(data, mediaToDeleteList));
    } else {
      props.createInformation(informationDisseminationMap(data));
    }
  };

  const isWebsite = (value) => {
    if(value === undefined || value === null || value === ''){
      return true;
    }

    const websitePattern = /^(https?:\/\/)?(www\.)?[\w-]+(\.[\w-]+)+[/#?]?.*$/i;
   
    return websitePattern.test(value);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Container maxWidth={false}>
          <Card classes={{ root: classes.cardRoot }}>
            <CardHeader
              classes={{ title: classes.cardHeader, action: classes.cardHeaderAction }}
              title={intl.formatMessage(labels.Label_Admin_InformationDissemination_InformationData)}
              action={
                <>
                  {props.isEditable ? (
                    <Typography component="div">
                      <Grid component="label" container alignItems="center" spacing={1}>
                        <Grid item>
                          {statePublished
                            ? intl.formatMessage(labels.Label_Admin_InformationDissemination_Published)
                            : intl.formatMessage(labels.Label_Admin_InformationDissemination_NotPublished)}
                        </Grid>
                        <Grid item>
                          <Controller
                            name="informationDisseminationPublished"
                            control={control}
                            render={({ field: { onChange, name } }) => (
                              <Switch
                                name={name}
                                checked={statePublished}
                                onChange={(e) => {
                                  onChange(e.target.checked);
                                  handleChangePublished(e);
                                }}
                                color="primary"
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </Typography>
                  ) : (
                    ""
                  )}
                </>
              }
            ></CardHeader>
            <CardContent>
              <Grid container style={{ alignItems: "flex-end" }}>
                <Grid item xs={12} sm={12} md={12} lg={3}>
                  <FormGroup>
                    <FormLabel>
                      {intl.formatMessage(labels.Label_Admin_InformationDissemination_InformationCategory)}
                    </FormLabel>
                    <GenericFormSelect
                      control={control}
                      fieldName="informationDisseminationType"
                      selectOptions={
                        props.dataSetState[DATASET.INFORMATION_DISSEMINATION_TYPE] !== undefined
                          ? props.dataSetState[DATASET.INFORMATION_DISSEMINATION_TYPE]
                          : []
                      }
                      getValues={getValues}
                      required={true}
                      requiredMessage={intl.formatMessage(messages.Message_Generic_MandatoryField)}
                      errors={errors}
                      classes={classes}
                      onOptionChange={() => trigger("informationDisseminationType")}
                    />
                    {errors["informationDisseminationType"] !== undefined && (
                      <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                        {errors["informationDisseminationType"].message}
                      </FormHelperText>
                    )}
                  </FormGroup>
                </Grid>

                <Grid item>
                  <FormGroup>
                    <FormLabel>
                      {intl.formatMessage(labels.Label_Admin_InformationDissemination_Authentication)}
                    </FormLabel>

                    <Typography component="div" variant="body2">
                      <Grid component="label" container alignItems="center" spacing={1}>
                        <Grid item>
                          {intl.formatMessage(labels.Label_Admin_InformationDissemination_AuthenticatedUsers)}
                        </Grid>
                        <Grid item>
                          <Controller
                            name="informationDisseminationIsPublic"
                            control={control}
                            render={({ field: { onChange, name } }) => (
                              <Switch
                                name={name}
                                checked={statePublic}
                                onChange={(e) => {
                                  onChange(e.target.checked);
                                  handleChangePublic(e);
                                }}
                                color="primary"
                              />
                            )}
                          />
                        </Grid>
                        <Grid item>
                          {intl.formatMessage(labels.Label_Admin_InformationDissemination_AuthenticationPublic)}
                        </Grid>
                      </Grid>
                    </Typography>
                  </FormGroup>
                </Grid>

                <Grid item>
                  <FormGroup>
                    <FormLabel>{intl.formatMessage(labels.Label_Admin_InformationDissemination_Highlight)}</FormLabel>

                    <Typography component="div" variant="body2">
                      <Grid component="label" container alignItems="center" spacing={1}>
                        <Grid item>
                          {intl.formatMessage(labels.Label_Admin_InformationDissemination_NotHighlighted)}
                        </Grid>
                        <Grid item>
                          <Controller
                            name="informationDisseminationHighlighted"
                            control={control}
                            render={({ field: { onChange, name } }) => (
                              <Switch
                                name={name}
                                checked={stateHighlighted}
                                onChange={(e) => {
                                  onChange(e.target.checked);
                                  handleChangeHighlighted(e);
                                }}
                                color="primary"
                              />
                            )}
                          />
                        </Grid>
                        <Grid item>{intl.formatMessage(labels.Label_Admin_InformationDissemination_Highlighted)}</Grid>
                      </Grid>
                    </Typography>
                  </FormGroup>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <FormGroup>
                    <FormLabel>{intl.formatMessage(labels.Label_Admin_InformationDissemination_Title)}</FormLabel>
                    <Controller
                      name="informationDisseminationTitle"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: {
                          value: true,
                          message: intl.formatMessage(messages.Message_Generic_MandatoryField),
                        },
                      }}
                      render={({ field: { onChange, name, value } }) => (
                        <OutlinedInput
                          name={name}
                          value={value}
                          onChange={onChange}
                          fullWidth
                          autoComplete="off"
                          type="text"
                          classes={{
                            notchedOutline: clsx({
                              [classes.borderWarning]: errors["informationDisseminationTitle"] !== undefined,
                            }),
                          }}
                        />
                      )}
                    />
                    {errors?.informationDisseminationTitle?.message !== undefined && (
                      <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                        {errors?.informationDisseminationTitle?.message}
                      </FormHelperText>
                    )}
                  </FormGroup>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <FormGroup>
                    <FormLabel>{intl.formatMessage(labels.Label_Admin_InformationDissemination_Description)}</FormLabel>
                    <Controller
                      name="informationDisseminationDescription"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: intl.formatMessage(messages.Message_Generic_MandatoryField),
                        },
                        minLength: {
                          value: 10, // minimum length of 3 char, <p></p> has length=7
                          message: intl.formatMessage(messages.Message_Generic_TextFieldLengthTooShort),
                        },
                        validate: {
                          validateEmptyContent: (value) =>
                            validateDescriptionContent(value) ||
                            intl.formatMessage(messages.Message_Generic_MandatoryField),
                        },
                      }}
                      render={({ field: { onChange, name, value } }) => (
                        <ReactQuill
                          className={classes.quillOverflowWrap}
                          theme="snow"
                          name={name}
                          value={value}
                          onChange={(e) => {
                            onChange(e);
                          }}
                        />
                      )}
                    />
                    {errors?.informationDisseminationDescription?.message !== undefined && (
                      <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                        {errors?.informationDisseminationDescription?.message}
                      </FormHelperText>
                    )}
                  </FormGroup>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <FormGroup>
                    <FormLabel>{intl.formatMessage(labels.Label_Admin_InformationDissemination_Images)}</FormLabel>
                    <DropzoneField
                      name={"informationDisseminationPhotoList"}
                      label={"Carregar imagem"}
                      register={register}
                      errors={errors}
                      required={handleRequiredPhoto()}
                      requiredMessage={intl.formatMessage(messages.Message_Generic_MandatoryField)}
                      setValue={setValue}
                      getValues={getValues}
                      trigger={trigger}
                      maxNumberOfFiles={isInformationType() ? 100 : 1}
                      accept={".jpg, .jpeg, .png"}
                      maxFileSize={10}
                      hasPreview={true}
                      showFileNameOnPreview={false}
                      photosPreviewURL={props.isEditable ? photosList : null}
                      isEditable={props.isEditable}
                      handleMediaDelete={handleMediaDelete}
                    />
                  </FormGroup>
                </Grid>
                {isInformationType() && (
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <FormGroup>
                      <FormLabel>
                        {intl.formatMessage(labels.Label_Admin_InformationDissemination_Videos)}
                        <Link to={"/tutorials/Tutorial Galeria de Eventos.webm"} target="_blank" className={classes.linkStandard}>
                                    {" ( ver Tutorial)"}
                                  </Link>
                        <Tooltip
                          classes={{
                            tooltip: classes.tooltipDescritionSizeWidth,
                          }}
                          title={intl.formatMessage(
                            labels.Label_Admin_InformationDissemination_VideoInputFieldInfoIcon
                          )}
                        >
                          <InfoOutlinedIcon fontSize="large" className={classes.tooltipInfoIcon} />
                        </Tooltip>
                      </FormLabel>
                      <EmbeddedVideosField
                        name={"informationDisseminationVideoUrlList"}
                        inputFieldName="url"
                        control={control}
                        setValue={setValue}
                        getValues={getValues}
                        trigger={trigger}
                        errors={errors}
                        maxNumberOfVideoUrls={100}
                        showPreviews={true}
                        videoUrlList={props.isEditable ? videoUrlList : null}
                        isEditable={props.isEditable}
                        handleMediaDelete={handleMediaDelete}
                      />
                    </FormGroup>
                  </Grid>
                )}

                {getValues("informationDisseminationType")?.keyValue === INFORMATION_DISSEMINATION_TYPE_KEYVALUE.STRUCTURING_PROJECT && (
                  (props.dataSetState?.SocialMediaType?.map((socialMediaType, key) => (
                    <Grid item xs={12} sm={12} md={12} xl={6} key={key} style={{height: "100px"}}>
                      <FormGroup>
                        <FormLabel>{intl.formatMessage(labels.Label_Admin_InformationDissemination_SocialMediaLink)} {socialMediaType.label}</FormLabel>
                        <Controller
                          name={socialMediaPrefix + socialMediaType.keyValue}
                          control={control}
                          defaultValue=""
                          rules={{
                            validate: {
                              validateIfWebsite: (value) =>
                                isWebsite(value) ||
                                intl.formatMessage(messages.Message_Generic_InvalidUrl), 
                            },
                          }}
                          render={({ field: { onChange, name, value } }) => (
                            <OutlinedInput
                              name={name}
                              value={value}
                              onChange={onChange}
                              fullWidth
                              autoComplete="off"
                              type="text"
                              classes={{
                                notchedOutline: clsx({
                                  [classes.borderWarning]: errors[socialMediaType.keyValue] !== undefined,
                                }),
                              }}
                            />
                          )}
                        />
                        {errors[socialMediaPrefix + socialMediaType.keyValue]?.message !== undefined && (
                          <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                            {errors[socialMediaPrefix + socialMediaType.keyValue]?.message}
                          </FormHelperText>
                        )}
                      </FormGroup>
                    </Grid>
                  )))
                )}
              </Grid>
            </CardContent>
          </Card>

          <Grid container>
            <Grid item xs={12} lg={6} xl={8}></Grid>
            <Grid item xs={12} lg={3} xl={2}>
              <FormGroup>
                <GenericButton typeSubmit={false} color="secondary" onClick={() => handleCancelOnClickEvent()}>
                  {intl.formatMessage(labels.Label_Generic_Cancel)}
                </GenericButton>
              </FormGroup>
            </Grid>
            <Grid item xs={12} lg={3} xl={2}>
              <FormGroup>
                <GenericButton
                  typeSubmit={true}
                  color="primary"
                  loading={
                    props.informationDisseminationState.createInformation_loading ||
                    props.informationDisseminationState.updateInformation_loading
                  }
                >
                  {props.isEditable ? intl.formatMessage(labels.Label_Generic_Save) : intl.formatMessage(labels.Label_Generic_Submit_Button)}
                </GenericButton>
              </FormGroup>
            </Grid>
          </Grid>
        </Container>
      </form>
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getDataSet: (name, parentKeyValue) => dispatch(getDataSetByNameAction(name, parentKeyValue)),
  createInformation: (data) => dispatch(createInformationAction(data)),
  updateInformation: (data) => dispatch(updateInformationAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateEditSection);
