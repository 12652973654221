import { React, useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getUnreadNotificationAction } from "redux/actions/notificationsAction";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Menu from "@material-ui/core/Menu";
import Typography from "@material-ui/core/Typography";
// @material-ui/icons components
import NotificationsIcon from "@material-ui/icons/Notifications";
import Badge from "@material-ui/core/Badge";
import GenericBackdrop from "components/Backdrops/GenericBackdrop";

// core components
import componentStyles from "assets/theme/components/dropdowns/notifications-dropdown.js";
import componentStylesGeneric from "assets/theme/views/admin/generic";

const useStyles = makeStyles(componentStyles);
const useStylesGeneric = makeStyles(componentStylesGeneric);

function NotificationsDropdown(props) {
  const classes = { ...useStylesGeneric(), ...useStyles() };
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const [filter, setFilter] = useState({
    origin: "",
    lastXNotifications: 5,
    onlyUnread: false,
    message: null,
  });

  const getUnreadNotificationsCount = () => {
    return props.notificationState.unreadNotificationsCount ?? 0;
  };

  const isMenuOpen = Boolean(anchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
    let temp_filter = filter;
    Object.assign(temp_filter, filter);
    temp_filter["origin"] = null;
    temp_filter["lastXNotifications"] = 5;
    temp_filter["onlyUnread"] = false;
    temp_filter["message"] = null;
    console.log(temp_filter);
    setFilter(temp_filter);

    props.getUnreadNotification(temp_filter);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const menuId = "dropdowns-notifications-dropdown-id";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
      classes={{
        list: classes.menuList,
        paper: classes.menuPaper,
      }}
    >
      <Box padding="1rem">
        <Typography variant="h6" component="h6" className={classes.typographyH6}>
          Tens{" "}
          <Box component="strong" color={theme.palette.primary.main}>
            {getUnreadNotificationsCount()}
          </Box>{" "}
          notificações por ler.
        </Typography>
      </Box>
      {props.notificationState.unreadNotifications !== undefined &&
        props.notificationState.unreadNotifications?.length > 0 && (
          <List disablePadding>
            {props.notificationState.unreadNotifications.map((prop, key) => {
              return (
                <ListItem key={key} className={classes.listItemRoot} component="a">
                  <Grid container alignItems="center">
                    <Box
                      flex="0 0 auto"
                      width="auto"
                      maxWidth="100%"
                      position="relative"
                      paddingRight="15px"
                      paddingLeft="15px"
                      minHeight="1px"
                    >
                      <Avatar
                        alt="..."
                        src={prop.image}
                        classes={{
                          root: classes.avatarRoot,
                        }}
                      />
                    </Box>
                    <Box
                      flexBasis="0"
                      flexGrow="1"
                      width="100%;"
                      maxWidth="100%"
                      position="relative"
                      paddingRight="15px"
                      paddingLeft="15px"
                      minHeight="1px"
                    >
                      <Box display="flex" alignItems="center">
                        <Box>
                          <Typography className={classes.typographyOrigin}>{prop.origin ?? "BackOffice"}</Typography>
                        </Box>
                        <Box marginLeft="5px">
                          <Typography className={classes.typographySentBy}>{prop.sentBy}</Typography>
                        </Box>
                      </Box>
                      <Box className={classes.typographySubject}>{prop.title}</Box>
                    </Box>
                  </Grid>
                </ListItem>
              );
            })}
          </List>
        )}
      <Box
        component={Link}
        className={classes.boxAnchor}
        to="/admin/notifications"
        onClick={handleMenuClose}
      >
        Ver todas
      </Box>
      <GenericBackdrop open={props.notificationState.unreadNotifications_Loading} />
    </Menu>
  );

  return (
    <>
      <IconButton
        aria-label="account of current user"
        aria-controls={menuId}
        aria-haspopup="true"
        onClick={handleProfileMenuOpen}
        classes={{
            root: classes.notificationsLargeBell + " " + classes.iconButtonOutlined
        }}
        style={{marginLeft: "15px"}}
      >
        <Badge badgeContent={getUnreadNotificationsCount()} classes={{ badge: classes.notificationBadge }}>
          <NotificationsIcon className={classes.notificationsLargeBell + " " + classes.iconButtonOutlined} />
        </Badge>
      </IconButton>

      {renderMenu}
    </>
  );
}
const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getUnreadNotification: (formData) => dispatch(getUnreadNotificationAction(formData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsDropdown);
