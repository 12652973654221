import { React, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { useIntl } from "react-intl";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Grid from "@material-ui/core/Grid";

import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import { Card, CardHeader, CardContent } from "@material-ui/core";
// components
import GenericButton from "components/Buttons/GenericButton";
//import custom components
import FormSelect from "components/FormFields/FormSelect";
import componentStylesGeneric from "assets/theme/views/admin/generic";
import { getAllOpportunityApplicationsFollowUpAction } from "redux/actions/opportunitiesAction";
import { getDataSetByNameAction } from "redux/actions/datasetsAction";
import { PAGINATION, DATASET } from "utils/consts";
import { getAllOpportunityApplicationsFollowUpMap } from "mappers/opportunityMap";
import { getDatasetStateByName } from "utils/datasets";
import { labels } from "resources/resources";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function OpportunityApplicationsFollowUpFilterSection(props) {
  const classes = useStylesGeneric();
  const intl = useIntl();
  const [filter, setFilter] = useState({
    applicationCode: null,
    searchText: null,
    applicationFollowUpState: null,
    userNameOrIdentityCard: null,
    companyName: null,
    companyTaxIdNumber: null,
    hasUnapprovedFeedbackReport: props.match?.params?.hasUnapprovedFeedbackReport === "1" ? true : null,
    pageIndex: PAGINATION.FIRST_PAGE,
    pageSize: PAGINATION.PAGE_SIZE,
    userCode: "",
  });
  const [filterCounter, setFilterCounter] = useState(0);

  const {
    handleSubmit,
    setValue,
    getValues,
    control,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  useEffect(() => {
    let count = 0;

    Object.keys(filter).map((key) => {
      if (filter[key] && key !== "pageIndex" && key !== "pageSize") {
        count += 1;
      }

      return count;
    });

    setFilterCounter(count);
  }, [filter]);

  useEffect(() => {
    if (
      props.dataSetState[DATASET.OPPORTUNITY_CANDIDATE_FOLLOWUP_STATE] === undefined ||
      props.dataSetState[DATASET.OPPORTUNITY_CANDIDATE_FOLLOWUP_STATE]?.length === 0
    ) {
      props.getDataSetByName(DATASET.OPPORTUNITY_CANDIDATE_FOLLOWUP_STATE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    refreshResults();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.page]);

  const handleSetFilter = (filterKey, value, clearChildKeyList) => {
    const newFilter = { ...filter, [filterKey]: value };

    clearChildKeyList?.forEach((child) => {
      newFilter[child] = null;
    });

    setFilter(newFilter);
  };

  useEffect(() => {
    if (props.match?.params?.hasUnapprovedFeedbackReport === "1") {
      setValue("hasUnapprovedFeedbackReport", {
        value: true,
        label: "Sim",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.match?.params?.hasUnapprovedFeedbackReport]);

  const cleanFilter = () => {
    setValue("applicationCode", "");
    setValue("searchText", "");
    setValue("applicationFollowUpState", undefined);
    setValue("userNameOrIdentityCard", "");
    setValue("companyName", "");
    setValue("companyTaxIdNumber", "");
    setValue("hasUnapprovedFeedbackReport", undefined);
    setValue("userCode", "");

    setFilterCounter(0);
    setFilter(0);
  };

  //Refresh Results
  const refreshResults = () => {
    let temp_filter = { ...filter, pageIndex: props.page };
    setFilter(temp_filter);
    props.getAllOpportunityApplicationsFollowUp(temp_filter);
  };

  const onSubmit = (data) => {
    let filter = getAllOpportunityApplicationsFollowUpMap(data);

    setFilter(filter);

    if (props.page === PAGINATION.FIRST_PAGE) {
      props.getAllOpportunityApplicationsFollowUp(filter);
    } else {
      props.handleChangePage(PAGINATION.FIRST_PAGE);
    }
  };

  return (
    <Card classes={{ root: classes.cardRoot }}>
      <CardHeader
        classes={{
          action: classes.cardHeaderAction,
          title: classes.cardHeader,
        }}
        title={
          <div>
            <div className={classes.sideBySideDiv}>Pesquisa de Estágios</div>
            <div className={classes.sideBySideDiv + " " + classes.cardElementsCount}>
              Filtros {"("}
              {filterCounter}
              {")"}
            </div>
          </div>
        }
      ></CardHeader>
      <CardContent>
        <Box>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container classes={{ root: classes.containerPadding }}>
              <Grid item xs={12} sm={6} md={3}>
                <FormGroup>
                  <FormLabel>Nº Candidatura</FormLabel>
                  <Controller
                    name="applicationCode"
                    control={control}
                    defaultValue=""
                    component={Box}
                    render={({ field: { onChange, name, value } }) => (
                      <OutlinedInput
                        name={name}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          handleSetFilter("applicationCode", e.target.value);
                        }}
                        fullWidth
                        autoComplete="off"
                        type="text"
                        placeholder={intl.formatMessage(labels.Label_Search_Filters_Placeholder)}
                        endAdornment={
                          <InputAdornment position="end">
                            <SearchIcon />
                          </InputAdornment>
                        }
                      />
                    )}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <FormGroup>
                  <FormLabel>Pesquisa (título/descrição)</FormLabel>
                  <Controller
                    name="searchText"
                    control={control}
                    defaultValue=""
                    component={Box}
                    render={({ field: { onChange, name, value } }) => (
                      <OutlinedInput
                        name={name}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          handleSetFilter("searchText", e.target.value);
                        }}
                        fullWidth
                        autoComplete="off"
                        type="text"
                        placeholder={intl.formatMessage(labels.Label_Search_Filters_Placeholder)}
                        endAdornment={
                          <InputAdornment position="end">
                            <SearchIcon />
                          </InputAdornment>
                        }
                      />
                    )}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <FormGroup>
                  <FormLabel>Nome ou Nº BI</FormLabel>
                  <Controller
                    name="userNameOrIdentityCard"
                    control={control}
                    defaultValue=""
                    component={Box}
                    render={({ field: { onChange, name, value } }) => (
                      <OutlinedInput
                        name={name}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          handleSetFilter("numApplication", e.target.value);
                        }}
                        fullWidth
                        autoComplete="off"
                        type="text"
                        placeholder={intl.formatMessage(labels.Label_Search_Filters_Placeholder)}
                        endAdornment={
                          <InputAdornment position="end">
                            <SearchIcon />
                          </InputAdornment>
                        }
                      />
                    )}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <FormGroup>
                  <FormLabel>Cód. Utilizador</FormLabel>
                  <Controller
                    name="userCode"
                    control={control}
                    defaultValue=""
                    component={Box}
                    render={({ field: { onChange, name, value } }) => (
                      <OutlinedInput
                        name={name}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          handleSetFilter("userCode", e.target.value);
                        }}
                        fullWidth
                        autoComplete="off"
                        type="text"
                        placeholder={intl.formatMessage(labels.Label_Search_Filters_Placeholder)}
                        endAdornment={
                          <InputAdornment position="end">
                            <SearchIcon />
                          </InputAdornment>
                        }
                      />
                    )}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <FormGroup>
                  <FormLabel>Empresa</FormLabel>
                  <Controller
                    name="companyName"
                    control={control}
                    defaultValue=""
                    component={Box}
                    render={({ field: { onChange, name, value } }) => (
                      <OutlinedInput
                        name={name}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          handleSetFilter("companyName", e.target.value);
                        }}
                        fullWidth
                        autoComplete="off"
                        type="text"
                        placeholder={intl.formatMessage(labels.Label_Search_Filters_Placeholder)}
                        endAdornment={
                          <InputAdornment position="end">
                            <SearchIcon />
                          </InputAdornment>
                        }
                      />
                    )}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <FormGroup>
                  <FormLabel>NIF</FormLabel>
                  <Controller
                    name="companyTaxIdNumber"
                    control={control}
                    defaultValue=""
                    component={Box}
                    render={({ field: { onChange, name, value } }) => (
                      <OutlinedInput
                        name={name}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          handleSetFilter("companyTexIdNumber", e.target.value);
                        }}
                        fullWidth
                        autoComplete="off"
                        type="text"
                        placeholder={intl.formatMessage(labels.Label_Search_Filters_Placeholder)}
                        endAdornment={
                          <InputAdornment position="end">
                            <SearchIcon />
                          </InputAdornment>
                        }
                      />
                    )}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <FormGroup>
                  <FormLabel>Estado</FormLabel>
                  <FormSelect
                    control={control}
                    fieldName="applicationFollowUpState"
                    selectOptions={getDatasetStateByName(props, DATASET.OPPORTUNITY_CANDIDATE_FOLLOWUP_STATE)}
                    getValues={getValues}
                    setValue={setValue}
                    errors={errors}
                    classes={classes}
                    handleSetFilter={handleSetFilter}
                    filterList={filter}
                    filterListKey={"applicationFollowupState"}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <FormGroup>
                  <FormLabel>Tem Formulários por Aprovar</FormLabel>
                  <FormSelect
                    control={control}
                    fieldName="hasUnapprovedFeedbackReport"
                    selectOptions={[
                      {
                        value: true,
                        label: "Sim",
                      },
                      {
                        value: false,
                        label: "Não",
                      },
                    ]}
                    getValues={getValues}
                    setValue={setValue}
                    errors={errors}
                    classes={classes}
                    handleSetFilter={handleSetFilter}
                    filterList={filter}
                    filterListKey={"hasUnapprovedFeedbackReport"}
                  />
                </FormGroup>
              </Grid>

              {/* Butões de Pesquisa */}

              <Grid item xs={12} md={12} classes={{ root: classes.alignRight }}>
                <GenericButton
                  typeSubmit={false}
                  color="ghost"
                  onClick={() => {
                    cleanFilter();
                  }}
                >
                  {intl.formatMessage(labels.Label_ClearFilters)}
                </GenericButton>
                <GenericButton
                  typeSubmit={true}
                  color="primary"
                  loading={props.opportunitiesState?.getAllOpportunityApplicationsFollowUp_loading}
                >
                  {intl.formatMessage(labels.Label_FilterSection_SearchButton)}
                </GenericButton>
              </Grid>
            </Grid>
          </form>
        </Box>
      </CardContent>
    </Card>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getAllOpportunityApplicationsFollowUp: (data) => dispatch(getAllOpportunityApplicationsFollowUpAction(data)),
  getDataSetByName: (data, parentKeyValue) => dispatch(getDataSetByNameAction(data, parentKeyValue)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(OpportunityApplicationsFollowUpFilterSection));
