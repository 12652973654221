import { React, useEffect } from "react";
import clsx from "clsx";
import { Controller, useFormContext } from "react-hook-form";
import { connect } from "react-redux";
// @material-ui/core components
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import OutlinedInput from "@material-ui/core/OutlinedInput";
//import custom components
import FormMultipleSelect from "components/FormFields/FormMultipleSelect";
import { getNotAssignedActiveCategoriesAction, getCategoriesAction } from "redux/actions/datasetsAction";
import { DATASET } from "utils/consts";

import componentStylesGeneric from "assets/theme/views/admin/generic.js";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function ProfessionalKitFields(props) {
  const classes = useStylesGeneric();
  const theme = useTheme();

  const { control } = useFormContext();

  const getOptions = () => {
    let dataSetName = "";
    if (props.loadData === true) {
      if (!props.isEditable) dataSetName = DATASET.CATEGORIES;
      else dataSetName = DATASET.NOT_ASSIGNED_ACTIVE_CATEGORIES;
    } else {
      dataSetName = DATASET.NOT_ASSIGNED_ACTIVE_CATEGORIES;
    }

    return props.dataSetState[dataSetName] !== undefined ? props.dataSetState[dataSetName] : [];
  };

  useEffect(() => {
    if (props.loadData === true) {
      if (
        props.dataSetState[DATASET.CATEGORIES] === undefined ||
        props.dataSetState[DATASET.CATEGORIES]?.length === 0
      ) {
        props.getCategories(DATASET.CATEGORIES);
      }
    } else {
      props.getNotAssignedActiveCategories(DATASET.NOT_ASSIGNED_ACTIVE_CATEGORIES);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.subprogramDetailsData !== undefined) {
      if (props.loadData === true && props.isEditable) {
        props.getNotAssignedActiveCategories(DATASET.NOT_ASSIGNED_ACTIVE_CATEGORIES);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isEditable]);

  return (
    <>
      <Grid container style={{ alignItems: "flex-end" }}>
        <Grid item xs={12} sm={8} md={6}>
          <FormGroup>
            <FormLabel>Especialidade do Kit</FormLabel>
            <FormMultipleSelect
              control={control}
              fieldName="categoryList"
              selectOptions={getOptions()}
              initialLabel="Seleccione opções aplicáveis"
              getValues={props.getValues}
              limitTags={3}
              required={true}
              requiredMessage={"O campo é obrigatório"}
              errors={props.errors}
              disabled={!props.isEditable}
              classes={classes}
            />
            {props.errors["categoryList"] !== undefined && (
              <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                {props.errors["categoryList"].message}
              </FormHelperText>
            )}
          </FormGroup>
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <FormGroup>
            <FormLabel>Nº Máximo de Kits</FormLabel>
            <Controller
              name="numMaxKits"
              control={control}
              defaultValue=""
              component={Box}
              rules={{
                required: {
                  value: true,
                  message: "O campo é obrigatório",
                },
                validate: {
                  minValue: (value) => {
                    return value > 0 || "O número máximo de kits tem de ser maior que zero.";
                  },
                },
              }}
              render={({ field: { onChange, name, value } }) => (
                <OutlinedInput
                  name={name}
                  value={value}
                  onChange={onChange}
                  fullWidth
                  autoComplete="off"
                  type="number"
                  readOnly={!props.isEditable}
                  inputProps={{ style: { textAlign: "right" } }}
                  disabled={!props.isEditable}
                  classes={{
                    notchedOutline: clsx({
                      [classes.borderWarning]: props.errors["numMaxKits"] !== undefined,
                    }),
                  }}
                />
              )}
            />
            {props.errors["numMaxKits"] !== undefined && (
              <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                {props.errors["numMaxKits"].message}
              </FormHelperText>
            )}
          </FormGroup>
        </Grid>
      </Grid>
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getNotAssignedActiveCategories: (name) => dispatch(getNotAssignedActiveCategoriesAction(name)),
  getCategories: (name) => dispatch(getCategoriesAction(name)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfessionalKitFields);
