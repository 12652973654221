import { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import clsx from "clsx";
import { useIntl } from "react-intl";
//@material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import FormGroup from "@material-ui/core/FormGroup";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import FormLabel from "@material-ui/core/FormLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputAdornment from "@material-ui/core/InputAdornment";
import OutlinedInput from "@material-ui/core/OutlinedInput";
//custom components
import { REGEX_PATTERNS, UNITS, MONETARYUNITPLACEHOLDER } from "utils/consts";
import { messages } from "resources/resources";

import componentStylesGeneric from "assets/theme/views/admin/generic.js";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function ApproveSection(props) {
  const classes = useStylesGeneric();
  const theme = useTheme();
  const intl = useIntl();
  const { control } = useFormContext();
  const [formattedValue, setFormattedValue] = useState();

  const onValChange = (value, onChange) => {
    value = value?.replace(/\./g, "");
    //TODO - Check better way to format currency on same input
    if (value && value.charAt(value.length - 1) === ",") setFormattedValue(value);
    else {
      if (value) {
        value = value?.replace(/,/g, ".");
        if (intl.formatNumber(value) !== "NaN") setFormattedValue(intl.formatNumber(value));
        else setFormattedValue(value);
      } else {
        setFormattedValue("");
      }
    }
    value = value?.replace(/\./g, ",");
    onChange(value);
  };
  return (
    <>
      <Container>
        <Box className={classes.modalHeader} marginBottom="30px!important">
          Pretende aprovar esta candidatura?
        </Box>
        <Box className={classes.subHeader}>
          Ao aprovar a candidatura, o candidato ficará com a candidatura aceite no INEFOP e irá receber o benefício do
          subprograma a que concorreu. Para prosseguir com a aprovação indique o valor da dívida que foi aprovada pelo
          Banco (o valor apresentado corresponde ao valor aprovado previamente pelo INEFOP). Se não quiser aprovar,
          simplesmente feche a janela.
        </Box>
        <Box marginTop="30px">
          <Grid container>
            <Grid item xs={12} md={12}>
              <FormGroup>
                <FormLabel>Dívida</FormLabel>
                <Controller
                  name="amountToBePaid"
                  control={control}
                  component={Box}
                  defaultValue=""
                  rules={{
                    required: {
                      value: props.isEditable,
                      message: intl.formatMessage(messages.Message_Generic_MandatoryField),
                    },
                    pattern: {
                      value: REGEX_PATTERNS.MONETARY,
                      message: intl.formatMessage(messages.Message_Generic_InvalidField),
                    },
                    validate: {
                      minValue: (value) => {
                        if (value === "0" || value === "0,00" || value === "0,0")
                          return intl.formatMessage(messages.Message_Generic_ValueGreaterThanZeroField);
                        return true;
                      },
                    },
                  }}
                  render={({ field: { onChange, name } }) => (
                    <OutlinedInput
                      name={name}
                      value={formattedValue}
                      onChange={(e) => {
                        onValChange(e.target.value, onChange);
                      }}
                      fullWidth
                      autoComplete="off"
                      type="text"
                      placeholder={MONETARYUNITPLACEHOLDER}
                      endAdornment={
                        <InputAdornment disableTypography={true} position="end">
                          {UNITS.MONETARY}
                        </InputAdornment>
                      }
                      classes={{
                        input: classes.monetaryFields,
                        notchedOutline: clsx({
                          [classes.borderWarning]: props.errors["amountToBePaid"] !== undefined,
                        }),
                      }}
                    />
                  )}
                />
                {props.errors["amountToBePaid"] !== undefined && (
                  <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                    {props.errors["amountToBePaid"].message}
                  </FormHelperText>
                )}
              </FormGroup>
            </Grid>
          </Grid>

          <Grid container></Grid>
        </Box>
      </Container>
    </>
  );
}

export default ApproveSection;
