import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { Controller, useFormContext } from "react-hook-form";
import { connect } from "react-redux";
import { useIntl } from "react-intl";
// @material-ui/core components
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
//import custom components
import ProfessionalKitFields from "./ProfessionalKitFields";
import MicrocreditFields from "./MicrocreditFields";
import GenericButton from "components/Buttons/GenericButton";
import { getDataSetByNameAction } from "redux/actions/datasetsAction";
import { labels, titles, messages } from "resources/resources";
import { DATASET, BENEFIT, SUBPROGRAM_STATES } from "utils/consts";

import componentStylesGeneric from "assets/theme/views/admin/generic.js";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function BenefitsSection(props) {
  const classes = useStylesGeneric();
  const theme = useTheme();
  const intl = useIntl();
  const [benefitType, setBenefitType] = useState(0);
  const { control } = useFormContext();
  const [formattedMinValue, setFormattedMinValue] = useState();
  const [formattedMaxValue, setFormattedMaxValue] = useState();

  const onMinValChange = (value, onChange) => {
    value = value?.replace(/\./g, "");

    if (value && value.charAt(value.length - 1) === ",") setFormattedMinValue(value);
    else {
      if (value) {
        value = value?.replace(/,/g, ".");
        if (intl.formatNumber(value) !== "NaN") setFormattedMinValue(intl.formatNumber(value));
        else setFormattedMinValue(value);
      } else {
        setFormattedMinValue("");
      }
    }
    value = value?.replace(/\./g, ",");
    onChange(value);
  };

  const onMaxValChange = (value, onChange) => {
    value = value?.replace(/\./g, "");

    if (value && value.charAt(value.length - 1) === ",") setFormattedMaxValue(value);
    else {
      if (value) {
        value = value?.replace(/,/g, ".");
        if (intl.formatNumber(value) !== "NaN") setFormattedMaxValue(intl.formatNumber(value));
        else setFormattedMaxValue(value);
      } else {
        setFormattedMaxValue("");
      }
    }
    value = value?.replace(/\./g, ",");
    onChange(value);
  };

  useEffect(() => {
    if (props.dataSetState[DATASET.BENEFITS] === undefined || props.dataSetState[DATASET.BENEFITS]?.length === 0) {
      props.getDataSet(DATASET.BENEFITS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.subprogramDetailsData !== undefined) {
      if (props.loadData) {
        let benefitItem =
          props.dataSetState[DATASET.BENEFITS] !== undefined
            ? props.dataSetState[DATASET.BENEFITS].find((r) => r.code === props.subprogramDetailsData.benefitTypeId)
            : [];

        props.setValue("benefitType", benefitItem);

        if (benefitItem?.keyValue === "ProfessionalKit") {
          let categoryItems =
            props.dataSetState[DATASET.CATEGORIES] !== undefined
              ? props.dataSetState[DATASET.CATEGORIES].filter((r) =>
                  props.subprogramDetailsData.categoryList.includes(r.code)
                )
              : [];
          props.setValue("categoryList", categoryItems);
          props.setValue("numMaxKits", props.subprogramDetailsData.numMaxKits);
        }

        if (benefitItem?.keyValue === "Microcredit") {
          props.setValue("minMicrocreditValue", props.subprogramDetailsData.minMicrocreditValue);
          props.setValue("maxMicrocreditValue", props.subprogramDetailsData.maxMicrocreditValue);
          props.setValue("interestRate", props.subprogramDetailsData.interestRate);
          props.setValue("gracePeriod", props.subprogramDetailsData.gracePeriod);
          props.setValue("repaymentPeriod", props.subprogramDetailsData.repaymentPeriod);

          setFormattedMinValue(intl.formatNumber(props.subprogramDetailsData?.minMicrocreditValue));
          setFormattedMaxValue(intl.formatNumber(props.subprogramDetailsData?.maxMicrocreditValue));
        }

        onSetBenefit(benefitItem);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.subprogramDetailsData]);

  const handleEditSection = () => {
    props.handleEditSection();
  };

  function loadSelectedBenefitFields(benefitKeyValue) {
    switch (benefitKeyValue) {
      case BENEFIT.PROFESSIONAL_KIT:
        return (
          <ProfessionalKitFields
            control={control}
            getValues={props.getValues}
            setValue={props.setValue}
            errors={props.errors}
            unregister={props.unregister}
            isEditable={props.isEditable}
            loadData={props.loadData}
            subprogramDetailsData={props.subprogramDetailsData}
            unregisterProfessionalKitFields={unregisterProfessionalKitFields}
          />
        );
      case BENEFIT.MICROCREDIT:
        return (
          <MicrocreditFields
            control={control}
            getValues={props.getValues}
            setValue={props.setValue}
            errors={props.errors}
            unregister={props.unregister}
            isEditable={props.isEditable}
            trigger={props.trigger}
            loadData={props.loadData}
            subprogramDetailsData={props.subprogramDetailsData}
            unregisterMicrocreditFields={unregisterMicrocreditFields}
            formattedMinValue={formattedMinValue}
            formattedMaxValue={formattedMaxValue}
            onMinValChange={onMinValChange}
            onMaxValChange={onMaxValChange}
          />
        );
      case BENEFIT.PROFESSIONAL_KIT_MICROCREDIT:
        return (
          <>
            <ProfessionalKitFields
              control={control}
              getValues={props.getValues}
              setValue={props.setValue}
              errors={props.errors}
              unregister={props.unregister}
              isEditable={props.isEditable}
              loadData={props.loadData}
              subprogramDetailsData={props.subprogramDetailsData}
              unregisterProfessionalKitFields={unregisterProfessionalKitFields}
            />
            <MicrocreditFields
              control={control}
              getValues={props.getValues}
              setValue={props.setValue}
              errors={props.errors}
              unregister={props.unregister}
              isEditable={props.isEditable}
              trigger={props.trigger}
              loadData={props.loadData}
              subprogramDetailsData={props.subprogramDetailsData}
              unregisterMicrocreditFields={unregisterMicrocreditFields}
              x
              formattedMinValue={formattedMinValue}
              formattedMaxValue={formattedMaxValue}
              onMinValChange={onMinValChange}
              onMaxValChange={onMaxValChange}
            />
          </>
        );
      default:
        return <></>;
    }
  }

  const unregisterProfessionalKitFields = () => {
    props.unregister("categoryList");
    props.unregister("numMaxKits");
  };

  const unregisterMicrocreditFields = () => {
    props.unregister("minMicrocreditValue");
    props.unregister("maxMicrocreditValue");
    props.unregister("interestRate");
    props.unregister("gracePeriod");
    props.unregister("repaymentPeriod");
    setFormattedMinValue("");
    setFormattedMaxValue("");
  };

  const onSetBenefit = (data) => {
    if (data !== undefined && data !== null) {
      setBenefitType(data.keyValue);
    } else {
      setBenefitType(0);
    }
  };

  const onChangeBenefitType = (data) => {
    unregisterProfessionalKitFields();
    unregisterMicrocreditFields();

    onSetBenefit(data);
  };

  return (
    <>
      <Card classes={{ root: classes.cardRoot }}>
        <CardHeader
          classes={{ title: classes.cardHeader, action: classes.cardHeaderAction }}
          title={intl.formatMessage(titles.Title_Admin_Subprogram_BenefitsSection)}
          action={
            props.loadData &&
            props.subprogramDetailsData?.stateKey !== SUBPROGRAM_STATES.PUBLICADO &&
            props.subprogramDetailsData?.stateKey !== SUBPROGRAM_STATES.CONCLUIDO && (
              <GenericButton typeSubmit={false} color="tertiary" size="small" onClick={handleEditSection}>
                {intl.formatMessage(labels.Label_Generic_Edit)}
              </GenericButton>
            )
          }
        ></CardHeader>
        <CardContent>
          <Grid container>
            <Grid item xs={12} sm={8} md={8}>
              <FormGroup>
                <FormLabel>{intl.formatMessage(labels.Label_Admin_Subprogram_BenefitsTypeField)}</FormLabel>
                <Controller
                  name="benefitType"
                  control={control}
                  rules={{
                    required: {
                      value: props.isEditable,
                      message: intl.formatMessage(messages.Message_Generic_MandatoryField),
                    },
                  }}
                  render={({ field: { onChange, name } }) => (
                    <Autocomplete
                      name={name}
                      options={props.dataSetState[DATASET.BENEFITS] ?? []}
                      value={props.getValues(name) ? props.getValues(name) : null}
                      getOptionLabel={(option) => option?.label ?? ""}
                      getOptionSelected={(option, value) => option.code === value.code}
                      disabled={!props.isEditable}
                      classes={{
                        inputRoot: clsx({
                          [classes.selectBorderWarningInputRoot]:
                            props.errors !== undefined && props.errors[name] !== undefined,
                        }),
                      }}
                      ListboxProps={{
                        style: {
                          fontWeight: "normal",
                          fontSize: "14px",
                        },
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={"Seleccione opção"}
                          variant="outlined"
                          InputLabelProps={{
                            style: {
                              fontWeight: "normal",
                              fontSize: "14px",
                              color: theme.palette.gray[600],
                            },
                          }}
                        />
                      )}
                      onChange={(_, data) => {
                        onChange(data);

                        onChangeBenefitType(data);
                      }}
                    />
                  )}
                />
                {props.errors["benefitType"] !== undefined && (
                  <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                    {props.errors["benefitType"].message}
                  </FormHelperText>
                )}
              </FormGroup>
            </Grid>
          </Grid>

          {loadSelectedBenefitFields(benefitType)}
        </CardContent>
      </Card>
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getDataSet: (name, parentKeyValue) => dispatch(getDataSetByNameAction(name, parentKeyValue)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BenefitsSection);
