import React from "react";
import { connect } from "react-redux";
import { useIntl } from "react-intl";

import { makeStyles } from "@material-ui/core/styles";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Avatar from "@material-ui/core/Avatar";
import WarningIcon from "@material-ui/icons/Warning";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import StarIcon from "@material-ui/icons/Star";

import CounterDisplayField from "components/Fields/CounterDisplayField";
import componentStylesGeneric from "assets/theme/views/admin/generic.js";
import componentStylescomponentStylesUserOverview from "assets/theme//views/admin/user-overview";
import { labels } from "resources/resources";
import { getNameInitials } from "utils/converters";
import { b64toBlob, getFirstAndLastName } from "utils/converters";
import useWindowSize from "hooks/useWindowSize";

const useStylesGeneric = makeStyles(componentStylesGeneric);
const useStylescomponentStylesUserOverview = makeStyles(componentStylescomponentStylesUserOverview);

function OverviewSection(props) {
  const classes = { ...useStylesGeneric(), ...useStylescomponentStylesUserOverview() };
  const intl = useIntl();
  const windowSize = useWindowSize();

  return (
    <>
      <div style={{ position: "relative" }}>
        <img
          src={"/images/empregoAngolaBackground2.png"} //Designed by 0melapics / Freepik: http://www.freepik.com
          className={classes.backgroundImage}
          alt={""}
        />
      </div>

      <CardContent>
        <Grid container style={{ position: "relative", zIndex: "1" }}>
          <Grid item xs={12} lg={6}>
            <Box className={classes.userMainDetailsBox}>
              <Box className={classes.userMainDetailsBoxLeft}>
                {props?.userOverview?.photo ? (
                  <Box>
                    <Avatar
                      alt="..."
                      src={b64toBlob(props?.userOverview?.photo)}
                      classes={{
                        root: classes.avatarRoot,
                      }}
                    />
                  </Box>
                ) : (
                  <Box component={Avatar} marginRight="1rem" alt="..." classes={{ root: classes.avatarRoot }}>
                    {" "}
                    {getNameInitials(props?.userOverview?.userSingularGeneralDataOverview?.fullName)}
                  </Box>
                )}
              </Box>

              <Box>
                <div className={classes.userNameDiv}>
                  {getFirstAndLastName(props?.userOverview?.userSingularGeneralDataOverview?.fullName)}
                </div>
                <div
                  className={classes.mainDetailsDiv + " " + classes.ellipsis}
                  title={props?.userOverview?.userSingularProfessionalDataOverview?.professionalExperienceDescriptions?.join(
                    ", "
                  )}
                >
                  {props?.userOverview?.userSingularProfessionalDataOverview?.professionalExperienceDescriptions
                    ?.length > 0
                    ? props?.userOverview?.userSingularProfessionalDataOverview?.professionalExperienceDescriptions?.join(
                        ", "
                      )
                    : intl.formatMessage(labels.Label_UserOverview_GeneralDataTab_OverviewSection_NoProfessionalData)}
                </div>
                {props?.userOverview?.userSingularGeneralDataOverview?.age && (
                  <div className={classes.mainDetailsDiv}>
                    {props?.userOverview?.userSingularGeneralDataOverview?.age} {intl.formatMessage(labels.Label_Years)}
                  </div>
                )}
                <div className={classes.mainDetailsDiv}>
                  {props?.userOverview?.userSingularGeneralDataOverview?.municipalityDescription
                    ? props?.userOverview?.userSingularGeneralDataOverview?.municipalityDescription +
                      ", " +
                      props?.userOverview?.userSingularGeneralDataOverview?.provinceDescription
                    : props?.userOverview?.userSingularGeneralDataOverview?.provinceDescription ??
                      intl.formatMessage(labels.Label_UserOverview_GeneralDataTab_OverviewSection_NoProvinceData)}
                </div>
                <div className={classes.mainDetailsDiv}>
                  {props?.userOverview?.accountCreationDate && props?.userOverview?.accountCreationDate !== "N/A"
                    ? intl.formatMessage(labels.Label_UserOverview_GeneralDataTab_OverviewSection_JoinedOn) +
                      " " +
                      props?.userOverview?.accountCreationDate
                    : intl.formatMessage(labels.Label_UserOverview_GeneralDataTab_OverviewSection_NoJoinedOnData)}
                </div>
              </Box>
            </Box>
          </Grid>

          {windowSize.width > 900 && (
            <>
              <CounterDisplayField
                xs={3}
                label={
                  props?.userOverview?.userSingularGeneralDataOverview?.microcreditIsMissingPayments ? (
                    <div>
                      <div>
                        {intl.formatMessage(labels.Label_UserOverview_GeneralDataTab_OverviewSection_MicrocreditDebt)}
                      </div>
                      <div>
                        {intl.formatMessage(
                          labels.Label_UserOverview_GeneralDataTab_OverviewSection_MicrocreditDebtWithMissingPayments
                        )}
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div>
                        {intl.formatMessage(labels.Label_UserOverview_GeneralDataTab_OverviewSection_MicrocreditDebt)}
                      </div>
                      <div>
                        {intl.formatMessage(
                          labels.Label_UserOverview_GeneralDataTab_OverviewSection_MicrocreditDebtWithoutMissingPayments
                        )}
                      </div>
                    </div>
                  )
                }
                data={
                  <>
                    {props?.userOverview?.userSingularGeneralDataOverview?.microcreditIsMissingPayments ? (
                      <WarningIcon className={classes.warningIcon} />
                    ) : (
                      <CheckCircleIcon className={classes.checkIcon} />
                    )}
                    {props?.userOverview?.userSingularGeneralDataOverview?.microcreditAmountToBePaid} Kz
                  </>
                }
                handleOnClick={() => {
                  props.handleChangeTab(null, "applicationsTab", true);
                }}
                highlighted={true}
                isButton={true}
              />
              <CounterDisplayField
                xs={3}
                label={intl.formatMessage(
                  labels.Label_UserOverview_GeneralDataTab_OverviewSection_OpportunityFollowUpOverallEvaluation
                )}
                data={
                  <>
                    <StarIcon className={classes.starIcon} />
                    {props?.userOverview?.userSingularGeneralDataOverview?.opportunityFollowUpOverallEvaluation}/5
                  </>
                }
                handleOnClick={() => {
                  props.handleChangeTab(null, "applicationsTab", true);
                }}
                highlighted={true}
                isButton={true}
              />
            </>
          )}
        </Grid>
        <Grid container style={{ marginLeft: "-39px", marginRight: "-39px" }}>
          {windowSize.width <= 900 && (
            <>
              <CounterDisplayField
                xs={6}
                label={
                  props?.userOverview?.userSingularGeneralDataOverview?.microcreditIsMissingPayments ? (
                    <div>
                      <div>
                        {intl.formatMessage(labels.Label_UserOverview_GeneralDataTab_OverviewSection_MicrocreditDebt)}
                      </div>
                      <div>
                        {intl.formatMessage(
                          labels.Label_UserOverview_GeneralDataTab_OverviewSection_MicrocreditDebtWithMissingPayments
                        )}
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div>
                        {intl.formatMessage(labels.Label_UserOverview_GeneralDataTab_OverviewSection_MicrocreditDebt)}
                      </div>
                      <div>
                        {intl.formatMessage(
                          labels.Label_UserOverview_GeneralDataTab_OverviewSection_MicrocreditDebtWithoutMissingPayments
                        )}
                      </div>
                    </div>
                  )
                }
                data={
                  <>
                    {props?.userOverview?.userSingularGeneralDataOverview?.microcreditIsMissingPayments ? (
                      <WarningIcon className={classes.warningIcon} />
                    ) : (
                      <CheckCircleIcon className={classes.checkIcon} />
                    )}
                    {props?.userOverview?.userSingularGeneralDataOverview?.microcreditAmountToBePaid} Kz
                  </>
                }
                handleOnClick={() => {
                  props.handleChangeTab(null, "applicationsTab", true);
                }}
                isButton={true}
              />
              <CounterDisplayField
                xs={6}
                label={intl.formatMessage(
                  labels.Label_UserOverview_GeneralDataTab_OverviewSection_OpportunityFollowUpOverallEvaluation
                )}
                data={
                  <>
                    <StarIcon className={classes.starIcon} />
                    {props?.userOverview?.userSingularGeneralDataOverview?.opportunityFollowUpOverallEvaluation}/5
                  </>
                }
                handleOnClick={() => {
                  props.handleChangeTab(null, "applicationsTab", true);
                }}
                isButton={true}
              />
            </>
          )}
          <CounterDisplayField
            xs={6}
            lg={3}
            label={intl.formatMessage(
              labels.Label_UserOverview_GeneralDataTab_OverviewSection_GovernmentProgramApplications
            )}
            data={props?.userOverview?.userSingularGeneralDataOverview?.governmentProgramApplicationsCount}
            handleOnClick={() => {
              props.handleChangeTab(null, "applicationsTab", true, "governmentProgramsTab");
            }}
            testId={"governmentProgramApplications"}
            isButton={true}
          />
          <CounterDisplayField
            xs={6}
            lg={3}
            label={intl.formatMessage(labels.Label_UserOverview_GeneralDataTab_OverviewSection_OpportunityApplications)}
            data={props?.userOverview?.userSingularGeneralDataOverview?.opportunityApplicationsCount}
            handleOnClick={() => {
              props.handleChangeTab(null, "applicationsTab", true, "internshipTab");
            }}
            isButton={true}
          />
          <CounterDisplayField
            xs={6}
            lg={3}
            label={intl.formatMessage(labels.Label_UserOverview_GeneralDataTab_OverviewSection_ProfessionalTraining)}
            data={props?.userOverview?.userSingularGeneralDataOverview?.professionalTrainingCount}
            handleOnClick={() => {
              props.handleChangeTab(null, "traingAndProfessionTab", true);
            }}
            isButton={true}
          />
          <CounterDisplayField
            xs={6}
            lg={3}
            label={intl.formatMessage(labels.Label_UserOverview_GeneralDataTab_OverviewSection_ProfessionalWallets)}
            data={
              props?.userOverview?.userSingularGeneralDataOverview?.hasProfessionalWallet
                ? intl.formatMessage(labels.Label_Yes)
                : intl.formatMessage(labels.Label_No)
            }
            handleOnClick={() => {
              props.handleChangeTab(null, "traingAndProfessionTab", true);
            }}
            isButton={true}
          />
          <CounterDisplayField
            xs={6}
            lg={3}
            label={intl.formatMessage(labels.Label_UserOverview_GeneralDataTab_OverviewSection_FinishedInterships)}
            data={props?.userOverview?.userSingularGeneralDataOverview?.opportunityInternshipFinishedCount}
            handleOnClick={() => {
              props.handleChangeTab(null, "applicationsTab", true, "finished");
            }}
            isButton={true}
          />
          <CounterDisplayField
            xs={6}
            lg={3}
            label={intl.formatMessage(labels.Label_UserOverview_GeneralDataTab_OverviewSection_GrantedMicrocredits)}
            data={props?.userOverview?.userSingularGeneralDataOverview?.concededMicrocreditCount}
            handleOnClick={() => {
              props.handleChangeTab(null, "applicationsTab", true);
            }}
            isButton={true}
          />
          <CounterDisplayField
            xs={6}
            lg={3}
            label={intl.formatMessage(labels.Label_UserOverview_GeneralDataTab_OverviewSection_GrantedProfessionalKits)}
            data={props?.userOverview?.userSingularGeneralDataOverview?.concededProfessionalKitsCount}
            handleOnClick={() => {
              props.handleChangeTab(null, "applicationsTab", true);
            }}
            isButton={true}
          />
          <CounterDisplayField
            xs={6}
            lg={3}
            label={intl.formatMessage(labels.Label_UserOverview_GeneralDataTab_OverviewSection_AllSchedules)}
            data={props?.userOverview?.userSingularGeneralDataOverview?.schedulesCount}
            handleOnClick={() => {
              props.handleChangeTab(null, "applicationsTab", true);
            }}
            isButton={true}
          />
        </Grid>
      </CardContent>
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

export default connect(mapStateToProps)(OverviewSection);
