import boxShadows from "assets/theme/box-shadow.js";

const componentStyles = (theme) => ({
  cardRoot: {
    boxShadow: boxShadows.boxShadow + "!important",
  },
  cardHeader: {
    backgroundColor: "initial",
  },
  containerRoot: {
    [theme.breakpoints.up("md")]: {
      paddingLeft: "30px",
      paddingRight: "30px",
    },
  },
  mb0: {
    marginBottom: "0!important",
  },
  boxNameCategory : {    
    marginTop:"-13px"
  },
  gridDeleteIconRoot: {
    textAlign:"center",
    [theme.breakpoints.down("sm")]: {
      marginTop: "-15px",
      marginBottom: "5px",
    },
  }
});

export default componentStyles;
