import React from "react";
import { connect } from "react-redux";
import { Container } from "@material-ui/core";

//import custom components
import OverviewSection from "views/admin/UserOverview/UserColective/Sections/OverviewSection";
import GeneralDataSection from "views/admin/UserOverview/UserColective/Sections/GeneralDataSection";
import ContactsSection from "views/admin/UserOverview/Components/Sections/ContactsSection";
import DocumentsSection from "views/admin/UserOverview/Components/Sections/DocumentsSection";

function GenericDataTab(props) {
  return (
    <>
      <Container maxWidth={false}>
        <OverviewSection userOverview={props?.userOverview} handleChangeTab={props.handleChangeTab} />
        <GeneralDataSection userOverview={props?.userOverview} userGeneralDataOverview={props?.userOverview?.userColectiveGeneralDataOverview} />
        <ContactsSection
          userGeneralDataOverview={props?.userOverview?.userColectiveGeneralDataOverview}
          isUserColective={true}
        />
        {props?.userOverview?.userColectiveGeneralDataOverview?.userDocuments?.length > 0 && (
          <DocumentsSection
            userDocuments={props?.userOverview?.userColectiveGeneralDataOverview?.userDocuments}
            userTypeDocuments={props?.userOverview?.userColectiveGeneralDataOverview?.userTypeDocuments}
          />
        )}
      </Container>
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

export default connect(mapStateToProps)(GenericDataTab);
