import React from "react";
// @material-ui/core components
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Rating from "@material-ui/lab/Rating";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Box from "@material-ui/core/Box";
import { Typography } from "@material-ui/core";
//import custom components
import limitaString from "utils/string";

import componentStylesGeneric from "assets/theme/views/admin/generic";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function FollowUpEvaluation(props) {
  const classes = useStylesGeneric();
  const [tabIndex, setTabIndex] = React.useState(0);

  const StyledTableRow = withStyles((theme) => ({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.gray[100],
      },
    },
  }))(TableRow);

  return (
    <Card classes={{ root: classes.cardRoot }}>
      <Tabs variant={"fullWidth"} value={tabIndex} onChange={(e, index) => setTabIndex(index)}>
        <Tab className={classes.cardTabButton} label={"Submetida Pela Empresa"} />
        <Tab className={classes.cardTabButton} label={"Submetida Pelo Estagiário"} />
      </Tabs>

      {tabIndex === 0 ? (
        <>
          <CardHeader
            classes={{ title: classes.cardHeader }}
            title="Média dos Formulários"
            action={
              <Typography variant="h4" className={classes.cardHeaderActionTypography}>
                Total formulários submetidos:{" "}
                {props.opportunityApplicationFollowUpDetails?.followUpEvaluation?.formsSubmittedByCompanyTotal}
              </Typography>
            }
          ></CardHeader>

          {props.opportunityApplicationFollowUpDetails?.followUpEvaluation?.evaluationSubmittedByCompany?.length > 0 ? (
            <Box>
              <TableContainer style={{ marginBottom: "20px" }}>
                <Box component={Table} alignItems="center" marginBottom="0!important">
                  {props.opportunityApplicationFollowUpDetails?.followUpEvaluation?.evaluationSubmittedByCompany?.map(
                    (evaluationSubmittedByCompany, key) => (
                      <TableBody key={key}>
                        <TableRow key={key}>
                          <TableCell
                            classes={{
                              root: classes.tableCellRoot + " " + classes.tableCellRootHeadMultiple,
                            }}
                            width="75%"
                          >
                            {limitaString(evaluationSubmittedByCompany?.questionArea, 100)}
                          </TableCell>
                          <TableCell
                            classes={{
                              root: classes.tableCellRoot + " " + classes.tableCellRootHeadMultiple,
                            }}
                          ></TableCell>
                          <TableCell
                            classes={{
                              root: classes.tableCellRoot + " " + classes.tableCellRootHeadMultiple,
                            }}
                          ></TableCell>
                        </TableRow>
                        <>
                          {evaluationSubmittedByCompany?.questionInformation?.map((questionInformation, key) => (
                            <StyledTableRow key={key}>
                              <TableCell
                                classes={{ root: classes.tableCellRoot }}
                                width="75%"
                                style={{ paddingLeft: "30px" }}
                              >
                                {limitaString(questionInformation?.question, 100)}
                              </TableCell>
                              <TableCell classes={{ root: classes.tableCellRoot }}>
                                <Rating value={Number(questionInformation?.meanEvaluation)} precision={0.5} readOnly />
                              </TableCell>
                              <TableCell classes={{ root: classes.tableCellRoot }}>
                                <div className={classes.detailLabels} style={{ paddingTop: "5px" }}>
                                  ({questionInformation?.meanEvaluation?.toFixed(1)}/5)
                                </div>
                              </TableCell>
                            </StyledTableRow>
                          ))}
                        </>
                      </TableBody>
                    )
                  )}
                </Box>
              </TableContainer>
            </Box>
          ) : (
            <Box className={classes.noResultsInfoInTable}>Não existem dados para apresentar.</Box>
          )}
        </>
      ) : (
        <>
          <CardHeader
            classes={{ title: classes.cardHeader }}
            title="Média dos Formulários"
            action={
              <Typography variant="h4" className={classes.cardHeaderActionTypography}>
                Total formulários submetidos:{" "}
                {props.opportunityApplicationFollowUpDetails?.followUpEvaluation?.formsSubmittedByCandidateTotal}
              </Typography>
            }
          ></CardHeader>

          {props.opportunityApplicationFollowUpDetails?.followUpEvaluation?.evaluationSubmittedByCandidate?.length >
          0 ? (
            <Box>
              <TableContainer style={{ marginBottom: "20px" }}>
                <Box component={Table} alignItems="center" marginBottom="0!important">
                  {props.opportunityApplicationFollowUpDetails?.followUpEvaluation?.evaluationSubmittedByCandidate?.map(
                    (evaluationSubmittedByCandidate, key) => (
                      <TableBody key={key}>
                        <TableRow key={key}>
                          <TableCell
                            classes={{
                              root: classes.tableCellRoot + " " + classes.tableCellRootHeadMultiple,
                            }}
                            width="75%"
                          >
                            {limitaString(evaluationSubmittedByCandidate?.questionArea, 100)}
                          </TableCell>
                          <TableCell
                            classes={{
                              root: classes.tableCellRoot + " " + classes.tableCellRootHeadMultiple,
                            }}
                          ></TableCell>
                          <TableCell
                            classes={{
                              root: classes.tableCellRoot + " " + classes.tableCellRootHeadMultiple,
                            }}
                          ></TableCell>
                        </TableRow>
                        <>
                          {evaluationSubmittedByCandidate?.questionInformation?.map((questionInformation, key) => (
                            <StyledTableRow key={key}>
                              <TableCell
                                classes={{ root: classes.tableCellRoot }}
                                width="75%"
                                style={{ paddingLeft: "30px" }}
                              >
                                {limitaString(questionInformation.question, 100)}
                              </TableCell>
                              <TableCell classes={{ root: classes.tableCellRoot }}>
                                <Rating value={Number(questionInformation?.meanEvaluation)} precision={0.5} readOnly />
                              </TableCell>
                              <TableCell classes={{ root: classes.tableCellRoot }}>
                                <div className={classes.detailLabels} style={{ paddingTop: "5px" }}>
                                  ({questionInformation?.meanEvaluation?.toFixed(1)}/5)
                                </div>
                              </TableCell>
                            </StyledTableRow>
                          ))}
                        </>
                      </TableBody>
                    )
                  )}
                </Box>
              </TableContainer>
            </Box>
          ) : (
            <Box className={classes.noResultsInfoInTable}>Não existem dados para apresentar.</Box>
          )}
        </>
      )}
    </Card>
  );
}

export default FollowUpEvaluation;
