import { React, useEffect, useState } from "react";
import { connect } from "react-redux";
import clsx from "clsx";
import { Controller, useFormContext } from "react-hook-form";
import "moment/locale/pt";
import { useIntl } from "react-intl";
// @material-ui/core components
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Grid from "@material-ui/core/Grid";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Tooltip from "@material-ui/core/Tooltip";
// @material-ui/icons components
import HelpIcon from "@material-ui/icons/Help";
//import custom components
import FormSelect from "components/FormFields/FormSelect";
import GenericButton from "components/Buttons/GenericButton";
import FormMultipleSelect from "components/FormFields/FormMultipleSelect";
import { getCategoriesAction, getDataSetByNameAction, getBackOfficeRolesAction } from "redux/actions/datasetsAction";
import { DATASET, ROLE_KEY_VALUE } from "utils/consts";
import { labels, messages, errorMessages } from "resources/resources";

import componentStylesGeneric from "assets/theme/views/admin/generic.js";
const useStylesGeneric = makeStyles(componentStylesGeneric);

function BackOfficeUserData(props) {
  const classes = useStylesGeneric();
  const theme = useTheme();
  const intl = useIntl();
  const { control } = useFormContext();

  const [showBankSelection, setShowBankSelection] = useState(false);
  const [showEmploymentAndEntrepreneurshipSelection, setShowEmploymentAndEntrepreneurshipSelection] = useState(false);

  function getShowExtraFields() {
    var selectedFunctions = props.getValues("function");

    if (selectedFunctions && selectedFunctions.length > 0) {
      if (selectedFunctions.some((op) => op.code === ROLE_KEY_VALUE.BANCOS)) {
        setShowBankSelection(true);
      } else {
        setShowBankSelection(false);
        props.unregister("managerBank");
        props.unregister("managerLoanTypes");
      }

      if (
        selectedFunctions.some(
          (op) =>
            op.code === ROLE_KEY_VALUE.EMPLOYMENT_AND_ENTREPRENEURSHIP_TECHNICAL_TEAM ||
            selectedFunctions.some((op) => op.code === ROLE_KEY_VALUE.EMPLOYMENT_AND_ENTREPRENEURSHIP_MANAGEMENT_TEAM)
        )
      ) {
        setShowEmploymentAndEntrepreneurshipSelection(true);
      } else {
        setShowEmploymentAndEntrepreneurshipSelection(false);
        props.unregister("employmentCenter");
      }
    } else {
      setShowBankSelection(false);
      props.unregister("managerBank");
      props.unregister("managerLoanTypes");

      setShowEmploymentAndEntrepreneurshipSelection(false);
      props.unregister("employmentCenter");
    }
  }

  useEffect(() => {
    if (props.dataSetState[DATASET.PROVINCES] === undefined || props.dataSetState[DATASET.PROVINCES] === []) {
      props.getDataSet(DATASET.PROVINCES);
    }
    if (
      props.dataSetState[DATASET.BACKOFFICE_ROLES] === undefined ||
      props.dataSetState[DATASET.BACKOFFICE_ROLES]?.length === 0
    ) {
      props.getBackOfficeRoles(DATASET.BACKOFFICE_ROLES);
    }
    if (props.dataSetState[DATASET.BANK] === undefined || props.dataSetState[DATASET.BANK]?.length === 0) {
      props.getDataSet(DATASET.BANK);
    }
    if (props.dataSetState[DATASET.LOAN_TYPE] === undefined || props.dataSetState[DATASET.LOAN_TYPE]?.length === 0) {
      props.getDataSet(DATASET.LOAN_TYPE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getShowExtraFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.getValues("managerBank")]);

  useEffect(() => {
    const provinceValue = props.getValues("province");
    const functionValue = props.getValues("function");

    getShowExtraFields();

    if (provinceValue && functionValue) {
      if (
        functionValue?.some(
          (op) =>
            op.code === ROLE_KEY_VALUE.EMPLOYMENT_AND_ENTREPRENEURSHIP_TECHNICAL_TEAM ||
            functionValue?.some((op) => op.code === ROLE_KEY_VALUE.EMPLOYMENT_AND_ENTREPRENEURSHIP_MANAGEMENT_TEAM)
        )
      ) {
        props.getDataSet(DATASET.EMPLOYMENT_CENTERS, provinceValue.keyValue);
      }
    } else if (!provinceValue && functionValue) {
      props.setValue("employmentCenter", undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.getValues("province"), props.getValues("function")]);

  return (
    <>
      <Card classes={{ root: classes.cardRoot }}>
        <CardHeader
          classes={{
            title: classes.cardHeader,
            action: classes.cardHeaderAction,
          }}
          title="Dados do Utilizador"
          action={
            !props.isCreate && (
              <GenericButton
                typeSubmit={false}
                size="small"
                color="tertiary"
                onClick={props.handleEditSection}
                disabled={!(props.usersInfo?.result?.isEditable ?? true)}
              >
                {intl.formatMessage(labels.Label_Generic_Edit)}
              </GenericButton>
            )
          }
        />
        <CardContent>
          <Grid container>
            <Grid item xs={12} sm={4} md={4}>
              <FormGroup>
                <FormLabel>Nome</FormLabel>
                <Controller
                  name="name"
                  control={control}
                  component={Box}
                  defaultValue=""
                  disabled={!props.isEditable}
                  rules={{
                    required: {
                      value: true,
                      message: intl.formatMessage(messages.Message_Generic_MandatoryField),
                    },
                  }}
                  render={({ field: { onChange, name, value } }) => (
                    <OutlinedInput
                      name={name}
                      value={value}
                      onChange={onChange}
                      disabled={!props.isEditable}
                      fullWidth
                      autoComplete="off"
                      type="text"
                      classes={{
                        notchedOutline: clsx({
                          [classes.borderWarning]: props.errors["name"] !== undefined,
                        }),
                      }}
                    />
                  )}
                />
                {props.errors["name"] !== undefined && (
                  <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                    {props.errors["name"].message}
                  </FormHelperText>
                )}
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={5} md={5}>
              <FormGroup>
                <FormLabel>Apelido</FormLabel>
                <Controller
                  name="surname"
                  control={control}
                  component={Box}
                  defaultValue=""
                  rules={{
                    required: {
                      value: true,
                      message: intl.formatMessage(messages.Message_Generic_MandatoryField),
                    },
                  }}
                  render={({ field: { onChange, name, value } }) => (
                    <OutlinedInput
                      name={name}
                      value={value}
                      onChange={onChange}
                      disabled={!props.isEditable}
                      fullWidth
                      autoComplete="off"
                      type="text"
                      classes={{
                        notchedOutline: clsx({
                          [classes.borderWarning]: props.errors["surname"] !== undefined,
                        }),
                      }}
                    />
                  )}
                />
                {props.errors["surname"] !== undefined && (
                  <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                    {props.errors["surname"].message}
                  </FormHelperText>
                )}
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={3} md={3}>
              <FormGroup>
                <FormLabel>Activo</FormLabel>
                <FormSelect
                  control={control}
                  fieldName="active"
                  selectOptions={DATASET.YES_OR_NO_DROPDOWN}
                  disabled={props.isCreate || !props.isEditable}
                  setValue={props.setValue}
                  getValues={props.getValues}
                  required={props.isEditable}
                  requiredMessage={intl.formatMessage(messages.Message_Generic_MandatoryField)}
                  errors={props.errors}
                  classes={classes}
                />
                {props.errors["active"] !== undefined && (
                  <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                    {props.errors["active"].message}
                  </FormHelperText>
                )}
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormGroup>
                <FormLabel>Província</FormLabel>
                <FormSelect
                  control={control}
                  fieldName="province"
                  selectOptions={props.dataSetState[DATASET.PROVINCES] ? props.dataSetState[DATASET.PROVINCES] : []}
                  disabled={props.authState?.province ? true : !props.isEditable}
                  loadChildrenDataSet={props.getDataSet}
                  childrenDataSet={DATASET.MUNICIPALITIES}
                  childrenSelect="county"
                  setValue={props.setValue}
                  getValues={props.getValues}
                  required={false}
                  requiredMessage={intl.formatMessage(messages.Message_Generic_MandatoryField)}
                  errors={props.errors}
                  classes={classes}
                />
                {props.errors["province"] !== undefined && (
                  <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                    {props.errors["province"].message}
                  </FormHelperText>
                )}
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormGroup>
                <FormLabel>Município</FormLabel>
                <FormSelect
                  control={control}
                  fieldName="county"
                  selectOptions={
                    props.dataSetState[DATASET.MUNICIPALITIES] ? props.dataSetState[DATASET.MUNICIPALITIES] : []
                  }
                  setValue={props.setValue}
                  getValues={props.getValues}
                  disabled={!props.isEditable || !props.getValues("province")}
                  required={false}
                  requiredMessage={intl.formatMessage(messages.Message_Generic_MandatoryField)}
                  errors={props.errors}
                  classes={classes}
                />
                {props.errors["county"] !== undefined && (
                  <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                    {props.errors["county"].message}
                  </FormHelperText>
                )}
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <FormGroup>
                <FormLabel>Função</FormLabel>
                <FormMultipleSelect
                  control={control}
                  fieldName="function"
                  selectOptions={
                    props.dataSetState[DATASET.BACKOFFICE_ROLES] ? props.dataSetState[DATASET.BACKOFFICE_ROLES] : []
                  }
                  limitTags={1}
                  getValues={props.getValues}
                  required={props.isEditable}
                  disabled={!props.isEditable}
                  onOptionChange={getShowExtraFields}
                  requiredMessage={intl.formatMessage(messages.Message_Generic_MandatoryField)}
                  errors={props.errors}
                  classes={classes}
                />
                {props.errors["function"] !== undefined && (
                  <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                    {props.errors["function"].message}
                  </FormHelperText>
                )}
              </FormGroup>
            </Grid>
            {showBankSelection && (
              <>
                <Grid item xs={12} sm={12} md={4}>
                  <FormGroup>
                    <FormLabel>Banco a gerir</FormLabel>
                    <FormSelect
                      control={control}
                      fieldName="managerBank"
                      selectOptions={props.dataSetState[DATASET.BANK] ? props.dataSetState[DATASET.BANK] : []}
                      setValue={props.setValue}
                      getValues={props.getValues}
                      disabled={!props.isEditable}
                      required={showBankSelection}
                      requiredMessage={intl.formatMessage(messages.Message_Generic_MandatoryField)}
                      errors={props.errors}
                      classes={classes}
                    />
                    {props.errors["managerBank"] !== undefined && (
                      <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                        {props.errors["managerBank"].message}
                      </FormHelperText>
                    )}
                  </FormGroup>
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <FormGroup>
                    <FormLabel>Tipos de produtos bancários a gerir</FormLabel>
                    <FormMultipleSelect
                      control={control}
                      fieldName="managerLoanTypes"
                      selectOptions={props.dataSetState[DATASET.LOAN_TYPE] ? props.dataSetState[DATASET.LOAN_TYPE] : []}
                      limitTags={2}
                      getValues={props.getValues}
                      required={showBankSelection}
                      disabled={!props.isEditable}
                      requiredMessage={intl.formatMessage(messages.Message_Generic_MandatoryField)}
                      errors={props.errors}
                      classes={classes}
                    />
                    {props.errors["managerLoanTypes"] !== undefined && (
                      <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                        {props.errors["managerLoanTypes"].message}
                      </FormHelperText>
                    )}
                  </FormGroup>
                </Grid>
              </>
            )}
            {showEmploymentAndEntrepreneurshipSelection && (
              <Grid item xs={12} sm={12} md={4}>
                <FormGroup>
                  <FormLabel>
                    Centro de Emprego
                    <Tooltip title="O centro de Emprego depende da província">
                      <HelpIcon className={classes.tooltipInfoIcon} />
                    </Tooltip>
                  </FormLabel>
                  <FormSelect
                    control={control}
                    fieldName="employmentCenter"
                    selectOptions={
                      props.dataSetState[DATASET.EMPLOYMENT_CENTERS]
                        ? props.dataSetState[DATASET.EMPLOYMENT_CENTERS]
                        : []
                    }
                    setValue={props.setValue}
                    getValues={props.getValues}
                    disabled={!props.isEditable || !props.getValues("province")}
                    required={showEmploymentAndEntrepreneurshipSelection}
                    requiredMessage={intl.formatMessage(messages.Message_Generic_MandatoryField)}
                    errors={props.errors}
                    classes={classes}
                  />
                  {props.errors["employmentCenter"] !== undefined && (
                    <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                      {props.errors["employmentCenter"].message}
                    </FormHelperText>
                  )}
                </FormGroup>
              </Grid>
            )}
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={6} md={6}>
              <FormGroup>
                <FormLabel>E-mail</FormLabel>
                <Controller
                  name="email"
                  control={control}
                  component={Box}
                  defaultValue=""
                  rules={{
                    required: {
                      value: true,
                      message: intl.formatMessage(messages.Message_Generic_MandatoryField),
                    },
                    validate: {
                      validEmail: (value) => {
                        if (value && !/\S+@\S+\.\S+/.test(value))
                          return intl.formatMessage(errorMessages.ErrorMessage_InvalidFormat);
                        return true;
                      },
                    },
                  }}
                  render={({ field: { onChange, name, value } }) => (
                    <OutlinedInput
                      name={name}
                      value={value}
                      disabled={!props.isEditable}
                      fullWidth
                      autoComplete="off"
                      type="email"
                      onChange={(e) => {
                        onChange(e);
                        props.trigger("emailConfirmation");
                      }}
                      classes={{
                        notchedOutline: clsx({
                          [classes.borderWarning]: props.errors["email"] !== undefined,
                        }),
                      }}
                    />
                  )}
                />
                {props.errors["email"] !== undefined && (
                  <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                    {props.errors["email"].message}
                  </FormHelperText>
                )}
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormGroup>
                <FormLabel>Confirmação de E-mail</FormLabel>
                <Controller
                  name="emailConfirmation"
                  control={control}
                  component={Box}
                  defaultValue=""
                  rules={{
                    required: {
                      value: true,
                      message: intl.formatMessage(messages.Message_Generic_MandatoryField),
                    },
                    validate: {
                      validEmail: (value) => {
                        if (value && !/\S+@\S+\.\S+/.test(value))
                          return intl.formatMessage(errorMessages.ErrorMessage_InvalidFormat);
                        return true;
                      },
                      EmailMatch: (value) => {
                        return (
                          value === props.getValues("email") || "O e-mail inserido deve ser igual ao campo 'E-mail'"
                        );
                      },
                    },
                  }}
                  render={({ field: { onChange, name, value } }) => (
                    <OutlinedInput
                      name={name}
                      value={value}
                      fullWidth
                      autoComplete="off"
                      disabled={!props.isEditable}
                      type="email"
                      onChange={(e) => {
                        onChange(e);
                        props.trigger("email");
                      }}
                      classes={{
                        notchedOutline: clsx({
                          [classes.borderWarning]: props.errors["emailConfirmation"] !== undefined,
                        }),
                      }}
                    />
                  )}
                />
                {props.errors["emailConfirmation"] !== undefined && (
                  <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                    {props.errors["emailConfirmation"].message}
                  </FormHelperText>
                )}
              </FormGroup>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getCategories: (name) => dispatch(getCategoriesAction(name)),
  getDataSet: (name, parentKeyValue) => dispatch(getDataSetByNameAction(name, parentKeyValue)),
  getBackOfficeRoles: (name) => dispatch(getBackOfficeRolesAction(name)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BackOfficeUserData);
