import React from "react";
import { connect } from "react-redux";
import { useIntl } from "react-intl";

import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";

import DisplayField from "components/Fields/DisplayField";
import componentStylesGeneric from "assets/theme/views/admin/generic.js";
import { titles, labels } from "resources/resources";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function ContactsSection(props) {
  const classes = useStylesGeneric();
  const intl = useIntl();

  return (
    <>
      <Card className={classes.cardRoot}>
        <CardHeader
            classes={{ title: classes.cardHeader }}
            title= {intl.formatMessage(titles.Title_UserOverview_GeneralDataTab_ContactsSection)}
            data-testid="user-overview-contacts-section-title"
        ></CardHeader>
        <CardContent>
          <Grid container>
            <DisplayField 
                xs={12} sm={6} md={3}
                label={intl.formatMessage(labels.Label_UserOverview_GeneralDataTab_ContactsSection_MobilePhone)}
                data={props?.userGeneralDataOverview?.mobilePhone} 
            />
            {!props.isUserColective && (
              <DisplayField 
                  xs={12} sm={6} md={4}
                  label={intl.formatMessage(labels.Label_UserOverview_GeneralDataTab_ContactsSection_AlternativeMobilePhone)}
                  data={props?.userGeneralDataOverview?.alternativeMobilePhone} 
              />
            )}
            <DisplayField 
                xs={12} sm={6} md={5}
                label={intl.formatMessage(labels.Label_UserOverview_GeneralDataTab_ContactsSection_Email)}
                data={props?.userGeneralDataOverview?.email} 
            />

            {props.isUserColective && (
              <>
                <Grid item xs={12}></Grid>
                <DisplayField 
                  xs={12} sm={6} md={3}
                  label={intl.formatMessage(labels.Label_UserOverview_GeneralDataTab_ContactsSection_ContactName)}
                  data={props?.userGeneralDataOverview?.contactName} 
                />
                <DisplayField 
                  xs={12} sm={6} md={3}
                  label={intl.formatMessage(labels.Label_UserOverview_GeneralDataTab_ContactsSection_ContactMobilePhone)}
                  data={props?.userGeneralDataOverview?.contactMobilePhone} 
                />
                <DisplayField 
                  xs={12} sm={6} md={3}
                  label={intl.formatMessage(labels.Label_UserOverview_GeneralDataTab_ContactsSection_ContactAlternativeMobilePhone)}
                  data={props?.userGeneralDataOverview?.contactAlternativeMobilePhone} 
                />
                <DisplayField 
                  xs={12} sm={6} md={3}
                  label={intl.formatMessage(labels.Label_UserOverview_GeneralDataTab_ContactsSection_ContactEmail)}
                  data={props?.userGeneralDataOverview?.contactEmail} 
                />
              </>
            )}
          </Grid>
        </CardContent>
      </Card>
    </> 
  ); 
}

const mapStateToProps = (state) => ({ ...state });

export default connect(mapStateToProps)(ContactsSection);
