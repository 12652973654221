import axios from "axios";
import { INTERNAL_API_URL, EXTERNAL_API_URL } from "../index";

export const GET_FILTERED_APPLICATIONS_URL =
  "Applications/get-filtered-applications";
export const GET_APPLICATION_DETAILS_GENERAL_DATA_URL =
  "Applications/get-application-details-general-data?applicationNumber=";
export const UPDATE_APPLICATION_DETAILS_GENERAL_DATA_URL =
  "Applications/update";
export const GET_APPLICATION_DETAILS_HISTORY_URL =
  "Applications/get-application-history?applicationNumber=";
export const CREATE_APPLICATION_DETAILS_HISTORY_ENTRY_URL =
  "Applications/create-application-history-entry";
export const GET_APPLICATION_DOCUMENT_URL =
  "Applications/get-application-document-by-id?docId=";
export const GET_USER_APPLICATIONS_URL = "applications/get-user-applications";  

export function getFilteredApplications(data) {
  return axios.post(INTERNAL_API_URL + GET_FILTERED_APPLICATIONS_URL, data);
}

export function getApplicationDetailsGeneralData(data) {
  return axios.get(
    EXTERNAL_API_URL + GET_APPLICATION_DETAILS_GENERAL_DATA_URL + data
  );
}

export function updateApplicationDetailGeneralData(data) {
  return axios.post(
    EXTERNAL_API_URL + UPDATE_APPLICATION_DETAILS_GENERAL_DATA_URL,
    data
  );
}

export function getApplicationDetailsHistory(data) {
  return axios.get(
    INTERNAL_API_URL + GET_APPLICATION_DETAILS_HISTORY_URL + data
  );
}

export function addApplicationDetailHistoryEntry(data) {
  return axios.post(
    INTERNAL_API_URL + CREATE_APPLICATION_DETAILS_HISTORY_ENTRY_URL,
    data
  );
}

export function getApplicationDocument(data) {
  return axios.get(EXTERNAL_API_URL + GET_APPLICATION_DOCUMENT_URL + data, {
    headers: {
      "Content-Type": "application/json",
    },
    responseType: "blob",
  });
}

export function getUserApplications(data) {
  return axios.post(EXTERNAL_API_URL + GET_USER_APPLICATIONS_URL, data);
}
