import { backOfficeUserRegister } from "crud/registerbouser.crud";
import { REGISTER_BO_USER, DIALOG } from "utils/actionsConsts";

export const registerBoUserAction = (formData) => async (dispatch) => {
  dispatch({
    type: REGISTER_BO_USER.REGISTER_LOADING,
    payload: true,
  });
  backOfficeUserRegister(formData)
    .then((res) => {
      dispatch({
        type: REGISTER_BO_USER.REGISTER,
        payload: res.data ? true : false,
      });

      dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            isSuccess: res.data ? true : false,
            message: "Utilizador registado com sucesso",
            goBack: true,
            onClose: "/admin/backofficeusers-management",
          },
        }
      });
      dispatch({
        type: REGISTER_BO_USER.REGISTER_LOADING,
        payload: false,
      });
    })
    .catch((error) => {
      dispatch({
        type: REGISTER_BO_USER.REGISTER,
        payload: false,
      });
      dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.SIMPLE_TYPE,
            isSuccess: false,
            message: error.response?.data?.errors
              ? error.response.data.errors[
              Object.keys(error.response.data.errors)[0]
              ][0]
              : "Erro a efetuar o seu pedido. Por favor tente mais tarde",
          },
        },
      });
      dispatch({
        type: REGISTER_BO_USER.REGISTER_LOADING,
        payload: false,
      });
      console.log("error", error);
    });
};

export default registerBoUserAction;