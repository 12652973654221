export const getListAsString = (list, separator) => {
  let returnString = "";
  let auxList = [];
  if (list == null || list === undefined) return null;
  list.map((l) => auxList.push(l.code));
  returnString = auxList.join(separator);
  return returnString;
};

export const getListAsStringLabels = (list, separator) => {
  let returnString = "";
  let auxList = [];
  if (list == null || list === undefined) return null;
  auxList = list.map((l) => l.label);
  returnString = auxList.join(separator);
  return returnString;
};

export const getStringFromList = (list, separator) => {
  let returnString = "";
  if (list == null || list === undefined) return null;
  returnString = list.join(separator);
  return returnString;
};

export const getListFromFormData = (list) => {
  if (!list) return null;
  return list.map((l) => l.code);
};
export default getListAsString;
