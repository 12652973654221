import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import Carousel from "react-material-ui-carousel";
// @material-ui/core components
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
// @material-ui/icons components
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
//import custom components
import componentStylesGeneric from "assets/theme/views/admin/generic.js";
import componentStylesCarousel from "assets/theme/components/genericPage/carouselMediaPreviewSection";
import GenericDialog from "components/Dialogs/GenericDialogV2";

const useStylesCarousel = makeStyles(componentStylesCarousel);
const useStylesGeneric = makeStyles(componentStylesGeneric);

function CarouselMediaPreviewSection(props) {
  const theme = useTheme();
  const classes = { ...useStylesGeneric(), ...useStylesCarousel() };

  const [fullscreenImageSrc, setFullscreenImageSrc] = useState(null);
  const [showImagePreview, setShowImagePreview] = useState(false);

  //Hook that alerts clicks outside of the passed ref
  function ClickOutsideComponent(ref) {
    useEffect(() => {
      //Alert if clicked on outside of element
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowImagePreview(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const wrapperRef = useRef(null);
  ClickOutsideComponent(wrapperRef);

  return (
    <>
      {props.mediaList !== undefined && props.mediaList !== null && props.mediaList.length > 0 && (
        <Carousel
          autoPlay={false}
          cycleNavigation={true}
          height="50vh"
          navButtonsAlwaysVisible={true}
          navButtonsProps={{
            style: {
              backgroundColor: theme.palette.primary.greyBlue,
            },
          }}
          indicatorIconButtonProps={{
            style: {
              padding: "2px",
              color: theme.palette.primary.main,
            },
          }}
          IndicatorIcon={<FiberManualRecordIcon className={classes.indicatorIcon} />}
          activeIndicatorIconButtonProps={{
            style: {
              backgroundColor: theme.palette.primary.main,
            },
          }}
          indicatorContainerProps={{
            style: {},
          }}
        >
          {props.mediaList.map((mediaItem, key) =>
            mediaItem.photo !== null ? (
              <div key={key} className={classes.photoItemDiv}>
                <Tooltip
                  title="Clique para visualizar a imagem"
                  placement="top"
                  arrow
                  classes={{ tooltip: classes.tooltip, arrow: classes.tooltipArrow }}
                >
                  <img
                    src={mediaItem[props.mediaItemPhotoName]}
                    alt={""}
                    className={classes.img}
                    onClick={() => {
                      setFullscreenImageSrc(mediaItem[props.mediaItemPhotoName]);
                      setShowImagePreview(true);
                    }}
                  />
                </Tooltip>
              </div>
            ) : (
              <div key={key} className={classes.videoItemDiv}>
                <iframe
                  key={key}
                  id={`mediaVideoUrlId${key}`}
                  src={mediaItem[props.mediaItemVideoName]}
                  width="50%"
                  loading="lazy"
                  title={`mediaVideoUrl${key}`}
                  allow="fullscreen"
                />
              </div>
            )
          )}
        </Carousel>
      )}

      {/* Image Preview */}
      <GenericDialog maxWidth={false} open={showImagePreview} onClose={() => setShowImagePreview(false)}>
        <div
          ref={wrapperRef}
          tabIndex={0}
          className={classes.previewDialogDiv}
          onKeyDown={(e) => {
            if (e.key === "Escape") {
              setShowImagePreview(false);
              e.preventDefault();
            }
          }}
        >
          <img src={fullscreenImageSrc} alt={""} style={{ maxHeight: "fit-content", maxWidth: "100%" }} />
        </div>
      </GenericDialog>
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(CarouselMediaPreviewSection);
