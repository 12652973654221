import { React, useState, useEffect } from "react";
import { connect } from "react-redux";
import { useIntl } from "react-intl";
import { Controller, useFormContext } from "react-hook-form";
import clsx from "clsx";
// @material-ui/core components
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import OutlinedInput from "@material-ui/core/OutlinedInput";
// @material-ui/icons components
import SearchIcon from "@material-ui/icons/Search";
//import custom components
import GenericBackdrop from "components/Backdrops/GenericBackdrop";
import FormSelect from "components/FormFields/FormSelect";
import OpportunityData from "./OpportunityData";

import { getDatasetStateByName } from "utils/datasets";
import { getDataSetByNameAction } from "redux/actions/datasetsAction";
import { getFoUserColectiveBasicInfoAction } from "redux/actions/usersAction";
import { USERS } from "utils/actionsConsts";
import { DATASET } from "utils/consts";
import { errorMessages } from "resources/resources";

import componentStylesGeneric from "assets/theme/views/admin/generic.js";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function OpportunityCreation(props) {
  const classes = useStylesGeneric();
  const theme = useTheme();
  const intl = useIntl();
  const methods = useFormContext();
  const [opportunityType, setOpportunityType] = useState(undefined);

  useEffect(() => {
    if (
      props.dataSetState[DATASET.OPPORTUNITY_TYPE] === undefined ||
      props.dataSetState[DATASET.OPPORTUNITY_TYPE]?.length === 0
    ) {
      props.getDataSetByName(DATASET.OPPORTUNITY_TYPE);
    }

    props.clearFoUserColectiveBasicInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (opportunityType !== undefined && props.usersInfo.foUserColectiveBasicInfo?.id) {
      props.setShowOpportunityDataSection(true);
    } else {
      props.setShowOpportunityDataSection(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opportunityType, props.usersInfo?.foUserColectiveBasicInfo?.id]);

  const controlOpportunityType = (selectedType) => {
    if (selectedType) {
      let auxData = [];
      auxData["opportunityTypeKey"] = selectedType.keyValue;
      setOpportunityType(auxData);
    } else {
      setOpportunityType(undefined);
    }
    unregisterAndClearAllFieldForms();
  };

  const unregisterAndClearAllFieldForms = () => {
    methods.setValue("province", undefined);
    methods.setValue("timetableType", undefined);
    methods.setValue("description", "");
    methods.setValue("amount", "");
    methods.setValue("numVacancies", "");
    methods.setValue("reference", "");
    methods.setValue("opportunityProfessionalCategories", undefined);
    methods.setValue("contractType", undefined);
    methods.setValue("internshipType", undefined);
    methods.setValue("validFrom", undefined);
    methods.setValue("validTo", undefined);
    methods.setValue("mentorName", undefined);
    methods.setValue("mentorMobilePhone", undefined);
    methods.setValue("reportingRequired", false);
    methods.setValue("reportingPeriod", "");
    methods.unregister([
      "province",
      "timetableType",
      "description",
      "amount",
      "numVacancies",
      "reference",
      "contractType",
      "internshipType",
      "opportunityProfessionalCategories",
      "validFrom",
      "validTo",
      "mentorName",
      "mentorMobilePhone",
      "reportingRequired",
      "reportingPeriod",
    ]);

    unregisterAndClearQuestionFields();
  };

  const unregisterAndClearQuestionFields = () => {
    props.opportunityQuestionsState?.forEach((q) => {
      methods.setValue(q.name, "");
      methods.unregister(q.name);
    });
    props.setOpportunityQuestionsState((prevState) => {
      prevState.length = 0;
      return prevState;
    });
  };

  const getEnterpriseBasicInfo = () => {
    const taxIdNumber = methods.getValues("taxIdNumber");
    if (taxIdNumber) props.getFoUserColectiveBasicInfo({ taxIdNumber: taxIdNumber });
    else {
      methods.trigger("taxIdNumber");
    }
  };

  return (
    <>
      <Container maxWidth={false}>
        <GenericBackdrop open={props.usersInfo.getUserInfo_loading} />

        <Card className={classes.cardRoot}>
          <CardHeader classes={{ title: classes.cardHeader }} title="Tipo de Oferta" />
          <CardContent>
            <Grid container>
              <Grid item xs={12} md={3}>
                <FormGroup>
                  <FormLabel>Tipo de Oferta</FormLabel>
                  <FormSelect
                    control={methods.control}
                    fieldName="opportunityType"
                    selectOptions={getDatasetStateByName(props, DATASET.OPPORTUNITY_TYPE)}
                    getValues={methods.getValues}
                    setValue={methods.setValue}
                    onOptionChange={controlOpportunityType}
                    required={true}
                    requiredMessage={intl.formatMessage(errorMessages.ErrorMessage_FieldRequired)}
                    errors={methods.formState.errors}
                    classes={classes}
                  />
                  {methods.formState.errors["opportunityType"] !== undefined && (
                    <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                      {methods.formState.errors["opportunityType"].message}
                    </FormHelperText>
                  )}
                </FormGroup>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} md={3}>
                <FormGroup>
                  <FormLabel>NIF da empresa</FormLabel>
                  <Controller
                    name="taxIdNumber"
                    control={methods.control}
                    component={Box}
                    defaultValue=""
                    rules={{
                      required: {
                        value: true,
                        message: "O campo é obrigatório",
                      },
                    }}
                    render={({ field: { onChange, name, value } }) => (
                      <OutlinedInput
                        name={name}
                        value={value}
                        onChange={onChange}
                        fullWidth
                        autoComplete="off"
                        type="text"
                        placeholder={"Pesquisar"}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              style={{ padding: "10px" }}
                              aria-label="details"
                              onClick={() => {
                                getEnterpriseBasicInfo();
                              }}
                            >
                              <Box component={SearchIcon} width="15px!important" height="15px!important" />
                            </IconButton>
                          </InputAdornment>
                        }
                        classes={{
                          notchedOutline: clsx({
                            [classes.borderWarning]: methods.formState.errors["taxIdNumber"] !== undefined,
                          }),
                        }}
                      />
                    )}
                  />
                  {methods.formState.errors["taxIdNumber"] !== undefined && (
                    <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                      {methods.formState.errors["taxIdNumber"].message}
                    </FormHelperText>
                  )}
                </FormGroup>
              </Grid>
              <Grid item xs={12} md={3}>
                <FormGroup>
                  <FormLabel>Nome</FormLabel>
                  <Box>{props.usersInfo.foUserColectiveBasicInfo?.name}</Box>
                </FormGroup>
              </Grid>
              <Grid item xs={12} md={3}>
                <FormGroup>
                  <FormLabel>NISS</FormLabel>
                  <Box>{props.usersInfo.foUserColectiveBasicInfo?.niss}</Box>
                </FormGroup>
              </Grid>
              <Grid item xs={12} md={3}>
                <FormGroup>
                  <FormLabel>Nome do Responsável</FormLabel>
                  <Box>{props.usersInfo.foUserColectiveBasicInfo?.contactName}</Box>
                </FormGroup>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        {opportunityType !== undefined && props.usersInfo.foUserColectiveBasicInfo?.id && (
          <>
            <OpportunityData
              isCreationPage={true}
              isEditable={true}
              opportunityDetailsData={opportunityType}
              setOpportunityQuestionsState={props.setOpportunityQuestionsState}
              opportunityQuestionsState={props.opportunityQuestionsState}
            />
          </>
        )}
      </Container>
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getDataSetByName: (name, parentKeyValue) => dispatch(getDataSetByNameAction(name, parentKeyValue)),
  getFoUserColectiveBasicInfo: (data) => dispatch(getFoUserColectiveBasicInfoAction(data)),
  clearFoUserColectiveBasicInfo: () =>
    dispatch({
      type: USERS.GET_FO_USER_COLECTIVE_BASIC_INFO,
      result: false,
      payload: {},
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(OpportunityCreation);
