const componentStyles = (theme) => ({
  modalResult: {
    backgroundColor: theme.palette.white.main,
    borderRadius: "5px",
    width: "512px",
    height: "350px",
  },
  modalResultClose: {
    marginLeft: "90%",
  },
  modalResultHeader: {
    fontStyle: "normal",
    lineHeight: "33px",
    fontSize: "24px",
    fontWeight: "600",
    textAlign: "center",
    marginBottom: "1.5rem",
    marginTop: "5rem",
    color: theme.palette.dark.main,
  },
  modalResultIcon: {
    marginRight: "8px",
    color: theme.palette.success.main,
    width: "88px",
    height: "88px",
    position: "absolute",
    left: "41.41%",
    right: "41.41%",
    top: "14.33%",
    bottom: "57.64%",
    boxSizing: "border-box",
  },
  modalHelpIcon: {
    marginRight: "8px",
    color: "#87adbd",
    width: "120px",
    height: "120px",
    position: "absolute",
    left: "38%",
    right: "41.41%",
    bottom: "57.64%",
    boxSizing: "border-box",
  },
  modalResultInfoText: {
    textAlign: "center",
    marginTop: "-15px",
  },
  cardContentKitCharacterizationResult: {
    [theme.breakpoints.up("md")]: {
      padding: "3rem",
    },
  },
  modalErrorResultIcon: {
    marginRight: "8px",
    color: theme.palette.error.main,
    width: "88px",
    height: "88px",
    position: "absolute",
    left: "41.41%",
    right: "41.41%",
    top: "14.33%",
    bottom: "57.64%",
    boxSizing: "border-box",
  },
  centeredButton: {
    marginRight: "auto", 
    marginLeft: "auto", 
    display: "block",
  },
});

export default componentStyles;
