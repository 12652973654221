import React from "react";
import { connect } from "react-redux";
import { useIntl } from "react-intl";
import "moment/locale/pt";
import { Link, useHistory } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import LinkMaterialUi from "@material-ui/core/Link";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";
//import custom components
import DisplayField from "components/Fields/DisplayField";
import GenericButton from "components/Buttons/GenericButton";
import { labels, titles } from "resources/resources";
import componentStylesGeneric from "assets/theme/views/admin/generic";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function UserColectiveGeneralDataSection(props) {
  const classes = useStylesGeneric();
  const intl = useIntl();
  const history = useHistory();

  const redirectToUserOverView = (event) => {
    event.preventDefault();
    history.push("/admin/user-overview/" + props.generalData.userId);
  };

  return (
    <>
      <Card classes={{ root: classes.cardRoot }}>
        <CardHeader
          classes={{ title: classes.cardHeader, action: classes.cardHeaderAction }}
          title={intl.formatMessage(titles.Title_Admin_ApplicationDetail_GeneralDataSection)}
          action={
            <GenericButton typeSubmit={false} color="tertiary" size="small" onClick={redirectToUserOverView}>
              {intl.formatMessage(labels.Label_SeeMore)}
            </GenericButton>
          }
        ></CardHeader>
        <CardContent>
          <Grid container>
            <DisplayField xs={12} sm={8} md={3} label={"Nome da Empresa"} data={props.generalData?.companyName} />
            <DisplayField
              xs={12}
              sm={8}
              md={3}
              label={intl.formatMessage(labels.Label_UserCodeField_short)}
              data={props.generalData?.userCode}
            />
            <DisplayField xs={12} sm={4} md={3} label={"NIF da Empresa"} data={props.generalData?.companyTaxIdNumber} />
            <DisplayField xs={12} sm={6} md={3} label={"Telemóvel da Empresa"} data={props.generalData?.companyPhone} />
            <DisplayField xs={12} sm={6} md={3} label={"Email da Empresa"} data={props.generalData?.companyEmail} />
            <DisplayField
              xs={12}
              sm={4}
              md={3}
              label={"Nome do Responsável"}
              data={props.generalData?.responsibleName}
            />
            <DisplayField
              xs={12}
              sm={4}
              md={3}
              label={"Telemóvel do Responsável"}
              data={props.generalData?.responsibleMobilePhone}
            />
            <DisplayField
              xs={12}
              sm={4}
              md={3}
              label={"Email do Responsável"}
              data={props.generalData?.responsibleEmail}
            />
            <Grid item xs={12} sm={4} md={3}>
              <FormGroup>
                <FormLabel className={classes.detailFormLabels}>
                  {intl.formatMessage(labels.Label_Admin_GeneralDataSection_OtherApplicationsField)}
                </FormLabel>
                <Box className={classes.detailLabels}>
                  {props.generalData !== undefined ? props.generalData.otherApplicationsOfUser : 0}
                  <LinkMaterialUi
                    style={{ marginLeft: "50px" }}
                    component={Link}
                    variant="h5"
                    to={`/admin/applications-management/${props.generalData?.numBI ?? ""}`}
                    className={classes.containedGhostButton}
                  >
                    Ver mais
                  </LinkMaterialUi>
                </Box>
              </FormGroup>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

export default connect(mapStateToProps)(UserColectiveGeneralDataSection);
