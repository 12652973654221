import { React, useEffect, useState } from "react";
import { connect } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";

//import custom components
import DisplayField from "components/Fields/DisplayField";
import componentStylesGeneric from "assets/theme/views/admin/generic.js";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function CandidateAnswers(props) {
  const classes = useStylesGeneric();
  const [data, setData] = useState();

  useEffect(() => {
    if (props.opportunitiesState?.candidateInfo !== null && props.opportunitiesState?.candidateInfo !== undefined) {
      setData(props.opportunitiesState.candidateInfo);
    } else {
      setData([]);
    }
  }, [props.opportunitiesState?.candidateInfo]);

  return (
    <>
      <Container maxWidth={false}>
        <Card className={classes.cardRoot}>
          <CardHeader classes={{ title: classes.cardHeader }} title={"Questões e Respostas do Candidato"} />
          <CardContent>
            <Grid container>
              {data?.opportunityCandidateAnswers?.map((prop, key) => (
                <DisplayField key={key} xs={12} sm={12} md={12} label={prop.question} data={prop.answer} />
              ))}
            </Grid>
          </CardContent>
        </Card>
      </Container>
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(CandidateAnswers);
