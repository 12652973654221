import { MONETARY_DECIMAL_SEPARATOR, COMPLEMENTARY_DATA_TYPE, PAGINATION } from "utils/consts";

export function subprogramCreationMap(formData, complementaryDataList) {
  if (formData !== null && formData !== undefined) {
    const listCategoryIds = formData.categoryList?.map((categoryId) => {
      return categoryId?.code;
    });
    const listDocumentIds = formData.documentsList?.map((docId) => {
      return docId?.code;
    });

    let listComplementaryDataItems = complementaryDataList?.map((compleData) => {
      return {
        title: compleData.itemTitle,
        question: compleData.itemText,
        answerTypeId: compleData.itemType,
        dataSetEnumId: compleData.itemType !== COMPLEMENTARY_DATA_TYPE.PLAIN_TEXT ? compleData.itemDatasetEnumId : null,
        complementaryDataQuestionKey: compleData.itemKey,
        isRequired: compleData.itemIsRequired,
        complementaryDataConditionsList: compleData.conditionsList?.map((condition) => {
          return {
            conditionTypeId: condition?.conditionType?.code,
            conditionalQuestionKey: condition?.conditionalQuestionId?.code,
            conditionalValue: condition?.conditionalValue?.code?.toString(),
          };
        }),
      };
    });

    return {
      governmentProgramId: formData?.governmentProgramId?.code ? formData?.governmentProgramId?.code : "",
      programId: formData.programId?.code ? formData.programId?.code : "",
      name: formData.subprogramName ? formData.subprogramName : "",
      objectives: formData.subprogramObjectives ? formData.subprogramObjectives : "",
      description: formData.subprogramDescription ? formData.subprogramDescription : "",
      validFrom: formData.subprogramValidFrom ? formData.subprogramValidFrom.format("YYYY-MM-DD") : "",
      validTo: formData.subprogramValidTo ? formData.subprogramValidTo.format("YYYY-MM-DD") : "",
      statusId: 1,
      published: false,
      publishedOn: null,
      numVacancies: formData.numVacancies ? formData.numVacancies : 0,
      microcreditMinValue: formData.minMicrocreditValue ? formData.minMicrocreditValue.replace(MONETARY_DECIMAL_SEPARATOR.WEB, MONETARY_DECIMAL_SEPARATOR.API) : 0,
      microcreditMaxValue: formData.maxMicrocreditValue ? formData.maxMicrocreditValue.replace(MONETARY_DECIMAL_SEPARATOR.WEB, MONETARY_DECIMAL_SEPARATOR.API) : 0,
      microcreditInterestRate: formData.interestRate ? formData.interestRate.replace(MONETARY_DECIMAL_SEPARATOR.WEB, MONETARY_DECIMAL_SEPARATOR.API) : 0,
      microcreditGracePeriod: formData.gracePeriod ? formData.gracePeriod : 0,
      microcreditRepaymentPeriod: formData.repaymentPeriod ? formData.repaymentPeriod : 0,
      categoriesList: listCategoryIds !== undefined ? listCategoryIds : [],
      numMaxKits: formData.numMaxKits ? formData.numMaxKits : 0,
      benefitType: formData.benefitType?.code ? formData.benefitType?.code : "",
      departmentId: formData.departmentId?.code ? formData.departmentId?.code : "",
      documentsList: listDocumentIds !== undefined ? listDocumentIds : [],
      complementaryDataList: listComplementaryDataItems !== undefined ? listComplementaryDataItems : [],
    };
  }

  return formData;
}

export function subprogramFilterMap(formData) {
  if (formData !== null && formData !== undefined) {
    return {
      benefitTypeId: formData.benefitTypeId?.code,
      departmentId: formData.departmentId?.code,
      governmentProgramId: formData.governmentProgramId?.code,
      programId: formData.programId?.code,
      subprogramStateId: formData.statusId?.code,
      subProgramId: formData.subProgramId?.code,
      pageIndex: PAGINATION.FIRST_PAGE,
      pageSize: PAGINATION.PAGE_SIZE,
    };
  }
}

export function subprogramUpdateMap(formData, complementaryDataList) {
  if (formData !== null && formData !== undefined) {
    let listCategoryIds = formData.categoryList?.map((categoryId) => {
      return categoryId.code;
    });
    let listDocumentIds = formData.documentsList?.map((docId) => {
      return docId.code;
    });

    let listComplementaryDataItems = complementaryDataList?.map((compleData) => {
      return {
        title: compleData.itemTitle,
        question: compleData.itemText,
        answerTypeId: compleData.itemType,
        dataSetEnumId: compleData.itemType !== COMPLEMENTARY_DATA_TYPE.PLAIN_TEXT ? compleData.itemDatasetEnumId : null,
        complementaryDataQuestionKey: compleData.itemKey,
        isRequired: compleData.itemIsRequired,
        complementaryDataConditionsList: compleData.conditionsList?.map((condition) => {
          return {
            conditionTypeId: condition?.conditionType?.code,
            conditionalQuestionKey: condition?.conditionalQuestionId?.code,
            conditionalValue: condition?.conditionalValue?.code?.toString(),
          };
        }),
      };
    });

    return {
      governmentProgramId: formData.governmentProgramId?.code ? formData.governmentProgramId?.code : "",
      programId: formData.programId?.code ? formData.programId?.code : "",
      subprogramId: formData.subprogramId ? formData.subprogramId : null,
      subprogramName: formData.subprogramName ? formData.subprogramName : "",
      objectives: formData.subprogramObjectives ? formData.subprogramObjectives : "",
      description: formData.subprogramDescription ? formData.subprogramDescription : "",
      validFrom: formData.subprogramValidFrom ? formData.subprogramValidFrom.format("YYYY-MM-DD") : "",
      validTo: formData.subprogramValidTo ? formData.subprogramValidTo.format("YYYY-MM-DD") : "",
      numVacancies: formData.numVacancies ? formData.numVacancies : 0,
      departmentId: formData.departmentId?.code ? formData.departmentId?.code : "",
      microcreditMinValue: formData.minMicrocreditValue ? formData.minMicrocreditValue.toString().replace(MONETARY_DECIMAL_SEPARATOR.WEB, MONETARY_DECIMAL_SEPARATOR.API) : 0,
      microcreditMaxValue: formData.maxMicrocreditValue ? formData.maxMicrocreditValue.toString().replace(MONETARY_DECIMAL_SEPARATOR.WEB, MONETARY_DECIMAL_SEPARATOR.API) : 0,
      microcreditInterestRate: formData.interestRate ? formData.interestRate.toString().replace(MONETARY_DECIMAL_SEPARATOR.WEB, MONETARY_DECIMAL_SEPARATOR.API) : 0,
      microcreditGracePeriod: formData.gracePeriod ? formData.gracePeriod : 0,
      microcreditRepaymentPeriod: formData.repaymentPeriod ? formData.repaymentPeriod : 0,
      categoriesList: listCategoryIds !== undefined ? listCategoryIds : [],
      numMaxKits: formData.numMaxKits ? formData.numMaxKits : 0,
      benefitType: formData.benefitType?.code ? formData.benefitType?.code : "",
      documentsList: listDocumentIds !== undefined ? listDocumentIds : [],
      complementaryDataList: listComplementaryDataItems !== undefined ? listComplementaryDataItems : [],
    };
  }

  return formData;
}

export default subprogramCreationMap;