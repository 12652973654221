import { React} from "react";
import { connect } from "react-redux";
import { useIntl } from "react-intl";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
//import custom components
import componentStylesGeneric from "assets/theme/views/admin/generic.js";
import "react-quill/dist/quill.snow.css";
//Header
import PageTitleHeader from "components/Headers/PageTitleHeader.js";
import LocationHeader from "components/Headers/LocationHeader.js";
import CreateEditSection from "./Sections/CreateEditSection";
import { titles } from "resources/resources";
import useScrollDirection from "hooks/useScrollDirection";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function Create() {
  const classes = useStylesGeneric();
  const intl = useIntl();
  const scrollDirection = useScrollDirection();

  return (
    <>
      {scrollDirection === 'up' && (
        <div className={classes.breadcrumbs}>
          <LocationHeader
            section={intl.formatMessage(titles.Title_InformationDissemination_CreateInformation)}
            subsection={intl.formatMessage(titles.Title_InformationDissemination_InformationManagement)}
            subsectionLink={"/admin/informationdissemination-management"}
          />
        </div>
      )}
      <Container maxWidth={false} classes={{ root: classes.containerRootAfterBreadcrumbs }}>
        <PageTitleHeader title={intl.formatMessage(titles.Title_InformationDissemination_CreateInformation)} />

        <CreateEditSection isEditable={false} />
      </Container>
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Create);
