import { React, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import { useIntl } from "react-intl";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import FormGroup from "@material-ui/core/FormGroup";
import Grid from "@material-ui/core/Grid";
//import custom components
import GenericButton from "components/Buttons/GenericButton";
import PageTitleHeader from "components/Headers/PageTitleHeader.js";
import LocationHeader from "components/Headers/LocationHeader.js";
import OpportunityCreation from "./Sections/OpportunityCreation";
import { createOpportunityAction } from "redux/actions/opportunitiesAction";
import { createOpportunityMap } from "mappers/opportunityMap";
import { DATASET } from "utils/consts";
import { labels } from "resources/resources";
import useScrollDirection from "hooks/useScrollDirection";

import componentStylesGeneric from "assets/theme/views/admin/generic.js";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function OpportunityCreate(props) {
  const classes = useStylesGeneric();
  const intl = useIntl();
  const history = useHistory();
  const scrollDirection = useScrollDirection();
  const [opportunityQuestionsState, setOpportunityQuestionsState] = useState(
    []
  );
  const [showOpportunityDataSection, setShowOpportunityDataSection] =
    useState(false);

  const methods = useForm({
    mode: "onChange",
  });

  // useEffect(() => {}, []);

  const handleCancelOnClickEvent = () => {
    history.goBack();
  };

  const onSubmit = (data) => {
    if (props.usersInfo.foUserColectiveBasicInfo?.id) {
      data["userId"] = props.usersInfo.foUserColectiveBasicInfo.id;
      props.createOpportunityAction(
        createOpportunityMap(
          data,
          opportunityQuestionsState,
          props.dataSetState[DATASET.OPPORTUNITY_DOCUMENT_TYPE]
        )
      );
    }
  };

  return (
    <>
      {scrollDirection === "up" && (
        <div className={classes.breadcrumbs}>
          <LocationHeader
            section={"Criar Oferta"}
            subsection={"Lista de Ofertas"}
            subsectionLink={"/admin/opportunity-management"}
          />
        </div>
      )}

      <Container
        maxWidth={false}
        classes={{ root: classes.containerRootAfterBreadcrumbs }}
      >
        <PageTitleHeader title={"Criar Oferta"} />
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <OpportunityCreation
              setOpportunityQuestionsState={setOpportunityQuestionsState}
              opportunityQuestionsState={opportunityQuestionsState}
              setShowOpportunityDataSection={setShowOpportunityDataSection}
            />

            <Container maxWidth={false}>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  lg={showOpportunityDataSection ? 6 : 9}
                  xl={showOpportunityDataSection ? 8 : 10}
                ></Grid>
                <Grid item xs={12} lg={3} xl={2}>
                  <FormGroup>
                    <GenericButton
                      typeSubmit={false}
                      color="secondary"
                      onClick={handleCancelOnClickEvent}
                    >
                      {intl.formatMessage(labels.Label_Generic_Cancel)}
                    </GenericButton>
                  </FormGroup>
                </Grid>
                {showOpportunityDataSection && (
                  <Grid item xs={12} lg={3} xl={2}>
                    <FormGroup>
                      <GenericButton
                        typeSubmit={true}
                        color="primary"
                        loading={
                          props.opportunitiesState?.opportunitySubmitLoading
                        }
                      >
                        {intl.formatMessage(labels.Label_Generic_Submit_Button)}
                      </GenericButton>
                    </FormGroup>
                  </Grid>
                )}
              </Grid>
            </Container>
          </form>
        </FormProvider>
      </Container>
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  createOpportunityAction: (data) => dispatch(createOpportunityAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OpportunityCreate);
