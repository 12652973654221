import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { connect } from "react-redux";
import { useIntl } from "react-intl";
// @material-ui/core components
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
//import custom components

import FormMultipleSelect from "../../../../components/FormFields/FormMultipleSelect";
import GenericButton from "components/Buttons/GenericButton";

import { getDocumentsAction } from "redux/actions/datasetsAction";
import { getMultipleSelectOptionsElementByCode } from "utils/formSelect";
import { DATASET, SUBPROGRAM_STATES } from "utils/consts";
import { titles, labels } from "resources/resources";

import componentStylesGeneric from "assets/theme/views/admin/generic.js";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function DocumentsSection(props) {
  const classes = useStylesGeneric();
  const theme = useTheme();
  const intl = useIntl();
  const { control } = useFormContext();

  useEffect(() => {
    if (props.dataSetState[DATASET.DOCUMENTS] === undefined || props.dataSetState[DATASET.DOCUMENTS]?.length === 0) {
      props.getDocuments(DATASET.DOCUMENTS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.subprogramDetailsData !== undefined) {
      if (props.loadData) {
        const documentsList = props.dataSetState[DATASET.DOCUMENTS] ? props.dataSetState[DATASET.DOCUMENTS] : [];

        const subProgramDocuments =
          props.subprogramDetailsData.documents?.map((d) => {
            return d.documentTypeId;
          }) ?? [];

        props.setValue("documentsList", getMultipleSelectOptionsElementByCode(documentsList, subProgramDocuments));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.subprogramDetailsData]);

  const handleEditSection = () => {
    props.handleEditSection();
  };

  return (
    <>
      <Card classes={{ root: classes.cardRoot }}>
        <CardHeader
          classes={{ title: classes.cardHeader, action: classes.cardHeaderAction }}
          title={intl.formatMessage(titles.Title_Admin_Subprogram_DocumentsSection)}
          action={
            props.loadData &&
            props.subprogramDetailsData?.stateKey !== SUBPROGRAM_STATES.PUBLICADO &&
            props.subprogramDetailsData?.stateKey !== SUBPROGRAM_STATES.CONCLUIDO && (
              <GenericButton typeSubmit={false} color="tertiary" size="small" onClick={handleEditSection}>
                {intl.formatMessage(labels.Label_Generic_Edit)}
              </GenericButton>
            )
          }
        ></CardHeader>
        <CardContent>
          <Grid container>
            <Grid item xs={12} md={6}>
              <FormGroup>
                <FormLabel>Descrição do documento (opcional)</FormLabel>
                <FormMultipleSelect
                  control={control}
                  fieldName="documentsList"
                  selectOptions={
                    props.dataSetState[DATASET.DOCUMENTS] !== undefined ? props.dataSetState[DATASET.DOCUMENTS] : []
                  }
                  initialLabel="Seleccione opções aplicáveis"
                  getValues={props.getValues}
                  limitTags={3}
                  required={false}
                  requiredMessage={"O campo é obrigatório"}
                  errors={props.errors}
                  classes={classes}
                  disabled={!props.isEditable}
                />
                {props.errors["documentsList"] !== undefined && (
                  <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                    {props.errors["documentsList"].message}
                  </FormHelperText>
                )}
              </FormGroup>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getDocuments: (name) => dispatch(getDocumentsAction(name)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsSection);
