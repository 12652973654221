import { React, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import clsx from "clsx";
import { useIntl } from "react-intl";
// @material-ui/core components
import FormLabel from "@material-ui/core/FormLabel";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import FormGroup from "@material-ui/core/FormGroup";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";
import InputAdornment from "@material-ui/core/InputAdornment";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
// @material-ui/icons components
import SearchIcon from "@material-ui/icons/Search";
// src components
import TableBackdrop from "components/Backdrops/TableBackdrop/TableBackdrop";
import FormSelect from "components/FormFields/FormSelect";
import FormInput from "components/FormFields/FormInput";
import GenericButton from "components/Buttons/GenericButton";
import {
  getFilteredPossibleOpportunityCandidatesAction,
  addOpportunityCandidatesListAction,
} from "redux/actions/opportunitiesAction";
import { getDataSetByNameAction } from "redux/actions/datasetsAction";
import { getFilteredPossibleOpportunityCandidatesMap } from "mappers/opportunityMap";
import { PAGINATION, DATASET, OPPORTUNITY_TYPE_KEY } from "utils/consts";
import { ArrayUtils } from "utils/array_utils";
import { getSelectOptionsElementByCode } from "utils/formSelect";
import { labels, errorMessages } from "resources/resources";
import { getDatasetStateByName } from "utils/datasets";

import componentStylesGeneric from "assets/theme/views/admin/generic.js";
const useStylesGeneric = makeStyles(componentStylesGeneric);

const tableHead = [
  "Nome",
  "BI",
  "Local Trabalho Pretendido",
  "Província",
  "Município",
  "Áreas",
  "Nível Académico",
  "Linguagens Estudadas",
];

function AddCandidates(props) {
  const classes = useStylesGeneric();
  const intl = useIntl();
  const methods = useForm({
    mode: "onChange",
  });

  const [candidatesListToAddToOpportunity, setCandidatesListToAddToOpportunity] = useState([]);
  const [possibleCandidatesList, setPossibleCandidatesList] = useState([]);
  const [page, setPage] = useState(PAGINATION.FIRST_PAGE);
  const [clearBlockedFilters, setClearBlockedFilters] = useState(false);

  const [filter, setFilter] = useState({
    opportunityId: props.opportunityDetailsData?.id,
    userNameOrIdentityCard: null,
    provinceId: props.opportunityDetailsData?.provinceId,
    municipalityId: props.opportunityDetailsData?.municipalityId,
    professionalCategoryId: props.opportunityDetailsData?.opportunityProfessionalCategories[0],
    isAvailableForOpportunities: true,
    pageIndex: PAGINATION.FIRST_PAGE,
    pageSize: PAGINATION.PAGE_SIZE_MODAL_RESULT_TABLE,
    genderId: props.opportunityDetailsData?.genderId,
    minAge: props.opportunityDetailsData?.minAge,
    maxAge: props.opportunityDetailsData?.maxAge,
  });

  const clearFilter = () => {
    setClearBlockedFilters(true);

    methods.setValue("userNameOrIdentityCard", "");

    var provinceList = props.dataSetState[DATASET.PROVINCES] ? props.dataSetState[DATASET.PROVINCES] : [];
    var selectedProvince = getSelectOptionsElementByCode(provinceList, props.opportunityDetailsData?.provinceId);
    methods.setValue("province", selectedProvince);
    props.getDataSetByName(DATASET.MUNICIPALITIES, selectedProvince.keyValue);

    methods.setValue(
      "municipality",
      getSelectOptionsElementByCode(
        props.dataSetState[DATASET.MUNICIPALITIES],
        props.opportunityDetailsData?.municipalityId
      )
    );

    methods.setValue(
      "professionalCategory",
      getSelectOptionsElementByCode(
        props.dataSetState[DATASET.PROFESSIONAL_CATEGORY],
        props.opportunityDetailsData?.opportunityProfessionalCategories[0]
      )
    );
    methods.setValue("isAvailableForOpportunities", true);

    methods.setValue(
      "gender",
      getSelectOptionsElementByCode(
        props.dataSetState[DATASET.GENDER],
        props.opportunityDetailsData?.genderId
      )
    );

    methods.setValue("minAge", props.opportunityDetailsData?.minAge);
    methods.setValue("maxAge", props.opportunityDetailsData?.maxAge);
  };

  useEffect(() => {
    if (methods.getValues("province") && clearBlockedFilters) {
      methods.setValue(
        "municipality",
        getSelectOptionsElementByCode(
          props.dataSetState[DATASET.MUNICIPALITIES],
          props.opportunityDetailsData?.municipalityId
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [methods.getValues("province")]);

  useEffect(() => {
    var provinceList = props.dataSetState[DATASET.PROVINCES] ? props.dataSetState[DATASET.PROVINCES] : [];
    var selectedProvince = getSelectOptionsElementByCode(provinceList, props.opportunityDetailsData?.provinceId);
    methods.setValue("province", selectedProvince);
    props.getDataSetByName(DATASET.MUNICIPALITIES, selectedProvince.keyValue);

    var municipalitiesList = props.dataSetState[DATASET.MUNICIPALITIES]
      ? props.dataSetState[DATASET.MUNICIPALITIES]
      : [];
    var selectedMunicipality = getSelectOptionsElementByCode(
      municipalitiesList,
      props.opportunityDetailsData?.municipalityId
    );
    methods.setValue("municipality", selectedMunicipality);

    var professionalCategoryList = props.dataSetState[DATASET.PROFESSIONAL_CATEGORY]
      ? props.dataSetState[DATASET.PROFESSIONAL_CATEGORY]
      : [];
    methods.setValue(
      "professionalCategory",
      getSelectOptionsElementByCode(
        professionalCategoryList,
        props.opportunityDetailsData?.opportunityProfessionalCategories[0]
      )
    );

    var genderList = props.dataSetState[DATASET.GENDER]
      ? props.dataSetState[DATASET.GENDER]
      : [];
    methods.setValue(
      "gender",
      getSelectOptionsElementByCode(
        genderList,
        props.opportunityDetailsData?.genderId
      )
    );

    methods.setValue("minAge", props.opportunityDetailsData?.minAge);
    methods.setValue("maxAge", props.opportunityDetailsData?.maxAge);

    props.getFilteredPossibleOpportunityCandidates(filter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!ArrayUtils.NullOrEmpty(props.opportunitiesState.possibleCandidates_result)) {
      setPossibleCandidatesList(props.opportunitiesState.possibleCandidates_result.data);
    } else {
      setPossibleCandidatesList([]);
    }
  }, [props.opportunitiesState.possibleCandidates_result]);

  const onSubmitSearch = (data) => {
    data = {
      ...data,
      opportunityId: props.opportunityDetailsData?.id,
    };
    let filter = getFilteredPossibleOpportunityCandidatesMap(data);
    setFilter(filter);
    if (page === PAGINATION.FIRST_PAGE) {
      props.getFilteredPossibleOpportunityCandidates(filter);
    } else {
      handleChangePage(undefined, PAGINATION.FIRST_PAGE);
    }
  };

  const handleChangePage = (event, value) => {
    if (page !== value) {
      setPage(value);
      let temp_filter = { ...filter, pageIndex: value };
      setFilter(temp_filter);
      props.getFilteredPossibleOpportunityCandidates(temp_filter);
    }
  };

  const checkIfCandidateInOpportunityList = (userID) => {
    if (candidatesListToAddToOpportunity.includes(userID)) {
      return true;
    }

    return false;
  };

  const addCandidateToOpportunityList = (userID) => {
    setCandidatesListToAddToOpportunity((prevState) => {
      if (checkIfCandidateInOpportunityList(userID)) {
        return [...prevState];
      }
      return [...prevState, userID];
    });
  };

  const removeCandidateFromOpportunityList = (userID) => {
    setCandidatesListToAddToOpportunity((prevState) => {
      let elements = prevState.filter((element) => element !== userID);

      return elements;
    });
  };

  let handleCandidateInOpportunityList = (userID, userState) => {
    if (userState) {
      addCandidateToOpportunityList(userID);
    } else {
      removeCandidateFromOpportunityList(userID);
    }
  };

  const checkIfAllCandidatesChecked = (userIDList) => {
    const getListOfStates = userIDList?.map((prop) => {
      if (!checkIfCandidateInOpportunityList(prop.userId)) {
        return false;
      }
      return true;
    });

    if (getListOfStates.includes(false)) {
      return false;
    }
    return true;
  };

  const handleAddOpportunityCandidatesList = () => {
    const data = {
      opportunityId: props.opportunityDetailsData?.id,
      usersId: candidatesListToAddToOpportunity,
    };

    props.addOpportunityCandidatesList(data, () => {
      props.handleClose();
      props.onSuccess();
    });
  };

  return (
    <div style={{ margin: "0px 30px" }}>
      <Box className={classes.modalHeader} style={{ marginBottom: "30px" }}>
        Adicionar Candidatos
      </Box>
      <form onSubmit={methods.handleSubmit(onSubmitSearch)}>
        <Grid container>
          <Grid item xs={12} sm={6} md={4}>
            <FormGroup>
              <FormLabel>Nome ou BI</FormLabel>
              <Controller
                name="userNameOrIdentityCard"
                control={methods.control}
                defaultValue=""
                rules={{ required: { value: false } }}
                render={({ field: { onChange, name, value } }) => (
                  <OutlinedInput
                    name={name}
                    value={value}
                    onChange={onChange}
                    fullWidth
                    autoComplete="off"
                    type="text"
                    placeholder="Pesquisar"
                    endAdornment={
                      <InputAdornment position="end">
                        <SearchIcon />
                      </InputAdornment>
                    }
                    classes={{
                      notchedOutline: clsx({
                        [classes.borderWarning]: methods.formState.errors["userNameOrIdentityCard"] !== undefined,
                      }),
                    }}
                  />
                )}
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormGroup>
              <FormLabel>Província</FormLabel>
              <FormSelect
                control={methods.control}
                fieldName="province"
                selectOptions={props.dataSetState[DATASET.PROVINCES] ? props.dataSetState[DATASET.PROVINCES] : []}
                loadChildrenDataSet={props.getDataSetByName}
                childrenDataSet={DATASET.MUNICIPALITIES}
                childrenSelect="municipality"
                getValues={methods.getValues}
                setValue={methods.setValue}
                errors={methods.formState.errors}
                classes={classes}
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormGroup>
              <FormLabel>Município</FormLabel>
              <FormSelect
                control={methods.control}
                fieldName="municipality"
                selectOptions={
                  props.dataSetState[DATASET.MUNICIPALITIES] ? props.dataSetState[DATASET.MUNICIPALITIES] : []
                }
                disabled={!methods.getValues("province")}
                getValues={methods.getValues}
                setValue={methods.setValue}
                errors={methods.formState.errors}
                classes={classes}
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormGroup>
              <FormLabel>Área Profissional</FormLabel>
              <FormSelect
                control={methods.control}
                fieldName="professionalCategory"
                selectOptions={
                  props.dataSetState[DATASET.PROFESSIONAL_CATEGORY]
                    ? props.dataSetState[DATASET.PROFESSIONAL_CATEGORY]
                    : []
                }
                getValues={methods.getValues}
                setValue={methods.setValue}
                errors={methods.formState.errors}
                classes={classes}
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormGroup>
              <FormLabel>Nível Académico</FormLabel>
              <FormSelect
                control={methods.control}
                fieldName="academicLevel"
                selectOptions={getDatasetStateByName(props, DATASET.ACADEMIC_LEVEL)}
                getValues={methods.getValues}
                setValue={methods.setValue}
                errors={methods.formState.errors}
                classes={classes}
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormGroup>
              <FormLabel>Línguas Estudadas</FormLabel>
              <FormSelect
                control={methods.control}
                fieldName="studiedLanguages"
                selectOptions={getDatasetStateByName(props, DATASET.LANGUAGES)}
                getValues={methods.getValues}
                setValue={methods.setValue}
                errors={methods.formState.errors}
                classes={classes}
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormGroup>
              <FormLabel>{intl.formatMessage(labels.Label_AddOpportunityCandidate_Gender)}</FormLabel>
              <FormSelect
                control={methods.control}
                fieldName="gender"
                selectOptions={ props.dataSetState[DATASET.GENDER] ?? [] }
                getValues={methods.getValues}
                setValue={methods.setValue}
                errors={methods.formState.errors}
                classes={classes}
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormInput
                name="minAge"
                label={intl.formatMessage(labels.Label_AddOpportunityCandidate_MinAge)}
                control={methods.control}
                errors={methods.formState.errors}
                numberType={true}
                trigger={methods.trigger}
                getValues={methods.getValues}
                otherFieldName="maxAge"
                rules={{
                  validate: {
                    minValue: (value) => {
                      if (value <= 17 && value !== "") return intl.formatMessage(errorMessages.ErrorMessage_MinimumAge);
                      return true;
                    },
                    isLessThanMaxAge: (value) => {
                      return (
                        !methods.getValues("maxAge") ||
                        !value ||
                        (value !== "" && value <= methods.getValues("maxAge")) ||
                        intl.formatMessage(errorMessages.ErrorMessage_MinAgeShouldBeLowerThanMaxAge)
                      );
                    },
                  },
                }}
              />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormInput
                name="maxAge"
                label={intl.formatMessage(labels.Label_AddOpportunityCandidate_MaxAge)}
                control={methods.control}
                errors={methods.formState.errors}
                numberType={true}
                trigger={methods.trigger}
                getValues={methods.getValues}
                otherFieldName="minAge"
                rules={{
                  validate: {
                    minValue: (value) => {
                      if (value <= 17 && value !== "") return intl.formatMessage(errorMessages.ErrorMessage_MinimumAge);
                      return true;
                    },
                    isGreaterThanMinDate: (value) => {
                      return (
                        !methods.getValues("minAge") ||
                        !value ||
                        (value !== "" && value >= methods.getValues("minAge")) ||
                        intl.formatMessage(errorMessages.ErrorMessage_MaxAgeShouldBeGreaterThanMinAge)
                      );
                    },
                  },
                }}
              />
          </Grid>
          <Grid item xs={12}>
            <Grid item>
              <FormLabel>
                Apenas cidadãos disponíveis para ofertas de
                {props.opportunityDetailsData?.opportunityTypeKey === OPPORTUNITY_TYPE_KEY.EMPREGO && " emprego"}
                {props.opportunityDetailsData?.opportunityTypeKey === OPPORTUNITY_TYPE_KEY.ESTAGIO_PROFISSIONAL &&
                  " estágio profissional"}
              </FormLabel>
            </Grid>
            <Grid item>
              <FormLabel style={{ padding: "0px 10px" }}>Não</FormLabel>
              <Controller
                name="isAvailableForOpportunities"
                control={methods.control}
                defaultValue={true}
                render={({ field: { onChange, name, value } }) => (
                  <Switch
                    name={name}
                    checked={value}
                    onChange={(e) => {
                      onChange(e.target.checked);
                    }}
                    color="primary"
                  />
                )}
              />
              <FormLabel style={{ padding: "0px 10px" }}>Sim</FormLabel>
            </Grid>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12} md={12} classes={{ root: classes.alignRight }}>
            <GenericButton
              typeSubmit={false}
              color="ghost"
              onClick={() => {
                clearFilter();
              }}
            >
              {intl.formatMessage(labels.Label_ClearFilters)}
            </GenericButton>
            <GenericButton
              typeSubmit={true}
              color="primary"
              size="medium"
              loading={props.opportunitiesState.possibleCandidates_loading}
            >
              {intl.formatMessage(labels.Label_FilterSection_SearchButton)}
            </GenericButton>
          </Grid>
        </Grid>
      </form>

      {/* Possible candidates table result */}
      {
        <Box style={{ marginTop: "2rem" }}>
          <TableContainer>
            <Box component={Table} alignItems="center" marginBottom="0!important">
              <TableHead>
                <TableRow>
                  {tableHead.map((prop, key) => (
                    <TableCell
                      key={key}
                      classes={{
                        root: classes.tableCellRoot + " " + classes.tableCellRootHead,
                      }}
                    >
                      {prop}
                    </TableCell>
                  ))}
                  <TableCell
                    classes={{
                      root: classes.tableCellRoot + " " + classes.tableCellRootHead,
                    }}
                  >
                    Lista de
                    {props.opportunityDetailsData?.opportunityTypeKey === OPPORTUNITY_TYPE_KEY.EMPREGO && " Emprego"}
                    {props.opportunityDetailsData?.opportunityTypeKey === OPPORTUNITY_TYPE_KEY.ESTAGIO_PROFISSIONAL &&
                      " Estágio"}
                  </TableCell>
                  <TableCell
                    key="selectCol"
                    classes={{
                      root:
                        classes.tableCellRoot +
                        " " +
                        classes.tableCellRootHead +
                        " " +
                        classes.tableCellHeadStickyRight,
                    }}
                    styles="width: '200px'"
                  >
                    <Grid container>
                      <Grid item xs={12} md={12} classes={{ root: classes.alignRight }}>
                        <FormControlLabel
                          onChange={(e) => {
                            possibleCandidatesList?.map((prop) =>
                              handleCandidateInOpportunityList(prop.userId, e.target.checked)
                            );
                          }}
                          value="end"
                          control={
                            <Checkbox
                              disabled={possibleCandidatesList.length === 0}
                              color="primary"
                              checked={
                                possibleCandidatesList.length > 0 && checkIfAllCandidatesChecked(possibleCandidatesList)
                              }
                            />
                          }
                          label={<Box className={classes.formControlLabelCheckboxInTableHeader}>Seleccionar</Box>}
                          labelPlacement="start"
                          className={classes.formControlLabelNoMargin}
                        />
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              </TableHead>

              {possibleCandidatesList.length > 0 && (
                <TableBody>
                  {possibleCandidatesList?.map((prop, key) => (
                    <TableRow key={key}>
                      <TableCell
                        classes={{
                          root: classes.tableRowEllipsis + " " + classes.tableCellRoot,
                        }}
                        title={prop.userName}
                      >
                        <Link href={`/admin/user-overview/${prop.userId}`} target="_blank">
                          {prop.userName}
                        </Link>
                      </TableCell>
                      <TableCell classes={{ root: classes.tableCellRoot }}>{prop.userIdentityCard}</TableCell>
                      <TableCell classes={{ root: classes.tableCellRoot }}>{prop.intendedWorkplaceProvince}</TableCell>
                      <TableCell classes={{ root: classes.tableCellRoot }}>{prop.province}</TableCell>
                      <TableCell classes={{ root: classes.tableCellRoot }}>{prop.municipality}</TableCell>
                      <TableCell
                        classes={{
                          root: classes.tableRowEllipsis + " " + classes.tableCellRoot,
                        }}
                        title={prop.professionalCategories?.join("; ") ?? ""}
                      >
                        {prop.professionalCategories?.join("; ") ?? ""}
                      </TableCell>
                      <TableCell classes={{ root: classes.tableCellRoot }}>{prop.academicLevel}</TableCell>
                      <TableCell
                        classes={{ root: classes.tableRowEllipsis + " " + classes.tableCellRoot }}
                        title={prop.studiedLanguages?.join(", ") ?? ""}
                      >
                        {prop.studiedLanguages?.join(", ") ?? ""}
                      </TableCell>
                      {props.opportunityDetailsData?.opportunityTypeKey ===
                        OPPORTUNITY_TYPE_KEY.ESTAGIO_PROFISSIONAL && (
                        <TableCell classes={{ root: classes.tableCellRoot }}>
                          {prop.isAvailableForInternshipOpportunities ? "Sim" : "Não"}
                        </TableCell>
                      )}
                      {props.opportunityDetailsData?.opportunityTypeKey === OPPORTUNITY_TYPE_KEY.EMPREGO && (
                        <TableCell classes={{ root: classes.tableCellRoot }}>
                          {prop.isAvailableForEmploymentOpportunities ? "Sim" : "Não"}
                        </TableCell>
                      )}
                      <TableCell
                        classes={{
                          root: classes.tableCellRoot + " " + classes.tableCellBodyStickyRight,
                        }}
                      >
                        <Box alignItems="center" justifyContent="center" display="flex">
                          <FormControlLabel
                            onChange={(e) => {
                              handleCandidateInOpportunityList(prop.userId, e.target.checked);
                            }}
                            control={
                              <Checkbox color="primary" checked={checkIfCandidateInOpportunityList(prop.userId)} />
                            }
                            style={{ margin: 0 }}
                          />
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              )}
            </Box>
            {!props.opportunitiesState.possibleCandidates_loading && possibleCandidatesList.length === 0 && (
              <Box className={classes.noResultsInfoInTable}>Não existem resultados disponíveis.</Box>
            )}
            <TableBackdrop open={props.opportunitiesState.possibleCandidates_loading} />
          </TableContainer>

          <Pagination
            className={classes.alignPagination}
            count={props.opportunitiesState?.possibleCandidates_total_pages}
            page={page}
            onChange={handleChangePage}
            color="primary"
          ></Pagination>
        </Box>
      }

      <Grid container>
        <Grid item xs={12} lg={6} xl={8}></Grid>
        <Grid item xs={12} lg={3} xl={2}>
          <FormGroup>
            <GenericButton typeSubmit={false} color="secondary" onClick={props.handleClose}>
              {intl.formatMessage(labels.Label_Generic_Cancel)}
            </GenericButton>
          </FormGroup>
        </Grid>
        <Grid item xs={12} lg={3} xl={2}>
          <FormGroup>
            <GenericButton
              typeSubmit={false}
              color="primary"
              onClick={handleAddOpportunityCandidatesList}
              disabled={candidatesListToAddToOpportunity.length === 0}
              loading={props.opportunitiesState.addOpportunityCandidatesList_loading}
            >
              {intl.formatMessage(labels.Label_Generic_Add)}
            </GenericButton>
          </FormGroup>
        </Grid>
      </Grid>
    </div>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getDataSetByName: (name, parentKeyValue) => dispatch(getDataSetByNameAction(name, parentKeyValue)),
  getFilteredPossibleOpportunityCandidates: (data) => dispatch(getFilteredPossibleOpportunityCandidatesAction(data)),
  addOpportunityCandidatesList: (data, onSuccess) => dispatch(addOpportunityCandidatesListAction(data, onSuccess)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddCandidates);
