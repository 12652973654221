import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { useFormContext } from "react-hook-form";
import "moment/locale/pt";
import { connect } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";
//import custom components
import componentStylesGeneric from "assets/theme/views/admin/generic";
import SubmitedDocumentsSection from "components/FormFields/SubmitedDocumentsSection";
import DropzoneField from "components/FormFields/DropzoneField";
import GenericButton from "components/Buttons/GenericButton";
import { getApplicationDocumentAction } from "redux/actions/applicationsAction";
import { titles, labels } from "resources/resources";
import { FORM_FIELDS_PREFIX } from "utils/consts";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function DocumentsSection(props) {
  const classes = useStylesGeneric();
  const intl = useIntl();
  const [filesState, setFiles] = useState([]);
  const [isEditable, setIsEditable] = useState(false);
  const methods = useFormContext();


  const handleEditSection = () => {
    setIsEditable(!isEditable);
  };

  const getDocumentFieldSize = (numDocs) => {
    if (12 % numDocs === 0) return 12 / numDocs;
    return 4;
  };

  useEffect(() => {
    if (props.generalData?.documents !== undefined) {
      setFiles(props.generalData?.documents);
    }
  }, [props.generalData?.documents]);

  const getFileInfo = (file) => {

    return file ?
      [{
        documentId: file.documentId,
        documentName: file.documentName
      }]
      :
      [];
  }

  return (
    <>
      <Card classes={{ root: classes.cardRoot }}>
        <CardHeader
          classes={{ title: classes.cardHeader, action: classes.cardHeaderAction  }}
          title={intl.formatMessage(
            titles.Title_Admin_ApplicationDetail_DocumentsSection
          )}
          action={
            <GenericButton
              typeSubmit={false}
              color="tertiary"
              size="small"
              onClick={handleEditSection}
            >
              Editar
            </GenericButton>
          }
        ></CardHeader>
        <CardContent>
          <Grid container component={Box}>
            {filesState?.map((file, key) => (
              <Grid item xs={12} md={getDocumentFieldSize(filesState.length)} key={key}>
                <FormGroup>
                  <FormLabel>
                    {file.documentTypeName}
                  </FormLabel>
                  {isEditable && (
                    <DropzoneField
                      name={FORM_FIELDS_PREFIX.DOCUMENT + file.documentTypeId.toString()}
                      multiple={false}
                      label={intl.formatMessage(labels.Label_Generic_Upload_Document)}
                      register={methods.register}
                      errors={methods.formState.errors}
                      required={false}
                      setValue={methods.setValue}
                      getValues={methods.getValues}
                      trigger={methods.trigger}
                      showFileNameOnPreview={true}
                    />
                  )}
                  <SubmitedDocumentsSection
                    files={getFileInfo(file)}
                    getDocument={props.getApplicationDocument}
                  />
                </FormGroup>
              </Grid>
            ))}
          </Grid>
          {
            (filesState.length === 0 && (
              <Box>Não existem documentos carregados nesta candidatura.</Box>
            ))
          }
        </CardContent>
      </Card>
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getApplicationDocument: (docId, docName) =>
    dispatch(getApplicationDocumentAction(docId, docName)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsSection);
