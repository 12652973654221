import  React, {useEffect, useState } from "react";
import clsx from "clsx";
import { Controller, useForm } from "react-hook-form";
import { useIntl } from "react-intl";

import "moment/locale/pt";
import { connect } from "react-redux";
// @material-ui/core components
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Container from "@material-ui/core/Container";
import Collapse from "@material-ui/core/Collapse";
import FormGroup from "@material-ui/core/FormGroup";
import Grid from "@material-ui/core/Grid";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import Typography from "@material-ui/core/Typography";
import InputBase from "@material-ui/core/InputBase";
import { FormHelperText } from "@material-ui/core";
// @material-ui/icons components
import NotificationsIcon from "@material-ui/icons/Notifications";
//import custom components
import componentStylesGeneric from "assets/theme/views/admin/generic.js";
import { getApplicationDetailsHistoryActions } from "redux/actions/applicationsAction";
import { addApplicationDetailHistoryEntryAction } from "redux/actions/applicationsAction";
import { addHistoryEntryMap } from "../../../../mappers/applicationsDetailsMap";
import GenericButton from "components/Buttons/GenericButton";
import { messages, titles, labels } from "resources/resources";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function HistoryTab(props) {
  const classes = useStylesGeneric();
  const theme = useTheme();
  const intl = useIntl();
  const [expanded, setExpanded] = useState(false);

  const {
    handleSubmit,
    setValue,
    control,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
  });

  useEffect(() => {
    if (
      props.applicationsState.applicationDetailsHistory === undefined ||
      props.applicationsState.applicationDetailsHistory.length === 0 ||
      props.applicationsState.applicationDetailsHistory[0].applicationNumber !==
        props.applicationNumber
    ) {
      props.getApplicationDetailsHistory(props.applicationNumber);
    }

    setValue("applicationNumber", props.applicationNumber);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.applicationsState.applicationDetailsHistory_refresh_results)
      props.getApplicationDetailsHistory(props.applicationNumber);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.applicationsState.applicationDetailsHistory_refresh_results]);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const onSubmit = (data) => {
    if (isValid) {
      props.addApplicationDetailHistoryEntry(addHistoryEntryMap(data));
    }
    setValue("historyComment", "")
  };

  return (
    <>
      <Container maxWidth={false}>
        <Card classes={{ root: classes.cardRoot }}>
          <CardHeader
            classes={{ title: classes.cardHeader }}
            title={intl.formatMessage(
              titles.Title_Admin_ApplicationDetail_HistorySection
            )}
          ></CardHeader>
          <CardContent>
            <Grid container>
              <Grid item xs={12} md={12}>
                <Timeline
                  style={{
                    height: "400px",
                    overflow: "auto",
                  }}
                >
                  {props.applicationsState.applicationDetailsHistory?.map(
                    (historyEntry) => {
                      return (
                        <TimelineItem key={historyEntry.id} className={classes.minHeight0PX}> 
                          <TimelineOppositeContent
                            style={{ flex: 0 }}
                          ></TimelineOppositeContent>
                          <TimelineSeparator>
                            <Box className={classes.timelineIconsBox}>
                              <NotificationsIcon
                                className={classes.timelineIcons}
                              />
                            </Box>
                            <TimelineConnector />
                          </TimelineSeparator>
                          <TimelineContent>
                            <Typography variant="caption">
                              {historyEntry.createUser} -{" "}
                              {historyEntry.createDatetime}
                            </Typography>
                            <Typography variant="h5" component="h1">
                              {historyEntry.actionName}
                            </Typography>
                            <Typography variant="caption">
                              {historyEntry.message}
                            </Typography>
                          </TimelineContent>
                        </TimelineItem>
                      );
                    }
                  )}
                </Timeline>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Container>

      <Container maxWidth={false}>
        <Card classes={{ root: classes.cardRoot }}>
          <CardHeader
            classes={{ title: classes.cardHeader, action: classes.cardHeaderAction }}
            title={intl.formatMessage(
              titles.Title_Admin_ApplicationDetail_HistoryNotesSection
            )}
            action={
              <GenericButton
                typeSubmit={false}
                size="small"
                color="tertiary"
                onClick={handleExpandClick}
                aria-expanded={expanded}
              >
                {intl.formatMessage(labels.Label_Generic_Add)}

              </GenericButton>
            }
          ></CardHeader>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container>
                  <Controller
                    name={"applicationNumber"}
                    control={control}
                    component={Box}
                    defaultValue=""
                    render={({ field: { onChange, name, value } }) => (
                      <InputBase
                        name={name}
                        value={value}
                        onChange={onChange}
                        fullWidth
                        autoComplete="off"
                        type="hidden"
                      />
                    )}
                  />
                </Grid>
                <Grid container>
                  <Grid item xs={12} md={12}>
                    <FormGroup>
                      <Controller
                        name="historyComment"
                        control={control}
                        component={Box}
                        rules={{
                          required: {
                            value: true,
                            message: intl.formatMessage(
                              messages.Message_Generic_MandatoryField
                            ),
                          },
                        }}
                        render={({ field: { onChange, name, value } }) => (
                          <OutlinedInput
                            name={name}
                            value={value}
                            onChange={onChange}
                            fullWidth
                            multiline
                            rows="3"
                            autoComplete="off"
                            type="text"
                            classes={{
                              notchedOutline: clsx({
                                [classes.borderWarning]:
                                  errors["historyComment"] !== undefined,
                              }),
                            }}
                          />
                        )}
                      />

                      {errors["historyComment"] !== undefined && (
                        <FormHelperText
                          component={Box}
                          color={theme.palette.warning.main + "!important"}
                        >
                          {errors["historyComment"].message}
                        </FormHelperText>
                      )}
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} lg={6} xl={8}></Grid>
                  <Grid item xs={12} lg={3} xl={2}>
                    <FormGroup>
                      <GenericButton
                        typeSubmit={false}
                        color="secondary"
                        onClick={handleExpandClick}
                      >
                        {intl.formatMessage(labels.Label_Generic_Cancel)}
                      </GenericButton>
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} lg={3} xl={2}>
                    <FormGroup>
                      <GenericButton
                        typeSubmit={true}
                        color="primary"
                      >
                        {intl.formatMessage(labels.Label_Generic_Add)}
                      </GenericButton>
                    </FormGroup>
                  </Grid>
                </Grid>
              </form>
            </CardContent>
          </Collapse>
        </Card>
      </Container>
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getApplicationDetailsHistory: (applicationNum) =>
    dispatch(getApplicationDetailsHistoryActions(applicationNum)),
  addApplicationDetailHistoryEntry: (data) =>
    dispatch(addApplicationDetailHistoryEntryAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HistoryTab);
