//compile the other users crud into this one!
import axios from "axios";
import { INTERNAL_API_URL, EXTERNAL_API_URL } from "../index";

export const GET_BO_FILTERED_USERS_URL = "User/get-filtered-backoffice-users";
export const GET_FO_FILTERED_USERS_URL = "User/get-filtered-frontoffice-users";
export const GET_USER_INFO = "User/get-user-info";
export const GET_USER_DOCUMENT_URL = "User/get-user-document-by-id";
export const GET_BACKOFFICE_USER_INFO = "User/get-backoffice-user-by-id";
export const POST_UPDATE_PROFILE = "User/update-profile";
export const INDIVIDUAL_USER_UPDATE_URL = "Account/update";
export const BACKOFFICE_USER_UPDATE_URL = "User/update-backoffice-user/";
export const VALIDATE_COLECTIVE_USERE_URL = "User/validate-colective-user";
export const GET_FO_USER_COLECTIVE_BASIC_INFO = "User/get-colective-user-basic-info";
export const REGISTER_URL = "account/register";
export const SEARCH_FO_USERS_URL = "User/search-frontoffice-users";
export const GET_FO_USER_OVERVIEW_URL = "User/get-fouser-overview";
export const CREATE_FO_USER_HISTORY_ENTRY_URL = "User/create-fouser-history-entry";
export const GET_FO_USER_HISTORY_URL = "User/get-fouser-history";
export const GET_BO_USER_LOGINS_HISTORY_URL = "User/get-backoffice-user-logins";

export const GET_FOUS_UNEMPLOYMENT_HISTORY_URL = "User/get-fous-unemployment-history";
export const CREATE_FOUS_UNEMPLOYMENT_HISTORY_URL = "User/create-fous-unemployment-history";
export const UPDATE_FOUS_UNEMPLOYMENT_HISTORY_URL = "User/update-fous-unemployment-history";

export const GET_FOUS_EMPLOYMENT_HISTORY_URL = "User/get-fous-employment-history";
export const CREATE_FOUS_EMPLOYMENT_HISTORY = "User/create-fous-employment-history";
export const UPDATE_FOUS_EMPLOYMENT_HISTORY = "User/update-fous-employment-history";

export const GET_FOUS_INTERVENTION_OTHER_AREA_URL = "User/get-fous-intervention-other-area";
export const CREATE_FOUS_INTERVENTION_OTHER_AREA_URL = "User/create-fous-intervention-other-area";
export const UPDATE_FOUS_INTERVENTION_OTHER_AREA_URL = "User/update-fous-intervention-other-area";

export function register(user) {
  return axios.post(EXTERNAL_API_URL + REGISTER_URL, user);
}

export function updateProfile(formData) {
  return axios.post(INTERNAL_API_URL + POST_UPDATE_PROFILE, formData);
}
export function getFilteredBackOfficeUsers(data) {
  return axios.post(INTERNAL_API_URL + GET_BO_FILTERED_USERS_URL, data);
}

export function getFilteredFrontOfficeUsers(formData) {
  return axios.post(INTERNAL_API_URL + GET_FO_FILTERED_USERS_URL, formData);
}

export function getUserInfo(userId) {
  return axios.get(EXTERNAL_API_URL + GET_USER_INFO, {
    params: {
      id: userId,
    },
  });
}

export function getUserInfoBo(userId) {
  return axios.get(INTERNAL_API_URL + GET_BACKOFFICE_USER_INFO, {
    params: {
      id: userId,
    },
  });
}

export function getUserDocument(data) {
  return axios.get(EXTERNAL_API_URL + GET_USER_DOCUMENT_URL, {
    headers: {
      "Content-Type": "application/json",
    },
    params: {
      docId: data,
    },
    responseType: "blob",
  });
}

export function frontOfficeIndividualUserUpdate(formData) {
  return axios.post(EXTERNAL_API_URL + INDIVIDUAL_USER_UPDATE_URL, formData);
}

export function backOfficeUserUpdate(formData) {
  return axios.post(INTERNAL_API_URL + BACKOFFICE_USER_UPDATE_URL + formData.id, formData);
}

export function validateColectiveUser(formData) {
  return axios.post(INTERNAL_API_URL + VALIDATE_COLECTIVE_USERE_URL, formData);
}

export function getFoUserColectiveBasicInfo(formData) {
  return axios.post(INTERNAL_API_URL + GET_FO_USER_COLECTIVE_BASIC_INFO, formData);
}

export function searchFOUsers(formData) {
  return axios.post(INTERNAL_API_URL + SEARCH_FO_USERS_URL, formData);
}

export function getFOUserOverview(formData) {
  return axios.post(INTERNAL_API_URL + GET_FO_USER_OVERVIEW_URL, formData);
}

export function addFOUserHistoryEntry(formData) {
  return axios.post(INTERNAL_API_URL + CREATE_FO_USER_HISTORY_ENTRY_URL, formData);
}

export function getFOUserHistory(formData) {
  return axios.post(INTERNAL_API_URL + GET_FO_USER_HISTORY_URL, formData);
}

export function getFOUserEmploymentHistory(data) {
  return axios.get(EXTERNAL_API_URL + GET_FOUS_EMPLOYMENT_HISTORY_URL, {
    params: data,
  });
}

export function getBOUserLoginsHistory(formData) {
  return axios.post(INTERNAL_API_URL + GET_BO_USER_LOGINS_HISTORY_URL, formData);
}

export function getFOUserUnemploymentHistory(data) {
  return axios.get(EXTERNAL_API_URL + GET_FOUS_UNEMPLOYMENT_HISTORY_URL, {
    params: data,
  });
}

export function createFOUserUnemploymentHistory(formData) {
  return axios.post(EXTERNAL_API_URL + CREATE_FOUS_UNEMPLOYMENT_HISTORY_URL, formData);
}

export function updateFOUserUnemploymentHistory(formData) {
  return axios.post(EXTERNAL_API_URL + UPDATE_FOUS_UNEMPLOYMENT_HISTORY_URL, formData);
}

export function createFOUserEmploymentHistory(data) {
  return axios.post(EXTERNAL_API_URL + CREATE_FOUS_EMPLOYMENT_HISTORY, data);
}

export function updateFOUserEmploymentHistory(data) {
  return axios.put(EXTERNAL_API_URL + UPDATE_FOUS_EMPLOYMENT_HISTORY, data);
}

export function getFoUSInterventionOtherArea(formData) {
  return axios.get(EXTERNAL_API_URL + GET_FOUS_INTERVENTION_OTHER_AREA_URL, {
    params: formData,
  });
}

export function createFoUSInterventionOtherArea(formData) {
  return axios.post(INTERNAL_API_URL + CREATE_FOUS_INTERVENTION_OTHER_AREA_URL, formData);
}

export function updateFoUSInterventionOtherArea(formData) {
  return axios.put(INTERNAL_API_URL + UPDATE_FOUS_INTERVENTION_OTHER_AREA_URL, formData);
}
