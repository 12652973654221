import axios from "axios";
import { EXTERNAL_API_URL } from "../index";

export const GET_APPLICATION_VERSION_URL = "AppConfiguration/get-web-version";
export const GET_USER_LAST_LOGIN_URL = "AppConfiguration/get-user-last-login";

export function getApplicationVersion(_userType,_date) {
  return axios.get(EXTERNAL_API_URL + GET_APPLICATION_VERSION_URL, {
    params: {
      userType: _userType,
      date: _date,
    },
  });
}


