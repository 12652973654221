import React, { useState, useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { connect } from "react-redux";
import { useIntl } from "react-intl";
// @material-ui/core components
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import InputBase from "@material-ui/core/InputBase";
import Grid from "@material-ui/core/Grid";
//import custom components
import componentStylesGeneric from "assets/theme/views/admin/generic";
import { COMPLEMENTARY_DATA_TYPE, DATASET, FORM_FIELDS_PREFIX } from "utils/consts";
import { getDataSetByNameAction } from "redux/actions/datasetsAction";
import TextField from "components/ComplementaryDataFields/Text";
import DropdownField from "components/ComplementaryDataFields/Dropdown";
import GenericButton from "components/Buttons/GenericButton";
import { titles } from "resources/resources";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function ComplementaryDataSection(props) {
  const classes = useStylesGeneric();
  const theme = useTheme();
  const intl = useIntl();
  const { control } = useFormContext();
  const [isEditable, setIsEditable] = useState(false);
  const [pageState, setPageState] = useState({ complementaryData: [] });

  const handleEditSection = () => {
    setIsEditable(!isEditable);
  };

  useEffect(() => {
    if (props.generalData?.applicationNumber === props.applicationNumber) {
      //Load here my complementarydata page state
      setComplementaryData(props.complementaryDataAnswers);

      //Load all complementary-data datasets if needed
      props.complementaryDataAnswers?.forEach((complementaryDataItem) => {
        if (complementaryDataItem.answerTypeId === COMPLEMENTARY_DATA_TYPE.DATASET) {
          if (
            props.dataSetState[complementaryDataItem.dataSetName] === undefined ||
            props.dataSetState[complementaryDataItem.dataSetName]?.length === 0
          ) {
            props.getDataSetByName(complementaryDataItem.dataSetName);
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.complementaryDataAnswers]);

  const setComplementaryData = (_complementaryData) => {
    setPageState({
      ...pageState,
      complementaryData: _complementaryData?.map((cd) => {
        return {
          ...cd,
          id: FORM_FIELDS_PREFIX.COMPLEMENTARY_DATA + cd.complementaryDataId,
          show: !cd.hasDependencies || (cd.hasDependencies && cd.answer),
        };
      }),
    });
  };

  // This might not be working because it's an old implementation and not used at the moment (We don't use other complementary types besides PLAIN_TEXT)
  const showHideFieldsOnChange = (childs, parentField, parentFieldType) => {
    if (childs == null || childs === undefined) {
      return;
    }

    childs.forEach((ch) => {
      let toShow = showChildField(
        ch.conditionalChildQuestionId.toString(),
        parentField,
        parentFieldType,
        ch.conditionalValue
      );

      if (!toShow) {
        props.unregister(ch.conditionalChildQuestionId.toString());

        //se a filha é para esconder, ele tem de ir a cada filha e ver se essa filha tem filhas e meter a false para esconder tudo
        var aux = pageState?.complementaryData.filter((r) => r.id === ch.conditionalChildQuestionId)[0]
          ?.complementaryDataConditionsList;
        showHideFieldsOnChange(aux, ch.conditionalChildQuestionId + "", ch.fieldTypeId);
      }

      setPageState((prevState) => ({
        ...prevState,
        complementaryData: prevState.complementaryData.map((cd) =>
          cd.complementaryDataId === ch.conditionalChildQuestionId.toString() ? { ...cd, show: toShow } : cd
        ),
      }));
    });
  };

  const showChildField = (childField, parentField, parentFieldType, valueCondition) => {
    if (!childField || !parentField) return true;
    const value = props.getValues(parentField);
    switch (parentFieldType) {
      case COMPLEMENTARY_DATA_TYPE.DATASET:
        return value !== undefined && value?.code + "" === valueCondition + "";
      case COMPLEMENTARY_DATA_TYPE.PLAIN_TEXT:
        return value !== undefined && value === valueCondition;
      default:
        return;
    }
  };

  const getComplementaryDataFields = (key, complementaryDataField) => {
    switch (complementaryDataField.answerTypeId) {
      case COMPLEMENTARY_DATA_TYPE.PLAIN_TEXT:
        return (
          <TextField
            show={complementaryDataField.show}
            isRequired={complementaryDataField.isRequired}
            isDisabled={!isEditable}
            answerValue={complementaryDataField.answer}
            name={complementaryDataField.id}
            childs={complementaryDataField.complementaryDataConditionsList}
            valueCondition={complementaryDataField.valueCondition}
            control={control}
            setValue={props.setValue}
            getValues={props.getValues}
            errors={props.errors}
            classes={classes}
            theme={theme}
            showHideChildOnChange={showHideFieldsOnChange}
          />
        );
      case COMPLEMENTARY_DATA_TYPE.DATASET:
        return (
          <DropdownField
            show={complementaryDataField.show}
            isRequired={complementaryDataField.isRequired}
            isDisabled={!isEditable}
            selectOptions={props.dataSetState[complementaryDataField.dataSetName]}
            answerValue={complementaryDataField.answer}
            name={complementaryDataField.id}
            childs={complementaryDataField.complementaryDataConditionsList}
            valueCondition={complementaryDataField.valueCondition}
            control={control}
            setValue={props.setValue}
            getValues={props.getValues}
            errors={props.errors}
            classes={classes}
            theme={theme}
            showHideChildOnChange={showHideFieldsOnChange}
          />
        );
      case COMPLEMENTARY_DATA_TYPE.BOOLEAN:
        return (
          <DropdownField
            show={complementaryDataField.show}
            isRequired={complementaryDataField.isRequired}
            isDisabled={!isEditable}
            selectOptions={DATASET.YES_OR_NO_DROPDOWN}
            answerValue={complementaryDataField.answer}
            name={complementaryDataField.id}
            childs={complementaryDataField.complementaryDataConditionsList}
            valueCondition={complementaryDataField.valueCondition}
            control={control}
            setValue={props.setValue}
            getValues={props.getValues}
            errors={props.errors}
            classes={classes}
            theme={theme}
            showHideChildOnChange={showHideFieldsOnChange}
          />
        );
      default:
        return (
          <TextField
            show={complementaryDataField.show}
            isRequired={complementaryDataField.isRequired}
            isDisabled={!isEditable}
            answerValue={complementaryDataField.answer}
            name={complementaryDataField.id}
            childs={complementaryDataField.complementaryDataConditionsList}
            control={control}
            setValue={props.setValue}
            getValues={props.getValues}
            errors={props.errors}
            classes={classes}
            theme={theme}
            showHideChildOnChange={showHideFieldsOnChange}
          />
        );
    }
  };

  return (
    <>
      <Card classes={{ root: classes.cardRoot }}>
        <CardHeader
          classes={{ title: classes.cardHeader, action: classes.cardHeaderAction }}
          title={intl.formatMessage(titles.Title_Admin_ApplicationDetail_ComplementaryDataSection)}
          action={
            <GenericButton typeSubmit={false} color="tertiary" size="small" onClick={handleEditSection}>
              Editar
            </GenericButton>
          }
        ></CardHeader>
        <CardContent>
          <Grid container>
            {pageState?.complementaryData?.map(
              (complementaryDataItem, key) =>
                complementaryDataItem.show && (
                  <Grid item xs={12} md={4} key={key}>
                    <FormGroup>
                      <FormLabel>{complementaryDataItem.question}</FormLabel>
                      {getComplementaryDataFields(key, complementaryDataItem)}
                      <Controller
                        name={`complementaryDataList[${key}].complementaryDataId`}
                        control={control}
                        component={Box}
                        defaultValue={complementaryDataItem.complementaryDataId}
                        render={({ field: { onChange, name, value } }) => (
                          <InputBase
                            name={name}
                            value={value}
                            onChange={onChange}
                            fullWidth
                            autoComplete="off"
                            type="hidden"
                          />
                        )}
                      />
                      <Controller
                        name={`complementaryDataList[${key}].answerTypeId`}
                        control={control}
                        component={Box}
                        defaultValue={complementaryDataItem.answerTypeId}
                        render={({ field: { onChange, name, value } }) => (
                          <InputBase
                            name={name}
                            value={value}
                            onChange={onChange}
                            fullWidth
                            autoComplete="off"
                            type="hidden"
                          />
                        )}
                      />
                    </FormGroup>
                  </Grid>
                )
            )}
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getDataSetByName: (id) => dispatch(getDataSetByNameAction(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ComplementaryDataSection);
