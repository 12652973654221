import { React, useEffect, useState } from "react";
import clsx from "clsx";
import { connect } from "react-redux";
import { Controller, useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import "moment/locale/pt";
import { labels, titles, messages } from "resources/resources";
// @material-ui/core components
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputAdornment from "@material-ui/core/InputAdornment";
import OutlinedInput from "@material-ui/core/OutlinedInput";
//import custom components
import componentStylesGeneric from "assets/theme/views/admin/generic.js";
import FormSelect from "components/FormFields/FormSelect";
import {
  getActiveCategoriesAction,
  getDataSetByNameAction,
  getWarehousesByProvinceAction,
} from "redux/actions/datasetsAction";
import { getUnitValueByCategoryAction } from "redux/actions/stockAction";
import { DATASET, UNITS } from "utils/consts";
import { getSelectOptionsElementByCode } from "utils/formSelect";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function GeneralData(props) {
  const classes = useStylesGeneric();
  const theme = useTheme();
  const { control } = useFormContext();
  const intl = useIntl();
  const [totalValue, setTotalValue] = useState("0");

  useEffect(() => {
    props.getActiveCategories(DATASET.ACTIVE_CATEGORIES);
    if (props.dataSetState[DATASET.PROVINCES] === undefined || props.dataSetState[DATASET.PROVINCES] === []) {
      props.getDataSet(DATASET.PROVINCES);
    }
    if (
      props.dataSetState[DATASET.LOSS_BREAK_MOTIVES] === undefined ||
      props.dataSetState[DATASET.LOSS_BREAK_MOTIVES] === []
    ) {
      props.getDataSet(DATASET.LOSS_BREAK_MOTIVES);
    }

    setFormValuesOnMount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    var provinceList = props.dataSetState[DATASET.PROVINCES] ? props.dataSetState[DATASET.PROVINCES] : [];
    if (props.authState?.province) {
      const selectedProvince = getSelectOptionsElementByCode(provinceList, props.authState.province);
      props.getWarehousesByProvince(DATASET.WAREHOUSES, selectedProvince.keyValue);
      props.setValue("province", selectedProvince);
    } else {
      if (props.stockState.RegisterLossBreakModal?.province) {
        const selectedProvince = getSelectOptionsElementByCode(
          provinceList,
          props.stockState.RegisterLossBreakModal.province
        );
        props.setValue("province", selectedProvince);
        props.getWarehousesByProvince(DATASET.WAREHOUSES, selectedProvince.keyValue);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dataSetState[DATASET.PROVINCES]]);

  useEffect(() => {
    if (props.stockState.RegisterLossBreakModal?.warehouse) {
      var warehouseList = props.dataSetState[DATASET.WAREHOUSES] ? props.dataSetState[DATASET.WAREHOUSES] : [];
      const warehouse =
        props.stockState.RegisterLossBreakModal !== undefined
          ? props.stockState.RegisterLossBreakModal.warehouse
          : null;
      props.setValue("warehouseId", getSelectOptionsElementByCode(warehouseList, warehouse));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dataSetState[DATASET.WAREHOUSES]]);

  useEffect(() => {
    if (props.stockState.RegisterLossBreakModal?.category) {
      var categoryList = props.dataSetState[DATASET.ACTIVE_CATEGORIES]
        ? props.dataSetState[DATASET.ACTIVE_CATEGORIES]
        : [];
      const category =
        props.stockState.RegisterLossBreakModal !== undefined ? props.stockState.RegisterLossBreakModal.category : null;
      props.setValue("categoryId", getSelectOptionsElementByCode(categoryList, category));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dataSetState[DATASET.ACTIVE_CATEGORIES]]);

  const setFormValuesOnMount = () => {
    const batchNumber =
      props.stockState.RegisterLossBreakModal !== undefined
        ? props.stockState.RegisterLossBreakModal.batchNumber
        : null;
    props.setValue("batchNumber", batchNumber);
  };

  const getBatchValueField = () => {
    var category = props.getValues("categoryId")?.code;
    var quantity = props.getValues("quantity");
    setTotalValue(
      intl.formatNumber(
        props.stockState &&
          props.stockState.categoriesUnitValue.find((x) => x.id === category)?.unitValue > 0 &&
          quantity > 0
          ? props.stockState.categoriesUnitValue.find((x) => x.id === category)?.unitValue * quantity
          : "0"
      )
    );
  };

  const quantityOnChange = () => {
    var category = props.getValues("categoryId");
    if (category != null && category.code > 0) {
      props.getUnitValueByCategory(category.code);
    }
    getBatchValueField();
  };

  const getCategoryIsDisabled = () => {
    return props.stockState.RegisterLossBreakModal !== undefined
      ? props.stockState.RegisterLossBreakModal.category !== undefined
      : false;
  };

  const getBatchNumberIsDisabled = () => {
    return props.stockState.RegisterLossBreakModal !== undefined
      ? props.stockState.RegisterLossBreakModal.batchNumber !== undefined
      : false;
  };

  const getProvinceIsDisabled = () => {
    return props.stockState.RegisterLossBreakModal?.province != null || props.authState?.province != null;
  };

  const getWarehouseIsDisabled = () => {
    return props.stockState.RegisterLossBreakModal !== undefined
      ? props.stockState.RegisterLossBreakModal.warehouse !== undefined
      : false;
  };

  return (
    <>
      <Container>
        <Box className={classes.modalHeader}>
          {intl.formatMessage(titles.Title_Component_ModalFormsContent_Stocks_RegisterLossBreak)}
        </Box>
        <Box marginLeft="15px">
          <Grid container>
            <Grid item xs={12} md={6}>
              <FormGroup>
                <FormLabel>Província</FormLabel>
                <FormSelect
                  control={control}
                  fieldName="province"
                  disabled={getProvinceIsDisabled()}
                  selectOptions={props.dataSetState[DATASET.PROVINCES] ? props.dataSetState[DATASET.PROVINCES] : []}
                  loadChildrenDataSet={props.getWarehousesByProvince}
                  childrenDataSet={DATASET.WAREHOUSES}
                  childrenSelect="warehouseId"
                  setValue={props.setValue}
                  getValues={props.getValues}
                  required={props.isEditable}
                  requiredMessage={intl.formatMessage(messages.Message_Generic_MandatoryField)}
                  errors={props.errors}
                  classes={classes}
                />
                {props.errors["province"] !== undefined && (
                  <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                    {props.errors["province"].message}
                  </FormHelperText>
                )}
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormGroup>
                <FormLabel>Armazém</FormLabel>
                <FormSelect
                  control={control}
                  fieldName="warehouseId"
                  disabled={getWarehouseIsDisabled()}
                  selectOptions={props.dataSetState[DATASET.WAREHOUSES] ? props.dataSetState[DATASET.WAREHOUSES] : []}
                  getValues={props.getValues}
                  required={props.isEditable}
                  requiredMessage={intl.formatMessage(messages.Message_Generic_MandatoryField)}
                  errors={props.errors}
                  classes={classes}
                />
                {props.errors["warehouseId"] !== undefined && (
                  <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                    {props.errors["warehouseId"].message}
                  </FormHelperText>
                )}
              </FormGroup>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={6}>
              <FormGroup>
                <FormLabel>
                  {intl.formatMessage(labels.Label_Component_ModalFormsContent_Stocks_RegisterLossBreak_CategoryField)}
                </FormLabel>
                <FormSelect
                  control={control}
                  disabled={getCategoryIsDisabled()}
                  fieldName="categoryId"
                  onOptionChange={quantityOnChange}
                  selectOptions={
                    props.dataSetState[DATASET.ACTIVE_CATEGORIES] ? props.dataSetState[DATASET.ACTIVE_CATEGORIES] : []
                  }
                  getValues={props.getValues}
                  required={props.isEditable && !getCategoryIsDisabled()}
                  requiredMessage={intl.formatMessage(messages.Message_Generic_MandatoryField)}
                  errors={props.errors}
                  classes={classes}
                />
                {props.errors["categoryId"] !== undefined && (
                  <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                    {props.errors["categoryId"].message}
                  </FormHelperText>
                )}
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormGroup>
                <FormLabel>
                  {intl.formatMessage(labels.Label_Component_ModalFormsContent_Stocks_RegisterLossBreak_QuantityField)}
                </FormLabel>
                <Controller
                  name="quantity"
                  control={control}
                  component={Box}
                  defaultValue=""
                  rules={{
                    required: {
                      value: props.isEditable,
                      message: intl.formatMessage(messages.Message_Generic_MandatoryField),
                    },
                    validate: {
                      greaterThanZero: (value) =>
                        value > 0 || intl.formatMessage(messages.Message_Generic_ValueGreaterThanZeroField),
                    },
                  }}
                  render={({ field: { onChange, name, value } }) => (
                    <OutlinedInput
                      name={name}
                      value={value}
                      onChange={onChange}
                      onBlur={() => {
                        quantityOnChange();
                      }}
                      fullWidth
                      autoComplete="off"
                      type="number"
                      classes={{
                        notchedOutline: clsx({
                          [classes.borderWarning]: props.errors["quantity"] !== undefined,
                        }),
                      }}
                    />
                  )}
                />
                {props.errors["quantity"] !== undefined && (
                  <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                    {props.errors["quantity"].message}
                  </FormHelperText>
                )}
              </FormGroup>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={6}>
              <FormGroup>
                <FormLabel>
                  {intl.formatMessage(labels.Label_Component_ModalFormsContent_Stocks_RegisterLossBreak_ValueField)}
                </FormLabel>
                <OutlinedInput
                  disabled={true}
                  readOnly={true}
                  fullWidth
                  value={totalValue}
                  classes={{ input: classes.monetaryFields }}
                  endAdornment={
                    <InputAdornment disableTypography={true} position="end">
                      {UNITS.MONETARY}
                    </InputAdornment>
                  }
                  type="text"
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormGroup>
                <FormLabel>
                  {intl.formatMessage(
                    labels.Label_Component_ModalFormsContent_Stocks_RegisterLossBreak_BatchNumberField
                  )}
                </FormLabel>
                <Controller
                  name="batchNumber"
                  control={control}
                  component={Box}
                  defaultValue=""
                  rules={{
                    required: {
                      value: props.isEditable && !getBatchNumberIsDisabled(),
                      message: intl.formatMessage(messages.Message_Generic_MandatoryField),
                    },
                  }}
                  render={({ field: { onChange, name, value } }) => (
                    <OutlinedInput
                      name={name}
                      value={value}
                      disabled={getBatchNumberIsDisabled()}
                      onChange={onChange}
                      fullWidth
                      autoComplete="off"
                      type="text"
                      classes={{
                        notchedOutline: clsx({
                          [classes.borderWarning]: props.errors["batchNumber"] !== undefined,
                        }),
                      }}
                    />
                  )}
                />
                {props.errors["batchNumber"] !== undefined && (
                  <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                    {props.errors["batchNumber"].message}
                  </FormHelperText>
                )}
              </FormGroup>
            </Grid>
          </Grid>
          <Grid container></Grid>
          <Grid container>
            <Grid item xs={12} md={12}>
              <FormGroup>
                <FormLabel>
                  {intl.formatMessage(labels.Label_Component_ModalFormsContent_Stocks_RegisterLossBreak_MotiveField)}
                </FormLabel>
                <FormSelect
                  control={control}
                  fieldName="motive"
                  selectOptions={
                    props.dataSetState[DATASET.LOSS_BREAK_MOTIVES] ? props.dataSetState[DATASET.LOSS_BREAK_MOTIVES] : []
                  }
                  getValues={props.getValues}
                  required={props.isEditable}
                  requiredMessage={intl.formatMessage(messages.Message_Generic_MandatoryField)}
                  errors={props.errors}
                  classes={classes}
                />
                {props.errors["motive"] !== undefined && (
                  <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                    {props.errors["motive"].message}
                  </FormHelperText>
                )}
              </FormGroup>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getActiveCategories: (name) => dispatch(getActiveCategoriesAction(name)),
  getDataSet: (name, parentKeyValue) => dispatch(getDataSetByNameAction(name, parentKeyValue)),
  getUnitValueByCategory: (category, quantity) => dispatch(getUnitValueByCategoryAction(category, quantity)),
  getWarehousesByProvince: (name, province) => dispatch(getWarehousesByProvinceAction(name, province)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GeneralData);
