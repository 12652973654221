import boxShadows from "assets/theme/box-shadow.js";

const componentStyles = (theme) => ({
  containerRoot: {
    [theme.breakpoints.up("lg")]: {
      paddingLeft: "3.5rem",
      paddingRight: "3.5rem",
    },
    paddingTop: "12rem",
  },
  cardRoot: {
    boxShadow: boxShadows.boxShadow + "!important",
  },
  pageTitle: {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "26px",
    lineHeight: "40px",
    alignItems: "center",
    color: theme.palette.primary.main,
    marginBottom: "20px",
  },
  descriptionRoot: {
    marginBottom: "1.5rem",
    textAlign: "justify",
  },
  itemRoot: {
    marginTop: "0.75rem",
    marginBottom: "0.75rem",
  },
  linkRoot: {
    color: theme.palette.black.main,
    "&:hover": {
      color: theme.palette.primary.greyBlue,
    },
  },
  iframe: {
    border: 0,
    width: "100%",
    height: "100%",
    minHeight: "300px",
  },
  phoneNumberGrey: {
    color: theme.palette.gray[500],
  },

  boxMargin: {
    marginTop: "20px",
    marginBottom: "10px",
  },
  title: {
    color: theme.palette.black.main,
    marginBottom: "60px",
  },
  content: {
    whiteSpace: "pre-line",
  },

  linkStandard: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "1rem",
    marginLeft: "0.25rem",
    marginRight: "0.25rem",
    paddingLeft: "0.5rem",
    paddingRight: "0.5rem",
    paddingTop: "0.25rem",
    paddingBottom: "0.25rem",
    transition: "all .15s linear",
    color: theme.palette.grey[900],
    margin: "0",
    "& i": {
      marginRight: "0.25rem",
    },
    "&:hover": {
      color: theme.palette.primary.greyBlue,
      fontWeight: "800",
    },
  },
  helpIcon: {
    width: "35px",
    height: "35px",
    color: theme.palette.primary.greyBlue,
  },
  fontWeight600: {
    fontWeight: "600",
  },
  fontWeight700: {
    fontWeight: "700",
  },
  displayBlock: {
    display: "block",
  },
});

export default componentStyles;
