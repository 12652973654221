import React from "react";
import moment from "moment";
import { useIntl } from "react-intl";
import { connect } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
//import custom components
import GenericButton from "components/Buttons/GenericButton";
import { getProfessionalKitDeliveryDocumentAction } from "redux/actions/stockAction";
import { labels } from "resources/resources";

import componentStylesGeneric from "assets/theme/views/admin/generic.js";

const useStylesGeneric = makeStyles(componentStylesGeneric);

const tableHead = ["Utilizador", "Data de Entrega", "Termo Assinado"];

function GeneralDataTab(props) {
  const classes = useStylesGeneric();
  const intl = useIntl();

  return (
    <>
      <Container maxWidth={false}>
        <Card classes={{ root: classes.cardRoot }}>
          <CardHeader classes={{ title: classes.cardHeader }} title="Kits atribuídos"></CardHeader>

          {props.subprogramDetailsData.deliveredProfessionalKits !== undefined &&
          props.subprogramDetailsData.deliveredProfessionalKits?.length > 0 ? (
            <TableContainer>
              <Box component={Table} alignItems="center" marginBottom="0!important">
                <TableHead>
                  <TableRow>
                    {tableHead.map((prop, key) => (
                      <TableCell
                        key={key}
                        classes={{
                          root: classes.tableCellRoot + " " + classes.tableCellRootHead,
                        }}
                      >
                        {prop}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {props.subprogramDetailsData.deliveredProfessionalKits.map((delivProfKit, key) => (
                    <TableRow key={key}>
                      <TableCell classes={{ root: classes.tableCellRoot }}>{delivProfKit.nameOfUser}</TableCell>
                      <TableCell classes={{ root: classes.tableCellRoot }}>
                        {moment(delivProfKit.deliveryDate).format("DD/MM/YYYY")}
                      </TableCell>
                      <TableCell classes={{ root: classes.tableCellRoot }}>
                        <GenericButton
                          key={key}
                          typeSubmit={false}
                          color="tertiary"
                          onClick={() => {
                            props.getProfessionalKitDeliveryDocument(delivProfKit.id, delivProfKit.deliveryDocument);
                          }}
                        >
                          {intl.formatMessage(labels.Label_Download)}
                        </GenericButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Box>
            </TableContainer>
          ) : (
            <Box className={classes.noResultsInfoInTable}>Não existem kits atribuídos.</Box>
          )}
        </Card>
      </Container>
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getProfessionalKitDeliveryDocument: (docId, docName) =>
    dispatch(getProfessionalKitDeliveryDocumentAction(docId, docName)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GeneralDataTab);
