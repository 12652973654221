import React, {useEffect, useState} from "react";
import { connect } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
// @material-ui/icons components

// core components
import CardOnGoingApplications from "components/Cards/Dashboard/CardOnGoingApplications.js";
import CardNewCompanies from "components/Cards/Dashboard/CardNewCompanies.js";
import CardStock from "components/Cards/Dashboard/CardStock";
import CardNotifications from "components/Cards/Dashboard/CardNotifications";
import CardPendingOpportunities from "components/Cards/Dashboard/CardPendingOpportunities";
import CardUnapprovedFeedbackReports from "components/Cards/Dashboard/CardUnapprovedFeedbackReports";
import componentStyles from "assets/theme/views/admin/dashboard.js";
import componentStylesCardDeck from "assets/theme/components/cards/card-deck.js";
import { checkOperationPermission } from "utils/permission";
import { OPERATIONS } from "utils/consts";
import GenericDialog from "components/Dialogs/GenericDialogV2";
import NewsModal from "./NewsModal"
import { getApplicationVersionAction } from "redux/actions/applicationConfigurationAction";

const useStyles = makeStyles(componentStyles);
const useStylesCardDeck = makeStyles(componentStylesCardDeck);

function Dashboard(props) {
  const classes = { ...useStyles(), ...useStylesCardDeck() };
  const [showNewsModal, setShowNewsModal] = useState(false);

  useEffect(() => {
    if (props.authState.loggedIn !== undefined) {
      props.getApplicationVersion("3", props.authState.lastLoginDate);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.authState.showNews) {
      setShowNewsModal(true);
    }
  }, [props.authState.showNews]);

  function handleCloseNewsModal() {
    setShowNewsModal(false);
  }

  return (
    <>      
      <Grid
        style={{ marginLeft: "25px", marginRight: "25px" }}
        classes={{ root: classes.containerRoot }}
        container
      >
        {checkOperationPermission(
          props.authState.operationsList,
          OPERATIONS.DASHBOARD_ONGOING_APPLICATIONS
        ) && (
          <Grid item xs={12} xl={6} classes={{ root: classes.gridItemRoot }}>
            <CardOnGoingApplications />
          </Grid>
        )}

        {checkOperationPermission(
          props.authState.operationsList,
          OPERATIONS.DASHBOARD_STOCKS
        ) && (
          <Grid item xs={12} xl={6}>
            <CardStock />
          </Grid>
        )}        

        {checkOperationPermission(
          props.authState.operationsList,
          OPERATIONS.DASHBOARD_NEW_COMPANIES
        ) && (
          <Grid item xs={12} xl={6}>
            <CardNewCompanies />
          </Grid>
        )}

        {checkOperationPermission(
          props.authState.operationsList,
          OPERATIONS.DASHBOARD_OPPORTUNITIES
        ) && (
          <Grid item xs={12} xl={6}>
            <CardPendingOpportunities />
          </Grid>
        )}

        {checkOperationPermission(
          props.authState.operationsList,
          OPERATIONS.DASHBOARD_OPPORTUNITIES
        ) && (
          <Grid item xs={12} xl={6}>
            <CardUnapprovedFeedbackReports />
          </Grid>
        )}   

        {checkOperationPermission(
          props.authState.operationsList,
          OPERATIONS.DASHBOARD_NOTIFICATIONS
        ) && (
          <Grid item xs={12} xl={6}>
            <CardNotifications />
          </Grid>
        )}
      </Grid>

      <GenericDialog maxWidth={"xl"} open={showNewsModal}>
        <NewsModal
          onClose={handleCloseNewsModal}
          newsMessage={props.authState.news}
        />
      </GenericDialog>
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getApplicationVersion: (data, date) => dispatch(getApplicationVersionAction(data, date)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
