import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import { useForm, FormProvider } from "react-hook-form";
import { connect } from "react-redux";
// @material-ui/core components
import Container from "@material-ui/core/Container";
import FormGroup from "@material-ui/core/FormGroup";
import Grid from "@material-ui/core/Grid";
//import custom components
import GenericButton from "components/Buttons/GenericButton";
import GeneralData from "../Sections/GeneralDataSection";
import Benefits from "../Sections/BenefitsSection";
import Documents from "../Sections/DocumentsSection";
import ComplementaryData from "../Sections/ComplementaryDataSection";
import { subprogramUpdateMap } from "mappers/subprogramsMap";
import {
  updateSubprogramAction,
  updateSubprogramStateAction,
} from "redux/actions/subprogramsAction";
import { SUBPROGRAM_STATES } from "utils/consts";
import { labels } from "resources/resources";

function GeneralDataTab(props) {
  const [complementaryDataList, setComplementaryDataList] = useState([]);
  const [isEditableGeneralData, setisEditableGeneralData] = useState(false);
  const [isEditableBenefits, setisEditableBenefits] = useState(false);
  const [isEditableDocuments, setisEditableDocuments] = useState(false);
  const [isEditableComplementaryData, setisEditableComplementaryData] =
    useState(false);

  const intl = useIntl();
  const history = useHistory();
  const methods = useForm({ mode: "onChange", reValidateMode: "onChange" });

  const onSubmit = (data) => {
    data["subprogramId"] = props.subprogramNumber;

    props.updateSubprogram(subprogramUpdateMap(data, complementaryDataList));

    setisEditableGeneralData(false);
    setisEditableBenefits(false);
    setisEditableDocuments(false);
    setisEditableComplementaryData(false);
  };

  const handleAproveSubprogramClick = () => {
    let auxData = {
      subprogramId: props.subprogramNumber,
      subprogramStateKeyValue: 2,
    };
    props.updateSubprogramState(auxData);
  };
  useEffect(() => {
    if (props.subprogramDetailsData !== undefined) {
      setComplementaryDataList(
        props.subprogramDetailsData.complementaryData?.map((c) => {
          return {
            itemKey: c.questionKey,
            itemType: c.answerTypeId,
            itemTitle: c.title,
            itemText: c.question,
            itemIsRequired: c.isRequired,
          };
        })
      );
    }
  }, [props.subprogramDetailsData]);

  const handleEditGeneralDataSection = () => {
    setisEditableGeneralData(true);
  };
  const handleEditBenefitsSection = () => {
    setisEditableBenefits(true);
  };
  const handleEditDocumentsSection = () => {
    setisEditableDocuments(true);
  };
  const handleEditComplementaryDataSection = () => {
    setisEditableComplementaryData(true);
  };

  return (
    <>
      <Container maxWidth={false}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <GeneralData
              control={methods.control}
              getValues={methods.getValues}
              setValue={methods.setValue}
              errors={methods.formState.errors}
              trigger={methods.trigger}
              isEditable={isEditableGeneralData}
              handleEditSection={handleEditGeneralDataSection}
              unregister={methods.unregister}
              loadData={true}
              subprogramDetailsData={props.subprogramDetailsData}
              onSubmit={methods.handleSubmit(onSubmit)}
            />
            <Benefits
              control={methods.control}
              getValues={methods.getValues}
              setValue={methods.setValue}
              errors={methods.formState.errors}
              isEditable={isEditableBenefits}
              handleEditSection={handleEditBenefitsSection}
              trigger={methods.trigger}
              unregister={methods.unregister}
              loadData={true}
              subprogramDetailsData={props.subprogramDetailsData}
            />
            <Documents
              control={methods.control}
              getValues={methods.getValues}
              setValue={methods.setValue}
              errors={methods.formState.errors}
              subprogramDetailsData={props.subprogramDetailsData}
              isEditable={isEditableDocuments}
              handleEditSection={handleEditDocumentsSection}
              loadData={true}
            />
            <ComplementaryData
              control={methods.control}
              getValues={methods.getValues}
              errors={methods.formState.errors}
              isEditable={isEditableComplementaryData}
              handleEditSection={handleEditComplementaryDataSection}
              unregister={methods.unregister}
              setValue={methods.setValue}
              trigger={methods.trigger}
              clearErrors={methods.clearErrors}
              complementaryDataList={complementaryDataList}
              setComplementaryDataList={setComplementaryDataList}
              subprogramDetailsData={props.subprogramDetailsData}
              loadData={true}
            />
            <Grid container>
              <Grid item xs={12} lg={6} xl={8}></Grid>
              <Grid item xs={12} lg={3} xl={2}>
                <FormGroup>
                  <GenericButton
                    typeSubmit={false}
                    color="secondary"
                    onClick={() => {
                      history.push("/admin/subprograms-management");
                    }}
                  >
                    {intl.formatMessage(labels.Label_Generic_Cancel)}
                  </GenericButton>
                </FormGroup>
              </Grid>
              <Grid item xs={12} lg={3} xl={2}>
                {props.subprogramDetailsData?.stateKey !==
                  SUBPROGRAM_STATES.CRIADO ||
                isEditableGeneralData ||
                isEditableBenefits ||
                isEditableDocuments ||
                isEditableComplementaryData ? (
                  <FormGroup>
                    <GenericButton
                      typeSubmit={true}
                      color="primary"
                      type="submit"
                      loading={props.subprogramState.updateSubprogram_loading}
                      disabled={
                        !(
                          isEditableGeneralData ||
                          isEditableBenefits ||
                          isEditableDocuments ||
                          isEditableComplementaryData
                        )
                      }
                    >
                      {intl.formatMessage(labels.Label_Generic_Save)}
                    </GenericButton>
                  </FormGroup>
                ) : (
                  props.subprogramDetailsData?.stateKey ===
                    SUBPROGRAM_STATES.CRIADO && (
                    <FormGroup>
                      <GenericButton
                        typeSubmit={false}
                        color="primary"
                        onClick={handleAproveSubprogramClick}
                        loading={
                          props.subprogramState.updateSubprogramState_loading
                        }
                      >
                        {intl.formatMessage(labels.Label_Approve_Submit)}
                      </GenericButton>
                    </FormGroup>
                  )
                )}
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </Container>
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  updateSubprogram: (subprogramData) =>
    dispatch(updateSubprogramAction(subprogramData)),
  updateSubprogramState: (data) => dispatch(updateSubprogramStateAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GeneralDataTab);
