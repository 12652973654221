import { React, useEffect, useState } from "react";
import { connect } from "react-redux";
import "moment/locale/pt";
import moment from "moment";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Pagination from "@material-ui/lab/Pagination";
// @material-ui/icons components
//custom components
import GenericButton from "components/Buttons/GenericButton";
import GenericDialog from "components/Dialogs/GenericDialogV2";
import CandidatesListSection from "../Sections/CandidatesListSection";
import AddCandidates from "../Modals/AddCandidatesModal.js";
import { getOpportunityUsersAction } from "redux/actions/opportunitiesAction";
import { getDataSetByNameAction } from "redux/actions/datasetsAction";
import { PAGINATION, DATASET, OPPORTUNITY_STATE_KEY } from "utils/consts";

import componentStyles from "assets/theme/views/admin/generic-page-table-result.js";
import componentStylesGeneric from "assets/theme/views/admin/generic";

const useStylesGeneric = makeStyles(componentStylesGeneric);
const useStyles = makeStyles(componentStyles);

function OpportunityCandidatesTab(props) {
  const classes = { ...useStylesGeneric(), ...useStyles() };
  const [page, setPage] = useState(1);
  const [showAddCandidatesModal, setShowAddCandidatesModal] = useState(false);

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    if (!props.dataSetState[DATASET.OPPORTUNITY_CANDIDATE_REJECT_MOTIVES])
      props.getDataSetByName(DATASET.OPPORTUNITY_CANDIDATE_REJECT_MOTIVES);

    if (!props.dataSetState[DATASET.PROVINCES]) props.getDataSetByName(DATASET.PROVINCES);

    if (!props.dataSetState[DATASET.PROFESSIONAL_CATEGORY]) props.getDataSetByName(DATASET.PROFESSIONAL_CATEGORY);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    refreshResults();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const refreshResults = () => {
    let filter = {
      opportunityId: props.opportunityId,
      pageIndex: page,
      pageSize: PAGINATION.PAGE_SIZE,
    };
    props.getOpportunityUsers(filter);
  };

  const handleOpenAddCandidatesModal = () => {
    setShowAddCandidatesModal(true);
  };

  const handleCloseAddCandidatesModal = () => {
    setShowAddCandidatesModal(false);
  };

  return (
    <Container maxWidth={false}>
      <Card classes={{ root: classes.cardRoot }}>
        <CardHeader
          classes={{
            title: classes.cardHeader,
            action: classes.cardHeaderAction,
          }}
          title={
            <div>
              <div className={classes.sideBySideDiv}>Candidatos</div>
              <div className={classes.sideBySideDiv + " " + classes.cardElementsCount}>
                {"("}
                {props.opportunitiesState?.total_records}
                {")"}
              </div>
            </div>
          }
          action={
            props.opportunitiesState.opportunityDetailsData?.opportunityStateKey === OPPORTUNITY_STATE_KEY.APROVADA && (
              <Grid item>
                <GenericButton
                  typeSubmit={false}
                  color="tertiary"
                  size="small"
                  onClick={handleOpenAddCandidatesModal}
                  disabled={
                    moment().isAfter(
                      moment(props.opportunitiesState?.opportunityDetailsData?.validTo).add(1, "days")
                    ) || moment().isBefore(moment(props.opportunitiesState?.opportunityDetailsData?.validFrom))
                  }
                >
                  Adicionar Candidatos
                </GenericButton>
              </Grid>
            )
          }
        />

        <CandidatesListSection opportunityId={props.opportunityId} />

        <Pagination
          className={classes.alignPagination}
          count={props.opportunitiesState?.total_pages}
          page={page}
          onChange={handleChangePage}
          color="primary"
        />
      </Card>

      {/* Add Candidates Modal */}
      <GenericDialog maxWidth={"xl"} open={showAddCandidatesModal} onClose={handleCloseAddCandidatesModal}>
        <AddCandidates
          opportunityDetailsData={props.opportunityDetailsData}
          handleClose={handleCloseAddCandidatesModal}
          onSuccess={refreshResults}
        />
      </GenericDialog>
    </Container>
  );
}
const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getDataSetByName: (name, parentKeyValue) => dispatch(getDataSetByNameAction(name, parentKeyValue)),
  getOpportunityUsers: (formData) => dispatch(getOpportunityUsersAction(formData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OpportunityCandidatesTab);
