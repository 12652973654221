import React, { useState, useEffect } from "react";

import { connect } from "react-redux";
import loginAction from "redux/actions/auth/loginAction";
import { recoverPasswordAction, resetRecoverStateAction } from "redux/actions/auth/recoverPasswordAction";
import resetResetPasswordStateAction from "redux/actions/auth/resetResetPasswordStateAction";
import resetSessionExpiredAction from "redux/actions/auth/resetSessionExpiredAction";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import FilledInput from "@material-ui/core/FilledInput";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormHelperText from "@material-ui/core/FormHelperText";

import Email from "@material-ui/icons/Email";
import Lock from "@material-ui/icons/Lock";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import IconButton from "@material-ui/core/IconButton";

// core components
import componentStylesLogin from "assets/theme/views/auth/login.js";
import componentStylesGeneric from "assets/theme/views/admin/generic";
import useWindowSize from "hooks/useWindowSize";
import GenericButton from "components/Buttons/GenericButton";

// react-router
import { Link } from "react-router-dom";
//lodash
import _ from "lodash";
import CustomDialog from "components/Dialogs/GenericDialog";
import GenericDialog from "components/Dialogs/GenericDialogV2";
import InfoContent from "./DialogsContent/InfoContent";
import RecoverDialogContent from "./DialogsContent/RecoverDialogContent";

const useStylesLogin = makeStyles(componentStylesLogin);
const useStylesGeneric = makeStyles(componentStylesGeneric);
const DIALOG = { SHOW: true, HIDE: false };

function Login(props) {
  const classes = { ...useStylesGeneric(), ...useStylesLogin() };
  const theme = useTheme();
  const windowSize = useWindowSize(); 
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const [state, setState] = useState({
    email: "",
    password: "",
  });

  const [dialogs, setDialogs] = useState({
    loginState: true,
    recoverModal: false,
    recoverSuccessModal: false,
    sessionExpiredModal: false,
  });

  const onChange = (stateName, value) => {
    setState((prevState) => ({
      ...prevState,
      [stateName]: value,
    }));
  };

  useEffect(() => {
    props.resetRecoverState();
    props.resetResetPasswordState();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.authState.sent) {
      showHideDialog("recoverSuccessModal", DIALOG.SHOW);

      props.resetRecoverState();
      props.resetResetPasswordState();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.authState.sent]);

  useEffect(() => {
    if (props.authState.session_expired) {
      showHideDialog("sessionExpiredModal", DIALOG.SHOW);

      props.resetRecoverState();
      props.resetResetPasswordState();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.authState.session_expired]);

  const showHideDialog = (dialog, op) => {
    let dialogs_temp = _.mapValues(dialogs, () => false);

    dialogs_temp[dialog] = op;

    if (op === DIALOG.HIDE) dialogs_temp["loginState"] = DIALOG.SHOW;

    setDialogs(dialogs_temp);
  };

  return (
    <>
        <Box>
          {windowSize.width > 900 &&
          <Box style={{width: "50%", float: "left"}}>
            <img
                src={"/images/empregoAngolaLogin.png"} //Designed by Rawpixel.com / Freepik: http://www.freepik.com
                className = {classes.backgroundImage}
                alt={""}
            />
          </Box>
        }
          
          {dialogs.loginState && (
            <Box 
              className={
                windowSize.width > 900 
                ? classes.loginBox 
                : classes.centerBoxHorizontally
              }
            >
              <Box className={classes.centerBoxItems}> 
                <Box> 
                  <Box 
                    className={classes.modalHeader}
                    style={{textAlign: "left", marginBottom: "30px"}}
                  > 
                    Login BackOffice
                  </Box>

                  <form>
                    <Box
                      color={theme.palette.gray[600]}
                      textAlign="center"
                      marginBottom="1rem"
                      marginTop=".5rem"
                      fontSize="1rem"
                    ></Box>
                    <FormControl
                      variant="filled"
                      component={Box}
                      width="100%"
                      marginBottom="1rem!important"
                      className={classes.boxHeader}
                    >
                      {" "}
                      E-mail
                      <FilledInput
                        required
                        autoComplete="off"
                        type="email"
                        placeholder="Email"
                        onChange={(e) => onChange("email", e.target.value)}
                        value={state.email}
                        startAdornment={
                          <InputAdornment position="start">
                            <Email />
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                    <FormControl
                      variant="filled"
                      component={Box}
                      width="100%"
                      marginBottom="1rem!important"
                      className={classes.boxHeader}
                    >
                      Palavra-passe
                      <FilledInput
                        required
                        autoComplete="off"
                        type={showPassword ? "text" : "password"}
                        placeholder="Palavra-passe"
                        value={state.password}
                        onChange={(e) => onChange("password", e.target.value)}
                        startAdornment={
                          <InputAdornment position="start">
                            <Lock />
                          </InputAdornment>
                        }
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                    <Grid container component={Box} marginTop="1rem">
                      <Grid item xs={6} component={Box} textAlign="right">
                        <a
                          underline="always"
                          href="#admui"
                          onClick={(e) => e.preventDefault()}
                          className={classes.footerLinks}
                        > </a>
                      </Grid>
                      <Grid item xs={12} component={Box} textAlign="right">
                        <Link
                          onClick={() => showHideDialog("recoverModal", DIALOG.SHOW)}
                          className={classes.footerLinks}
                          to="#"
                        >
                          Esqueceu-se da palavra-passe?
                        </Link>
                      </Grid>
                    </Grid>
                    {props.authState.login_error && (
                      <FormHelperText component={Box} color={theme.palette.error.main + "!important"}>
                        E-mail ou Palavra-passe inválidos!
                      </FormHelperText>
                    )}
                    <Box textAlign="right" marginTop="1.5rem" marginBottom="1.5rem">
                      <GenericButton
                        typeSubmit={false}
                        onClick={() => {
                          props.loginAction(state.email, state.password);
                        }}
                        color="primary"
                        loading={props.authState.login_loading}
                      >
                        Entrar
                      </GenericButton>
                    </Box>
                  </form>
                </Box>
              </Box>
            </Box>
          )}
        </Box>

        <GenericDialog
          name="recover-password-dialog"
          open={dialogs.recoverModal}
          onClose={() => showHideDialog("recoverModal", DIALOG.HIDE)}
          maxWidth={"xs"}
        >
          <RecoverDialogContent
            loading={props.authState.recover_password_loading}
            onClick={() => {
              showHideDialog("recoverModal", DIALOG.HIDE);
            }}
          />
        </GenericDialog>

        <GenericDialog
          name="recover-password-success-dialog"
          open={dialogs.recoverSuccessModal}
          onClose={() => showHideDialog("recoverSuccessModal", DIALOG.HIDE)}
        >
          <InfoContent
            message={
              "No caso de o e-mail indicado existir no sistema, irá receber um pedido de recuperação de palavra-passe."
            }
            onClick={() => showHideDialog("recoverSuccessModal", DIALOG.HIDE)}
          />
        </GenericDialog>

        <GenericDialog
          name="session-expired-dialog"
          open={dialogs.sessionExpiredModal}
          onClose={() => {
            props.resetSessionExpiredState();
            showHideDialog("sessionExpiredModal", DIALOG.HIDE);
          }}
        >
          <InfoContent
            message={"Sessão Expirada. Por favor entre novamente."}
            onClick={() => {
              props.resetSessionExpiredState();
              showHideDialog("sessionExpiredModal", DIALOG.HIDE);
            }}
          />
        </GenericDialog>
        
        <CustomDialog />
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  loginAction: (email, password) => dispatch(loginAction(email, password)),
  recoverPassword: (email) => dispatch(recoverPasswordAction(email)),
  resetRecoverState: () => dispatch(resetRecoverStateAction()),
  resetSessionExpiredState: () => dispatch(resetSessionExpiredAction()),
  resetResetPasswordState: () => dispatch(resetResetPasswordStateAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
