import {
  getFilteredOpportunities,
  getOpportunitiesDetails,
  updateOpportunityState,
  getCandidateInformation,
  getOpportunityUsers,
  createOpportunity,
  updateOpportunity,
  approveOpportunityCandidate,
  rejectOpportunityCandidate,
  opportunityCandidateWithdraw,
  getOpportunityDocument,
  getFilteredPossibleOpportunityCandidates,
  addOpportunityCandidatesList,
  getAllOpportunityApplications,
  getAllOpportunityApplicationsFollowUp,
  getOpportunityApplicationsFollowUpDetails,
  updateOpportunityFollowupPeriod,
  getOpportunityApplicationsFollowUpFeedbackReport,
  validateFeedbackReport,
  createAdHocFeedbackReport,
  getFilteredAvailableOpportunitiesForUser,
  createApplicationsForUser,
} from "crud/opportunities.crud";
import { OPPORTUNITIES_ACTIONS, DIALOG } from "utils/actionsConsts";
import { UPDATE_OPPORTUNITY_STATE_ACTION_TYPE } from "utils/consts";
import { convertByteArrayToBlobUrl } from "utils/converters";
import moment from "moment";
import { takeResponseErrors } from "utils/takeResponseErrors";
import { get } from "lodash";
import { INTERNSHIP_TYPE_KEY } from "utils/consts";

export const getFilteredOpportunitiesAction = (formData) => async (dispatch) => {
  dispatch({
    type: OPPORTUNITIES_ACTIONS.GET_FILTERED_OPPORTUNITIES_ACTIONS_LOADING,
    payload: true,
  });
  getFilteredOpportunities(formData)
    .then((res) => {
      const mappedList = res.data?.data.map((record) => {
        return {
          id: record.id,
          userId: record.userId,
          userName: record.userName,
          userTaxIdNumber: record.userTaxIdNumber,
          numVacancies: record.numVacancies,
          numVacanciesToCandidatesRatio: record.approvedCandidates + "/" + record.numVacancies,
          provinceId: record.provinceId,
          provinceKey: record.provinceKey,
          municipalityId: record.municipalityId,
          municipalityKey: record.municipalityKey,
          employmentCenterId: record.employmentCenterId,
          employmentCenterKey: record.employmentCenterKey,
          startDate: record.startDate,
          endDate: record.endDate,
          provinceDescription: record.provinceDescription,
          reference: record.reference,
          title: record.title,
          validFrom: record.validFrom,
          validTo: record.validTo,
          validPeriod:
            record.validFrom !== null && record.validTo !== null
              ? moment(record.validFrom).format("DD/MM/YYYY") + " - " + moment(record.validTo).format("DD/MM/YYYY")
              : "N/A",
          opportunityStateId: record.opportunityStateId,
          opportunityStateKey: record.opportunityStateKey,
          opportunityStateDescription: record.opportunityStateDescription,
          opportunityTypeId: record.opportunityTypeId,
          opportunityTypeKey: record.opportunityTypeKey,
          opportunityTypeDescription: record.opportunityTypeDescription,
          totalCandidates: record.totalCandidates,
          contractTypeId: record.contractTypeId,
          contractTypeKey: record.contractTypeKeyValue,
          contractTypeDescription: record.contractTypeDescription,
          internshipTypeId: record.internshipTypeId,
          internshipTypeKey: record.internshipTypeKeyValue,
          internshipTypeDescription: record.internshipTypeDescription,
        };
      });
      dispatch({
        type: OPPORTUNITIES_ACTIONS.GET_FILTERED_OPPORTUNITIES_ACTIONS,
        result: true,
        payload: {
          data: mappedList,
          total_records: res.data.totalCount,
          total_pages: res.data.totalPages,
        },
      });
      dispatch({
        type: OPPORTUNITIES_ACTIONS.GET_FILTERED_OPPORTUNITIES_ACTIONS_LOADING,
        payload: false,
      });
    })
    .catch((error) => {
      dispatch({
        type: OPPORTUNITIES_ACTIONS.GET_FILTERED_OPPORTUNITIES_ACTIONS,
        result: false,
        payload: [],
      });
      dispatch({
        type: OPPORTUNITIES_ACTIONS.GET_FILTERED_OPPORTUNITIES_ACTIONS_LOADING,
        payload: false,
      });
      console.log("error", error);
    });
};

export const createOpportunityAction = (data) => async (dispatch) => {
  const internshipTypeId = data.get("opportunity.internshipTypeId");

  dispatch({
    type: OPPORTUNITIES_ACTIONS.GET_SUBMIT_LOADING,
    payload: true,
  });
  createOpportunity(data)
    .then((res) => {
      dispatch({
        type: OPPORTUNITIES_ACTIONS.CREATE,
        payload: true,
      });
      dispatch({
        type: OPPORTUNITIES_ACTIONS.GET_SUBMIT_LOADING,
        payload: false,
      });
      dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            isSuccess: res.data,
            goBack: res.data,
            buttonLabel: "Ok",
            onClose: "/admin/opportunity-management",
            message:
              !internshipTypeId || internshipTypeId === INTERNSHIP_TYPE_KEY.GERAL
                ? "Oferta criada com sucesso."
                : "Oferta submetida para aprovação.",
          },
        },
      });
    })
    .catch((error) => {
      dispatch({
        type: OPPORTUNITIES_ACTIONS.CREATE,
        payload: false,
      });
      dispatch({
        type: OPPORTUNITIES_ACTIONS.GET_SUBMIT_LOADING,
        payload: false,
      });

      if (get(error, "response.status") === 400) {
        dispatch({
          type: DIALOG.SHOW,
          payload: {
            showGenericDialog: true,
            genericDialogData: {
              type: DIALOG.ONE_BUTTON_TYPE,
              isSuccess: false,
              message: takeResponseErrors(error?.response.data?.errors),
            },
          },
        });
      } else {
        dispatch({
          type: DIALOG.SHOW,
          payload: {
            showGenericDialog: true,
            genericDialogData: {
              type: DIALOG.ONE_BUTTON_TYPE,
              isSuccess: false,
              message: "Ocorreu um erro a efetuar o seu pedido, por favor tente mais tarde.",
            },
          },
        });
      }
      console.log("error", error);
    });
};

export const updateOpportunityAction = (data, _onCloseAction) => async (dispatch) => {
  dispatch({
    type: OPPORTUNITIES_ACTIONS.GET_UPDATE_LOADING,
    payload: true,
  });
  updateOpportunity(data)
    .then((res) => {
      dispatch({
        type: OPPORTUNITIES_ACTIONS.UPDATE,
        payload: true,
      });
      dispatch({
        type: OPPORTUNITIES_ACTIONS.GET_UPDATE_LOADING,
        payload: false,
      });
      dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            isSuccess: res.data,
            goBack: true,
            buttonLabel: "Ok",
            okAction: res.data ? _onCloseAction : undefined,
            onCloseAction: res.data ? _onCloseAction : undefined,
            message: res.data ? "Oferta actualizada com sucesso." : "Ocorreu um erro ao actualizar Oferta.",
          },
        },
      });
    })
    .catch((error) => {
      dispatch({
        type: OPPORTUNITIES_ACTIONS.UPDATE,
        payload: false,
      });
      dispatch({
        type: OPPORTUNITIES_ACTIONS.GET_UPDATE_LOADING,
        payload: false,
      });
      dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            isSuccess: false,
            message: "Ocorreu um erro a efetuar o seu pedido, por favor tente mais tarde.",
          },
        },
      });
      console.log("error", error);
    });
};

export const getOpportunitiesDetailsAction = (opportunityId) => async (dispatch) => {
  dispatch({
    type: OPPORTUNITIES_ACTIONS.GET_OPPORTUNITY_DETAILS_ACTIONS_LOADING,
    payload: true,
  });
  getOpportunitiesDetails(opportunityId)
    .then((res) => {
      dispatch({
        type: OPPORTUNITIES_ACTIONS.GET_OPPORTUNITY_DETAILS_ACTIONS,
        result: true,
        payload: res.data,
      });
      dispatch({
        type: OPPORTUNITIES_ACTIONS.GET_OPPORTUNITY_DETAILS_ACTIONS_LOADING,
        payload: false,
      });
    })
    .catch((error) => {
      dispatch({
        type: OPPORTUNITIES_ACTIONS.GET_OPPORTUNITY_DETAILS_ACTIONS,
        result: false,
        payload: {},
      });
      dispatch({
        type: OPPORTUNITIES_ACTIONS.GET_OPPORTUNITY_DETAILS_ACTIONS_LOADING,
        payload: false,
      });
      console.log("error", error);
    });
};

export const updateOpportunityStateAction = (formData, _onCloseAction) => async (dispatch) => {
  dispatch({
    type: OPPORTUNITIES_ACTIONS.UPDATE_OPPORTUNITY_STATE_ACTIONS_LOADING,
    payload: true,
  });
  updateOpportunityState(formData)
    .then((res) => {
      dispatch({
        type: OPPORTUNITIES_ACTIONS.UPDATE_OPPORTUNITY_STATE_ACTIONS_LOADING,
        payload: false,
      });
      dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            onCloseAction: res.data ? _onCloseAction : undefined,
            okAction: res.data ? _onCloseAction : undefined,
            isSuccess: res.data,
            message: res.data
              ? getSuccessMessage(formData.actionType)
              : "Ocorreu um erro ao actualizar o estado da oferta.",
          },
        },
      });
    })
    .catch((error) => {
      dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            isSuccess: false,
            message: error.response?.data?.errors
              ? error.response.data.errors[Object.keys(error.response.data.errors)[0]][0]
              : "Ocorreu um erro ao actualizar o estado da oferta.",
          },
        },
      });
      dispatch({
        type: OPPORTUNITIES_ACTIONS.UPDATE_OPPORTUNITY_STATE_ACTIONS_LOADING,
        payload: false,
      });
      console.log("error", error);
    });
};

export const getCandidateInformationAction = (userId) => async (dispatch) => {
  dispatch({
    type: OPPORTUNITIES_ACTIONS.GET_CANDIDATE_INFORMATION_LOADING,
    payload: true,
  });
  getCandidateInformation(userId)
    .then((res) => {
      dispatch({
        type: OPPORTUNITIES_ACTIONS.GET_CANDIDATE_INFORMATION,
        payload: res.data,
      });
      dispatch({
        type: OPPORTUNITIES_ACTIONS.GET_CANDIDATE_INFORMATION_LOADING,
        payload: false,
      });
    })
    .catch((error) => {
      dispatch({
        type: OPPORTUNITIES_ACTIONS.GET_CANDIDATE_INFORMATION_LOADING,
        payload: false,
      });

      console.log("error", error);
    });
};

export const getOpportunityUsersAction = (formData) => async (dispatch) => {
  dispatch({
    type: OPPORTUNITIES_ACTIONS.GET_OPPORTUNITY_USERS_ACTIONS_LOADING,
    payload: true,
  });
  getOpportunityUsers(formData)
    .then((res) => {
      const mappedList = res.data?.data.map((record) => {
        return {
          userId: record.userId,
          alternateKey: record.alternateKey,
          opportunityId: record.opportunityId,
          userName: record.userName,
          identityCard: record.userIdentityCard,
          candidateState: record.opportunityCandidateStateId,
          candidateStateDescription:
            record.opportunityCandidateStateDescription === "Aprovada"
              ? "Recrutado"
              : record.opportunityCandidateStateDescription,
          applicationDate:
            record.opportunityCandidateDate !== null
              ? moment(record.opportunityCandidateDate).format("DD/MM/YYYY")
              : "N/A",
          papeInternshipOverlapsOthersOfUser: record.papeInternshipOverlapsOthersOfUser,
        };
      });
      dispatch({
        type: OPPORTUNITIES_ACTIONS.GET_OPPORTUNITY_USERS_ACTIONS,
        result: true,
        payload: {
          data: mappedList,
          total_records: res.data.totalCount,
          total_pages: res.data.totalPages,
        },
      });
      dispatch({
        type: OPPORTUNITIES_ACTIONS.GET_OPPORTUNITY_USERS_ACTIONS_LOADING,
        payload: false,
      });
    })
    .catch((error) => {
      dispatch({
        type: OPPORTUNITIES_ACTIONS.GET_OPPORTUNITY_USERS_ACTIONS,
        result: false,
        payload: [],
      });
      dispatch({
        type: OPPORTUNITIES_ACTIONS.GET_OPPORTUNITY_USERS_ACTIONS_LOADING,
        payload: false,
      });
      console.log("error", error);
    });
};

export const getOpportunityDocumentAction = (docId, docName) => async (dispatch) => {
  getOpportunityDocument(docId)
    .then((res) => {
      const link = document.createElement("a");
      link.href = convertByteArrayToBlobUrl(res.data);
      link.setAttribute("download", docName);
      link.setAttribute("target", "_blank");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })
    .catch((error) => {
      dispatch({
        type: OPPORTUNITIES_ACTIONS.GET_OPPORTUNITY_DOCUMENT,
        result: false,
        payload: null,
      });

      console.log("error", error);
    });
};

const getSuccessMessage = (actionType) => {
  switch (actionType) {
    case UPDATE_OPPORTUNITY_STATE_ACTION_TYPE.REJECT:
      return "Oferta rejeitada com sucesso";
    case UPDATE_OPPORTUNITY_STATE_ACTION_TYPE.CLOSE:
      return "Oferta fechada com sucesso";
    case UPDATE_OPPORTUNITY_STATE_ACTION_TYPE.APPROVE:
      return "Oferta aprovada com sucesso";
    default:
      return "Oferta actualizada com sucesso";
  }
};

export const approveOpportunityCandidateAction = (formData, isOpportunityApplication) => async (dispatch) => {
  dispatch({
    type: OPPORTUNITIES_ACTIONS.APPROVE_CANDIDATE_LOADING,
    payload: true,
  });
  approveOpportunityCandidate(formData)
    .then((res) => {
      dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            onClose: isOpportunityApplication
              ? "/admin/opportunity-applications-management"
              : "/admin/opportunity-management",
            goBack: true,
            isSuccess: res.data,
            message: res.data ? "Candidato recrutado!" : "Ocorreu um erro ao recrutar candidato.",
          },
        },
      });
      dispatch({
        type: OPPORTUNITIES_ACTIONS.APPROVE_CANDIDATE_LOADING,
        payload: false,
      });
    })
    .catch((error) => {
      dispatch({
        type: OPPORTUNITIES_ACTIONS.APPROVE_CANDIDATE_LOADING,
        payload: false,
      });
      dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            isSuccess: false,
            message: error.response?.data?.errors
              ? error.response.data.errors[Object.keys(error.response.data.errors)[0]][0]
              : "Erro a efetuar o seu pedido. Por favor tente mais tarde",
          },
        },
      });
      console.log("error", error);
    });
};

export const rejectOpportunityCandidateAction = (formData, isOpportunityApplication) => async (dispatch) => {
  dispatch({
    type: OPPORTUNITIES_ACTIONS.REJECT_CANDIDATE_LOADING,
    payload: true,
  });
  rejectOpportunityCandidate(formData)
    .then((res) => {
      dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            onClose: isOpportunityApplication
              ? "/admin/opportunity-applications-management"
              : "/admin/opportunity-management",
            goBack: true,
            isSuccess: res.data,
            message: res.data ? "Candidato rejeitado!" : "Ocorreu um erro ao rejeitar candidato.",
          },
        },
      });
      dispatch({
        type: OPPORTUNITIES_ACTIONS.REJECT_CANDIDATE_LOADING,
        payload: false,
      });
    })
    .catch((error) => {
      dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            isSuccess: false,
            message: error.response?.data?.errors
              ? error.response.data.errors[Object.keys(error.response.data.errors)[0]][0]
              : "Erro a efetuar o seu pedido. Por favor tente mais tarde",
          },
        },
      });
      console.log("error", error);
    });
};

export const opportunityCandidateWithdrawAction =
  (formData, isOpportunityApplication, toWithdraw) => async (dispatch) => {
    dispatch({
      type: OPPORTUNITIES_ACTIONS.CANDIDATE_WITHDRAW_LOADING,
      payload: true,
    });
    opportunityCandidateWithdraw(formData)
      .then((res) => {
        dispatch({
          type: DIALOG.SHOW,
          payload: {
            showGenericDialog: true,
            genericDialogData: {
              type: DIALOG.ONE_BUTTON_TYPE,
              onClose: isOpportunityApplication
                ? "/admin/opportunity-applications-management"
                : "/admin/opportunity-management",
              goBack: true,
              isSuccess: !!res.data,
              message: res.data
                ? toWithdraw
                  ? "Registada a desistência do candidato."
                  : "Anulada a desistência do candidato."
                : "Ocorreu um erro ao registar a desistência do candidato.",
            },
          },
        });
        dispatch({
          type: OPPORTUNITIES_ACTIONS.CANDIDATE_WITHDRAW_LOADING,
          payload: false,
        });
      })
      .catch((error) => {
        dispatch({
          type: DIALOG.SHOW,
          payload: {
            showGenericDialog: true,
            genericDialogData: {
              type: DIALOG.ONE_BUTTON_TYPE,
              isSuccess: false,
              message: error.response?.data?.errors
                ? error.response.data.errors[Object.keys(error.response.data.errors)[0]][0]
                : "Erro a efetuar o seu pedido. Por favor tente mais tarde",
            },
          },
        });
        console.log("error", error);
      });
  };

export const getFilteredPossibleOpportunityCandidatesAction = (formData) => async (dispatch) => {
  dispatch({
    type: OPPORTUNITIES_ACTIONS.GET_FILTERED_POSSIBLE_OPPORTUNITY_CANDIDATES_LOADING,
    payload: true,
  });
  getFilteredPossibleOpportunityCandidates(formData)
    .then((res) => {
      const mappedList = res.data?.data.map((record) => {
        return {
          userId: record.userId,
          userName: record.userName,
          userIdentityCard: record.userIdentityCard,
          province: record.province,
          municipality: record.municipality,
          intendedWorkplaceProvince: record.intendedWorkplaceProvince,
          academicLevel: record.academicLevel,
          professionalCategories: record.professionalCategories,
          studiedLanguages: record.studiedLanguages,
          isAvailableForInternshipOpportunities: record.isAvailableForInternshipOpportunities,
          isAvailableForEmploymentOpportunities: record.isAvailableForEmploymentOpportunities,
        };
      });
      dispatch({
        type: OPPORTUNITIES_ACTIONS.GET_FILTERED_POSSIBLE_OPPORTUNITY_CANDIDATES,
        result: true,
        payload: {
          data: mappedList,
          total_records: res.data.totalCount,
          total_pages: res.data.totalPages,
        },
      });
      dispatch({
        type: OPPORTUNITIES_ACTIONS.GET_FILTERED_POSSIBLE_OPPORTUNITY_CANDIDATES_LOADING,
        payload: false,
      });
    })
    .catch((error) => {
      dispatch({
        type: OPPORTUNITIES_ACTIONS.GET_FILTERED_POSSIBLE_OPPORTUNITY_CANDIDATES,
        result: false,
        payload: [],
      });
      dispatch({
        type: OPPORTUNITIES_ACTIONS.GET_FILTERED_POSSIBLE_OPPORTUNITY_CANDIDATES_LOADING,
        payload: false,
      });
      console.log("error", error);
    });
};

export const addOpportunityCandidatesListAction = (data, _onCloseAction) => async (dispatch) => {
  dispatch({
    type: OPPORTUNITIES_ACTIONS.ADD_OPPORTUNITY_CANDIDATES_LIST_LOADING,
    payload: true,
  });
  addOpportunityCandidatesList(data)
    .then((res) => {
      dispatch({
        type: OPPORTUNITIES_ACTIONS.ADD_OPPORTUNITY_CANDIDATES_LIST_LOADING,
        payload: false,
      });
      dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            isSuccess: res.data.success,
            buttonLabel: "Ok",
            okAction: res.data.success ? _onCloseAction : undefined,
            onCloseAction: res.data.success ? _onCloseAction : undefined,
            message: res.data.success
              ? `Foram adicionados ${res.data.addedCandidatesCount} candidatos com sucesso à oferta`
              : "Ocorreu um erro ao adicionar candidatos à oferta.",
          },
        },
      });
    })
    .catch((error) => {
      dispatch({
        type: OPPORTUNITIES_ACTIONS.ADD_OPPORTUNITY_CANDIDATES_LIST_LOADING,
        payload: false,
      });
      dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            isSuccess: false,
            message: "Ocorreu um erro a efetuar o seu pedido, por favor tente mais tarde.",
          },
        },
      });
      console.log("error", error);
    });
};

export const getAllOpportunityApplicationsAction = (formData) => async (dispatch) => {
  dispatch({
    type: OPPORTUNITIES_ACTIONS.GET_ALL_OPPORTUNITY_APPLICATIONS_LOADING,
    payload: true,
  });
  getAllOpportunityApplications(formData)
    .then((res) => {
      const mappedList = res.data?.data.map((record) => {
        return {
          opportunityId: record.opportunityId,
          opportunityTypeId: record.opportunityTypeId,
          opportunityTypeKey: record.opportunityTypeKey,
          opportunityTypeDescription: record.opportunityTypeDescription,
          opportunityTitle: record.opportunityTitle,
          opportunityReference: record.opportunityReference,
          candidateCode: record.candidateCode,
          candidateStateId: record.candidateStateId,
          candidateStateKey: record.candidateStateKey,
          candidateStateDescription: record.candidateStateDescription,
          userId: record.userId,
          userName: record.userName,
          userIdentityCard: record.userIdentityCard,
          companyId: record.companyId,
          companyName: record.companyName,
          companyTaxIdNumber: record.companyTaxIdNumber,
          creationDate: moment(record.creationDate).format("DD/MM/YYYY"),
          provinceId: record.provinceId,
          provinceKey: record.provinceKey,
          municipalityId: record.municipalityId,
          municipalityKey: record.municipalityKey,
          employmentCenterId: record.employmentCenterId,
          employmentCenterKey: record.employmentCenterKey,
          startDate: record.startDate,
          endDate: record.endDate,
          userCode: record.userCode,
        };
      });
      dispatch({
        type: OPPORTUNITIES_ACTIONS.GET_ALL_OPPORTUNITY_APPLICATIONS,
        result: true,
        payload: {
          data: mappedList,
          total_records: res.data.totalCount,
          total_pages: res.data.totalPages,
        },
      });
      dispatch({
        type: OPPORTUNITIES_ACTIONS.GET_ALL_OPPORTUNITY_APPLICATIONS_LOADING,
        payload: false,
      });
    })
    .catch((error) => {
      dispatch({
        type: OPPORTUNITIES_ACTIONS.GET_ALL_OPPORTUNITY_APPLICATIONS,
        result: false,
        payload: [],
      });
      dispatch({
        type: OPPORTUNITIES_ACTIONS.GET_ALL_OPPORTUNITY_APPLICATIONS_LOADING,
        payload: false,
      });
      console.log("error", error);
    });
};

export const getIsOpportunityApplicationAction = (isOpportunityApplication) => async (dispatch) => {
  dispatch({
    type: OPPORTUNITIES_ACTIONS.GET_IS_OPPORTUNITY_APPLICATION,
    payload: isOpportunityApplication,
  });
};

export const getAllOpportunityApplicationsFollowUpAction = (formData) => async (dispatch) => {
  dispatch({
    type: OPPORTUNITIES_ACTIONS.GET_ALL_OPPORTUNITY_APPLICATIONS_FOLLOWUP_LOADING,
    payload: true,
  });

  getAllOpportunityApplicationsFollowUp(formData)
    .then((res) => {
      const mappedList = res.data?.data.map((record) => {
        return {
          opportunityId: record.opportunityId,
          opportunityTypeId: record.opportunityTypeId,
          opportunityTypeKey: record.opportunityTypeKey,
          opportunityTypeDescription: record.opportunityTypeDescription,
          opportunityTitle: record.opportunityTitle,
          opportunityReference: record.opportunityReference,
          candidateCode: record.candidateCode,
          candidateFollowUpStateId: record.candidateFollowUpStateId,
          candidateFollowUpStateKey: record.candidateFollowUpStateKey,
          candidateFollowUpStateDescription: record.candidateFollowUpStateDescription,
          candidateOverallEvaluation: record.candidateOverallEvaluation,
          userId: record.userId,
          userName: record.userName,
          userIdentityCard: record.userIdentityCard,
          userCode: record.userCode,
          companyId: record.companyId,
          companyName: record.companyName,
          companyTaxIdNumber: record.companyTaxIdNumber,
          hasUnapprovedFeedbackReport: record.hasUnapprovedFeedbackReport,
          creationDate: record.creationDate,
        };
      });
      dispatch({
        type: OPPORTUNITIES_ACTIONS.GET_ALL_OPPORTUNITY_APPLICATIONS_FOLLOWUP,
        result: true,
        payload: {
          data: mappedList,
          total_records: res.data.totalCount,
          total_pages: res.data.totalPages,
        },
      });
      dispatch({
        type: OPPORTUNITIES_ACTIONS.GET_ALL_OPPORTUNITY_APPLICATIONS_FOLLOWUP_LOADING,
        payload: false,
      });
    })
    .catch((error) => {
      dispatch({
        type: OPPORTUNITIES_ACTIONS.GET_ALL_OPPORTUNITY_APPLICATIONS_FOLLOWUP,
        result: false,
        payload: [],
      });
      dispatch({
        type: OPPORTUNITIES_ACTIONS.GET_ALL_OPPORTUNITY_APPLICATIONS_FOLLOWUP_LOADING,
        payload: false,
      });
      console.log("error", error);
    });
};

export const getOpportunityApplicationsFollowUpDetailsAction = (candidateCode) => async (dispatch) => {
  dispatch({
    type: OPPORTUNITIES_ACTIONS.GET_OPPORTUNITY_APPLICATIONS_FOLLOWUP_DETAILS_LOADING,
    payload: true,
  });
  getOpportunityApplicationsFollowUpDetails(candidateCode)
    .then((res) => {
      dispatch({
        type: OPPORTUNITIES_ACTIONS.GET_OPPORTUNITY_APPLICATIONS_FOLLOWUP_DETAILS,
        payload: res.data,
      });
      dispatch({
        type: OPPORTUNITIES_ACTIONS.GET_OPPORTUNITY_APPLICATIONS_FOLLOWUP_DETAILS_LOADING,
        payload: false,
      });
    })
    .catch((error) => {
      dispatch({
        type: OPPORTUNITIES_ACTIONS.GET_OPPORTUNITY_APPLICATIONS_FOLLOWUP_DETAILS_LOADING,
        payload: false,
      });

      console.log("error", error);
    });
};

export const updateOpportunityFollowupPeriodAction = (formData, _onCloseAction) => async (dispatch) => {
  dispatch({
    type: OPPORTUNITIES_ACTIONS.UPDATE_OPPORTUNITY_FOLLOWUP_PERIOD_LOADING,
    payload: true,
  });
  updateOpportunityFollowupPeriod(formData)
    .then((res) => {
      dispatch({
        type: OPPORTUNITIES_ACTIONS.UPDATE_OPPORTUNITY_FOLLOWUP_PERIOD_LOADING,
        payload: false,
      });
      dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            onCloseAction: res.data ? _onCloseAction : undefined,
            okAction: res.data ? _onCloseAction : undefined,
            isSuccess: res.data,
            message: res.data
              ? "Estágio atualizado com sucesso"
              : "Ocorreu um erro ao actualizar as datas de início/fim do estágio.",
          },
        },
      });
    })
    .catch((error) => {
      dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            isSuccess: false,
            message: "Ocorreu um erro ao actualizar as datas de início/fim do estágio.",
          },
        },
      });
      dispatch({
        type: OPPORTUNITIES_ACTIONS.UPDATE_OPPORTUNITY_FOLLOWUP_PERIOD_LOADING,
        payload: false,
      });
      console.log("error", error);
    });
};

export const getFeedbackReportDetailsAction = (feedbackReportId) => async (dispatch) => {
  dispatch({
    type: OPPORTUNITIES_ACTIONS.CLEAR_VALIDATE_FEEDBACK_REPORT_LOADING,
  });
  dispatch({
    type: OPPORTUNITIES_ACTIONS.GET_FEEDBACK_REPORT_DETAILS_LOADING,
    payload: true,
  });
  getOpportunityApplicationsFollowUpFeedbackReport(feedbackReportId)
    .then((res) => {
      dispatch({
        type: OPPORTUNITIES_ACTIONS.GET_FEEDBACK_REPORT_DETAILS,
        payload: res.data,
      });
      dispatch({
        type: OPPORTUNITIES_ACTIONS.GET_FEEDBACK_REPORT_DETAILS_LOADING,
        payload: false,
      });
    })
    .catch((error) => {
      dispatch({
        type: OPPORTUNITIES_ACTIONS.GET_FEEDBACK_REPORT_DETAILS_LOADING,
        payload: false,
      });
      console.log("error", error);
    });
};

export const validateFeedbackReportAction = (formData, _onCloseAction) => async (dispatch) => {
  dispatch({
    type: OPPORTUNITIES_ACTIONS.VALIDATE_FEEDBACK_REPORT_LOADING,
    payload: true,
  });
  validateFeedbackReport(formData)
    .then((res) => {
      dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            onCloseAction: res.data ? _onCloseAction : undefined,
            okAction: res.data ? _onCloseAction : undefined,
            goBack: true,
            isSuccess: res.data,
            message: res.data
              ? formData.action && formData.amountPaid
                ? "Pagamento da bolsa de estágio registado com sucesso!"
                : "Formulário " + (formData.action ? "aprovado!" : "rejeitado!")
              : "Ocorreu um erro ao " + (formData.action ? "aprovar" : "rejeitar") + " o formulário.",
          },
        },
      });
      dispatch({
        type: OPPORTUNITIES_ACTIONS.VALIDATE_FEEDBACK_REPORT_LOADING,
        payload: false,
      });
    })
    .catch((error) => {
      dispatch({
        type: OPPORTUNITIES_ACTIONS.VALIDATE_FEEDBACK_REPORT_LOADING,
        payload: false,
      });
      dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            isSuccess: false,
            message: error.response?.data?.errors
              ? error.response.data.errors[Object.keys(error.response.data.errors)[0]][0]
              : "Erro a efetuar o seu pedido. Por favor tente mais tarde",
          },
        },
      });
      console.log("error", error);
    });
};

export const createAdHocFeedbackReportAction = (formData) => async (dispatch) => {
  dispatch({
    type: OPPORTUNITIES_ACTIONS.CREATE_ADHOC_FEEDBACK_REPORT_LOADING,
    payload: true,
  });
  await createAdHocFeedbackReport(formData)
    .then((res) => {
      dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            goBack: true,
            isSuccess: res.data,
            message: res.data
              ? "Formulário de acompanhamento criado com sucesso!"
              : "Ocorreu um erro ao criar o formulário.",
          },
        },
      });
      dispatch({
        type: OPPORTUNITIES_ACTIONS.CREATE_ADHOC_FEEDBACK_REPORT_LOADING,
        payload: false,
      });
    })
    .catch((error) => {
      dispatch({
        type: OPPORTUNITIES_ACTIONS.CREATE_ADHOC_FEEDBACK_REPORT_LOADING,
        payload: false,
      });
      dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            isSuccess: false,
            message: error.response?.data?.errors
              ? error.response.data.errors[Object.keys(error.response.data.errors)[0]][0]
              : "Erro a efetuar o seu pedido. Por favor tente mais tarde",
          },
        },
      });
      console.log("error", error);
    });
};

export const getFilteredAvailableOpportunitiesForUserAction = (formData) => async (dispatch) => {
  dispatch({
    type: OPPORTUNITIES_ACTIONS.GET_FILTERED_AVAILABLE_OPPORTUNITIES_FOR_USER_LOADING,
    payload: true,
  });
  getFilteredAvailableOpportunitiesForUser(formData)
    .then((res) => {
      dispatch({
        type: OPPORTUNITIES_ACTIONS.GET_FILTERED_AVAILABLE_OPPORTUNITIES_FOR_USER,
        payload: res.data,
      });
      dispatch({
        type: OPPORTUNITIES_ACTIONS.GET_FILTERED_AVAILABLE_OPPORTUNITIES_FOR_USER_LOADING,
        payload: false,
      });
    })
    .catch(() => {
      dispatch({
        type: OPPORTUNITIES_ACTIONS.GET_FILTERED_AVAILABLE_OPPORTUNITIES_FOR_USER_LOADING,
        payload: false,
      });
    });
};

export const createApplicationsForUserAction = (data, _onCloseAction) => async (dispatch) => {
  dispatch({
    type: OPPORTUNITIES_ACTIONS.CREATE_OPPORTUNITY_APPLICATIONS_FOR_USER_LOADING,
    payload: true,
  });
  createApplicationsForUser(data)
    .then((res) => {
      dispatch({
        type: OPPORTUNITIES_ACTIONS.CREATE_OPPORTUNITY_APPLICATIONS_FOR_USER_LOADING,
        payload: false,
      });
      dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            isSuccess: res.data.success,
            buttonLabel: "Ok",
            okAction: res.data.success ? _onCloseAction : undefined,
            onCloseAction: res.data.success ? _onCloseAction : undefined,
            message: res.data
              ? "Candidatura(s) a emprego/estágio criada(s) com sucesso!"
              : "Ocorreu um erro ao adicionar o candidato a ofertas de emprego/estágio.",
          },
        },
      });
    })
    .catch((error) => {
      dispatch({
        type: OPPORTUNITIES_ACTIONS.CREATE_OPPORTUNITY_APPLICATIONS_FOR_USER_LOADING,
        payload: false,
      });
      dispatch({
        type: DIALOG.SHOW,
        payload: {
          showGenericDialog: true,
          genericDialogData: {
            type: DIALOG.ONE_BUTTON_TYPE,
            isSuccess: false,
            message: "Ocorreu um erro a efetuar o seu pedido, por favor tente mais tarde.",
          },
        },
      });
      console.log("error", error);
    });
};

export default getFilteredOpportunitiesAction;
