import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";
import { useIntl } from "react-intl";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
// @material-ui/icons components
import AddIcon from "@material-ui/icons/Add";
//import custom components

import ComplementaryDataItem from "./ComplementaryDataItem";
import ComplementaryDataCard from "../Components/ComplementaryDataCard";
import ComplementaryDataItemReadOnly from "../Sections/ComplementaryDataItemReadOnly";
import GenericButton from "components/Buttons/GenericButton";
import { COMPLEMENTARY_DATA_TYPE, SUBPROGRAM_STATES } from "utils/consts";
import { labels } from "resources/resources";

import componentStylesGeneric from "assets/theme/views/admin/generic.js";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function ComplementaryDataSection(props) {
  const classes = useStylesGeneric();
  const intl = useIntl();

  const { control } = useFormContext();
  const [complementaryDataTypeState, setComplementaryDataTypeState] = useState({ typeId: 0, keyValue: null });
  const [complementaryDataItemUuid, setComplementaryDataItemUuid] = useState(uuidv4());

  // #region Axuliary functions
  const getQuestionTitle = (questionType) => {
    switch (questionType) {
      case COMPLEMENTARY_DATA_TYPE.DEFAULT_QUESTIONS:
        return "Questão Pré-definida";
      case COMPLEMENTARY_DATA_TYPE.DATASET:
        return "Escolha múltipla";
      case COMPLEMENTARY_DATA_TYPE.PLAIN_TEXT:
        return "Texto livre";
      default:
        return "";
    }
  };
  // #endregion

  // #region ComplementaryData List Events
  const saveComplementaryDataListEvent = (questionType, questionInfo) => {
    let existingComplementaryDataItem = props.complementaryDataList.filter((r) => r.itemKey === questionInfo.keyValue);

    if (existingComplementaryDataItem.length > 0) {
      updateComplementaryDataListEvent(questionInfo, existingComplementaryDataItem);
    } else {
      addComplementaryDataListEvent(questionType, questionInfo);
    }
  };

  const addComplementaryDataListEvent = (questionType, questionInfo) => {
    props.setComplementaryDataList((prevState) => {
      let newElement = {
        itemKey: questionInfo.keyValue,
        itemType: questionType,
        itemTitle: getQuestionTitle(questionType),
        itemText: questionInfo?.text,
        itemIsRequired: questionInfo?.isRequired,
        itemPredefinedQuestion: questionInfo?.predefinedQuestion,
        itemDatasetEnumId: questionInfo?.answer?.code,
        itemDatasetKey: questionInfo?.answer?.keyValue,
        conditionsList: questionInfo?.conditionsList,
      };

      return [...prevState, newElement];
    });
  };

  const updateComplementaryDataListEvent = (questionInfo, existingComplementaryDataItem) => {
    props.setComplementaryDataList((prevState) => {
      existingComplementaryDataItem[0].itemText = questionInfo?.text;
      existingComplementaryDataItem[0].itemIsRequired = questionInfo?.isRequired;
      existingComplementaryDataItem[0].itemDatasetKey = questionInfo?.answer?.keyValue;
      existingComplementaryDataItem[0].conditionsList = questionInfo?.conditionsList;

      let indexOfComplementaryDataItem = prevState.findIndex(
        (cd) => cd.itemKey === existingComplementaryDataItem[0].itemKey
      );

      prevState[indexOfComplementaryDataItem] = existingComplementaryDataItem[0];

      prevState?.map((cd) => {
        let newFilteredCondlitionList = cd.conditionsList?.filter(
          (condition) => condition.complementaryDataDependencyKey !== existingComplementaryDataItem[0].itemKey
        );
        cd.conditionsList = newFilteredCondlitionList;
        return { ...cd };
      });

      return [...prevState];
    });
  };

  const removeComplementaryDataListEvent = (questionKey) => {
    props.setComplementaryDataList((prevState) => {
      let elements = prevState.filter((question) => question.itemKey !== questionKey);
      return elements;
    });

    resetComplementaryDataItemEvent();
  };

  function resetComplementaryDataItemEvent() {
    unregisterComplementaryDataFields();

    setComplementaryDataItemUuid(uuidv4());

    setComplementaryDataTypeState({
      typeId: 0,
      keyValue: null,
    });
  }
  // #endregion

  const updateComplementaryDataDropdownListEvent = (
    compDataKey,
    complementaryDataQuestion,
    complementaryDataDatasetEnumId
  ) => {
    props.setComplementaryDataList((prevState) => {
      let indexOfCompDataItem = prevState.findIndex((cd) => cd.itemKey === compDataKey);
      let item = { ...prevState[indexOfCompDataItem] };

      item.questionText = complementaryDataQuestion !== null ? complementaryDataQuestion : item.questionText;
      item.datasetEnumId =
        complementaryDataDatasetEnumId !== null ? complementaryDataDatasetEnumId : item.datasetEnumId;

      prevState[indexOfCompDataItem] = item;

      return [...prevState];
    });
  };

  function loadComplementaryDataItem(aux_complementaryDataType) {
    if (aux_complementaryDataType.typeId !== 0) {
      return (
        <ComplementaryDataItem
          control={control}
          getValues={props.getValues}
          errors={props.errors}
          unregister={props.unregister}
          setValue={props.setValue}
          complementaryDataType={aux_complementaryDataType.typeId}
          saveComplementaryDataListEvent={saveComplementaryDataListEvent}
          resetComplementaryDataItemEvent={resetComplementaryDataItemEvent}
          key={aux_complementaryDataType.keyValue}
          keyValue={aux_complementaryDataType.keyValue}
          questionInfo={props.complementaryDataList.filter((r) => r.itemKey === aux_complementaryDataType.keyValue)}
          unregisterComplementaryDataFieldsEvent={unregisterComplementaryDataFields}
          hasComplementaryDataWithDatasets={
            props.complementaryDataList.filter(
              (r) => r.itemType === COMPLEMENTARY_DATA_TYPE.DATASET && r.itemKey !== aux_complementaryDataType.keyValue
            )?.length > 0
          }
          complementaryDataInfoForConditions={props.complementaryDataList.filter(
            (r) => r.itemType === COMPLEMENTARY_DATA_TYPE.DATASET
          )}
          updateComplementaryDataDropdownListEvent={updateComplementaryDataDropdownListEvent}
          trigger={props.trigger}
          clearErrors={props.clearErrors}
        />
      );
    }

    return <></>;
  }

  const addComplementaryDataItemComponent = (type, keyValue) => {
    setComplementaryDataTypeState({
      typeId: type,
      keyValue: keyValue,
    });
  };

  const unregisterComplementaryDataFields = () => {
    props.unregister(`${props.keyValue}_question`);
    props.unregister(`${props.keyValue}_isRequired`);
    props.unregister(`${props.keyValue}_answer`);
    props.unregister(`${props.keyValue}_predefinedQuestion`);
  };

  const handleEditSection = () => {
    props.handleEditSection();
  };

  return (
    <>
      <Card classes={{ root: classes.cardRoot }}>
        <CardHeader
          classes={{ title: classes.cardHeader, action: classes.cardHeaderAction }}
          title="Dados Complementares"
          action={
            props.loadData &&
            props.subprogramDetailsData?.stateKey !== SUBPROGRAM_STATES.PUBLICADO &&
            props.subprogramDetailsData?.stateKey !== SUBPROGRAM_STATES.CONCLUIDO && (
              <GenericButton
                typeSubmit={false}
                color="tertiary"
                size="small"
                onClick={handleEditSection}
                id="complementary-data-section-edit-button"
              >
                {intl.formatMessage(labels.Label_Generic_Edit)}
              </GenericButton>
            )
          }
        />
        <CardContent>
          {props.isEditable ? (
            <Grid container>
              <Grid item xs={12} md={3}>
                <Grid container>
                  <Grid item xs={12} md={12}>
                    <FormGroup>
                      {/* <FormLabel>Tipo de Dados</FormLabel>
                    <Button //in the future change to GenericButton
                      classes={{
                        root: classes.cdTypeAddButton,
                        label: classes.cdTypeAddButtonLabel,
                      }}
                      size="medium"
                      onClick={() => {
                        addComplementaryDataItemComponent(
                          COMPLEMENTARY_DATA_TYPE.DEFAULT_QUESTIONS,
                          complementaryDataItemUuid
                        );
                      }}
                    >
                      <Box display="flex">
                        <Box>Questões pré-definidas</Box>
                        <Box marginLeft="50px">
                          <AddIcon />
                        </Box>
                      </Box>
                    </Button>
                    <br /> */}
                      {/* <Button //in the future change to GenericButton
                      classes={{
                        root: classes.cdTypeAddButton,
                        label: classes.cdTypeAddButtonLabel,
                      }}
                      size="medium"
                      onClick={() => {
                        addComplementaryDataItemComponent(COMPLEMENTARY_DATA_TYPE.DATASET, complementaryDataItemUuid);
                      }}
                    >
                      <Box display="flex">
                        <Box>Escolha Múltipla</Box>
                        <Box marginLeft="50px">
                          <AddIcon />
                        </Box>
                      </Box>
                    </Button>
                    <br /> */}
                      <GenericButton
                        typeSubmit={false}
                        color="tertiary"
                        size="medium"
                        onClick={() => {
                          addComplementaryDataItemComponent(
                            COMPLEMENTARY_DATA_TYPE.PLAIN_TEXT,
                            complementaryDataItemUuid
                          );
                        }}
                      >
                        <Box display="flex">
                          <Box>Texto Livre</Box>
                          <Box marginLeft="95px">
                            <AddIcon />
                          </Box>
                        </Box>
                      </GenericButton>
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormGroup>
                      <FormLabel>Dados Adicionados</FormLabel>
                      {props.complementaryDataList?.map((question, index) => {
                        return (
                          <ComplementaryDataCard
                            key={index}
                            index={index}
                            keyValue={question.itemKey}
                            complementaryDataType={question.itemType}
                            questionInfo={question}
                            addComplementaryDataItemComponentEvent={addComplementaryDataItemComponent}
                            removeComplementaryDataListEvent={removeComplementaryDataListEvent}
                          />
                        );
                      })}
                    </FormGroup>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={9}>
                <FormGroup>
                  <FormLabel>Dados seleccionados / a apresentar</FormLabel>
                  {loadComplementaryDataItem(complementaryDataTypeState)}
                </FormGroup>
              </Grid>
            </Grid>
          ) : (
            <Grid container>
              <Grid item xs={12} md={12}>
                {props.subprogramDetailsData?.complementaryData?.map((complemData, key) => {
                  return <ComplementaryDataItemReadOnly key={key} index={key} complemData={complemData} />;
                })}
              </Grid>
            </Grid>
          )}
        </CardContent>
      </Card>
    </>
  );
}

export default ComplementaryDataSection;
