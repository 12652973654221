import React from "react";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";

// @material-ui/core components
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";

// @material-ui/icons components
import MenuIcon from "@material-ui/icons/Menu";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";

// core components
import componentStyles from "assets/theme/components/dropdowns/navbar-menu-dropdown.js";

import { checkOperationPermission } from "utils/permission";
import { titles } from "resources/resources";

//Redux
import { connect } from "react-redux";

import { OPERATIONS } from "utils/consts";

const useStyles = makeStyles(componentStyles);

function NavbarMenuDropdown({ ...props }) {
  const theme = useTheme();
  const classes = useStyles();
  const intl = useIntl();
  const history = useHistory();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);

  const [openApplication, setOpenApplication] = React.useState(false);
  const [openProfKits, setOpenProfKits] = React.useState(false);
  const [openSubprogs, setOpenSubprogs] = React.useState(false);
  const [openUsers, setOpenUsers] = React.useState(false);
  const [openInformationDissemination, setOpenInformationDissemination] = React.useState(false);
  const [openOpportunity, setOpenOpportunity] = React.useState(false);

  const handleNavbarMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setOpenApplication(false);
    setOpenProfKits(false);
    setOpenSubprogs(false);
    setOpenUsers(false);
    setOpenInformationDissemination(false);
    setOpenOpportunity(false);
  };

  const handleClickApplication = () => {
    setOpenApplication(!openApplication);
  };

  const handleClickProfKits = () => {
    setOpenProfKits(!openProfKits);
  };

  const handleClickSubprogs = () => {
    setOpenSubprogs(!openSubprogs);
  };

  const handleClickUsers = () => {
    setOpenUsers(!openUsers);
  };

  const handleClickInformationDissemination = () => {
    setOpenInformationDissemination(!openInformationDissemination);
  };

  const handleClickOpportunity = () => {
    setOpenOpportunity(!openOpportunity);
  };

  const menuId = "dropdowns-navbar-menu-dropdown-id";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <Typography variant="h6" component="h6" classes={{ root: classes.menuTitle }}>
        {intl.formatMessage(titles.Title_AdminNavBar_NavBarListMenu)}
      </Typography>

      <List component="nav" aria-labelledby="navbar-menu-list" className={classes.menuRoot}>

        {/* Candidaturas */}
        {checkOperationPermission(props.authState.operationsList, OPERATIONS.APPLICATIONS_MENU) && (
          <>
            <ListItem button onClick={handleClickApplication} className={classes.listItemRoot}>
              <ListItemText
                classes={{ primary: classes.listItemTextPrimary }}
                primary={intl.formatMessage(titles.Title_AdminNavBar_GestaoCandidaturasMenu)}
              />
              {openApplication ? (
                <ExpandLessIcon className={classes.expandIcon} />
              ) : (
                <ExpandMoreIcon className={classes.expandIcon} />
              )}
            </ListItem>
            <Collapse in={openApplication} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {checkOperationPermission(props.authState.operationsList, OPERATIONS.APPLICATIONS_LIST_ITEM) && (
                  <ListItem
                    button
                    onClick={() => {
                      handleMenuClose();
                      history.push("/admin/applications-management");
                    }}
                    className={classes.nestedItem}
                  >
                    <FiberManualRecordIcon className={classes.dotIcon} />
                    <ListItemText
                      classes={{ primary: classes.listItemTextPrimary }}
                      primary={intl.formatMessage(titles.Title_AdminNavBar_ListaDeCandidaturasMenuItem)}
                    />
                  </ListItem>
                )}
              </List>
            </Collapse>
          </>
        )}

        {/* Agendamentos */}
        {checkOperationPermission(props.authState.operationsList, OPERATIONS.SCHEDULES_ITEM) && (
          <ListItem
            button
            onClick={() => {
              handleMenuClose();
              history.push("/admin/schedules-management");
            }}
            className={classes.listItemRoot}
          >
            <ListItemText
              classes={{ primary: classes.listItemTextPrimary }}
              primary={intl.formatMessage(titles.Title_AdminNavBar_AgendamentosMenuItem)}
            />
          </ListItem>
        )}

        {/* Kits Profissionais */}
        {checkOperationPermission(props.authState.operationsList, OPERATIONS.PROFESSIONAL_KITS_MENU) && (
          <>
            <ListItem button onClick={handleClickProfKits} className={classes.listItemRoot}>
              <ListItemText
                classes={{ primary: classes.listItemTextPrimary }}
                primary={intl.formatMessage(titles.Title_AdminNavBar_ProfessionalKitsMenu)}
              />
              {openProfKits ? (
                <ExpandLessIcon className={classes.expandIcon} />
              ) : (
                <ExpandMoreIcon className={classes.expandIcon} />
              )}
            </ListItem>
            <Collapse in={openProfKits} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {checkOperationPermission(props.authState.operationsList, OPERATIONS.PROFESSIONAL_KITS_LIST_ITEM) && (
                  <ListItem
                    button
                    onClick={() => {
                      handleMenuClose();
                      history.push("/admin/kit-management");
                    }}
                    className={classes.nestedItem}
                  >
                    <FiberManualRecordIcon className={classes.dotIcon} />
                    <ListItemText
                      classes={{ primary: classes.listItemTextPrimary }}
                      primary={intl.formatMessage(titles.Title_AdminNavBar_ProfessionalKitsMenu_KitsListMenuItem)}
                    />
                  </ListItem>
                )}

                {checkOperationPermission(props.authState.operationsList, OPERATIONS.PROFESSIONAL_KITS_CHARACTERIZE_KIT_ITEM) && (
                  <ListItem
                    button
                    onClick={() => {
                      handleMenuClose();
                      history.push("/admin/characterize-kit");
                    }}
                    className={classes.nestedItem}
                  >
                    <FiberManualRecordIcon className={classes.dotIcon} />
                    <ListItemText
                      classes={{ primary: classes.listItemTextPrimary }}
                      primary={intl.formatMessage(
                        titles.Title_AdminNavBar_ProfessionalKitsMenu_CharacterizeKitMenuItem
                      )}
                    />
                  </ListItem>
                )}

                {checkOperationPermission(props.authState.operationsList, OPERATIONS.PROFESSIONAL_KITS_REGISTER_LOSS_KIT_ITEM) && (
                  <ListItem
                    button
                    onClick={() => {
                      handleMenuClose();
                      props.handleRegisterLoss();
                    }}
                    className={classes.nestedItem}
                  >
                    <FiberManualRecordIcon className={classes.dotIcon} />
                    <ListItemText
                      classes={{ primary: classes.listItemTextPrimary }}
                      primary={intl.formatMessage(titles.Title_AdminNavBar_ProfessionalKitsMenu_RegisterLossMenuItem)}
                    />
                  </ListItem>
                )}

                {checkOperationPermission(props.authState.operationsList, OPERATIONS.PROFESSIONAL_KITS_REGISTER_IN_WAREHOUSE_ITEM) && (
                  <ListItem
                    button
                    onClick={() => {
                      handleMenuClose();
                      props.handleRegisterWarehouse();
                    }}
                    className={classes.nestedItem}
                  >
                    <FiberManualRecordIcon className={classes.dotIcon} />
                    <ListItemText
                      classes={{ primary: classes.listItemTextPrimary }}
                      primary={intl.formatMessage(
                        titles.Title_AdminNavBar_ProfessionalKitsMenu_RegisterInWarehouseMenuItem
                      )}
                    />
                  </ListItem>
                )}

                {checkOperationPermission(props.authState.operationsList, OPERATIONS.PROFESSIONAL_KITS_STOCKS_ITEM) && (
                  <ListItem
                    button
                    onClick={() => {
                      handleMenuClose();
                      history.push("/admin/stocks-management");
                    }}
                    className={classes.nestedItem}
                  >
                    <FiberManualRecordIcon className={classes.dotIcon} />
                    <ListItemText
                      classes={{ primary: classes.listItemTextPrimary }}
                      primary={intl.formatMessage(titles.Title_AdminNavBar_ProfessionalKitsMenu_StocksMenuItem)}
                    />
                  </ListItem>
                )}

                {checkOperationPermission(props.authState.operationsList, OPERATIONS.PROFESSIONAL_KITS_DELIVERY_ITEM) && (
                  <ListItem
                    button
                    onClick={() => {
                      handleMenuClose();
                      props.handleDeliverKit();
                    }}
                    className={classes.nestedItem}
                  >
                    <FiberManualRecordIcon className={classes.dotIcon} />
                    <ListItemText
                      classes={{ primary: classes.listItemTextPrimary }}
                      primary={intl.formatMessage(titles.Title_AdminNavBar_ProfessionalKitsMenu_KitDeliveryMenuItem)}
                    />
                  </ListItem>
                )}
              </List>
            </Collapse>
          </>
        )}

        {/* Subprogramas */}
        {checkOperationPermission(props.authState.operationsList, OPERATIONS.SUBPROGRAMS_MENU) && (
          <>
            <ListItem button onClick={handleClickSubprogs} className={classes.listItemRoot}>
              <ListItemText
                classes={{ primary: classes.listItemTextPrimary }}
                primary={intl.formatMessage(titles.Title_AdminNavBar_SubprogramasMenu)}
              />
              {openSubprogs ? (
                <ExpandLessIcon className={classes.expandIcon} />
              ) : (
                <ExpandMoreIcon className={classes.expandIcon} />
              )}
            </ListItem>
            <Collapse in={openSubprogs} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {checkOperationPermission(props.authState.operationsList, OPERATIONS.SUBPROGRAMS_CHARACTERIZE_SUBPROGRAM_ITEM) && (
                  <ListItem
                    button
                    onClick={() => {
                      handleMenuClose();
                      history.push("/admin/subprograms-create");
                    }}
                    className={classes.nestedItem}
                  >
                    <FiberManualRecordIcon className={classes.dotIcon} />
                    <ListItemText
                      classes={{ primary: classes.listItemTextPrimary }}
                      primary={intl.formatMessage(titles.Title_AdminNavBar_CharacterizeSubprogramMenuItem)}
                    />
                  </ListItem>
                )}

                {checkOperationPermission(props.authState.operationsList, OPERATIONS.SUBPROGRAMS_LIST_ITEM) && (
                  <ListItem
                    button
                    onClick={() => {
                      handleMenuClose();
                      history.push("/admin/subprograms-management");
                    }}
                    className={classes.nestedItem}
                  >
                    <FiberManualRecordIcon className={classes.dotIcon} />
                    <ListItemText
                      classes={{ primary: classes.listItemTextPrimary }}
                      primary={intl.formatMessage(titles.Title_AdminNavBar_ListaSubprogramasMenuItem)}
                    />
                  </ListItem>
                )}
              </List>
            </Collapse>
          </>
        )}

        {/* Emprego e Estágio */}
        {checkOperationPermission(props.authState.operationsList, OPERATIONS.OPPORTUNITIES_MENU) && (
          <>
            <ListItem button onClick={handleClickOpportunity} className={classes.listItemRoot}>
              <ListItemText
                classes={{ primary: classes.listItemTextPrimary }}
                primary={intl.formatMessage(titles.Title_AdminNavBar_Opportunity)}
              />
              {openOpportunity ? (
                <ExpandLessIcon className={classes.expandIcon} />
              ) : (
                <ExpandMoreIcon className={classes.expandIcon} />
              )}
            </ListItem>
            <Collapse in={openOpportunity} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {checkOperationPermission(props.authState.operationsList, OPERATIONS.OPPORTUNITIES_CREATE_ITEM) && (
                  <ListItem
                    button
                    onClick={() => {
                      handleMenuClose();
                      history.push("/admin/opportunity-create");
                    }}
                    className={classes.nestedItem}
                  >
                    <FiberManualRecordIcon className={classes.dotIcon} />
                    <ListItemText
                      classes={{ primary: classes.listItemTextPrimary }}
                      primary={intl.formatMessage(titles.Title_AdminNavBar_OpportunityCreateMenuItem)}
                    />
                  </ListItem>
                )}

                {checkOperationPermission(props.authState.operationsList, OPERATIONS.OPPORTUNITIES_CANDIDATES_LIST_ITEM) && (
                  <ListItem
                    button
                    onClick={() => {
                      handleMenuClose();
                      history.push("/admin/opportunity-management");
                    }}
                    className={classes.nestedItem}
                  >
                    <FiberManualRecordIcon className={classes.dotIcon} />
                    <ListItemText
                      classes={{ primary: classes.listItemTextPrimary }}
                      primary={intl.formatMessage(titles.Title_AdminNavBar_ListOpportunitiesMenuItem)}
                    />
                  </ListItem>
                )}

                {checkOperationPermission(props.authState.operationsList, OPERATIONS.OPPORTUNITIES_APPLICATIONS_LIST_ITEM) && (
                  <ListItem
                    button
                    onClick={() => {
                      handleMenuClose();
                      history.push("/admin/opportunity-applications-management");
                    }}
                    className={classes.nestedItem}
                  >
                    <FiberManualRecordIcon className={classes.dotIcon} />
                    <ListItemText
                      classes={{ primary: classes.listItemTextPrimary }}
                      primary={intl.formatMessage(titles.Title_AdminNavBar_ListOpportunitiesApplicationsMenuItem)}
                    />
                  </ListItem>
                )}

                {checkOperationPermission(props.authState.operationsList, OPERATIONS.OPPORTUNITIES_APPLICATIONS_FOLLOWUP_LIST_ITEM) && (
                  <ListItem
                    button
                    onClick={() => {
                      handleMenuClose();
                      history.push("/admin/opportunity-applications-followup-management");
                    }}
                    className={classes.nestedItem}
                  >
                    <FiberManualRecordIcon className={classes.dotIcon} />
                    <ListItemText
                      classes={{ primary: classes.listItemTextPrimary }}
                      primary={intl.formatMessage(titles.Title_AdminNavBar_ListOpportunitiesApplicationsFollowUpMenuItem)}
                    />
                  </ListItem>
                )}
              </List>
            </Collapse>
          </>
        )}

        {/* Bancos */}
        {checkOperationPermission(props.authState.operationsList, OPERATIONS.BANKS_MENU) && (
          <ListItem
            button
            onClick={() => {
              handleMenuClose();
              history.push("/admin/loans-management");
            }}
            className={classes.listItemRoot}
          >
            <ListItemText
              classes={{ primary: classes.listItemTextPrimary }}
              primary={intl.formatMessage(titles.Title_AdminNavBar_Bancos)}
            />
          </ListItem>
        )}

        {/* Gestão de Utilizadores */}
        {checkOperationPermission(props.authState.operationsList, OPERATIONS.USER_MANAGEMENT_MENU) && (
          <>
            <ListItem button onClick={handleClickUsers} className={classes.listItemRoot}>
              <ListItemText
                classes={{ primary: classes.listItemTextPrimary }}
                primary={intl.formatMessage(titles.Title_AdminNavBar_ManageUsersMenu)}
              />
              {openUsers ? (
                <ExpandLessIcon className={classes.expandIcon} />
              ) : (
                <ExpandMoreIcon className={classes.expandIcon} />
              )}
            </ListItem>
            <Collapse in={openUsers} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {checkOperationPermission(props.authState.operationsList, OPERATIONS.USER_MANAGEMENT_BACKOFFICE_ITEM) && (
                  <ListItem
                    button
                    onClick={() => {
                      handleMenuClose();
                      history.push("/admin/backofficeusers-management");
                    }}
                    className={classes.nestedItem}
                  >
                    <FiberManualRecordIcon className={classes.dotIcon} />
                    <ListItemText
                      classes={{ primary: classes.listItemTextPrimary }}
                      primary={intl.formatMessage(titles.Title_AdminNavBar_ManageUsersMenuItemBackOffice)}
                    />
                  </ListItem>
                )}

                {checkOperationPermission(props.authState.operationsList, OPERATIONS.USER_MANAGEMENT_FRONTOFFICE_ITEM) && (
                  <ListItem
                    button
                    onClick={() => {
                      handleMenuClose();
                      history.push("/admin/frontofficeusers-management");
                    }}
                    className={classes.nestedItem}
                  >
                    <FiberManualRecordIcon className={classes.dotIcon} />
                    <ListItemText
                      classes={{ primary: classes.listItemTextPrimary }}
                      primary={intl.formatMessage(titles.Title_AdminNavBar_ManageUsersMenuItemFrontOffice)}
                    />
                  </ListItem>
                )}
              </List>
            </Collapse>
          </>
        )}
        
        {/* Informação */}
        {checkOperationPermission(props.authState.operationsList, OPERATIONS.INFORMATION_MENU) && (
          <>
            <ListItem button onClick={handleClickInformationDissemination} className={classes.listItemRoot}>
              <ListItemText
                classes={{ primary: classes.listItemTextPrimary }}
                primary={intl.formatMessage(titles.Title_AdminNavBar_InformationDissemination)}
              />
              {openInformationDissemination ? (
                <ExpandLessIcon className={classes.expandIcon} />
              ) : (
                <ExpandMoreIcon className={classes.expandIcon} />
              )}
            </ListItem>
            <Collapse in={openInformationDissemination} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {checkOperationPermission(props.authState.operationsList, OPERATIONS.INFORMATION_CREATE_ITEM) && (
                  <ListItem
                    button
                    onClick={() => {
                      handleMenuClose();
                      history.push("/admin/informationdissemination-create");
                    }}
                    className={classes.nestedItem}
                  >
                    <FiberManualRecordIcon className={classes.dotIcon} />
                    <ListItemText
                      classes={{ primary: classes.listItemTextPrimary }}
                      primary={intl.formatMessage(titles.Title_AdminNavBar_CreateInformationDisseminationMenuItem)}
                    />
                  </ListItem>
                )}

                {checkOperationPermission(props.authState.operationsList, OPERATIONS.INFORMATION_LIST_ITEM) && (
                  <ListItem
                    button
                    onClick={() => {
                      handleMenuClose();
                      history.push("/admin/informationdissemination-management");
                    }}
                    className={classes.nestedItem}
                  >
                    <FiberManualRecordIcon className={classes.dotIcon} />
                    <ListItemText
                      classes={{ primary: classes.listItemTextPrimary }}
                      primary={intl.formatMessage(titles.Title_AdminNavBar_ListaInformationDisseminationMenuItem)}
                    />
                  </ListItem>
                )}
              </List>
            </Collapse>
          </>
        )}
      </List>
    </Menu>
  );

  return (
    <>
      <Box display="flex" justifyContent="center" alignItems="center" marginLeft="0.5rem">
        <IconButton onClick={handleNavbarMenuOpen} className={classes.menuButton}>
          <Box
            component={MenuIcon}
            color={theme.palette.dark.main}
            width="1.5rem!important"
            height="1.5rem!important"
          />
          <Box
            fontStyle="normal"
            fontSize=".875rem"
            fontWeight="600"
            alignContent="center"
            paddingLeft="0.1rem"
            paddingRight="0.1rem"
            paddingTop="0.5rem"
            paddingBottom="0.5rem"
            margin="0"
          >
            {intl.formatMessage(titles.Title_AdminNavBar_NavBarMenu)}
          </Box>
        </IconButton>
        {renderMenu}
      </Box>
    </>
  );
}

const mapStateToProps = (state) => ({
  ...state,
});
export default connect(mapStateToProps, {})(NavbarMenuDropdown);
