import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useIntl } from "react-intl";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
//import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
//import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import { ArrayUtils } from "utils/array_utils";
//import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import TableStateChip from "components/Cards/Tables/TableStateChip";
import Tooltip from "@material-ui/core/Tooltip";
import Pagination from "@material-ui/lab/Pagination";
// @material-ui/icons components
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import HelpIcon from "@material-ui/icons/Help";
// core componentssrc
import TableBackdrop from "components/Backdrops/TableBackdrop/TableBackdrop";
import componentStylesGeneric from "assets/theme/views/admin/generic";
import { UNITS, TABLE_STATE_CHIP_COLOR, USER_TYPE_KEY } from "utils/consts";
import { labels } from "resources/resources";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function ResultSection(props) {
  const theme = useTheme();
  const classes = useStylesGeneric();
  const [loanList, setLoanList] = useState([]);
  const intl = useIntl();

  const tableHead = [
    intl.formatMessage(labels.Label_Admin_GeneralDataSection_NameField),
    intl.formatMessage(labels.Label_Admin_ApplicationsManagementFilter_NumBIField),
    intl.formatMessage(labels.Label_Admin_Loans_ApplicationNumber),
    intl.formatMessage(labels.Label_Admin_Loans_GovernmentProgram),
    intl.formatMessage(labels.Label_Admin_Loans_AmountApproved),
    intl.formatMessage(labels.Label_Admin_Loans_LastUpdateDate_short),
    intl.formatMessage(labels.Label_Admin_Loans_AmountToBePaid),
    intl.formatMessage(labels.Label_Admin_Loans_IsMissingPayments),
    intl.formatMessage(labels.Label_Admin_Loans_State_short),
    intl.formatMessage(labels.Label_Admin_Loans_BankName),
    intl.formatMessage(labels.Label_Admin_Loans_LoanType_short),
  ];

  const LOAN_STATES = {
    EM_APROVACAO: 1,
    REEMBOLSADO: 2,
    REJEITADO: 3,
    APROVADO: 4,
  };

  useEffect(() => {
    if (!ArrayUtils.NullOrEmpty(props.loansState.result)) {
      setLoanList(props.loansState.result.data);
    } else {
      setLoanList([]);
    }
  }, [props.loansState.result]);

  const MapStateToTableChipColors = (state) => {
    const greenChipArray = [LOAN_STATES.REEMBOLSADO];
    const lightOrangeChipArray = [LOAN_STATES.APROVADO];
    const orangeChipArray = [LOAN_STATES.REJEITADO];
    const whiteChipArray = [LOAN_STATES.EM_APROVACAO];

    if (greenChipArray.includes(state)) {
      return TABLE_STATE_CHIP_COLOR.GREEN;
    }
    if (orangeChipArray.includes(state)) {
      return TABLE_STATE_CHIP_COLOR.DARK_ORANGE;
    }
    if (lightOrangeChipArray.includes(state)) {
      return TABLE_STATE_CHIP_COLOR.LIGHT_ORANGE;
    }
    if (whiteChipArray.includes(state)) return TABLE_STATE_CHIP_COLOR.BLUE;
  };

  return (
    <Card classes={{ root: classes.cardRoot }}>
      <CardHeader
        classes={{
          title: classes.cardHeader,
          action: classes.cardHeaderAction,
        }}
        title={
          <div>
            <div className={classes.sideBySideDiv}>Lista de Empréstimos</div>
            <div className={classes.sideBySideDiv + " " + classes.cardElementsCount}>
              {"("}
              {props.loansState?.result?.total_records}
              {")"}
            </div>
          </div>
        }
      />
      <TableContainer>
        <Box component={Table} alignItems="center" marginBottom="0!important">
          <TableHead>
            <TableRow>
              {tableHead.map((prop, key) =>
                key === 1 ? (
                  <TableCell
                    key={key}
                    classes={{
                      root: classes.tableCellRoot + " " + classes.tableCellRootHead,
                    }}
                  >
                    {prop}
                    <Tooltip title="Nº BI se Cidadão, NIF se Empresa">
                      <HelpIcon className={classes.tooltipInfoIcon} />
                    </Tooltip>
                  </TableCell>
                ) : (
                  <TableCell
                    key={key}
                    classes={{
                      root: classes.tableCellRoot + " " + classes.tableCellRootHead,
                    }}
                  >
                    {prop}
                  </TableCell>
                )
              )}
              <TableCell
                key="detailsCol"
                classes={{
                  root:
                    classes.tableCellRoot + " " + classes.tableCellRootHead + " " + classes.tableCellHeadStickyRight,
                }}
              >
                {intl.formatMessage(labels.Label_Generic_Details)}
              </TableCell>
            </TableRow>
          </TableHead>

          {loanList?.length > 0 && (
            <TableBody>
              {loanList?.map((prop, key) => (
                <TableRow key={key}>
                  <TableCell
                    classes={{ root: classes.tableRowEllipsis + " " + classes.tableCellRoot }}
                    title={(prop.userTypeKey === USER_TYPE_KEY.INDIVIDUAL ? "Cidadão: " : "Empresa: ") + prop.nameOfUser}
                  >
                    {prop.nameOfUser}
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>{prop.identityCardOrTaxIdNum}</TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>{prop.applicationNumber}</TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>{prop.governmentProgramName}</TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                    {intl.formatNumber(prop.aprovedValue) + " " + UNITS.MONETARY}
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>{prop.lastInstallment}</TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                    {prop.amountToBePaid != null ? intl.formatNumber(prop.amountToBePaid) + " " + UNITS.MONETARY : ""}
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>{prop.isMissingPayments}</TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>
                    <TableStateChip
                      state={MapStateToTableChipColors(prop.loanStateKeyValue)}
                      message={prop.loanState}
                    />
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>{prop.bankNameInitials}</TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot }}>{prop.loanTypeDescription}</TableCell>
                  <TableCell classes={{ root: classes.tableCellRoot + " " + classes.tableCellBodyStickyRight }}>
                    <Tooltip title={intl.formatMessage(labels.Label_Generic_Details)}>
                      <IconButton aria-label="details" component={Link} to={`/admin/loan-detail/${prop.id}`}>
                        <Box
                          component={ArrowForwardIosIcon}
                          width="12px!important"
                          height="12px!important"
                          color={theme.palette.primary.main}
                        />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Box>

        {(loanList == null || loanList.length === 0) && !props.loansState.search_loading && (
          <Box className={classes.noResultsInfoInTable}>Não existem resultados disponíveis.</Box>
        )}
      </TableContainer>

      <TableBackdrop open={props.loansState.search_loading} />

      <Pagination
        className={classes.alignPagination}
        count={props.loansState?.result?.total_pages}
        page={props.page}
        onChange={props.handleChangePage}
        color="primary"
      ></Pagination>
    </Card>
  );
}
const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ResultSection);
