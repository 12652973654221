import { React, useEffect } from "react";
import clsx from "clsx";
import { Controller } from "react-hook-form";
// @material-ui/core components
import Box from "@material-ui/core/Box";
import FormHelperText from "@material-ui/core/FormHelperText";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import { COMPLEMENTARY_DATA_TYPE } from "utils/consts";

export default function TextField(props) {
  useEffect(() => {
    props.setValue(props.name, props.answerValue !== undefined ? props.answerValue : "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {props?.show && (
        <Controller
          name={props.name}
          control={props.control}
          defaultValue=""
          rules={{
            required: {
              value: props.isRequired,
              message: "O campo é obrigatório",
            },
          }}
          render={({ field: { onChange, name, value } }) => (
            <OutlinedInput
              name={name}
              value={value}
              onChange={(data) => {
                onChange(data);
                if (props.showHideChildOnChange !== undefined)
                  props.showHideChildOnChange(props.childs, props.name, COMPLEMENTARY_DATA_TYPE.PLAIN_TEXT);
              }}
              fullWidth
              autoComplete="off"
              disabled={props.isDisabled}
              type="text"
              classes={{
                notchedOutline: clsx({
                  [props.classes.borderWarning]: props.errors[name] !== undefined,
                }),
              }}
            />
          )}
        />
      )}
      {props?.show && props.errors[props.name] !== undefined && (
        <FormHelperText component={Box} color={props.theme.palette.warning.main + "!important"}>
          {props.errors[props.name].message}
        </FormHelperText>
      )}
    </>
  );
}
