import { React, useEffect, useState } from "react";
import { connect } from "react-redux";
// @material-ui/core components
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons components
import Pagination from "@material-ui/lab/Pagination";

// core componentssrc
import componentStylesGeneric from "assets/theme/views/admin/generic";
import InformationDisseminationCard from "../Card/InformationDisseminationCard";
import { ArrayUtils } from "utils/array_utils";
import CardsListBackdrop from "components/Backdrops/CardsListBackdrop";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function ResultSection(props) {
  const classes = useStylesGeneric();
  const [informationList, setInformationList] = useState([]);

  useEffect(() => {
    if (!ArrayUtils.NullOrEmpty(props.informationDisseminationState.result)) {
      setInformationList(props.informationDisseminationState.result.data);
    } else {
      setInformationList([]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.informationDisseminationState.result.data]);

  return (
    <>
      {informationList !== undefined && informationList.length > 0 ? (
        <Grid container style={{ position: "relative" }}>
          {informationList?.map((info, key) => (
            <Grid key={key} item xs={12} md={6} lg={4} xl={3}>
              <InformationDisseminationCard
                alternateKey={info.alternateKey}
                informationDisseminationTypeDescription={info.informationDisseminationTypeDescription}
                isPublic={info.isPublic}
                title={info.title}
                description={info.description}
                published={info.published}
                publishedOn={info.publishedOn}
                isHighlighted={info.isHighlighted}
                photo={info.photo}
              />
            </Grid>
          ))}
          <CardsListBackdrop open={props.informationDisseminationState.searchInformation_loading}/>
        </Grid>
      ) : (
        <Box classes={{ root: classes.noResultsInfoInTable }}>
          Não foram encontrados resultados para esta pesquisa.
        </Box>
      )}
      <Pagination
        className={classes.alignPagination}
        count={props.informationDisseminationState?.total_pages}
        page={props.page}
        onChange={props.handleChangePage}
        color="primary"
      ></Pagination>
    </>
  );
}
const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ResultSection);
