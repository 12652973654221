import { SUBPROGRAM } from "utils/actionsConsts";

const subprogramReducer = (
  state = {
    createSubprogram_loading: false,
    updateSubprogram_loading: false,
    searchSubprograms_loading: false,
    refresh_results: false,
    total_records: 0,
    total_pages: 0,
  },
  action
) => {
  switch (action.type) {
    case SUBPROGRAM.CREATE:
      return {
        ...state,
        result: action.payload,
        createSubprogram_loading: false,
      };
    case SUBPROGRAM.GET_FILTERED_SUBPROGRAMS:
      return {
        ...state,
        result: action.payload,
        total_records: action.payload.total_records,
        total_pages: action.payload.total_pages,
      };
    case SUBPROGRAM.GET_FILTERED_SUBPROGRAMS_LOADING:
      return { ...state, searchSubprograms_loading: action.payload };
    case SUBPROGRAM.GET_SUBPROGRAM_DETAILS:
      return { ...state, subprogramDetailsData: action.payload };
    case SUBPROGRAM.GET_SUBPROGRAM_DETAILS_LOADING:
      return { ...state, get_subprogram_details_loading: action.payload };
    case SUBPROGRAM.CREATE_SUBPROGRAM_LOADING:
      return { ...state, createSubprogram_loading: true };
    case SUBPROGRAM.UPDATE_SUBPROGRAM_LOADING:
      return { ...state, updateSubprogram_loading: action.payload };
    case SUBPROGRAM.UPDATE_SUBPROGRAM_STATE_LOADING:
      return { ...state, updateSubprogramState_loading: action.payload };
    case SUBPROGRAM.REFRESH_RESULTS:
      return { ...state, refresh_results: action.payload };
    case SUBPROGRAM.CLEAR_DETAIL:
      return { ...state, subprogramDetailsData: {} };
    default:
      return state;
  }
};

export default subprogramReducer;
