import { React, useState } from "react";
import { connect } from "react-redux";
import { FormProvider, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
// @material-ui/core components
import { Container, Grid, FormGroup } from "@material-ui/core";
//import custom components
import OpportunityData from "../Sections/OpportunityData";
import GenericButton from "components/Buttons/GenericButton";
import UpdateOpportunityStateDialog from "../Dialogs/UpdateOpportunityStateDialog";
import { getDataSetByNameAction } from "redux/actions/datasetsAction";
import {
  updateOpportunityStateAction,
  getOpportunitiesDetailsAction,
  updateOpportunityAction,
} from "redux/actions/opportunitiesAction";
import { updateOpportunityMap } from "mappers/opportunityMap";
import {
  OPPORTUNITY_STATE_KEY,
  UPDATE_OPPORTUNITY_STATE_ACTION_TYPE,
  OPPORTUNITY_MOTIVES_TYPE_KEY,
  DATASET,
} from "utils/consts";
import { labels } from "resources/resources";

function OpportunityDetailsTab(props) {
  const history = useHistory();
  const intl = useIntl();

  const [opportunityQuestionsState, setOpportunityQuestionsState] = useState(
    []
  );
  const [isEditable, setIsEditable] = useState(false);
  const [opportunityStateData, setopportunityStateData] = useState();
  const [dialogs, setDialogs] = useState(false);
  const DIALOG = { SHOW: true, HIDE: false };

  const methods = useForm({
    mode: "onChange",
  });

  const showHideDialog = (op) => {
    setDialogs(op);
  };

  const onSubmit = (data) => {
    data["alternateKey"] = props.opportunityId;
    data["opportunityTypeId"] = props.opportunityDetailsData?.opportunityTypeId;
    data["opportunityTypeKey"] =
      props.opportunityDetailsData?.opportunityTypeKey;

    let updateData = updateOpportunityMap(
      data,
      opportunityQuestionsState,
      props.dataSetState[DATASET.OPPORTUNITY_DOCUMENT_TYPE]
    );

    props.updateOpportunity(updateData, () => {
      props.getOpportunityDetails(props.opportunityDetailsData?.id);
      setIsEditable(false);
    });
  };
  const handleEditSection = () => {
    setIsEditable(true);
  };
  const handleRejectButton = () => {
    setopportunityStateData({
      id: props.opportunityDetailsData?.id,
      actionType: UPDATE_OPPORTUNITY_STATE_ACTION_TYPE.REJECT,
      motivesType: OPPORTUNITY_MOTIVES_TYPE_KEY.REJECT,
    });
    setDialogs(true);
  };
  const handleCloseButton = () => {
    setopportunityStateData({
      id: props.opportunityDetailsData?.id,
      actionType: UPDATE_OPPORTUNITY_STATE_ACTION_TYPE.CLOSE,
      motivesType: OPPORTUNITY_MOTIVES_TYPE_KEY.CLOSE,
    });
    setDialogs(true);
  };
  const handleApproveButton = () => {
    props.updateOpportunityState(
      {
        opportunityId: props.opportunityDetailsData?.id,
        actionType: UPDATE_OPPORTUNITY_STATE_ACTION_TYPE.APPROVE,
      },
      () => {
        props.getOpportunityDetails(props.opportunityDetailsData?.id);
      }
    );
  };

  return (
    <>
      <Container maxWidth={false}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <OpportunityData
              setOpportunityQuestionsState={setOpportunityQuestionsState}
              opportunityQuestionsState={opportunityQuestionsState}
              opportunityDetailsData={props.opportunityDetailsData}
              isEditable={isEditable}
              handleEditSection={handleEditSection}
            />

            <Grid container>
              <Grid item xs={12} lg={6} xl={8}></Grid>
              <Grid item xs={12} lg={3} xl={2}>
                <FormGroup>
                  {props.opportunityDetailsData?.opportunityStateKey ===
                    OPPORTUNITY_STATE_KEY.SUBMETIDA && (
                    <GenericButton
                      typeSubmit={false}
                      color="secondary"
                      onClick={handleRejectButton}
                    >
                      {intl.formatMessage(labels.Label_Reject_Submit)}
                    </GenericButton>
                  )}
                </FormGroup>
              </Grid>
              <Grid item xs={12} lg={3} xl={2}>
                <FormGroup>
                  {!isEditable &&
                    props.opportunityDetailsData?.opportunityStateKey ===
                      OPPORTUNITY_STATE_KEY.APROVADA && (
                      <GenericButton
                        typeSubmit={false}
                        color="primary"
                        onClick={handleCloseButton}
                      >
                        Fechar Oferta
                      </GenericButton>
                    )}
                  {isEditable && (
                    <GenericButton
                      typeSubmit={true}
                      color="primary"
                      loading={
                        props.opportunitiesState?.opportunityUpdateLoading
                      }
                    >
                      {intl.formatMessage(labels.Label_Generic_Save)}
                    </GenericButton>
                  )}
                  {!isEditable &&
                    props.opportunityDetailsData?.opportunityStateKey ===
                      OPPORTUNITY_STATE_KEY.SUBMETIDA && (
                      <GenericButton
                        typeSubmit={true}
                        color="primary"
                        loading={
                          props.opportunitiesState
                            ?.update_opportunity_state_loading
                        }
                        onClick={handleApproveButton}
                      >
                        {intl.formatMessage(labels.Label_Approve_Submit)}
                      </GenericButton>
                    )}
                </FormGroup>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </Container>
      <UpdateOpportunityStateDialog
        name="update_opportunity-state-dialog"
        open={dialogs}
        history={history}
        data={opportunityStateData}
        onClose={() => showHideDialog(DIALOG.HIDE)}
      />
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getDataSetByName: (name, parentKeyValue) =>
    dispatch(getDataSetByNameAction(name, parentKeyValue)),
  updateOpportunityState: (data, onCloseMethod) =>
    dispatch(updateOpportunityStateAction(data, onCloseMethod)),
  getOpportunityDetails: (opportunityId) =>
    dispatch(getOpportunitiesDetailsAction(opportunityId)),
  updateOpportunity: (data, onCloseMethod) =>
    dispatch(updateOpportunityAction(data, onCloseMethod)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OpportunityDetailsTab);
