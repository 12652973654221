const componentStyles = (theme) => ({
    avatarRoot: {
        width: "150px",
        height: "150px",
        fontSize: "4.3rem",
        display: "flex",
        justifyContent: "center",
    },
    userMainDetailsBox: {
        color: theme.palette.white.main,
    },
    userMainDetailsBoxLeft: {
        float: "left",
        width: "186px",
        paddingBottom: "28px",
    },
    userNameDiv: {
        fontSize: "25px",
        fontWeight: "bold",
        alignItems: "center",
    },
    mainDetailsDiv: {
        marginTop: "5px",
        lineHeight: "20px",
        alignItems: "center",
        height: "21px"
    },
    warningIcon: {
        width: "30px",
        height: "30px",
        marginRight: "5px",
        marginBottom: "5px",
        color: theme.palette.error.main + "!important",
    },
    checkIcon: {
        width: "30px",
        height: "30px",
        marginRight: "5px",
        marginBottom: "5px",
        color: theme.palette.success.main + "!important"
    },
    starIcon: {
        width: "30px",
        height: "30px",
        marginRight: "5px",
        marginBottom: "5px",
        color: theme.palette.tableStateChip.lightOrange + "!important"
    },
    ellipsis: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap"
    },
    backgroundImage: {
        height: "198px", 
        width: "100%", 
        objectFit: "cover",
        position: "absolute"
    }
});

export default componentStyles;
