import { APPLICATIONS } from "utils/actionsConsts";

const applicationReducer = (
  state = {
    applicationDetailsHistory: [],
    applicationDetailsHistory_refresh_results: false,
    subprogramsInfo: [],
    loading: false,
    application_details_loading: false,
    application_update_loading: false,
    total_records: 0,
    total_pages: 0,
  },
  action
) => {
  switch (action.type) {
    case APPLICATIONS.GET_FILTERED_APPLICATIONS:
      return {
        ...state,
        result: action.payload,
        total_records: action.payload.total_records,
        total_pages: action.payload.total_pages,
      };
    case APPLICATIONS.GET_FILTERED_APPLICATIONS_LOADING:
      return { ...state, loading: action.payload };
    case APPLICATIONS.GET_APPLICATION_DETAILS_GENERAL_DATA:
      return { ...state, applicationDetailsGeneralData: action.payload };
    case APPLICATIONS.GET_APPLICATION_DETAILS_GENERAL_DATA_LOADING:
      return { ...state, application_details_loading: action.payload };
    case APPLICATIONS.GET_APPLICATION_UPDATE_LOADING:
      return { ...state, application_update_loading: action.payload };
    case APPLICATIONS.UPDATE_APPLICATION_DETAILS_GENERAL_DATA:
      return { ...state, editResult: action.payload };
    case APPLICATIONS.GET_APPLICATION_DETAILS_HISTORY:
      return { ...state, applicationDetailsHistory: action.payload };
    case APPLICATIONS.APPLICATION_DETAILS_HISTORY_REFRESH_RESULTS:
      return { ...state, applicationDetailsHistory_refresh_results: action.payload };
    case APPLICATIONS.CREATE_APPLICATION_DETAILS_HISTORY_ENTRY:
      return { ...state, result: action.payload };
    case APPLICATIONS.GET_USER_APPLICATIONS:
      return { ...state, getUserApplications_result: action.payload };
    case APPLICATIONS.GET_USER_APPLICATIONS_LOADING:
      return { ...state, getUserApplications_loading: action.payload };
    default:
      return state;
  }
};

export default applicationReducer;
