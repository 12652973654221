import { recover_password } from "crud/auth.crud";
import { AUTH } from "utils/actionsConsts";

export const recoverPasswordAction = (email) => async (dispatch) => {
  dispatch({
    type: AUTH.RECOVER_PASSWORD_LOADING,
    payload: true,
  });
  recover_password(email)
    .then((res) => {
      dispatch({
        type: AUTH.RECOVER_PASSWORD,
        payload: {
          sent: res.data !== null ? true : false,
        },
      });
    })
    .catch((error) => {
      dispatch({
        type: AUTH.RECOVER_PASSWORD,
        payload: { sent: false },
      });
      console.log("error", error);
    });
};

export const resetRecoverStateAction = () => async (dispatch) => {
  dispatch({
    type: AUTH.RESET_RECOVER_STATE,
    payload: false,
  });
};
