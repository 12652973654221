import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useIntl } from "react-intl";

import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";

import GenericBackdrop from "components/Backdrops/GenericBackdrop";
import GenericButton from "components/Buttons/GenericButton";
import componentStylesGeneric from "assets/theme/views/admin/generic";
import { getFOUserOverviewAction } from "redux/actions/usersAction";
import { getReportAction } from "redux/actions/reportsAction";
import PageTitleHeader from "components/Headers/PageTitleHeader.js";
import LocationHeader from "components/Headers/LocationHeader.js";
import { titles, labels } from "resources/resources";
import { USER_TYPE_KEY, REPORT_TYPES } from "utils/consts";
import UserSingularGeneralDataTab from "views/admin/UserOverview/UserSingular/Tabs/GeneralDataTab";
import UserSingularTrainingAndProfessionTab from "views/admin/UserOverview/UserSingular/Tabs/TrainingAndProfessionTab";
import UserSingularApplicationsTab from "views/admin/UserOverview/UserSingular/Tabs/ApplicationsTab";
import UserColectiveGeneralDataTab from "views/admin/UserOverview/UserColective/Tabs/GeneralDataTab";
import UserCollectiveApplicationsTab from "views/admin/UserOverview/UserColective/Tabs/ApplicationsTab";
import HistoryTab from "./Components/Tabs/HistoryTab";
import EmploymentAndUnemploymentHistoryTab from "../UserManagement/FrontOffice/IndividualUser/EmploymentAndUnemploymentHistory";
import useScrollDirection from "hooks/useScrollDirection";
import InterventionOtherAreaSection from "../UserManagement/FrontOffice/IndividualUser/Sections/InterventionOtherAreaSection";
import Tabs from "components/Tabs/Tabs";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function OpportunityApplicationFollowUpDetails(props) {
  const classes = useStylesGeneric();
  const intl = useIntl();

  const TABS = {
    GENERAL_DATA: "generalDataTab",
    TRAINING_AND_PROFESSION: "traingAndProfessionTab",
    APPLICATIONS: "applicationsTab",
    EMPLOYMENT_AND_UNEMPLOYMENT_HISTORY: "employmentAndUnemploymentHistoryTab",
    INTERVENTION_FROM_OTHERS_AREAS: "interventionFromOthersAreasTab",
    GENERAL_HISTORY: "generalHistoryTab",
  };

  const [tabStep, setTabStep] = useState(TABS.GENERAL_DATA);
  const [goToSectionTab, setGoToSectionTab] = useState(null);
  const [goToTopOfPage, setGoToTopOfPage] = useState(null);
  const scrollDirection = useScrollDirection();

  useEffect(() => {
    props.getFOUserOverview({ userId: props.match?.params?.userId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeTab = (event, value, scrollToTopOfPage, sectionTab) => {
    if (value !== null) {
      setTabStep(value);
    }

    setGoToSectionTab(sectionTab);

    setGoToTopOfPage(scrollToTopOfPage);
  };

  function loadTabContent() {
    switch (props?.usersState?.getFOUserOverview_result?.userTypeKey) {
      case USER_TYPE_KEY.COLETIVO:
        switch (tabStep) {
          case TABS.GENERAL_DATA:
            return (
              <UserColectiveGeneralDataTab
                userOverview={props?.usersState?.getFOUserOverview_result}
                handleChangeTab={handleChangeTab}
              />
            );
          case TABS.APPLICATIONS:
            return <UserCollectiveApplicationsTab selectedTab={goToSectionTab} goToTopOfPage={goToTopOfPage} />;
          case TABS.GENERAL_HISTORY:
            return (
              <HistoryTab userId={props?.usersState?.getFOUserOverview_result?.id} goToTopOfPage={goToTopOfPage} />
            );
          default:
            return (
              <UserColectiveGeneralDataTab
                userOverview={props?.usersState?.getFOUserOverview_result}
                handleChangeTab={handleChangeTab}
                goToTopOfPage={goToTopOfPage}
              />
            );
        }
      default:
        switch (tabStep) {
          case TABS.GENERAL_DATA:
            return (
              <UserSingularGeneralDataTab
                userOverview={props?.usersState?.getFOUserOverview_result}
                handleChangeTab={handleChangeTab}
              />
            );
          case TABS.TRAINING_AND_PROFESSION:
            return (
              <UserSingularTrainingAndProfessionTab
                userSingularProfessionalDataOverview={
                  props?.usersState?.getFOUserOverview_result?.userSingularProfessionalDataOverview
                }
                goToTopOfPage={goToTopOfPage}
              />
            );
          case TABS.APPLICATIONS:
            return <UserSingularApplicationsTab selectedTab={goToSectionTab} goToTopOfPage={goToTopOfPage} />;
          case TABS.GENERAL_HISTORY:
            return (
              <HistoryTab userId={props?.usersState?.getFOUserOverview_result?.id} goToTopOfPage={goToTopOfPage} />
            );
          case TABS.EMPLOYMENT_AND_UNEMPLOYMENT_HISTORY:
            return (
              <div className={classes.cardRoot} style={{ marginLeft: "25px", marginRight: "25px" }}>
                <EmploymentAndUnemploymentHistoryTab userId={props?.usersState?.getFOUserOverview_result?.id} />
              </div>
            );
          case TABS.INTERVENTION_FROM_OTHERS_AREAS:
            return (
              <div className={classes.cardRoot} style={{ marginLeft: "25px", marginRight: "25px" }}>
                <InterventionOtherAreaSection />
              </div>
            );
          default:
            return (
              <UserSingularGeneralDataTab
                userOverview={props?.usersState?.getFOUserOverview_result}
                handleChangeTab={handleChangeTab}
              />
            );
        }
    }
  }

  function userSingularCurriculumMap(userId) {
    return {
        reportEnumKey: REPORT_TYPES.CURRICULUM,
        parameters: JSON.stringify({ userId : userId }),
    };
}

  return (
    <>
      {scrollDirection === "up" && (
        <div className={classes.breadcrumbs}>
          <LocationHeader
            subsection={intl.formatMessage(titles.Title_UserOverview_Advanced_Search_Title)}
            section={intl.formatMessage(titles.Title_UserOverview_User_Overview_Title)}
            subsectionLink={"/admin/advanced-search"}
          />
        </div>
      )}

      <GenericBackdrop open={props?.usersState?.getFOUserOverview_loading} />

      <Container maxWidth={false} component={Box} classes={{ root: classes.containerRootAfterBreadcrumbs }}>
        <Box display="flex" alignItems="flex-start">
          <PageTitleHeader
            title={
              props?.usersState?.getFOUserOverview_result?.userTypeKey === USER_TYPE_KEY.INDIVIDUAL
                ? props?.usersState?.getFOUserOverview_result?.userSingularGeneralDataOverview?.fullName
                : props?.usersState?.getFOUserOverview_result?.userColectiveGeneralDataOverview?.name
            }
            subsectionLink={""}
          />
          {props?.usersState?.getFOUserOverview_result?.userTypeKey === USER_TYPE_KEY.INDIVIDUAL  && 
            <GenericButton
              typeSubmit={false}
              color="tertiary"
              loading={props.reportsState.get_report_loading}
              onClick={() => {
                props.getUserSingularCurriculum(
                  userSingularCurriculumMap(props?.usersState?.getFOUserOverview_result?.id),
                  `${props?.usersState?.getFOUserOverview_result?.userSingularGeneralDataOverview?.fullName} Curriculum.pdf`
                );
              }}
            >
              Exportar Currículo
            </GenericButton>
          }
        </Box>

        <Box component={Grid} container justifyContent="center">
          <Tabs
            tabs={[
              {
                value: TABS.GENERAL_DATA,
                testId: "user-overview-general-data-tab",
                text: intl.formatMessage(labels.Label_UserOverview_General_Data_Tab),
              },
              {
                renderIf: props?.usersState?.getFOUserOverview_result?.userTypeKey === USER_TYPE_KEY.INDIVIDUAL,
                value: TABS.TRAINING_AND_PROFESSION,
                testId: "user-overview-training-and-profession-tab",
                text: intl.formatMessage(labels.Label_UserOverview_Training_And_Profession_Tab),
              },
              {
                value: TABS.APPLICATIONS,
                testId: "user-overview-applications-tab",
                text: intl.formatMessage(labels.Label_UserOverview_Applications_Tab),
              },
              {
                renderIf: props?.usersState?.getFOUserOverview_result?.userTypeKey === USER_TYPE_KEY.INDIVIDUAL,
                value: TABS.EMPLOYMENT_AND_UNEMPLOYMENT_HISTORY,
                testId: "user-overview-employment-and-unemployment-history-tab",
                text: intl.formatMessage(labels.Label_UserOverview_EmploymentAndUnemploymentHistory_Tab),
              },
              {
                value: TABS.INTERVENTION_FROM_OTHERS_AREAS,
                testId: "user-overview-other-areas-intervention-tab",
                text: intl.formatMessage(labels.Label_InterventionOtherArea),
              },
              {
                value: TABS.GENERAL_HISTORY,
                testId: "user-overview-history-tab",
                text: intl.formatMessage(labels.Label_UserOverview_History_Tab),
              },
            ]}
            step={tabStep}
            onChange={handleChangeTab}
            dataTestId="user-overview-toggle-button-group"
          />

          <Grid item xs={12} lg={12} md={12}>
            {loadTabContent()}
          </Grid>
        </Box>
      </Container>
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getFOUserOverview: (data) => dispatch(getFOUserOverviewAction(data)),
  getUserSingularCurriculum: (data, reportFileName) => dispatch(getReportAction(data, reportFileName)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OpportunityApplicationFollowUpDetails);
