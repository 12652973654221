import React, {useState, useEffect, useRef} from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import { useIntl } from "react-intl";
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import { InputBase, IconButton, CircularProgress } from '@material-ui/core';
// @material-ui/icons components
import SearchIcon from '@material-ui/icons/Search';

// core components
import componentStyles from "assets/theme/components/search-bar";
import componentStylesGeneric from "assets/theme/views/admin/generic.js";
import { labels } from "resources/resources";

const useStyles = makeStyles(componentStyles);
const useStylesGeneric = makeStyles(componentStylesGeneric);

function SearchBar(props) {
    const classes = { ...useStyles(), ...useStylesGeneric() };
    const history = useHistory();
    const intl = useIntl();
    const [searchValue, setSearchValue] = useState(null)
    const [openDropdown, setOpenDropdown] = useState(false)

    //Hook that alerts clicks outside of the passed ref
    function ClickOutsideComponent(ref) {
        useEffect(() => {
        //Alert if clicked on outside of element
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                setOpenDropdown(false)
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
        }, [ref]);
    }

    const wrapperRef = useRef(null);
    ClickOutsideComponent(wrapperRef);

    const handleSearch= () => {
        props?.handleSearch(searchValue)
    }

    const handleChangeSearchValue= (newValue) => {
        setSearchValue(newValue)
        setOpenDropdown(false)
    }

    useEffect(() => {
        if (searchValue) {
            setOpenDropdown(true)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.searchResultList]);

    return (
        <>
            <div>
                <img
                    src={"/images/empregoAngolaBackground3.png"} //Designed by 0melapics / Freepik: http://www.freepik.com
                    style={{ 
                        height: "80px", 
                        width: "100%", 
                        objectFit: "cover",
                        position: "absolute",
                    }}
                    alt={""}
                />
            </div>
            
            <div ref={wrapperRef} className={classes.searchBar}> 
                <div className={classes.searchIconDiv}> 
                    <div className={classes.searchIconDiv}>
                        <div className={classes.advancedSearchDiv}>
                            {props.advancedSearchPath && (    
                                <button
                                    className={classes.advancedSearchButton}
                                    onClick={()=> {history.push(props.advancedSearchPath)}} 
                                >
                                    {intl.formatMessage(labels.Label_Admin_AdvancedSearch_AdvancedSearch)}
                                </button>
                            )}
                        </div>
                        <div className={classes.searchFieldDiv}>
                            <IconButton 
                                classes={{
                                    root: classes.searchIconButton + " " + classes.iconButtonOutlined
                                }}
                                onClick={handleSearch}
                                disabled={props?.loadingSearch}
                                data-testid="search-bar-search-button"
                            >
                                <SearchIcon 
                                    classes={{
                                        root: classes.searchIconRoot
                                    }}
                                /> 
                            </IconButton>
                        </div>
                    </div>
                </div>
                <div className={classes.searchFieldDiv}> 
                    <div className={classes.searchFieldDiv}>
                        <InputBase
                            placeholder={props.placeholder}
                            classes={{
                                root: classes.inputRoot
                            }}
                            inputProps={{ "aria-label": "search" }}
                            onChange={(e) => handleChangeSearchValue(e?.target?.value)}
                            onKeyDown= {(e) => {
                                if (e.key === 'Enter') {
                                    handleSearch()
                                }
                            }}
                        />
                        {props?.loadingSearch && (
                            <CircularProgress
                                size={24}
                                className={classes.circularProgress}
                            />
                        )}
                    </div>

                    {props.searchResultList?.options?.length > 0 && openDropdown && (
                        <div className={classes.searchResults}>
                            {props.searchResultList?.options?.map((prop, key) => (
                                <button
                                    className={classes.searchResultsButton}
                                    key={key}
                                    onClick={()=> {
                                        history.push(prop?.searchResultPath)
                                        document.location.reload()
                                    }} 
                                >
                                    {ReactHtmlParser(prop?.text)}
                                </button>
                            ))}
                        </div>
                    )}

                    {props.searchResultList?.options?.length === 0 && openDropdown && (
                        <div className={classes.searchResults}>
                            {intl.formatMessage(labels.Label_Admin_SearchBarNavbar_NoResults)}
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

const mapStateToProps = (state) => ({ ...state });

export default connect(mapStateToProps)(SearchBar);