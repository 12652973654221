import { React, useEffect, useState } from "react";
import clsx from "clsx";
import { Controller, useForm } from "react-hook-form";
import ReactDatetime from "react-datetime";
import { useIntl } from "react-intl";
import moment from "moment";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// @material-ui/core components
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import Container from "@material-ui/core/Container";
import CardContent from "@material-ui/core/CardContent";
import Collapse from "@material-ui/core/Collapse";
import CardHeader from "@material-ui/core/CardHeader";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputBase from "@material-ui/core/InputBase";


import componentStylesGeneric from "assets/theme/views/admin/generic";
import { labels, titles, messages } from "resources/resources";
import { getDataSetByNameAction } from "redux/actions/datasetsAction";
import { DATASET } from "utils/consts";
import { createSchedulingMap } from "../../../../mappers/applicationsDetailsMap";
import GenericFormSelect from "components/FormFields/GenericFormSelect";
import { createSchedulingAction } from "redux/actions/schedulingAction";
import GenericButton from "components/Buttons/GenericButton";
import { REGEX_PATTERNS } from "utils/consts"


const useStylesGeneric = makeStyles(componentStylesGeneric);

function SchedulingTab(props) {
  const classes = useStylesGeneric()
  const theme = useTheme();
  const intl = useIntl();
  const [dateTime, setDateTime] = useState(true);

  const {
    handleSubmit,
    getValues,
    setValue,
    control,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
  });

  const handleValidDate = (date, onChange) => {
    if (date === "" || REGEX_PATTERNS.DATE.test(date)) {
      onChange(date);
    }
  };

  useEffect(() => {
    if (
      props.dataSetState[DATASET.APPLICATION_SCHEDULING_MOTIVES] ===
        undefined ||
      props.dataSetState[DATASET.APPLICATION_SCHEDULING_MOTIVES]?.length === 0
    ) {
      props.getDataSet(DATASET.APPLICATION_SCHEDULING_MOTIVES);
    }

    setValue("applicationNumber", props.applicationNumber);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clearFields = () => {
    setDateTime(false);
    setValue("schedulingMotive", undefined);
    setValue("schedulingDate", "");
    setValue("schedulingObjectives", "");
  };

  const onSubmit = (data) => {
    if (isValid) {
      props.createScheduling(createSchedulingMap(data), clearFields);
    }
  };

  return (
    <>
      <Container maxWidth={false}>
        <Card classes={{ root: classes.cardRoot }}>
          <CardHeader
            classes={{ title: classes.cardHeader }}
            title={intl.formatMessage(
              titles.Title_Admin_ApplicationDetail_SchedulingSection
            )}            
          ></CardHeader>
          <CardContent>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container>
                <Controller
                  name={"applicationNumber"}
                  control={control}
                  defaultValue=""
                  render={({ field: { onChange, name, value } }) => (
                    <InputBase
                      name={name}
                      value={value}
                      onChange={onChange}
                      fullWidth
                      autoComplete="off"
                      type="hidden"
                    />
                  )}
                />
              </Grid>
              <Grid container>
                <Grid item xs={12} md={8}>
                  <FormGroup>
                    <FormLabel>
                      {intl.formatMessage(
                        labels.Label_Admin_SchedulingTab_MotiveField
                      )}
                    </FormLabel>
                    <GenericFormSelect
                      control={control}
                      fieldName="schedulingMotive"
                      selectOptions={
                        props.dataSetState[
                          DATASET.APPLICATION_SCHEDULING_MOTIVES
                        ] !== undefined
                          ? props.dataSetState[
                              DATASET.APPLICATION_SCHEDULING_MOTIVES
                            ]
                          : []
                      }
                      getValues={getValues}
                      required={true}
                      requiredMessage={intl.formatMessage(
                        messages.Message_Generic_MandatoryField
                      )}
                      errors={errors}
                      classes={classes}
                    />
                    {errors["schedulingMotive"] !== undefined && (
                      <FormHelperText
                        component={Box}
                        color={theme.palette.warning.main + "!important"}
                      >
                        {errors["schedulingMotive"].message}
                      </FormHelperText>
                    )}
                  </FormGroup>
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormGroup>
                    <FormLabel>
                      {intl.formatMessage(
                        labels.Label_Admin_SchedulingTab_SchedulingDateField
                      )}
                    </FormLabel>
                    <Controller
                      name="schedulingDate"
                      control={control}
                      rules={{
                        validate: {
                          maxDate: (value) => {
                            return (
                              value >= moment().startOf("day") ||
                              intl.formatMessage(
                                messages.Message_Generic_DateToGreaterThanOrEqualToTodayField
                              )
                            );
                          },
                        },
                      }}
                      render={({ field: { onChange, name, value } }) => (
                        <ReactDatetime
                          name={name}
                          value={value}
                          onChange={(e) => {
                            setDateTime(true);
                            onChange(e);
                          }}
                          locale="pt"
                          dateFormat={true}
                          timeFormat={true}
                          closeOnTab={true}
                          closeOnClickOutside={true}
                          isValidDate={(currentDate, _) => {
                            return currentDate > moment().startOf("day");
                          }}
                          inputProps={{
                            placeholder: "DD/MM/AAAA hh:mm",
                            className: "",
                          }}
                          renderInput={(dateInputProps, open) => (
                            <OutlinedInput
                              onFocus={open}
                              value={dateTime ? dateInputProps.value : ""}
                              placeholder="DD/MM/AAAA hh:mm"
                              onChange={(e) => {
                                handleValidDate(
                                  e.target.value,
                                  dateInputProps.onChange
                                );
                              }}
                              startAdornment={
                                <InputAdornment position="start">
                                  <Box
                                    component={AccessTimeIcon}
                                    marginRight=".5rem"
                                    color={theme.palette.primary.main}
                                  />
                                </InputAdornment>
                              }
                              classes={{
                                notchedOutline: clsx({
                                  [classes.borderWarning]:
                                    errors["schedulingDate"] !== undefined,
                                }),
                              }}
                            />
                          )}
                        />
                      )}
                    />
                    {errors["schedulingDate"] !== undefined && (
                      <FormHelperText
                        component={Box}
                        color={theme.palette.warning.main + "!important"}
                      >
                        {errors["schedulingDate"].message}
                      </FormHelperText>
                    )}
                  </FormGroup>
                </Grid>
                <Grid item xs={12} md={12}>
                  <FormGroup>
                    <FormLabel>
                      {intl.formatMessage(
                        labels.Label_Admin_SchedulingTab_ObservationsField
                      )}
                    </FormLabel>
                    <Controller
                      name="schedulingObjectives"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: {
                          value: true,
                          message: intl.formatMessage(
                            messages.Message_Generic_MandatoryField
                          ),
                        },
                      }}
                      render={({ field: { onChange, name, value } }) => (
                        <OutlinedInput
                          name={name}
                          value={value}
                          onChange={onChange}
                          fullWidth
                          multiline
                          rows="3"
                          autoComplete="off"
                          type="text"
                          classes={{
                            notchedOutline: clsx({
                              [classes.borderWarning]:
                                errors["schedulingObjectives"] !== undefined,
                            }),
                          }}
                        />
                      )}
                    />
                    {errors["schedulingObjectives"] !== undefined && (
                      <FormHelperText
                        component={Box}
                        color={theme.palette.warning.main + "!important"}
                      >
                        {errors["schedulingObjectives"].message}
                      </FormHelperText>
                    )}
                  </FormGroup>
                </Grid>
                <Grid item xs={12} md={10}></Grid>
                <Grid item xs={12} md={2}>
                  <FormGroup>
                    <br />
                    <GenericButton
                      typeSubmit={true}
                      color="primary"
                      disabled={props.schedulingState.create_loading}
                      loading={props.schedulingState.create_loading}
                    >
                      Submeter
                    </GenericButton>
                  </FormGroup>
                </Grid>
              </Grid>
            </form>
          </CardContent>
        </Card>
      </Container>
      <Container maxWidth={false}>
        <Card classes={{ root: classes.cardRoot }}>
          <CardHeader
            classes={{ title: classes.cardHeader, action: classes.cardHeaderAction }}
            title={intl.formatMessage(
              titles.Title_Admin_ApplicationDetail_ReportsSection
            )}
            action={
              <GenericButton
                typeSubmit={false}
                color="tertiary"
                size="small"
                component={Link}
                to={`/admin/schedules-management/${
                  props.generalData !== undefined
                    ? props.generalData.applicationNumber + "?"
                    : ""
                }`}
                aria-expanded={false}
              >
                Ver / Entregar
              </GenericButton>
            }
          ></CardHeader>

          <Collapse in={false} timeout="auto" unmountOnExit>
            <CardContent></CardContent>
          </Collapse>
        </Card>
      </Container>
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getDataSet: (name, parentKeyValue) =>
    dispatch(getDataSetByNameAction(name, parentKeyValue)),
  createScheduling: (schedulingData, successFunction) =>
    dispatch(createSchedulingAction(schedulingData, successFunction)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SchedulingTab);
