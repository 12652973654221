const componentStyles = (theme) => ({
  addButtonGrid: {
    padding: 0,
    paddingRight: "20px",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    marginTop: "0.25rem",
  },
  addButtonRoot: {
    color: theme.palette.primary.main,
    background: theme.palette.white.main,
    boxShadow: "0px 4px 6px rgba(50, 50, 93, 0.11), 0px 1px 3px rgba(0, 0, 0, 0.08) !important",
    borderRadius: "4px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "16px",
    display: "flex",
    alignItems: "center",
    border: "1px solid",
    borderColor: theme.palette.primary.mediumBlue,
    padding: "4px 8px",
    "&.Mui-disabled": {
      "& .MuiSvgIcon-root": {
        color: theme.palette.primary.mediumBlue,
      },
    },
  },
  addButtonIconRoot: {
    color: theme.palette.primary.main,
    width: "25px",
    height: "25px",
  },
  deleteButtonIconRoot: {
    color: theme.palette.primary.main,
    width: "20px",
    height: "20px",
  },
  deleteButtonRoot: {
    padding: 0,
  },
  previewsContainer: {
    paddingLeft: "15px",
    overflow: "auto",
    maxHeight: "300px",
  },
  previewOuterBox: {
    display: "flex",
    backgroundColor: theme.palette.white.main,
    marginRight: "10px",
    marginBottom: "10px",
    fontWeight: "600",
    fontSize: "12px",
    lineHeight: "16px",
    color: theme.palette.primary.main,
    borderRadius: "4px",
    border: "1px solid " + theme.palette.primary.main,
    padding: ".4em .3em .3em .85em",
    alignItems: "center",
  },
  previewBox: {
    paddingRight: "5px",
  },

  iframe: {
    border: 0,
  },
});

export default componentStyles;
