import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { Controller, useForm } from "react-hook-form";
import { useIntl } from "react-intl";

import "moment/locale/pt";
import { connect } from "react-redux";
// @material-ui/core components
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Container from "@material-ui/core/Container";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Grid from "@material-ui/core/Grid";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import TextField from "@material-ui/core/TextField";
import InputBase from "@material-ui/core/InputBase";
//import custom components
import componentStylesGeneric from "assets/theme/views/admin/generic.js";
import GenericFormSelect from "components/FormFields/GenericFormSelect";
import GenericButton from "components/Buttons/GenericButton";
import {
  getDataSetByNameAction,
  getBackOfficeRolesAction,
  getBackOfficeUsersAction,
} from "redux/actions/datasetsAction";
import { createNotificationAction } from "redux/actions/notificationsAction";
import { DATASET, NOTIFICATION_RECIPIENT_TYPE } from "utils/consts";
import { labels, titles, messages } from "resources/resources";
import { createNotificationsMap } from "../../../../mappers/applicationsDetailsMap";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function NotificationsTab(props) {
  const classes = useStylesGeneric();
  const theme = useTheme();
  const intl = useIntl();
  const [recipientType, setRecipientType] = useState(0);

  const {
    handleSubmit,
    getValues,
    setValue,
    unregister,
    control,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
  });

  useEffect(() => {
    if (
      props.dataSetState[DATASET.APPLICATION_NOTIFICATION_SUBJECTS] ===
        undefined ||
      props.dataSetState[DATASET.APPLICATION_NOTIFICATION_SUBJECTS]?.length ===
        0
    ) {
      props.getDataSet(DATASET.APPLICATION_NOTIFICATION_SUBJECTS);
    }

    if (
      props.dataSetState[DATASET.APPLICATION_NOTIFICATION_MOTIVES] ===
        undefined ||
      props.dataSetState[DATASET.APPLICATION_NOTIFICATION_MOTIVES]?.length === 0
    ) {
      props.getDataSet(DATASET.APPLICATION_NOTIFICATION_MOTIVES);
    }

    if (
      props.dataSetState[DATASET.APPLICATION_NOTIFICATION_RECIPIENT_TYPES] ===
        undefined ||
      props.dataSetState[DATASET.APPLICATION_NOTIFICATION_RECIPIENT_TYPES]
        ?.length === 0
    ) {
      props.getDataSet(DATASET.APPLICATION_NOTIFICATION_RECIPIENT_TYPES);
    }

    if (
      props.dataSetState[DATASET.BACKOFFICE_USERS] === undefined ||
      props.dataSetState[DATASET.BACKOFFICE_USERS]?.length === 0
    ) {
      props.getBackOfficeUsers(DATASET.BACKOFFICE_USERS);
    }

    if (
      props.dataSetState[DATASET.BACKOFFICE_ROLES] === undefined ||
      props.dataSetState[DATASET.BACKOFFICE_ROLES]?.length === 0
    ) {
      props.getBackOfficeRoles(DATASET.BACKOFFICE_ROLES);
    }

    setValue("applicationNumber", props.applicationNumber);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clearFields = () => {
    setValue("notificationBackOfficeUser", undefined);
    setValue("notificationBackOfficeGroup", undefined);
    setValue("notificationRecipient", undefined);
    setValue("notificationSubject", undefined);
    setValue("notificationMotive", undefined);
    setValue("notificationObservations", "");

    unregister("notificationCandidate");
    unregister("notificationBackOfficeUser");
    unregister("notificationBackOfficeGroup");
    setRecipientType(0);
  };

  function loadSpecificGroupOrUser(recipientType) {
    switch (recipientType) {
      case NOTIFICATION_RECIPIENT_TYPE.CANDIDATE:
        setValue(
          "notificationCandidate",
          props.generalData?.userSingularGeneralDataSection != null ? props.generalData.userSingularGeneralDataSection.nameOfUser : 
          props.generalData?.userColectiveGeneralDataSection != null ? props.generalData.userColectiveGeneralDataSection.companyName : ""
        );
        return (
          <>
            <FormLabel>
              {intl.formatMessage(
                labels.Label_Admin_NotificationsTab_CandidateField
              )}
            </FormLabel>
            <Controller
              name="notificationCandidate"
              control={control}
              component={Box}
              render={({ field: { onChange, name, value } }) => (
                <OutlinedInput
                  name={name}
                  value={value}
                  onChange={(e) => onChange("provinceName", e.target.value)}
                  fullWidth
                  autoComplete="off"
                  type="text"
                  disabled={true}
                  classes={{
                    notchedOutline: clsx({
                      [classes.borderWarning]:
                        errors["notificationCandidate"] !== undefined,
                    }),
                  }}
                />
              )}
            />
            {errors["notificationCandidate"] !== undefined && (
              <FormHelperText
                component={Box}
                color={theme.palette.warning.main + "!important"}
              >
                {errors["notificationCandidate"].message}
              </FormHelperText>
            )}
          </>
        );
      case NOTIFICATION_RECIPIENT_TYPE.BO_USER:
        return (
          <>
            <FormLabel>
              {intl.formatMessage(
                labels.Label_Admin_NotificationsTab_BackofficeUserField
              )}
            </FormLabel>
            <GenericFormSelect
              control={control}
              fieldName="notificationBackOfficeUser"
              selectOptions={
                props.dataSetState[DATASET.BACKOFFICE_USERS] !== undefined
                  ? props.dataSetState[DATASET.BACKOFFICE_USERS]
                  : []
              }
              getValues={getValues}
              required={true}
              requiredMessage={intl.formatMessage(
                messages.Message_Generic_MandatoryField
              )}
              errors={errors}
              classes={classes}
            />
            {errors["notificationBackOfficeUser"] !== undefined && (
              <FormHelperText
                component={Box}
                color={theme.palette.warning.main + "!important"}
              >
                {errors["notificationBackOfficeUser"].message}
              </FormHelperText>
            )}
          </>
        );
      case NOTIFICATION_RECIPIENT_TYPE.BO_GROUP:
        return (
          <>
            <FormLabel>
              {intl.formatMessage(
                labels.Label_Admin_NotificationsTab_BackofficeGroupField
              )}
            </FormLabel>
            <GenericFormSelect
              control={control}
              fieldName="notificationBackOfficeGroup"
              selectOptions={
                props.dataSetState[DATASET.BACKOFFICE_ROLES] !== undefined
                  ? props.dataSetState[DATASET.BACKOFFICE_ROLES]
                  : []
              }
              getValues={getValues}
              required={true}
              requiredMessage={intl.formatMessage(
                messages.Message_Generic_MandatoryField
              )}
              errors={errors}
              classes={classes}
            />
            {errors["notificationBackOfficeGroup"] !== undefined && (
              <FormHelperText
                component={Box}
                color={theme.palette.warning.main + "!important"}
              >
                {errors["notificationBackOfficeGroup"].message}
              </FormHelperText>
            )}
          </>
        );
      default:
        return <></>;
    }
  }

  const onSubmit = (data) => {
    if (isValid) {
      props.createNotification(createNotificationsMap(data), clearFields);
    }
  };

  return (
    <>
      <Container maxWidth={false}>
        <Card classes={{ root: classes.cardRoot }}>
          <CardHeader
            classes={{ title: classes.cardHeader }}
            title={intl.formatMessage(
              titles.Title_Admin_ApplicationDetail_NotificationsSection
            )}
          ></CardHeader>
          <CardContent>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container>
                <Controller
                  name={"applicationNumber"}
                  control={control}
                  defaultValue=""
                  render={({ field: { onChange, name, value } }) => (
                    <InputBase
                      name={name}
                      value={value}
                      onChange={onChange}
                      fullWidth
                      autoComplete="off"
                      type="hidden"
                    />
                  )}
                />
              </Grid>
              <Grid container>
                <Grid item xs={12} md={6}>
                  <FormGroup>
                    <FormLabel>
                      {intl.formatMessage(
                        labels.Label_Admin_NotificationsTab_RecipientField
                      )}
                    </FormLabel>
                    <Controller
                      name="notificationRecipient"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: intl.formatMessage(
                            messages.Message_Generic_MandatoryField
                          ),
                        },
                      }}
                      render={({ field: { onChange, name } }) => (
                        <Autocomplete
                          name={name}
                          options={
                            props.dataSetState[
                              DATASET.APPLICATION_NOTIFICATION_RECIPIENT_TYPES
                            ] !== undefined
                              ? props.dataSetState[
                                  DATASET
                                    .APPLICATION_NOTIFICATION_RECIPIENT_TYPES
                                ]
                              : []
                          }
                          value={getValues(name) ? getValues(name) : null}
                          getOptionLabel={(option) => option.label}
                          getOptionSelected={(option, value) =>
                            option.code === value.code
                          }
                          classes={{
                            inputRoot: clsx({
                              [classes.selectBorderWarningInputRoot]:
                                errors !== undefined &&
                                errors[name] !== undefined,
                            }),
                          }}
                          ListboxProps={{
                            style: {
                              fontWeight: "normal",
                              fontSize: "14px",
                            },
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={"Seleccione opção"}
                              variant="outlined"
                              InputLabelProps={{
                                style: {
                                  fontWeight: "normal",
                                  fontSize: "14px",
                                  color: theme.palette.gray[600],
                                },
                              }}
                            />
                          )}
                          onChange={(_, data) => {
                            onChange(data);
                            if (data !== null) {
                              setValue("notificationBackOfficeUser", undefined);
                              setValue(
                                "notificationBackOfficeGroup",
                                undefined
                              );

                              unregister("notificationCandidate");
                              unregister("notificationBackOfficeUser");
                              unregister("notificationBackOfficeGroup");

                              setRecipientType(data.keyValue);
                            } else {
                              setRecipientType(0);
                            }
                          }}
                        />
                      )}
                    />
                    {errors["notificationRecipient"] !== undefined && (
                      <FormHelperText
                        component={Box}
                        color={theme.palette.warning.main + "!important"}
                      >
                        {errors["notificationRecipient"].message}
                      </FormHelperText>
                    )}
                  </FormGroup>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormGroup>
                    {loadSpecificGroupOrUser(recipientType)}
                  </FormGroup>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} md={6}>
                  <FormGroup>
                    <FormLabel>
                      {intl.formatMessage(
                        labels.Label_Admin_NotificationsTab_MotiveField
                      )}
                    </FormLabel>
                    <GenericFormSelect
                      control={control}
                      fieldName="notificationMotive"
                      selectOptions={
                        props.dataSetState[
                          DATASET.APPLICATION_NOTIFICATION_MOTIVES
                        ] !== undefined
                          ? props.dataSetState[
                              DATASET.APPLICATION_NOTIFICATION_MOTIVES
                            ]
                          : []
                      }
                      getValues={getValues}
                      required={true}
                      requiredMessage={intl.formatMessage(
                        messages.Message_Generic_MandatoryField
                      )}
                      errors={errors}
                      classes={classes}
                    />
                    {errors["notificationMotive"] !== undefined && (
                      <FormHelperText
                        component={Box}
                        color={theme.palette.warning.main + "!important"}
                      >
                        {errors["notificationMotive"].message}
                      </FormHelperText>
                    )}
                  </FormGroup>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormGroup>
                    <FormLabel>
                      {intl.formatMessage(
                        labels.Label_Admin_NotificationsTab_SubjectField
                      )}
                    </FormLabel>
                    <GenericFormSelect
                      control={control}
                      fieldName="notificationSubject"
                      selectOptions={
                        props.dataSetState[
                          DATASET.APPLICATION_NOTIFICATION_SUBJECTS
                        ] !== undefined
                          ? props.dataSetState[
                              DATASET.APPLICATION_NOTIFICATION_SUBJECTS
                            ]
                          : []
                      }
                      getValues={getValues}
                      required={true}
                      requiredMessage={intl.formatMessage(
                        messages.Message_Generic_MandatoryField
                      )}
                      errors={errors}
                      classes={classes}
                    />
                    {errors["notificationSubject"] !== undefined && (
                      <FormHelperText
                        component={Box}
                        color={theme.palette.warning.main + "!important"}
                      >
                        {errors["notificationSubject"].message}
                      </FormHelperText>
                    )}
                  </FormGroup>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} md={12}>
                  <FormGroup>
                    <FormLabel>
                      {intl.formatMessage(
                        labels.Label_Admin_NotificationsTab_ObservationsField
                      )}
                    </FormLabel>
                    <Controller
                      name="notificationObservations"
                      control={control}
                      component={Box}
                      rules={{
                        required: {
                          value: true,
                          message: intl.formatMessage(
                            messages.Message_Generic_MandatoryField
                          ),
                        },
                      }}
                      render={({ field: { onChange, name, value } }) => (
                        <OutlinedInput
                          name={name}
                          value={value}
                          onChange={onChange}
                          fullWidth
                          multiline
                          rows="3"
                          autoComplete="off"
                          type="text"
                          classes={{
                            notchedOutline: clsx({
                              [classes.borderWarning]:
                                errors["notificationObservations"] !==
                                undefined,
                            }),
                          }}
                        />
                      )}
                    />
                    {errors["notificationObservations"] !== undefined && (
                      <FormHelperText
                        component={Box}
                        color={theme.palette.warning.main + "!important"}
                      >
                        {errors["notificationObservations"].message}
                      </FormHelperText>
                    )}
                  </FormGroup>
                </Grid>
                <Grid item xs={12} md={10}></Grid>
                <Grid item xs={12} md={2}>
                  <FormGroup>
                    <br />
                    <GenericButton
                      typeSubmit={true}
                      color="primary"
                      disabled={props.notificationState.create_loading}
                      loading={props.notificationState.create_loading}                                           
                    >
                      Enviar
                    </GenericButton>
                  </FormGroup>
                </Grid>
              </Grid>
            </form>
          </CardContent>
        </Card>
      </Container>
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getDataSet: (name, parentKeyValue) =>
    dispatch(getDataSetByNameAction(name, parentKeyValue)),
  getBackOfficeUsers: (name) => dispatch(getBackOfficeUsersAction(name)),
  getBackOfficeRoles: (name) => dispatch(getBackOfficeRolesAction(name)),
  createNotification: (notificationData, successFunction) =>
    dispatch(createNotificationAction(notificationData, successFunction)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsTab);
