export function informationDisseminationMap(formData, mediaToDeleteList) {
  if (formData !== null && formData !== undefined) {
    const infoDissemination = {
      alternateKey:
        formData.informationDisseminationAlternateKey !== undefined
          ? formData.informationDisseminationAlternateKey
          : null,
      title: formData.informationDisseminationTitle,
      description:
        formData.informationDisseminationDescription !== undefined
          ? formData.informationDisseminationDescription
          : null,
      isPublic:
        formData.informationDisseminationIsPublic !== undefined ? formData.informationDisseminationIsPublic : false,
      published:
        formData.informationDisseminationPublished !== undefined ? formData.informationDisseminationPublished : false,
      informationDisseminationTypeId:
        formData.informationDisseminationType !== undefined ? formData.informationDisseminationType.code : "",
      informationDisseminationTypeKeyValue:
        formData.informationDisseminationType !== undefined ? formData.informationDisseminationType.keyValue : "",
      isHighlighted:
        formData.informationDisseminationHighlighted !== undefined
          ? formData.informationDisseminationHighlighted
          : false,
    };   
    
    //Set FormData
    const data = new FormData();
    //Append fields
    for (const key in infoDissemination) {
      data.append("InfoDissemination." + key, infoDissemination[key]);
    }
    //Append files
    formData.informationDisseminationPhotoList?.forEach((file) => {
      data.append("PhotoList", file);
    });

    formData.informationDisseminationVideoUrlList?.forEach((mediaUrl) => {
      if (!mediaUrl.id) data.append("InfoDissemination.Urls", mediaUrl.url);
    });
    mediaToDeleteList?.forEach((mediaId) => {
      data.append("InfoDissemination.MediaListToDelete", mediaId);
    });

    formData.socialMediaList?.forEach((socialMedia, key) => {
      data.append("InfoDissemination.SocialMediaList[" + key + "].SociaMediaTypeKeyValue", socialMedia.sociaMediaTypeKeyValue);
      data.append("InfoDissemination.SocialMediaList[" + key + "].Url", socialMedia.url);
    });

    return data;
  }

  return formData;
}

export function informationDisseminationSearchMap(formData) {
  if (formData !== null && formData !== undefined) {
    return {
      searchText: formData.searchText,
      InformationDisseminationTypeKeyValueList:
        formData.informationDisseminationType
          ? [formData.informationDisseminationType?.keyValue]
          : [],
      Published:
        formData.informationDisseminationPublished !== undefined
          ? formData.informationDisseminationPublished?.code
          : undefined,
      isPublic:
        formData.informationDisseminationAccess !== undefined
          ? formData.informationDisseminationAccess.label === "Público"
          : undefined,
      isHighlighted:
        formData.informationDisseminationHighlighted !== undefined
          ? formData.informationDisseminationHighlighted?.code
          : undefined,
    };
  }
  return formData;
}

export default informationDisseminationMap;
