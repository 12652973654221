import { React, useEffect, useState } from "react";
import { connect } from "react-redux";
import "moment/locale/pt";
import moment from "moment";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
// @material-ui/icons components
import HelpIcon from "@material-ui/icons/Help";
//import custom components
import DisplayField from "components/Fields/DisplayField";
import TableStateChip from "components/Cards/Tables/TableStateChip";
import { getDataSetByNameAction } from "redux/actions/datasetsAction";
import { OPPORTUNITY_CANDIDATE_STATE_KEY, TABLE_STATE_CHIP_COLOR, DATASET } from "utils/consts";

import componentStylesGeneric from "assets/theme/views/admin/generic.js";
import { Box, Typography } from "@material-ui/core";

const useStylesGeneric = makeStyles(componentStylesGeneric);

const useStyles = makeStyles((theme) => ({
  cardRoot: {
    [theme.breakpoints.down("md")]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "flex-start",
    },
  },
}));

function CandidateGeneralDetails(props) {
  const classes = useStylesGeneric();
  const styles = useStyles();

  const [data, setData] = useState();

  useEffect(() => {
    if (props.opportunitiesState?.candidateInfo !== null && props.opportunitiesState?.candidateInfo !== undefined) {
      setData(props.opportunitiesState.candidateInfo);
    } else {
      setData([]);
    }
  }, [props.opportunitiesState?.candidateInfo]);

  //#region DataSets Setup
  useEffect(() => {
    if (props.dataSetState[DATASET.GENDER] === undefined || props.dataSetState[DATASET.GENDER].length === 0) {
      props.getDataSetByName(DATASET.GENDER);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dataSetState[DATASET.GENDER]]);

  useEffect(() => {
    if (
      props.dataSetState[DATASET.MARITAL_STATUS] === undefined ||
      props.dataSetState[DATASET.MARITAL_STATUS].length === 0
    ) {
      props.getDataSetByName(DATASET.MARITAL_STATUS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dataSetState[DATASET.MARITAL_STATUS]]);

  useEffect(() => {
    if (
      props.dataSetState[DATASET.NATIONALITIES] === undefined ||
      props.dataSetState[DATASET.NATIONALITIES].length === 0
    ) {
      props.getDataSetByName(DATASET.NATIONALITIES);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dataSetState[DATASET.NATIONALITIES]]);

  useEffect(() => {
    if (props.dataSetState[DATASET.PROVINCES] === undefined || props.dataSetState[DATASET.PROVINCES].length === 0) {
      props.getDataSetByName(DATASET.PROVINCES);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dataSetState[DATASET.PROVINCES]]);

  useEffect(() => {
    if (
      props.dataSetState[DATASET.MUNICIPALITIES] === undefined ||
      props.dataSetState[DATASET.MUNICIPALITIES].length === 0
    ) {
      props.getDataSetByName(DATASET.MUNICIPALITIES);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dataSetState[DATASET.MUNICIPALITIES]]);

  useEffect(() => {
    if (
      props.dataSetState[DATASET.ACADEMIC_DEGREE] === undefined ||
      props.dataSetState[DATASET.ACADEMIC_DEGREE].length === 0
    ) {
      props.getDataSetByName(DATASET.ACADEMIC_DEGREE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dataSetState[DATASET.ACADEMIC_DEGREE]]);

  //#endregion

  const mapOpportunityCandidateStateToTableChipColors = (stateKey) => {
    switch (stateKey) {
      case OPPORTUNITY_CANDIDATE_STATE_KEY.APROVADA:
        return TABLE_STATE_CHIP_COLOR.GREEN;
      case OPPORTUNITY_CANDIDATE_STATE_KEY.REJEITADA:
        return TABLE_STATE_CHIP_COLOR.RED;
      case OPPORTUNITY_CANDIDATE_STATE_KEY.EMRECRUTAMENTO:
        return TABLE_STATE_CHIP_COLOR.LIGHT_ORANGE;
      case OPPORTUNITY_CANDIDATE_STATE_KEY.DESISTENCIA:
        return TABLE_STATE_CHIP_COLOR.BLUE;
      default:
        return <></>;
    }
  };

  return (
    <>
      <Container maxWidth={false}>
        <Card className={classes.cardRoot}>
          <CardHeader
            classes={{ title: classes.cardHeader, action: classes.cardHeaderAction, root: styles.cardRoot }}
            title={"Dados do Candidato"}
            action={
              <Grid container>
                <Grid item alignItems="center">
                  <Box height="100%" display="flex" alignItems="center" gridGap={12}>
                    <Typography variant="caption">
                      Criado por: <strong>{data?.audit?.createdBy}</strong> -{" "}
                      {moment.utc(data?.audit?.created).format("DD/MM/YYYY HH:mm")}
                    </Typography>

                    {data?.audit?.lastModifiedBy && (
                      <Typography variant="caption">
                        Atualizado por: <strong>{data?.audit?.lastModifiedBy}</strong> -{" "}
                        {moment.utc(data?.audit?.lastModified).format("DD/MM/YYYY HH:mm")}
                      </Typography>
                    )}
                  </Box>
                </Grid>
                <Grid item>
                  <TableStateChip
                    state={mapOpportunityCandidateStateToTableChipColors(
                      props.opportunitiesState?.candidateInfo?.opportunityCandidateStateKey
                    )}
                    message={props.opportunitiesState?.candidateInfo?.opportunityCandidateStateDescription}
                  />
                </Grid>
              </Grid>
            }
          />
          <CardContent>
            <Grid container>
              <DisplayField xs={12} sm={4} md={2} label="Nome" data={data?.name} />
              <DisplayField xs={12} sm={4} md={2} label="Sobrenome" data={data?.userSingular?.lastName} />
              <DisplayField
                xs={12}
                sm={4}
                md={2}
                label="Data de Nascimento"
                data={moment(data?.userSingular?.dateOfBirth).format("DD/MM/YYYY")}
              />
              <DisplayField xs={12} sm={4} md={2} label="Nº BI" data={data?.userSingular?.identityCard} />
              <DisplayField
                xs={12}
                sm={4}
                md={3}
                label="Género"
                data={
                  props.candidateInfo !== undefined &&
                  props.candidateInfo.length !== 0 &&
                  props.dataSetState[DATASET.GENDER] !== undefined
                    ? props.dataSetState[DATASET.GENDER].find((r) => r.code === data?.userSingular?.genderId)?.label
                    : ""
                }
              />

              {/* New Line md */}
              <DisplayField
                xs={12}
                sm={4}
                md={2}
                label="Estado Civil"
                data={
                  props.candidateInfo !== undefined &&
                  props.candidateInfo.length !== 0 &&
                  props.dataSetState[DATASET.MARITAL_STATUS] !== undefined
                    ? props.dataSetState[DATASET.MARITAL_STATUS].find(
                        (r) => r.code === data?.userSingular?.maritalStatusId
                      )?.label
                    : ""
                }
              />
              <DisplayField
                xs={12}
                sm={4}
                md={2}
                label="Nacionalidade"
                data={
                  props.candidateInfo !== undefined &&
                  props.candidateInfo.length !== 0 &&
                  props.dataSetState[DATASET.NATIONALITIES] !== undefined
                    ? props.dataSetState[DATASET.NATIONALITIES].find(
                        (r) => r.code === data?.userSingular?.nationalityId
                      )?.label
                    : ""
                }
              />
              <DisplayField
                xs={12}
                sm={4}
                md={2}
                label="Província"
                data={
                  props.candidateInfo !== undefined &&
                  props.candidateInfo.length !== 0 &&
                  props.dataSetState[DATASET.PROVINCES] !== undefined
                    ? props.dataSetState[DATASET.PROVINCES].find(
                        (r) => r.code === data?.userSingular?.compositeAddress?.provinceId
                      )?.label
                    : ""
                }
              />
              <DisplayField
                xs={12}
                sm={4}
                md={2}
                label="Município"
                data={
                  props.candidateInfo !== undefined &&
                  props.candidateInfo.length !== 0 &&
                  props.dataSetState[DATASET.MUNICIPALITIES] !== undefined
                    ? props.dataSetState[DATASET.MUNICIPALITIES].find(
                        (r) => r.code === data?.userSingular?.compositeAddress?.municipalityId
                      )?.label
                    : ""
                }
              />
              <DisplayField
                xs={12}
                sm={4}
                md={3}
                label="Bairro"
                data={data?.userSingular?.compositeAddress?.district}
              />
              <DisplayField
                xs={12}
                sm={4}
                md={2}
                label="Endereço"
                data={data?.userSingular?.compositeAddress?.address}
              />
              <DisplayField
                xs={12}
                sm={4}
                md={2}
                label={
                  <>
                    NISS
                    <Tooltip title="Número de Identificação da Segurança Social">
                      <HelpIcon className={classes.tooltipInfoIcon} />
                    </Tooltip>
                  </>
                }
                data={data?.userSingular?.NISS}
              />
              <DisplayField xs={12} sm={4} md={2} label="Telemóvel" data={data?.mobilePhone} />
              <DisplayField xs={12} sm={4} md={2} label="E-mail" data={data?.email} />
              <DisplayField
                xs={12}
                sm={4}
                md={2}
                label="Idade"
                data={moment().diff(data?.userSingular?.dateOfBirth, "years") + " anos"}
              />
            </Grid>
          </CardContent>
        </Card>
      </Container>
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getDataSetByName: (name, addEmptyField) => dispatch(getDataSetByNameAction(name, addEmptyField)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CandidateGeneralDetails);
