import React, { useRef, useEffect } from "react";
import { connect } from "react-redux";
import { Container } from "@material-ui/core";

//import custom components
import FollowUpsListSection from "views/admin/UserOverview/UserColective/Sections/FollowupsListSection";
import ApplicationsListSection from "views/admin/UserOverview/UserColective/Sections/ApplicationsListSection";
import useScrollTo from "hooks/useScrollTo";

function ApplicationTab(props) {
  const scrollTo = useScrollTo;
  const applicationsListSection = useRef(null);

  useEffect(() => {
    if(props.goToTopOfPage){
      scrollTo()
    }
    
    if(props.selectedTab === 'internshipTab' || props.selectedTab === 'employmentTab'){
      scrollTo(applicationsListSection)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Container maxWidth={false}>
        <FollowUpsListSection {...props} />
        <div ref={applicationsListSection}>
          <ApplicationsListSection {...props}/>
        </div>
      </Container>
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

export default connect(mapStateToProps)(ApplicationTab);
